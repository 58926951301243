// @flow
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Form, Table, DatePicker, Select, Typography, InputNumber } from "antd";
import { Pagination } from "antd";
import { Row, Col, PageHeader } from "antd";
import _ from "lodash/array";

import PropTypes from "prop-types";
import accounting from "accounting";

import moment from "moment";
import "moment/locale/ru";

import SelectMultiFetch from "../../../components/SelectMultiFetch";
import Details from "./Details";

const { Title } = Typography;
const { RangePicker } = DatePicker;
const Option = Select.Option;
const FormItem = Form.Item;

class DataList extends Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  render() {
    const {
      height,
      threshold,
      loading,
      tickets,
      fetchParams,
      period,
      onChangeDateRange,
      onChangeRole,
      onChangeCustomers,
      onChangeDepartments,
      // details
      isLoadingDetails,
      onDetails,
      details,
    } = this.props;

    // render: (effectiveness, record) => `${Number(record.closed / record.total * 100).toFixed(2)}%`,

    const dateFormat = "DD.MM.YYYY HH:mm";
    const { getFieldDecorator } = this.props.form;

    const columns = [
      {
        title: "Имя",
        dataIndex: "name",
        fixed: "left",
        align: "left",
        width: 250,
      },
      {
        title: "Заявки",
        children: [
          {
            title: "Выполнено",
            dataIndex: "tickets_finished",
            render: (tickets_finished, record) => (
              <div style={{ display: "flex", flexDirection: "column" }}>
                {tickets_finished > 0 ? (
                  <a>
                    <Details
                      loading={isLoadingDetails}
                      value={tickets_finished}
                      data={details}
                      path={"tickets"}
                      onChange={onDetails.bind(this, record.id, "1")}
                    />
                  </a>
                ) : (
                  ""
                )}
              </div>
            ),
            align: "center",
            width: 120,
          },
          {
            title: "В работе",
            dataIndex: "tickets_progress",
            render: (tickets_progress, record) => (
              <div style={{ display: "flex", flexDirection: "column" }}>
                {tickets_progress > 0 ? (
                  <a>
                    <Details
                      loading={isLoadingDetails}
                      value={tickets_progress}
                      data={details}
                      path={"tickets"}
                      onChange={onDetails.bind(this, record.id, "2")}
                    />
                  </a>
                ) : (
                  ""
                )}
              </div>
            ),
            align: "center",
            width: 120,
          },
          {
            title: "0-3 дня",
            dataIndex: "tickets_3days",
            render: (tickets_3days, record) => (
              <div style={{ display: "flex", flexDirection: "column" }}>
                {tickets_3days > 0 ? (
                  <a>
                    <Details
                      loading={isLoadingDetails}
                      value={tickets_3days}
                      data={details}
                      path={"tickets"}
                      onChange={onDetails.bind(this, record.id, "3")}
                    />
                  </a>
                ) : (
                  ""
                )}
              </div>
            ),
            align: "center",
            width: 120,
          },
          {
            title: "3-7 дня",
            dataIndex: "tickets_7days",
            render: (tickets_7days, record) => (
              <div style={{ display: "flex", flexDirection: "column" }}>
                {tickets_7days > 0 ? (
                  <a>
                    <Details
                      loading={isLoadingDetails}
                      value={tickets_7days}
                      data={details}
                      path={"tickets"}
                      onChange={onDetails.bind(this, record.id, "4")}
                    />
                  </a>
                ) : (
                  ""
                )}
              </div>
            ),
            align: "center",
            width: 120,
          },
          {
            title: "> 7 дней",
            dataIndex: "tickets_restdays",
            render: (tickets_restdays, record) => (
              <div style={{ display: "flex", flexDirection: "column" }}>
                {tickets_restdays > 0 ? (
                  <a>
                    <Details
                      loading={isLoadingDetails}
                      value={tickets_restdays}
                      data={details}
                      path={"tickets"}
                      onChange={onDetails.bind(this, record.id, "5")}
                    />
                  </a>
                ) : (
                  ""
                )}
              </div>
            ),
            align: "center",
            width: 120,
          },
        ],
      },
      {
        title: "Заявки c заменой",
        children: [
          {
            title: "Создано",
            dataIndex: "replaced_tickets_created",
            render: (replaced_tickets_created, record) => (
              <div style={{ display: "flex", flexDirection: "column" }}>
                {replaced_tickets_created > 0 ? (
                  <a>
                    <Details
                      loading={isLoadingDetails}
                      value={replaced_tickets_created}
                      data={details}
                      path={"tickets"}
                      onChange={onDetails.bind(this, record.id, "6")}
                    />
                  </a>
                ) : (
                  ""
                )}
              </div>
            ),
            align: "center",
            width: 120,
          },
          {
            title: "Согласовано",
            dataIndex: "replaced_tickets_approved",
            render: (replaced_tickets_approved, record) => (
              <div style={{ display: "flex", flexDirection: "column" }}>
                {replaced_tickets_approved > 0 ? (
                  <a>
                    <Details
                      loading={isLoadingDetails}
                      value={replaced_tickets_approved}
                      data={details}
                      path={"tickets"}
                      onChange={onDetails.bind(this, record.id, "7")}
                    />
                  </a>
                ) : (
                  ""
                )}
              </div>
            ),
            align: "center",
            width: 120,
          },
          {
            title: "Согласование замены",
            dataIndex: "replaced_tickets_approving",
            render: (replaced_tickets_approving, record) => (
              <div style={{ display: "flex", flexDirection: "column" }}>
                {replaced_tickets_approving > 0 ? (
                  <a>
                    <Details
                      loading={isLoadingDetails}
                      value={replaced_tickets_approving}
                      data={details}
                      path={"tickets"}
                      onChange={onDetails.bind(this, record.id, "77")}
                    />
                  </a>
                ) : (
                  ""
                )}
              </div>
            ),
            align: "center",
            width: 120,
          },
          {
            title: "В работе",
            dataIndex: "replaced_tickets_progress",
            render: (replaced_tickets_progress, record) => (
              <div style={{ display: "flex", flexDirection: "column" }}>
                {replaced_tickets_progress > 0 ? (
                  <a>
                    <Details
                      loading={isLoadingDetails}
                      value={replaced_tickets_progress}
                      data={details}
                      path={"tickets"}
                      onChange={onDetails.bind(this, record.id, "8")}
                    />
                  </a>
                ) : (
                  ""
                )}
              </div>
            ),
            align: "center",
            width: 120,
          },
        ],
      },
      {
        title: "Задачи по заказам",
        children: [
          {
            title: "Выполнено",
            dataIndex: "order_tasks_finished",
            render: (order_tasks_finished, record) => (
              <div style={{ display: "flex", flexDirection: "column" }}>
                {order_tasks_finished > 0 ? (
                  <a>
                    <Details
                      loading={isLoadingDetails}
                      value={order_tasks_finished}
                      data={details}
                      path={"order_tasks"}
                      onChange={onDetails.bind(this, record.id, "9")}
                    />
                  </a>
                ) : (
                  ""
                )}
              </div>
            ),
            align: "center",
            width: 120,
          },
          {
            title: "В работе",
            dataIndex: "order_tasks_progress",
            render: (order_tasks_progress, record) => (
              <div style={{ display: "flex", flexDirection: "column" }}>
                {order_tasks_progress > 0 ? (
                  <a>
                    <Details
                      loading={isLoadingDetails}
                      value={order_tasks_progress}
                      data={details}
                      path={"order_tasks"}
                      onChange={onDetails.bind(this, record.id, "10")}
                    />
                  </a>
                ) : (
                  ""
                )}
              </div>
            ),
            align: "center",
            width: 120,
          },
          {
            title: "Просрочено",
            dataIndex: "order_tasks_overdue",
            render: (order_tasks_overdue, record) => (
              <div style={{ display: "flex", flexDirection: "column" }}>
                {order_tasks_overdue > 0 ? (
                  <a>
                    <Details
                      loading={isLoadingDetails}
                      value={order_tasks_overdue}
                      data={details}
                      path={"order_tasks"}
                      onChange={onDetails.bind(this, record.id, "11")}
                    />
                  </a>
                ) : (
                  ""
                )}
              </div>
            ),
            align: "center",
            width: 120,
          },
        ],
      },
    ];

    return (
      <PageHeader
        onBack={() => window.history.back()}
        title={`Ежедневный отчет на ${fetchParams.endDate}`}
        footer={
          <Row gutter={{ md: 8, lg: 24, xl: 48 }} style={{ paddingBottom: 15 }}>
            <Col span={24}>
              <Table
                bordered
                size={"middle"}
                loading={loading}
                pagination={false}
                columns={columns}
                dataSource={tickets}
                scroll={{ x: 1300, y: height - 310 }}
                // scroll={{ x: 1200, y: height - 410 }}
                rowKey="id"
              />
            </Col>
          </Row>
        }
      >
        <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
          <Col xs={12} sm={12} md={12} lg={8} xl={8}>
            <RangePicker
              style={{ width: "100%" }}
              value={[
                moment(fetchParams.startDate, dateFormat),
                moment(fetchParams.endDate, dateFormat),
              ]}
              format={dateFormat}
              onChange={onChangeDateRange}
              showTime={{
                hideDisabledOptions: true,
                defaultValue: [
                  moment("00:00:00", "HH:mm:ss"),
                  moment("23:59:59", "HH:mm:ss"),
                ],
              }}
            />
          </Col>
          <Col xs={12} sm={12} md={12} lg={6} xl={6}>
            <Select
              style={{ width: "100%" }}
              value={fetchParams.role}
              placeholder="Роль"
              filterOption={false}
              onChange={onChangeRole}
            >
              <Option value={3}>инженер</Option>
              <Option value={4}>менеджер</Option>
            </Select>
          </Col>
          <Col xs={24} sm={24} md={24} lg={10} xl={10}>
            <SelectMultiFetch
              showSearch
              placeholder="Подразделение"
              onChange={onChangeDepartments}
              selected={fetchParams.departments ? fetchParams.departments : []}
              url={"/departments/search/?"}
            />
          </Col>
          {/* <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <SelectMultiFetch
              showSearch
              placeholder="Клиенты"
              onChange={onChangeCustomers}
              selected={fetchParams.customers ? fetchParams.customers : []}
              url={"/customers/search/?'"}
            />
          </Col> */}
        </Row>
      </PageHeader>
    );
  }
}

DataList.propTypes = {
  payments: PropTypes.arrayOf(PropTypes.object),
  meta: PropTypes.object,
  fetchParams: PropTypes.object,
};

export default Form.create()(DataList);
