// @flow
import React, { Component } from "react";
import {
  Row,
  Col,
  Modal,
  Select,
  Button,
  Form,
  Input,
  Upload,
  List,
  Avatar,
  Popover,
  Tooltip,
  Divider,
} from "antd";

import SelectFetch from "../../components/SelectFetch";

const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

function selectIcon(contentType) {
  switch (contentType) {
    case "application/pdf":
      return "file-pdf";
    case "image/png":
      return "file-image";
    case "image/jpeg":
      return "file-image";
    case "application/msword":
      return "file-word";
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      return "file-word";
    case "application/vnd.ms-excel":
      return "file-excel";
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      return "file-excel";
    default:
      return "file-unknown";
  }
}

function selectColor(contentType) {
  switch (contentType) {
    case "application/pdf":
      return "red";
    case "image/png":
      return "brown";
    case "image/jpeg":
      return "brown";
    case "application/msword":
      return "cornflowerblue";
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      return "cornflowerblue";
    case "application/vnd.ms-excel":
      return "green";
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      return "green";
    default:
      return "brown";
  }
}

class SupportMessage extends React.Component {
  onSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }
      this.props.onSave(values);
    });
  };

  beforeUpload = (file) => {
    this.setState((state) => ({
      file: file,
    }));
  };

  onChange = (info) => {
    getBase64(info.file.originFileObj, (imageUrl) => {
      this.props.onUploadAttachment(imageUrl);
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      visible,
      onCancel,
      message,
      onChangeFeedbackType,
      onChangeSubject,
      onChangeDescription,
      // attachments
      isUploading,
      onUploadAttachment,
      onDeleteAttachment,
    } = this.props;
    return (
      <Modal
        style={{ top: 20 }}
        title={"Сообщение в службу поддержки"}
        destroyOnClose
        visible={visible}
        onOk={this.onSubmit}
        onCancel={onCancel}
      >
        <Form layout={"vertical"}>
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col span={24}>
              <FormItem label={"Тип"}>
                {getFieldDecorator("support_message_type", {
                  initialValue: message.support_message_type ? message.support_message_type : null,
                  rules: [
                    {
                      required: true,
                      message: "Введите тип!",
                    },
                  ],
                })(
                  <SelectFetch
                    showSearch
                    showSubtitle
                    placeholder="Тип"
                    onChange={onChangeFeedbackType}
                    selected={message.support_message_type ? message.support_message_type : null}
                    url={"/support_message_types/search/?"}
                  />
                )}
              </FormItem>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <FormItem label={"Тема"}>
                {getFieldDecorator("subject", {
                  onChange: onChangeSubject,
                  initialValue: message.subject,
                  rules: [
                    {
                      required: true,
                      message: "Введите тему!",
                    },
                  ],
                })(<TextArea autoSize={{ minRows: 1, maxRows: 6 }} />)}
              </FormItem>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <FormItem label={"Описание"}>
                {getFieldDecorator("Описание", {
                  onChange: onChangeDescription,
                  initialValue: message.description,
                  rules: [
                    {
                      required: true,
                      message: "Введите описание!",
                    },
                  ],
                })(<TextArea autoSize={{ minRows: 3, maxRows: 6 }} />)}
              </FormItem>
            </Col>
          </Row>
          <Row
            gutter={{ md: 8, lg: 24, xl: 48 }}
            style={{ marginBottom: "10px" }}
          >
            <Divider>
              Файлы{" "}
              <Tooltip placement="bottom" title={"Загрузить файл"}>
                <Upload
                  customRequest={onUploadAttachment}
                  showUploadList={false}
                >
                  <Button type="dashed" shape="circle" icon="plus" />
                </Upload>
              </Tooltip>
            </Divider>
            <Col span={24}>
              <List
                loading={isUploading}
                dataSource={message.attachments}
                renderItem={(item, idx) => (
                  <List.Item
                    actions={[
                      <Tooltip placement="bottom" title={"Удалить"}>
                        <Button
                          type="dashed"
                          shape="circle"
                          icon="close"
                          onClick={onDeleteAttachment.bind(this, idx)}
                        />
                      </Tooltip>,
                    ]}
                  >
                    <List.Item.Meta
                      avatar={
                        <Avatar
                          style={{
                            backgroundColor: "transparent",
                            color: selectColor(item.type),
                          }}
                          icon={selectIcon(item.type)}
                          size="large"
                        />
                      }
                      title={item.name}
                      description={`${item.size} kB`}
                    />
                  </List.Item>
                )}
              />
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
}

export default Form.create()(SupportMessage);
