// @flow
import React, { Component } from "react";
import { Row, Col, Descriptions, Badge, Avatar } from "antd";
import moment from "moment";
import "moment/locale/ru";

function statuses(status) {
  switch (status) {
    case "pended":
      return "error";
    case "accepted":
      return "processing";
    case "closed":
      return "success";
    default:
      return "default";
  }
}

class ExpandDescription extends Component {
  render() {
    const {
      created_at,
      priority_locale,
      incident_locale,
      description
    } = this.props;

    return (
      <Descriptions title="Описание заявки" bordered>
        <Descriptions.Item label="Дата создания" span={3}>
         {moment(created_at).format("LLL")}
        </Descriptions.Item>
        <Descriptions.Item label="Описание" span={3}>
          {description}
        </Descriptions.Item>
      </Descriptions>
    );
  }
}

export default ExpandDescription;
