// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import List from "./List";
import { Modal } from "antd";
import moment from "moment";
import "moment/locale/ru";
import ModalForm from "../../Faqs/Modal";

class Faqs extends Component {
  state = { visible: false };

  onOpenFaq = (id) => {
    this.setState(
      {
        visible: true,
      },
      () => {
        this.props.fetchFaq(id);
      }
    );
  };

  onOk = (e) => {
    this.setState({
      visible: false,
    });
  };

  onCancel = (e) => {
    this.setState({
      visible: false,
    });
  };

  render() {
    const { loading, isFaqLoading, faqs, faq } = this.props;

    return (
      <React.Fragment>
        <List loading={loading} faqs={faqs} onOpenFaq={this.onOpenFaq} />

        <ModalForm
          faq={faq}
          loading={isFaqLoading}
          visible={this.state.visible}
          onOk={this.onOk}
          onCancel={this.onCancel}
        />
      </React.Fragment>
    );
  }
}
Faqs.propTypes = {
  faqs: PropTypes.arrayOf(PropTypes.object),
};

export default Faqs;
