// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, Button, Form, Card, Tabs } from "antd";
import Header from "./Header";

const TabPane = Tabs.TabPane;

class CustomerForm extends Component {
  onSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onSave(values);
      }
    });
  };

  render() {
    const { isLoading, customer, onClose, onChangeName } = this.props;
    const { getFieldDecorator } = this.props.form;

    return (
      <Card
        loading={isLoading}
        title={
          <h2
            style={{ marginBottom: 0 }}
          >{`Организация #${`${customer.name}`}`}</h2>
        }
        extra={
          <div>
            <Button onClick={onClose}>Закрыть</Button>
          </div>
        }
      >
        <Form layout={"vertical"}>
          <Col xs={24} sm={24} md={24} lg={14} xl={14}>
            <Row>
              <Header members={customer.members} />
            </Row>
          </Col>
        </Form>
      </Card>
    );
  }
}

CustomerForm.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  onSave: PropTypes.func,
  isLoading: PropTypes.bool,
  router: PropTypes.object,
  getFieldDecorator: PropTypes.func,
  form: PropTypes.object,
  onChangeName: PropTypes.func
};

export default Form.create()(CustomerForm);
