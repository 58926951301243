import update from "immutability-helper";

const initialState = {
  tickets: [],
  organisation: null,
  employees: [],
  employeeRates: [],
  memberRates: [],
  statistic: { count: 0, comments: 0, rate: 0 },
  details: [],
  points: [],
  meta: {
    total_pages: 1,
    current_page: 1,
    total_count: 1,
  },
  errors: false,
  isLoading: true,
  isLoadingDetails: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "FETCH_REPORT_PLAN_REQUEST":
      return {
        ...state,
        tickets: [],
        meta: {
          total_pages: 1,
          current_page: 1,
          total_count: 1,
        },
        totals: {},
        isLoading: true,
        errors: false,
      };
    case "FETCH_REPORT_PLAN":
      return {
        ...state,
        tickets: action.response.data,
        isLoading: false,
        errors: false,
      };

    case "FETCH_REPORT_PLAN_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true,
      };

    case "FETCH_REPORT_DAILY_REQUEST":
      return {
        ...state,
        tickets: [],
        meta: {
          total_pages: 1,
          current_page: 1,
          total_count: 1,
        },
        totals: {},
        isLoading: true,
        errors: false,
      };
    case "FETCH_REPORT_DAILY":
      return {
        ...state,
        tickets: action.response.data,
        isLoading: false,
        errors: false,
      };

    case "FETCH_REPORT_DAILY_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true,
      };

    case "FETCH_DAILY_DETAILS_REQUEST":
      return {
        ...state,
        details: [],
        isLoadingDetails: true,
        errors: false,
      };
    case "FETCH_DAILY_DETAILS":
      return {
        ...state,
        details: action.response.data,
        isLoadingDetails: false,
        errors: false,
      };

    case "FETCH_DAILY_DETAILS_FAILURE":
      return {
        ...state,
        isLoadingDetails: false,
        errors: true,
      };

    case "FETCH_REPORT_ORGANISATION_REQUEST":
      return {
        ...state,
        organisation: null,
        employees: [],
        isLoadind: true,
        errors: false,
      };
    case "FETCH_REPORT_ORGANISATION":
      return {
        ...state,
        organisation: action.response.data.organisation,
        employees: action.response.data.departments,
        isLoadind: false,
        errors: false,
      };

    case "FETCH_REPORT_ORGANISATION_FAILURE":
      return {
        ...state,
        isLoadind: false,
        errors: true,
      };

    case "FETCH_ORGANISATION_DETAILS_REQUEST":
      return {
        ...state,
        details: [],
        isLoadingDetails: true,
        errors: false,
      };
    case "FETCH_ORGANISATION_DETAILS":
      return {
        ...state,
        details: action.response.data,
        isLoadingDetails: false,
        errors: false,
      };

    case "FETCH_ORGANISATION_DETAILS_FAILURE":
      return {
        ...state,
        isLoadingDetails: false,
        errors: true,
      };

    case "FETCH_ENTITIES_MAP_REQUEST":
      return {
        ...state,
        points: [],
        isLoading: true,
        errors: false,
      };
    case "FETCH_ENTITIES_MAP":
      return {
        ...state,
        points: action.response.points,
        isLoading: false,
        errors: false,
      };

    case "FETCH_ENTITIES_MAP_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true,
      };

    case "FETCH_EMPLOYEE_RATES_REQUEST":
      return {
        ...state,
        employeeRates: [],
        memberRates: [],
        statistic: { count: 0, comments: 0, rate: 0 },
        isLoading: true,
        errors: false,
      };
    case "FETCH_EMPLOYEE_RATES":
      return {
        ...state,
        employeeRates: action.response.data,
        memberRates: action.response.members,
        statistic: action.response.statistic,
        details: [],
        isLoading: false,
        errors: false,
      };

    case "FETCH_EMPLOYEE_RATES_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true,
      };

    case "FETCH_EMPLOYEE_RATE_DETAILS_REQUEST":
      return {
        ...state,
        details: [],
        isLoadingDetails: true,
        errors: false,
      };
    case "FETCH_EMPLOYEE_RATE_DETAILS":
      return {
        ...state,
        details: action.response.data,
        isLoadingDetails: false,
        errors: false,
      };

    case "FETCH_EMPLOYEE_RATE_DETAILS_FAILURE":
      return {
        ...state,
        isLoadingDetails: false,
        errors: true,
      };

    default:
      return state;
  }
}
