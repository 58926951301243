// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Collapse, Modal, Input, Form, Row, Col } from "antd";
import moment from "moment";
import "moment/locale/ru";
import update from "immutability-helper";
import TodoItems from "./TodoItems";

const FormItem = Form.Item;
const { Panel } = Collapse;
const { TextArea } = Input;

class Todos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      todoItem: {},
      entityTaskTodoIdx: null,
      idx: null,
    };
  }
  callback = (key) => {
    // console.log(key);
  };

  onOpenComment = (item, entityTaskTodoIdx, idx) => {
    this.setState({
      visible: true,
      todoItem: item,
      entityTaskTodoIdx: entityTaskTodoIdx,
      idx: idx,
    });
  };

  onCloseComment = (e) => {
    this.setState({
      visible: false,
    });
  };

  onSaveComment = () => {
    const { todoItem, entityTaskTodoIdx, idx } = this.state;
    this.setState(
      {
        visible: false,
      },
      () => {
        this.props.onSaveComment(
          todoItem.entity_task_todo_id,
          todoItem.id,
          entityTaskTodoIdx,
          idx,
          todoItem.comment
        );
      }
    );
  };

  onChangeComment = (e) => {
    this.setState({
      todoItem: update(this.state.todoItem, {
        comment: { $set: e.target.value },
      }),
    });
  };

  render() {
    const { visible, todoItem } = this.state;
    const { getFieldDecorator } = this.props.form;
    const { isTodoLoading, entityTaskTodos } = this.props;
    return (
      <React.Fragment>
        <Collapse onChange={this.callback}>
          {entityTaskTodos.map((entityTaskTodo, idx) => {
            const completedTodos = entityTaskTodo.entity_task_todo_items.filter(
              function (item) {
                return item.completed_at;
              }
            );
            const entityTaskTodos = entityTaskTodo.entity_task_todo_items;
            return (
              <Panel
                header={`${entityTaskTodo.todo_template.name} (${completedTodos.length}/${entityTaskTodos.length})`}
                key={entityTaskTodo.id}
              >
                <TodoItems
                  entityTaskTodoIdx={idx}
                  isTodoLoading={isTodoLoading}
                  entityTaskTodoItems={entityTaskTodos}
                  onOpenComment={this.onOpenComment}
                />
              </Panel>
            );
          })}
        </Collapse>
        <Modal
          destroyOnClose
          title={todoItem.todo_item ? todoItem.todo_item.name : null}
          visible={visible}
          onOk={this.onSaveComment}
          onCancel={this.onCloseComment}
        >
          <Form layout={"vertical"}>
            <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
              <Col span={24}>
                <FormItem label={"Комментарий"}>
                  {getFieldDecorator("comment", {
                    onChange: this.onChangeComment,
                    initialValue: todoItem.comment ? todoItem.comment : null,
                    rules: [
                      {
                        required: true,
                        message: "Введите комментарий!",
                      },
                    ],
                  })(<TextArea rows={4} />)}
                </FormItem>
              </Col>
            </Row>
          </Form>
        </Modal>
      </React.Fragment>
    );
  }
}

Todos.propTypes = {
  entityTask: PropTypes.object,
  onChangeDate: PropTypes.func,
  onChangeAssignee: PropTypes.func,
  onChangeAgreement: PropTypes.func,
  onChangeAgreementDate: PropTypes.func,
  onChangeAdditional: PropTypes.func,
  onChangeComment: PropTypes.func,
  onChangeNotice: PropTypes.func,
};

export default Form.create()(Todos);
