// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Card, Row, Col, Form, Descriptions } from "antd";
import Assignees from "./Assignees";
import Documents from "../../../components/Documents";
import moment from "moment";
import "moment/locale/ru";
import SelectFetch from "../../../components/SelectFetch";

const FormItem = Form.Item;

class Statuses extends Component {
  render() {
    const {
      currentMember,
      due_by,
      start,
      finish,
      // assignees
      isAssigneesLoading,
      organisationAssignees,
      customerAssignees,
      onCreateAssignee,
      onDeleteAssignee,
      // statuses
      getFieldDecorator,
      taskId,
      status,
      statusUrl,
      onChangeStatus,
      // attachments
      attachments
    } = this.props;

    let isStatusVisible = false;
    var index = customerAssignees.findIndex(function(o) {
      return o.member.id == currentMember.member_id;
    });

    if (index != -1) {
      isStatusVisible = true;
    }

    return (
      <Card style={{ borderColor: "#1890ff" }}>
        {isStatusVisible ? (
          <Row gutter={10}>
            <Col span={24}>
              <FormItem label={"Статус"}>
                {getFieldDecorator("status", {
                  initialValue: status ? status : null
                })(
                  <SelectFetch
                    placeholder="Статус"
                    onChange={onChangeStatus}
                    selected={status ? status : null}
                    url={`/order_tasks/${taskId}/${statusUrl}`}
                  />
                )}
              </FormItem>
            </Col>
          </Row>
        ) : null}

        <Row gutter={16}>
          <Descriptions bordered>
            <Descriptions.Item label="Плановые дата и время" span={3}>
              {due_by ? moment(due_by).format("lll") : null}
            </Descriptions.Item>
            <Descriptions.Item label="Начало выполнения работ" span={3}>
              {start ? moment(start).format("lll") : "не начались"}
            </Descriptions.Item>
            <Descriptions.Item label="Окончание выполнения работ" span={3}>
              {start ? moment(finish).format("lll") : "не закончены"}
            </Descriptions.Item>
          </Descriptions>
        </Row>

        <Row gutter={10}>
          <Col span={24}>
            <Assignees
              isReadOnly={true}
              isAssigneesLoading={isAssigneesLoading}
              assigneeType="organisation"
              assignees={organisationAssignees}
            />
          </Col>
        </Row>
        <Row gutter={10}>
          <Col span={24}>
            <Assignees
              title={"От заказчика"}
              isReadOnly={false}
              isAssigneesLoading={isAssigneesLoading}
              assigneeType="customer"
              assignees={customerAssignees}
              onCreateAssignee={onCreateAssignee}
              onDeleteAssignee={onDeleteAssignee}
            />
          </Col>
        </Row>
        <Row gutter={10}>
          <Documents
            isReadOnly={true}
            currentUser={currentMember}
            isUploading={false}
            attachments={attachments}
            onUploadAttachment={false}
            onDeleteAttachment={null}
          />
        </Row>
      </Card>
    );
  }
}
Statuses.propTypes = {
  customer: PropTypes.object,
  getFieldDecorator: PropTypes.func,
  onAdressEdit: PropTypes.func,
  onChangePaymentType: PropTypes.func,
  onChangePaymentStatus: PropTypes.func,
  onChangeShippingStatus: PropTypes.func,
  onChangeShippingMethod: PropTypes.func
};

export default Statuses;
