import api from "../api";

const Actions = {};

Actions.fetchCurrentMember = () => {
  return (dispatch, getState) => {
    dispatch({ type: "FETCH_CURRENT_MEMBER_REQUEST" });
    const currentMemberID = getState().session.currentMember.member_id;
    api
      .fetch(`/members/${currentMemberID}`)
      .then(response => {
        dispatch({ type: "FETCH_CURRENT_MEMBER", response });
      })
      .catch(response => {
        dispatch({ type: "FETCH_CURRENT_MEMBER_FAILURE", response });
      });
  };
};

Actions.onUpdate = () => {
  return (dispatch, getState) => {
    dispatch({ type: "PATCH_CURRENT_MEMBER_REQUEST" });
    const currentMember = getState().currentMember.currentMember;
    const data = {
      member: {
        email: currentMember.email,
        name: currentMember.name,
        member_profile_attributes: {
          id: currentMember.member_profile.id,
          notify_comment_settings:
            currentMember.member_profile.notify_comment_settings
        }
      }
    };
    api
      .patch(`/members/${currentMember.id}`, data)
      .then(response => {
        dispatch({ type: "PATCH_CURRENT_MEMBER", response });
        api.openNotification("success", "Пользователь", "Успешно обновлен!");
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            return api.openNotification("error", "Пользователь", error);
          });
        }
        dispatch({ type: "PATCH_CURRENT_MEMBER_FAILURE", response });
      });
  };
};

Actions.onUpdateCurrentMemberPassword = () => {
  return (dispatch, getState) => {
    dispatch({ type: "PATCH_PASSWORD_CURRENT_MEMBER_REQUEST" });
    const currentMember = getState().currentMember.currentMember;
    const data = {
      member: {
        password: currentMember.password,
        password_confirmation: currentMember.password_confirmation
      }
    };
    api
      .patch(`/members/${currentMember.id}/update_password`, data)
      .then(response => {
        dispatch({ type: "PATCH_PASSWORD_CURRENT_MEMBER", response });
        api.openNotification("success", "Пароль", "Успешно обновлен!");
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            return api.openNotification("error", "Пароль", error);
          });
        }
        dispatch({ type: "PATCH_PASSWORD_CURRENT_MEMBER_FAILURE", response });
      });
  };
};

Actions.onChangeCurrentMemberName = value => {
  return dispatch => {
    dispatch({
      type: "CURRENT_MEMBER_CHANGE_NAME",
      value: value
    });
  };
};

Actions.onChangeCurrentMemberEmail = value => {
  return dispatch => {
    dispatch({
      type: "CURRENT_MEMBER_CHANGE_EMAIL",
      value: value
    });
  };
};

Actions.onChangeCurrentMemberPassword = value => {
  return dispatch => {
    dispatch({
      type: "CURRENT_MEMBER_CHANGE_PASSWORD",
      value: value
    });
  };
};

Actions.onChangeCurrentMemberPasswordConfirmation = value => {
  return dispatch => {
    dispatch({
      type: "CURRENT_MEMBER_CHANGE_PASSWORD_CONFIRMATION",
      value: value
    });
  };
};

// notifications
Actions.onChangeNotifyTickets = value => {
  return dispatch => {
    dispatch({
      type: "CURRENT_MEMBER_CHANGE_NOTIFY_COMMENT_SETTINGS_TICKETS",
      value: value
    });
  };
};

Actions.onChangeNotifyEntityTasks = value => {
  return dispatch => {
    dispatch({
      type: "CURRENT_MEMBER_CHANGE_NOTIFY_COMMENT_SETTINGS_ENTITY_TASKS",
      value: value
    });
  };
};

Actions.onChangeNotifyOrders = value => {
  return dispatch => {
    dispatch({
      type: "CURRENT_MEMBER_CHANGE_NOTIFY_COMMENT_SETTINGS_ORDERS",
      value: value
    });
  };
};

Actions.onChangeNotifyOrderTasks = value => {
  return dispatch => {
    dispatch({
      type: "CURRENT_MEMBER_CHANGE_NOTIFY_COMMENT_SETTINGS_ORDER_TASKS",
      value: value
    });
  };
};

Actions.onChangeNotifyProjects = (value) => {
  return (dispatch) => {
    dispatch({
      type: "CURRENT_MEMBER_CHANGE_NOTIFY_COMMENT_SETTINGS_PROJECTS",
      value: value,
    });
  };
};

export default Actions;
