// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Col, Row, Descriptions } from "antd";
import moment from "moment";
import "moment/locale/ru";

class General extends Component {
  constructor(props, context) {
    super(props, context);
  }
  render() {
    const { entity } = this.props;

    return (
      <Row gutter={16}>
        <Col span={24}>
          <Descriptions bordered>
            <Descriptions.Item label="Наименование" span={3}>
              {entity ? entity.name : null}
            </Descriptions.Item>
            <Descriptions.Item label="Тип объекта" span={3}>
              {entity.entity_type ? entity.entity_type.name : null}
            </Descriptions.Item>
            <Descriptions.Item label="Дата принятия" span={3}>
              {entity ? moment(entity.start).format("LLL") : null}
            </Descriptions.Item>
            <Descriptions.Item label="Дата окончания" span={3}>
              {entity ? moment(entity.finish).format("LLL") : null}
            </Descriptions.Item>
            <Descriptions.Item label="Адрес" span={3}>
              {entity ? entity.address : null}
            </Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
    );
  }
}

General.propTypes = {
  entity: PropTypes.object,
  onChangeDate: PropTypes.func,
  onChangeAssignee: PropTypes.func,
  onChangeAgreement: PropTypes.func,
  onChangeAgreementDate: PropTypes.func,
  onChangeAdditional: PropTypes.func,
  onChangeComment: PropTypes.func,
  onChangeNotice: PropTypes.func,
};

export default General;
