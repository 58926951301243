import api from "../api";
import _ from "lodash/array";

const Actions = {};

Actions.fetchProjects = () => {
  return (dispatch, getState) => {
    dispatch({ type: "FETCH_PROJECTS_REQUEST" });
    let users = [];
    let members = [];
    let params = getState().projects.fetchParams;
    if (params.users) {
      users = params.users.map((user) => {
        if (user && user.id) {
          return user.id;
        }
      });
    }
    if (params.members) {
      members = params.members.map((member) => {
        if (member && member.id) {
          return member.id;
        }
      });
    }

    const data = {
      startDate: params.startDate,
      endDate: params.endDate,
      startFinish: params.startFinish,
      endFinish: params.endFinish,
      search: params.search,
      sort: params.sort,
      direction: params.direction,
      page: params.page,
      limit: params.limit,
      status: params.status,
      users: users,
      members: members
    };

    // console.log(params)
    return api
      .fetch("/projects", data)
      .then((response) => {
        dispatch({ type: "FETCH_PROJECTS", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_PROJECTS_FAILURE", response });
      });
  };
};

Actions.fetchProject = (id = null) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_PROJECT_REQUEST" });
    return api
      .fetch(`/projects/${id}`)
      .then((response) => {
        dispatch({ type: "FETCH_PROJECT", response });
      })
      .catch((error) => {
        api.handleError(error, "Проект");
        dispatch({ type: "FETCH_PROJECT_FAILURE" });
      });
  };
};

Actions.onNew = () => {
  return (dispatch, getState) => {
    const currentUser = getState().session.currentUser;
    dispatch({
      type: "PROJECT_NEW",
      user: currentUser,
    });
  };
};

Actions.onUpdate = (id) => {
  return (dispatch, getState) => {
    dispatch({ type: "PATCH_PROJECT_REQUEST" });
    const project = getState().projects.project;
    const data = {
      project: {
        status: project.status,
        number: project.number,
        customer_id: project.customer ? project.customer.id : null,
        entity_id: project.entity ? project.entity.id : null,
        project_type_id: project.project_type ? project.project_type.id : null,
        amount: project.amount,
        description: project.description,
      },
    };

    api
      .patch(`/projects/${id}`, data)
      .then((response) => {
        dispatch({ type: "PATCH_PROJECT", response });
        api.openNotification("success", "Проект", "Успешно обновлен!");
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Проект", error);
          });
        }
        dispatch({ type: "PATCH_PROJECT_FAILURE", response });
      });
  };
};

Actions.onCreate = (project) => {
  return (dispatch, getState) => {
    dispatch({ type: "POST_PROJECT_REQUEST" });

    const project_stages = project.project_stages.map((item) => {
      return {
        project_stage_id: item.project_stage ? item.project_stage.id : null,
        position: item.position,
        description: item.description,
        plan_start: item.plan_start,
        plan_finish: item.plan_finish,
        _destroy: false,
      };
    });

    const project_assignees = project.assignees.map((assignee) => {
      return {
        assignee_type: assignee.assignee_type,
        user_id: assignee.assignee_type == "organisation" ? assignee.id : null,
        member_id:
          assignee.assignee_type == "organisation" ? null : assignee.id,
      };
    });

    const data = {
      project: {
        status: project.status,
        number: project.number,
        customer_id: project.customer ? project.customer.id : null,
        entity_id: project.entity ? project.entity.id : null,
        project_type_id: project.project_type ? project.project_type.id : null,
        amount: project.amount,
        description: project.description,
        project_stage_items_attributes: project_stages,
        project_assignees_attributes: project_assignees,
      },
    };

    return api
      .post(`/projects`, data)
      .then((response) => {
        dispatch({ type: "POST_PROJECT", response });
        api.openNotification("success", "Проект", "Успешно создан!");
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Проект", error);
          });
        }
        dispatch({ type: "POST_PROJECT_FAILURE", response });
      });
  };
};

Actions.onDelete = (id) => {
  return (dispatch) => {
    dispatch({ type: "DELETE_PROJECT_REQUEST" });
    return api
      .delete(`/projects/${id}`)
      .then((response) => {
        api.openNotification("success", "Проект", "Успешно удален!");
        dispatch({ type: "DELETE_PROJECT", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Проект", error);
          });
        }
        dispatch({
          type: "DELETE_PROJECT_FAILURE",
        });
      });
  };
};

// assignees
Actions.fetchAssignees = (id) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_ASSIGNEES_REQUEST" });
    api
      .fetch(`/projects/${id}/project_assignees`)
      .then((response) => {
        dispatch({ type: "FETCH_ASSIGNEES", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_ASSIGNEES_FAILURE", response });
      });
  };
};

Actions.onCreateAssignee = (id, assignee, type) => {
  return (dispatch) => {
    dispatch({ type: "POST_ASSIGNEE_REQUEST" });
    return api
      .post(`/projects/${id}/project_assignees`, {
        project_assignee: {
          assignee_type: type,
          user_id: type == "organisation" ? assignee.id : null,
          member_id: type == "organisation" ? null : assignee.id,
        },
      })
      .then((response) => {
        dispatch({ type: "POST_ASSIGNEE", response });
        api.openNotification("success", "Ответственный", "Успешно добавлен!");
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Ответственный", error);
          });
        }
        dispatch({ type: "POST_ASSIGNEE_FAILURE", response });
      });
  };
};

Actions.onDeleteAssignee = (project_id, id) => {
  return (dispatch) => {
    dispatch({
      type: "ASSIGNEE_DELETE_REQUEST",
    });
    return api
      .delete(`/projects/${project_id}/project_assignees/${id}`)
      .then((response) => {
        api.openNotification("success", "Ответственный", "Успешно удален!");
        dispatch({ type: "ASSIGNEE_DELETE_SUCCEED", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Ответственный", error);
          });
        }
        dispatch({ type: "ASSIGNEE_DELETE_FAILED" });
      });
  };
};

// comments
Actions.fetchProjectComments = (id) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_PROJECT_COMMENTS_REQUEST" });
    api
      .fetch(`/projects/${id}/project_comments`)
      .then((response) => {
        dispatch({ type: "FETCH_PROJECT_COMMENTS", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Статус", error);
          });
        }
        dispatch({ type: "FETCH_PROJECT_COMMENTS_FAILURE", response });
      });
  };
};

Actions.onCreateComment = (id, value) => {
  return (dispatch) => {
    dispatch({ type: "POST_PROJECT_COMMENT_REQUEST" });
    return api
      .post(`/projects/${id}/project_comments`, {
        project_comment: { comment: value },
      })
      .then((response) => {
        dispatch({ type: "POST_PROJECT_COMMENT", response });
        api.openNotification("success", "Комментарий", "Успешно создан!");
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Комментарий", error);
          });
        }
        dispatch({ type: "POST_PROJECT_COMMENT_FAILURE", response });
      });
  };
};

// attachments
Actions.fetchAttachments = (id) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_PROJECT_ATTACHMENTS_REQUEST" });
    api
      .fetch(`/projects/${id}/project_attachments`)
      .then((response) => {
        // console.log(response)
        dispatch({ type: "FETCH_PROJECT_ATTACHMENTS", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_PROJECT_ATTACHMENTS_FAILURE", response });
      });
  };
};

Actions.onUploadAttachment = (id, file) => {
  return (dispatch) => {
    dispatch({
      type: "ATTACHMENT_UPLOAD",
    });
    // console.log(file);
    const formData = new FormData();

    formData.append("content_type", file.type);
    formData.append("attachment", file);
    return api
      .postFormData(`/projects/${id}/project_attachments`, formData)
      .then((response) => {
        api.openNotification("success", "Файл", "Успешно загружен!");
        dispatch({ type: "ATTACHMENT_UPLOAD_SUCCEED", response });
      })
      .catch((response) => {
        dispatch({
          type: "ATTACHMENT_UPLOAD_FAILED",
        });
        dispatch({
          type: "ADD_NOTIFICATION",
          message: { label: response.error, type: "error" },
        });
      });
  };
};

Actions.onDeleteAttachment = (entity_task_id, id) => {
  return (dispatch) => {
    dispatch({
      type: "ATTACHMENT_DELETE_REQUEST",
    });
    return api
      .delete(`/projects/${entity_task_id}/project_attachments/${id}`)
      .then((response) => {
        api.openNotification("success", "Файл", "Успешно удален!");
        dispatch({ type: "ATTACHMENT_DELETE_SUCCEED", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Файл", error);
          });
        }
        dispatch({ type: "ATTACHMENT_DELETE_FAILED" });
      });
  };
};

// activities
Actions.fetchProjectActivities = (id) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_PROJECT_ACTIVITIES_REQUEST" });
    return api
      .fetch(`/projects/${id}/project_activities`)
      .then((response) => {
        dispatch({ type: "FETCH_PROJECT_ACTIVITIES", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Заявка", error);
          });
        }
        dispatch({
          type: "FETCH_PROJECT_ACTIVITIES_FAILURE",
        });
      });
  };
};


Actions.onSearch = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_PROJECT_SEARCH",
      value: value,
    });
    return Promise.resolve();
  };
};

//header
Actions.onChangeNumber = (value) => {
  return (dispatch) => {
    dispatch({
      type: "PROJECT_CHANGE_NUMBER",
      value: value,
    });
  };
};

Actions.onChangeCustomer = (value) => {
  return (dispatch) => {
    dispatch({
      type: "PROJECT_CHANGE_CUSTOMER",
      value: value,
    });
  };
};

Actions.onChangeEntity = (value) => {
  return (dispatch) => {
    dispatch({
      type: "PROJECT_CHANGE_CUSTOMER_ENTITY",
      value: value,
    });
  };
};

Actions.onChangeProjectType = (value) => {
  return (dispatch) => {
    dispatch({
      type: "PROJECT_CHANGE_PROJECT_TYPE",
      value: value,
    });
  };
};

Actions.onChangeAmount = (value) => {
  return (dispatch) => {
    dispatch({
      type: "PROJECT_CHANGE_AMOUNT",
      value: value,
    });
  };
};

Actions.onChangeSubject = (value) => {
  return (dispatch) => {
    dispatch({
      type: "PROJECT_CHANGE_SUBJECT",
      value: value,
    });
  };
};

Actions.onChangeDescription = (value) => {
  return (dispatch) => {
    dispatch({
      type: "PROJECT_CHANGE_DESCRIPTION",
      value: value,
    });
  };
};

Actions.onChangeStatus = (value) => {
  return (dispatch) => {
    dispatch({
      type: "PROJECT_CHANGE_STATUS",
      value: value,
    });
  };
};

// stages
Actions.fetchProjectStages = (id) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_PROJECT_STAGES_REQUEST" });
    return api
      .fetch(`/projects/${id}/project_stage_items`)
      .then((response) => {
        dispatch({ type: "FETCH_PROJECT_STAGES", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Проект", error);
          });
        }
        dispatch({
          type: "FETCH_PROJECT_STAGES_FAILURE",
        });
      });
  };
};

Actions.onUpdateProjectStage = (id, stage) => {
  return (dispatch) => {
    dispatch({ type: "PATCH_PROJECT_STAGE_REQUEST" });
    const project_stage_costs = stage.project_stage_costs.map((item) => {
      return {
        id: item.is_exist ? item.id : null,
        name: item.name,
        cost: item.cost,
        cost_plan: item.cost_plan,
        _destroy: item._destroy,
      };
    });
    const data = {
      project_stage_item: {
        project_stage_id: stage.project_stage.id,
        position: stage.position,
        description: stage.description,
        start: stage.start,
        finish: stage.finish,
        plan_start: stage.plan_start,
        plan_finish: stage.plan_finish,
        complete: stage.complete,
        project_stage_costs_attributes: project_stage_costs,
      },
    };
    return api
      .patch(`/projects/${id}/project_stage_items/${stage.id}`, data)
      .then((response) => {
        dispatch({ type: "PATCH_PROJECT_STAGE", response });
        api.openNotification("success", "Этап", "Успешно обновлен!");
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Этап", error);
          });
        }
        dispatch({ type: "PATCH_PROJECT_STAGE_FAILURE", response });
      });
  };
};

Actions.onCreateProjectStage = (id, stage) => {
  return (dispatch) => {
    dispatch({ type: "POST_PROJECT_SCHEDULE_REQUEST" });
    const project_stage_costs = stage.project_stage_costs.map((item) => {
      return {
        id: item.is_exist ? item.id : null,
        name: item.name,
        cost: item.cost,
        _destroy: item._destroy,
      };
    });
    const data = {
      project_stage_item: {
        project_stage_id: stage.project_stage ? stage.project_stage.id : null,
        position: stage.position,
        description: stage.description,
        start: stage.start,
        finish: stage.finish,
        plan_start: stage.plan_start,
        plan_finish: stage.plan_finish,
        complete: stage.complete,
        project_stage_costs_attributes: project_stage_costs,
      },
    };
    return api
      .post(`/projects/${id}/project_stage_items`, data)
      .then((response) => {
        dispatch({ type: "POST_PROJECT_STAGE", response });
        api.openNotification("success", "Этап", "Успешно добавлен!");
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Этап", error);
          });
        }
        dispatch({ type: "POST_PROJECT_STAGE_FAILURE", response });
      });
  };
};

Actions.onDeleteProjectStages = (id, ids) => {
  return (dispatch) => {
    dispatch({ type: "DELETE_PROJECT_STAGES_REQUEST" });
    return api
      .patch(`/projects/${id}/project_stage_items/delete`, {
        project_stage_item: { ids: ids },
      })
      .then((response) => {
        api.openNotification("success", "Этап", "Строки успешно удалены!");
        dispatch({ type: "DELETE_PROJECT_STAGES_SUCCEED", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Этап", error);
          });
        }
        dispatch({
          type: "DELETE_PROJECT_STAGES_FAILURE",
        });
      });
  };
};

// filters
Actions.onSetFilters = () => {
  return (dispatch) => {
    dispatch({
      type: "PROJECTS_SET_FILTERS",
    });
    return Promise.resolve();
  };
};

Actions.onClearFilters = () => {
  return (dispatch) => {
    dispatch({
      type: "PROJECTS_CLEAR_FILTERS",
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterStatus = (values) => {
  return (dispatch) => {
    dispatch({
      type: "PROJECTS_FILTER_STATUS",
      values: values,
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterPeriod = (startDate, endDate) => {
  return (dispatch) => {
    dispatch({
      type: "PROJECTS_FILTER_PERIOD",
      startDate: startDate,
      endDate: endDate,
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterPeriodFinish = (startFinish, endFinish) => {
  return (dispatch) => {
    dispatch({
      type: "PROJECTS_FILTER_PERIOD_FINISH",
      startFinish: startFinish,
      endFinish: endFinish,
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterUsers = (values) => {
  return (dispatch) => {
    dispatch({
      type: "PROJECTS_FILTER_USERS",
      values: values,
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterMembers = (values) => {
  return (dispatch) => {
    dispatch({
      type: "PROJECTS_FILTER_MEMBERS",
      values: values,
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterCustomer = (value) => {
  return (dispatch) => {
    dispatch({
      type: "PROJECTS_FILTER_CUSTOMER",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onChangePage = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_PROJECT_CHANGE_PAGE",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onChangeLimit = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_PROJECT_CHANGE_LIMIT",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onSortBy = (field, direction) => {
  return (dispatch) => {
    dispatch({
      type: "PROJECTS_SORT_BY",
      field: field,
      direction: direction,
    });
    return Promise.resolve();
  };
};

// notices
Actions.onClearNotices = () => {
  return (dispatch) => {
    dispatch({ type: "POST_PROJECT_MARK_ALL_AS_READ_REQUEST" });

    return api
      .post(`/project_comment_marks/mark_all_as_read`)
      .then((response) => {
        api.openNotification(
          "success",
          "Проекты",
          "Все комментарии отмечены как прочитанные!"
        );
        dispatch({ type: "POST_PROJECT_MARK_ALL_AS_READ", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Проекты", error);
          });
        }
        dispatch({
          type: "POST_PROJECT_MARK_ALL_AS_READ_FAILURE",
        });
      });
  };
};

export default Actions;
