import update from "immutability-helper";

const initialState = {
  users: [],
  user: {},
  meta: {
    total_pages: 1,
    current_page: 1,
    total_count: 1
  },
  fetchParams: {
    active: true,
    search: null,
    sort: null,
    direction: null,
    page: 1,
    limit: 15
  },
  errors: false,
  isLoading: true
};

export default function(state = initialState, action) {
  switch (action.type) {
    case "FETCH_USERS_REQUEST":
      return {
        ...state,
        isLoading: true
      };
    case "FETCH_USERS":
      return {
        ...state,
        users: action.response.data,
        meta: action.response.meta,
        isLoading: false
      };
    case "FETCH_USER_REQUEST":
      return {
        ...state,
        isLoading: true
      };
    case "FETCH_USER":
      return {
        ...state,
        user: action.response.data,
        isLoading: false
      };
    case "PATCH_USER_REQUEST":
      return {
        ...state,
        isLoading: true,
        errors: false
      };
    case "PATCH_USER":
      return {
        ...state,
        user: action.response.data,
        isLoading: false,
        errors: false
      };

    case "PATCH_USER_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true
      };

    case "POST_USER_REQUEST":
      return {
        ...state,
        errors: false,
        isLoading: true
      };
    case "POST_USER":
      return {
        ...state,
        errors: false,
        user: action.response.data,
        isLoading: false
      };

    case "POST_USER_FAILURE":
      return {
        ...state,
        errors: true,
        isLoading: false
      };

    case "DELETE_USER_REQUEST":
      return {
        ...state,
        isLoading: true,
        errors: false
      };
    case "DELETE_USER":
      return {
        ...state,
        users: action.response.data,
        isLoading: false,
        errors: false
      };
    case "DELETE_USER_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true
      };

    case "USER_NEW":
      return {
        ...state,
        errors: false,
        user: {
          active: true,
          role: "manager"
        },
        isLoading: false
      };

    case "PATCH_PASSWORD_USER_REQUEST":
      return {
        ...state,
        errors: false,
        isLoading: true
      };
    case "PATCH_PASSWORD_USER":
      return {
        ...state,
        errors: false,
        isLoading: false
      };
    case "PATCH_PASSWORD_USER_FAILURE":
      return {
        ...state,
        errors: true,
        isLoading: false
      };

    case "REQUEST_USER_SEARCH":
      return update(state, {
        fetchParams: { search: { $set: action.value }, page: { $set: 1 } }
      });

    case "REQUEST_USER_CHANGE_PAGE":
      return update(state, {
        fetchParams: { page: { $set: action.value } }
      });

    case "REQUEST_USER_CHANGE_LIMIT":
      return update(state, {
        fetchParams: { limit: { $set: action.value }, page: { $set: 1 } }
      });

    case "REQUEST_USERS_FILTER_ACTIVE":
      return update(state, {
        fetchParams: { active: { $set: action.value } }
      });

    case "USERS_SORT_BY":
      return update(state, {
        fetchParams: {
          sort: { $set: action.field },
          direction: { $set: action.direction }
        }
      });

    case "USER_CHANGE_ACTIVE":
      return update(state, {
        user: { active: { $set: action.value } }
      });

    case "USER_CHANGE_ROLE":
      return update(state, {
        user: { role: { $set: action.value } }
      });

    case "USER_CHANGE_NAME":
      return update(state, {
        user: { first_name: { $set: action.value } }
      });

    case "USER_CHANGE_MIDDLE_NAME":
      return update(state, {
        user: { middle_name: { $set: action.value } }
      });

    case "USER_CHANGE_LAST_NAME":
      return update(state, {
        user: { last_name: { $set: action.value } }
      });

    case "USER_CHANGE_EMAIL":
      return update(state, {
        user: { email: { $set: action.value } }
      });

    case "USER_CHANGE_PASSWORD":
      return update(state, {
        user: { password: { $set: action.value } }
      });

    case "USER_CHANGE_PASSWORD_CONFIRMATION":
      return update(state, {
        user: { password_confirmation: { $set: action.value } }
      });

    default:
      return state;
  }
}
