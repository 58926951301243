// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Actions from "../../../actions/orderTasks";
import { Spin } from "antd";
import Form from "./Form";
import moment from "moment";
import "moment/locale/ru";

class OrderTask extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNew: false,
    };
  }

  componentWillMount = () => {
    if (this.props.match.params.id) {
      this.props.dispatch(Actions.fetchOrderTask(this.props.match.params.id));
    } else {
      this.setState({ isNew: true }, () => {
        this.props.dispatch(Actions.onNew());
      });
    }
  };

  onClose = () => {
    this.props.history.push(`/order_tasks`);
  };

  // comments
  onCreateComment = (comment, mentioned) => {
    this.props.dispatch(Actions.onCreateComment(this.props.match.params.id, comment, mentioned))
      .then(() => {
        if (!this.props.errors) {
          this.props.dispatch(
            Actions.fetchOrderTaskComments(this.props.match.params.id)
          );
        }
      });
  };

  // assignees
  onCreateAssignee = (value, type) => {
    this.props
      .dispatch(
        Actions.onCreateAssignee(this.props.match.params.id, value, type)
      )
      .then(() => {
        if (!this.props.errors) {
          this.props.dispatch(
            Actions.fetchAssignees(this.props.match.params.id)
          );
        }
      });
  };

  onDeleteAssignee = (id) => {
    this.props
      .dispatch(Actions.onDeleteAssignee(this.props.match.params.id, id))
      .then(() => {
        if (!this.props.errors) {
          this.props.dispatch(
            Actions.fetchAssignees(this.props.match.params.id)
          );
        }
      });
  };

  // attachments
  onDeleteAttachment = (id) => {
    this.props
      .dispatch(Actions.onDeleteAttachment(this.props.match.params.id, id))
      .then(() => {
        if (!this.props.errors) {
          this.props.dispatch(
            Actions.fetchAttachments(this.props.match.params.id)
          );
        }
      });
  };

  onUploadAttachment = (file) => {
    this.props
      .dispatch(Actions.onUploadAttachment(this.props.match.params.id, file))
      .then(() => {
        if (!this.props.errors) {
          this.props.dispatch(
            Actions.fetchAttachments(this.props.match.params.id)
          );
        }
      });
  };

  // activities
  fetchOrderTasksActivities = () => {
    this.props.dispatch(
      Actions.fetchOrderTasksActivities(this.props.match.params.id)
    );
  };

  // statuses
  onChangeStatus = (value) => {
    this.props.dispatch(
      Actions.onChangeStatus(this.props.match.params.id, value.id)
    );
  };

  render() {
    const {
      isLoading,
      isUploading,
      isAssigneesLoading,
      orderTask,
      currentMember,
      isActivitiesLoading,
      taskActivities,
    } = this.props;
    return isLoading ? (
      <Spin />
    ) : (
      <Form
        isNew={this.state.isNew}
        currentMember={currentMember}
        orderTask={orderTask}
        onClose={this.onClose}
        // comments
        onCreateComment={this.onCreateComment}
        // assignees
        isAssigneesLoading={isAssigneesLoading}
        onCreateAssignee={this.onCreateAssignee}
        onDeleteAssignee={this.onDeleteAssignee}
        // attachments
        isUploading={isUploading}
        onDeleteAttachment={this.onDeleteAttachment}
        onUploadAttachment={this.onUploadAttachment}
        // activities
        isActivitiesLoading={isActivitiesLoading}
        taskActivities={taskActivities}
        fetchOrderTasksActivities={this.fetchOrderTasksActivities}
        // statuses
        onChangeStatus={this.onChangeStatus}
      />
    );
  }
}

OrderTask.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  orderTask: PropTypes.object,
  isLoading: PropTypes.bool,
  errors: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  currentMember: state.session.currentMember,
  isLoading: state.orderTasks.isLoading,
  isUploading: state.tickets.isUploading,
  errors: state.orderTasks.errors,
  orderTask: state.orderTasks.orderTask,
  // activities
  taskActivities: state.orderTasks.taskActivities,
  isActivitiesLoading: state.orderTasks.isActivitiesLoading,
  // assignees
  isAssigneesLoading: state.orderTasks.isAssigneesLoading,
});

export default connect(mapStateToProps)(withRouter(OrderTask));
