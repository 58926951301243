// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { List } from "antd";

class EntityTaskItems extends Component {
  constructor(props, context) {
    super(props, context);
  }

  render() {
    const { entityTaskItems } = this.props;

    return (
      <List
        itemLayout="horizontal"
        dataSource={entityTaskItems}
        renderItem={item => (
          <List.Item>
            <List.Item.Meta
              title={item.entity_service.service.name}
              description={item.entity_service.service.extra.category}
            />
          </List.Item>
        )}
      />
    );
  }
}

EntityTaskItems.propTypes = {
  entityTask: PropTypes.object,
  onChangeDate: PropTypes.func,
  onChangeAssignee: PropTypes.func,
  onChangeAgreement: PropTypes.func,
  onChangeAgreementDate: PropTypes.func,
  onChangeAdditional: PropTypes.func,
  onChangeComment: PropTypes.func,
  onChangeNotice: PropTypes.func
};

export default EntityTaskItems;
