import React, { Component } from "react";
import { Row, Col, Progress, Popover, Table } from "antd";
import moment from "moment";
import "moment/locale/ru";
import api from "../../api";

function progressInfo(complete) {
  return <Progress percent={Number(complete)} size="small" />;
}

export default class ProjectProgress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      fetching: true,
    };
  }
  
  fetchProjectProgress = async (id) => {
    const response = await api.fetch(`/projects/${id}/progress`);
    const data = response.data;
    this.setState({ data, fetching: false });
  };

  render() {
    const { fetching, data } = this.state;
    const { projectId, title } = this.props;


  return (
    <Popover
      placement="right"
      content={<ProgressInfo loading={fetching} data={data} />}
      title="Этапы"
      trigger="click"
      
    >
      <a href="" onClick={this.fetchProjectProgress.bind(this, projectId)} >{title}</a>
    </Popover>
  );
  }
}

function ProgressInfo({ loading = false, data = null }) {
  const columns = [
    {
      title: "Этап",
      dataIndex: "complete",
      render: (complete, record) => progressInfo(complete),
      key: "status",
      align: "center",
      width: "22%"
    },
    {
      title: "Описание",
      dataIndex: "project_stage",
      render: (project_stage, record) => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span>{project_stage ? project_stage.name : null}</span>
          <span style={{ opacity: "0.7" }}>{record.description}</span>
        </div>
      ),
      key: "project_stage",
      align: "center",
      width: "30%"
    },
    {
      title: "Начало (факт)",
      dataIndex: "start",
      render: start => (start ? moment(start).format("L") : null),
      key: "start",
      align: "center",
      width: "12%"
    },
    {
      title: "Окончание (факт)",
      dataIndex: "finish",
      render: finish => (finish ? moment(finish).format("L") : null),
      key: "finish",
      align: "center",
      width: "12%"
    },
    {
      title: "Начало (план)",
      dataIndex: "plan_start",
      render: plan_start => (plan_start ? moment(plan_start).format("L") : null),
      key: "plan_start",
      align: "center",
      width: "12%"
    },
    {
      title: "Окончание (план)",
      dataIndex: "plan_finish",
      render: plan_finish => (plan_finish ? moment(plan_finish).format("L") : null),
      key: "plan_finish",
      align: "center",
      width: "12%"
    }
  ];

  return (
    <div style={{ width: 940 }}>
      <Table
        rowClassName={"progress-row"}
        size={"small"}
        loading={loading}
        // showHeader={false}
        columns={columns}
        dataSource={data}
        pagination={false}
        rowKey="id"
      />
    </div>
  );
}
