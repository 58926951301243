// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, Card, Form, Input, Select } from "antd";
import Assignees from "../../../components/Assignees";
import User from "./User";
import Documents from "../../../components/Documents";
import SelectFetch from "../../../components/SelectFetch";

const FormItem = Form.Item;
const Option = Select.Option;

class Statuses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAddingAssignee: false,
      assignee: null,
    };
  }

  onUpload = ({ onSuccess, onError, file }) => {
    this.props.onUploadProposal(file);
  };

  render() {
    const {
      user,
      member,
      customer,
      organisationAssignees,
      customerAssignees,
      isAssigneesLoading,
      onCreateAssignee,
      onDeleteAssignee,
    } = this.props;

    return (
      <Card style={{ borderColor: "#1890ff" }}>
        <Row gutter={10}>
          <Col span={24}>
            <User user={user ? user : member} />
          </Col>
        </Row>
        <Row gutter={10}>
          <Col span={24}>
            <Assignees
              isReadOnly={true}
              title={"Ответственные по проекту"}
              isAssigneesLoading={isAssigneesLoading}
              assigneeType="organisation"
              customer={customer}
              assignees={organisationAssignees}
              onCreateAssignee={onCreateAssignee}
              onDeleteAssignee={onDeleteAssignee}
            />
          </Col>
        </Row>
        <Row gutter={10}>
          <Col span={24}>
            <Assignees
              isReadOnly={true}
              isAssigneesLoading={isAssigneesLoading}
              assigneeType="customer"
              customer={customer}
              assignees={customerAssignees}
              onCreateAssignee={onCreateAssignee}
              onDeleteAssignee={onDeleteAssignee}
            />
          </Col>
        </Row>
      </Card>
    );
  }
}
Statuses.propTypes = {
  entity: PropTypes.object,
  getFieldDecorator: PropTypes.func,
};

export default Statuses;
