// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import List from "./List";
import { Modal, Button } from "antd";
import moment from "moment";
import "moment/locale/ru";

class Posts extends Component {
  state = { visible: false };

  onOpenPost = id => {
    this.setState(
      {
        visible: true
      },
      () => {
        this.props.fetchPost(id);
      }
    );
  };

  handleOk = e => {
    this.setState({
      visible: false
    });
  };

  handleCancel = e => {
    this.setState({
      visible: false
    });
  };

  render() {
    const { isPostsLoading, fetchPost, posts, post } = this.props;

    return (
      <React.Fragment>
        <List
          loading={isPostsLoading}
          posts={posts}
          onOpenPost={this.onOpenPost}
        />
        <Modal
          title={`#${post.id} от ${moment(post.published_at).format("LLL")}`}
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <p>{post.title}</p>
          <div dangerouslySetInnerHTML={{ __html: post.body }}></div>
        </Modal>
      </React.Fragment>
    );
  }
}
Posts.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.object)
};

export default Posts;
