// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, Button, Form, Card, Tabs, Divider, Badge } from "antd";
import Statuses from "./Statuses";
import General from "./General";
import Comments from "./Comments";
import Activities from "./Activities";
import moment from "moment";
import "moment/locale/ru";

const { TabPane } = Tabs;

function statuses(status) {
  switch (status) {
    case "pending":
      return "error";
    case "processing":
      return "processing";
    case "finished":
      return "success";
    default:
      return "default";
  }
}

class CustomerForm extends Component {
  constructor(props) {
    super(props);
  }

  callback = key => {
    if (key == "2") {
      this.props.fetchOrderTasksActivities();
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      isNew,
      currentMember,
      errors,
      isLoading,
      isCommentsLoading,
      orderTask,
      onClose,
      // comments
      onCreateComment,
      // assignees
      isAssigneesLoading,
      onCreateAssignee,
      onDeleteAssignee,
      // attachments
      attachments,
      // activities
      isActivitiesLoading,
      taskActivities,
      // statuses
      onChangeStatus,
    } = this.props;

    const organisationAssignees = orderTask.assignees.filter(function (item) {
      return item.assignee_type == "organisation";
    });

    const customerAssignees = orderTask.assignees.filter(function (item) {
      return item.assignee_type == "customer";
    });

    return (
      <Form layout={"vertical"}>
        <Card
          loading={isLoading}
          title={
            <div>
              <h2
                style={{ marginBottom: 0 }}
              >{`Задача по заказу (${orderTask.task_type_locale}) #${orderTask.number}`}</h2>
              <span style={{ fontSize: "12px" }}>
                <Badge
                  status={statuses(
                    orderTask.status ? orderTask.status.id : null
                  )}
                  text={orderTask.status ? orderTask.status.name : null}
                />
              </span>
            </div>
          }
          extra={
            <div>
              <Button onClick={onClose}>Закрыть</Button>
            </div>
          }
        >
          <Col xs={24} sm={24} md={24} lg={13} xl={13}>
            <Row>
              <General
                entity={orderTask.entity}
                order={orderTask.order}
                customer={orderTask.customer}
                subject={orderTask.subject}
                description={orderTask.description}
              />
            </Row>
            <Divider>Комментарии</Divider>
            <Row>
              <Comments
                getFieldDecorator={getFieldDecorator}
                isCommentsLoading={isCommentsLoading}
                errors={errors}
                onCreateComment={onCreateComment}
                comments={orderTask.comments}
              />
            </Row>
          </Col>
          <Col xs={0} sm={0} md={1} lg={1} xl={1} />
          <Col xs={24} sm={24} md={24} lg={10} xl={10}>
            <Tabs defaultActiveKey="1" onChange={this.callback}>
              <TabPane tab="Статусы" key="1">
                <Statuses
                  currentMember={currentMember}
                  getFieldDecorator={getFieldDecorator}
                  due_by={orderTask.due_by}
                  start={orderTask.start}
                  finish={orderTask.finish}
                  isAssigneesLoading={isAssigneesLoading}
                  // assignees
                  isAssigneesLoading={isAssigneesLoading}
                  organisationAssignees={organisationAssignees}
                  customer={orderTask.customer}
                  customerAssignees={customerAssignees}
                  onCreateAssignee={onCreateAssignee}
                  onDeleteAssignee={onDeleteAssignee}
                  // attachments
                  attachments={orderTask.attachments}
                  // statuses
                  taskId={orderTask.id}
                  status={orderTask.status}
                  statusUrl={orderTask.status_url}
                  onChangeStatus={onChangeStatus}
                />
              </TabPane>
              <TabPane tab="Лог изменений" key="2">
                <Activities
                  taskActivities={taskActivities}
                  isActivitiesLoading={isActivitiesLoading}
                />
              </TabPane>
            </Tabs>
          </Col>
        </Card>
      </Form>
    );
  }
}

CustomerForm.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  orderTask: PropTypes.object,
  onSave: PropTypes.func,
  isLoading: PropTypes.bool,
  router: PropTypes.object,
  getFieldDecorator: PropTypes.func,
  form: PropTypes.object,
};

export default Form.create()(CustomerForm);
