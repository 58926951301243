// @flow
import React, { Component, Fragment } from "react";
import { Row, Col, Button, Card, Tag, Checkbox, Divider, Empty } from "antd";
import RBCalendar from "../../../components/RBCalendar";
import Info from "./Info";
import moment from "moment";
import "moment/locale/ru";

const { CheckableTag } = Tag;

class Calendar extends Component {
  
  onSelectEvent = (event) => {
    this.setState({selected: event})
    this.props.onSelectEvent(event.id, event.eventType)
  }

  render() {
    const {
      currentUser,
      onCalendarNavigate,
      isCalendarLoading,
      events,
      onChangeEventType,
      isEventLoading,
      selectedEvent,
      fetchParams
    } = this.props;

    const data = [];
    

    return (
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Card>
            <Empty description={<span>В разработке</span>}></Empty>
          </Card>
        </Col>
      </Row>
    );
  }
}

export default Calendar;
