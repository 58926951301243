// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Col, Row, DatePicker, Select, Form, Drawer } from "antd";
import moment from "moment";
import "moment/locale/ru";
import SelectMultiFetch from "../../../components/SelectMultiFetch";

const { RangePicker } = DatePicker;
const { Option, OptGroup } = Select;
const FormItem = Form.Item;

const commonStatuses = [
  { value: "pending", text: "новая" },
  { value: "processing", text: "принято в работу" },
  { value: "working", text: "приступил к выполнению" },
  { value: "creating", text: "создание ДА" },
  { value: "approving", text: "согласование замены" },
  { value: "approved", text: "замена согласована" },
  { value: "replacing", text: "приступил к замене" },
  { value: "paused", text: "приостановлена" },
  { value: "closed", text: "выполнена" },
  { value: "canceled", text: "отменена" },
];

const statuses = [
  { value: "pending", text: "новая" },
  { value: "processing", text: "принято в работу" },
  { value: "working", text: "приступил к выполнению" },
  { value: "paused", text: "приостановлена" },
  { value: "closed", text: "выполнена" },
  { value: "canceled", text: "отменена" },
];

const replacedStatuses = [
  { value: "pending", text: "новая" },
  { value: "processing", text: "принято в работу" },
  { value: "creating", text: "создание ДА" },
  { value: "approving", text: "согласование замены" },
  { value: "approved", text: "замена согласована" },
  { value: "replacing", text: "приступил к замене" },
  { value: "paused", text: "приостановлена" },
  { value: "closed", text: "выполнена" },
  { value: "canceled", text: "отменена" },
];

class Filter extends Component {
  onChangeUsers = (values) => {
    this.props.onChangeUsers(values);
  };

  onChangeMembers = (values) => {
    this.props.onChangeMembers(values);
  };

  onChangeOrderUsers = (values) => {
    this.props.onChangeOrderUsers(values);
  };

  render() {
    const {
      visible,
      status,
      isReplaced,
      isOrdered,
      isRated,
      startDate,
      endDate,
      startCreatedDate,
      endCreatedDate,
      users,
      members,
      priorities,
      onClose,
      onChangeStatus,
      onChangeReplaced,
      onChangeOrdered,
      onChangePeriod,
      onChangeCreatedPeriod,
      onChangePriorities,
      onChangeRated,
      downloadReport,
    } = this.props;
    const { getFieldDecorator } = this.props.form;

    const filtredStatuses =
      isReplaced == true
        ? replacedStatuses
        : isReplaced == false
        ? statuses
        : commonStatuses;

    return (
      <Drawer
        width={540}
        visible={visible}
        title="Фильтры"
        placement={"right"}
        closable={false}
        onClose={onClose}
      >
        <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <FormItem label={"Период (создание)"}>
              {getFieldDecorator("created", {
                onChange: onChangeCreatedPeriod,
                initialValue: [
                  startCreatedDate
                    ? moment(startCreatedDate, "DD.MM.YYYY")
                    : null,
                  endCreatedDate ? moment(endCreatedDate, "DD.MM.YYYY") : null,
                ],
              })(
                <RangePicker
                  showTime={false}
                  format={"DD.MM.YYYY"}
                  placeholder={["начало", "окончание"]}
                  style={{ width: "100%" }}
                />
              )}
            </FormItem>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <FormItem label={"Период"}>
              {getFieldDecorator("start", {
                onChange: onChangePeriod,
                initialValue: [
                  startDate ? moment(startDate, "DD.MM.YYYY") : null,
                  endDate ? moment(endDate, "DD.MM.YYYY") : null,
                ],
              })(
                <RangePicker
                  showTime={false}
                  format={"DD.MM.YYYY"}
                  placeholder={["начало", "окончание"]}
                  style={{ width: "100%" }}
                />
              )}
            </FormItem>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <FormItem label={"Ответственные исполнителя"}>
              {getFieldDecorator("users", {
                initialValue: users ? users : [],
              })(
                <SelectMultiFetch
                  showSearch
                  placeholder="Ответственные"
                  onChange={this.onChangeUsers}
                  selected={users ? users : []}
                  url={"/users/search/?"}
                />
              )}
            </FormItem>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <FormItem label={"Ответственные"}>
              {getFieldDecorator("members", {
                initialValue: members ? members : [],
              })(
                <SelectMultiFetch
                  showSearch
                  placeholder="Ответственные"
                  onChange={this.onChangeMembers}
                  selected={members ? members : []}
                  url={"/members/search/?"}
                />
              )}
            </FormItem>
          </Col>

          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <FormItem label={"Статус"}>
              {getFieldDecorator("status", {
                onChange: onChangeStatus,
                initialValue: status ? status : [],
              })(
                <Select
                  mode={"multiple"}
                  style={{ width: "100%" }}
                  placeholder="Статус"
                  optionFilterProp="children"
                  filterOption={false}
                >
                  {filtredStatuses.map((status, i) => (
                    <Option key={i} value={status.value}>
                      {status.text}
                    </Option>
                  ))}
                </Select>
              )}
            </FormItem>
          </Col>

          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <FormItem label={"Замена"}>
              {getFieldDecorator("isReplaced", {
                onChange: onChangeReplaced,
                initialValue: isReplaced,
              })(
                <Select
                  style={{ width: "100%" }}
                  placeholder="Замена"
                  optionFilterProp="children"
                  filterOption={false}
                >
                  <Option value={null}>все</Option>
                  <Option value={false}>без замены</Option>
                  <Option value={true}>с заменой</Option>
                </Select>
              )}
            </FormItem>
          </Col>

          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <FormItem label={"Перенос в заказ"}>
              {getFieldDecorator("isOrdered", {
                onChange: onChangeOrdered,
                initialValue: isOrdered,
              })(
                <Select
                  style={{ width: "100%" }}
                  placeholder="Перенос в заказ"
                  optionFilterProp="children"
                  filterOption={false}
                >
                  <Option value={null}>все</Option>
                  <Option value={false}>нет</Option>
                  <Option value={true}>да</Option>
                </Select>
              )}
            </FormItem>
          </Col>

          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <FormItem label={"Приоритет"}>
              {getFieldDecorator("priorities", {
                onChange: onChangePriorities,
                initialValue: priorities ? priorities : [],
              })(
                <Select
                  mode={"multiple"}
                  style={{ width: "100%" }}
                  placeholder="Приоритет"
                  optionFilterProp="children"
                  filterOption={false}
                >
                  <Option value={1}>низкий</Option>
                  <Option value={2}>средний</Option>
                  <Option value={3}>высокий</Option>
                  <Option value={4}>критичный</Option>
                </Select>
              )}
            </FormItem>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <FormItem label={"Рейтинг"}>
              {getFieldDecorator("isRated", {
                onChange: onChangeRated,
                initialValue: isRated,
              })(
                <Select
                  style={{ width: "100%" }}
                  placeholder="Рейтинг"
                  optionFilterProp="children"
                  filterOption={false}
                >
                  <Option value={null}>все</Option>
                  <Option value={false}>без оценки</Option>
                  <Option value={true}>с оценкой</Option>
                </Select>
              )}
            </FormItem>
          </Col>
        </Row>
      </Drawer>
    );
  }
}

Filter.propTypes = {
  entity: PropTypes.object,
};

export default Form.create()(Filter);
