// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Actions from "../../../actions/supportMessages";
import { Spin } from "antd";
import SupportMessageForm from "./Form";

class SupportMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNew: false
    };
  }

  componentWillMount = () => {
    // console.log(this.props.location);
    if (this.props.match.params.id) {
      this.props.dispatch(
        Actions.fetchSupportMessage(this.props.match.params.id)
      );
    } else {
      this.setState({ isNew: true }, () => {
        this.props.dispatch(Actions.onNew());
      });
    }
  };

  onSave = () => {
    if (this.props.match.params.id) {
      this.props.dispatch(Actions.onUpdate(this.props.match.params.id));
    } else {
      this.props.dispatch(Actions.onCreate()).then(() => {
        if (!this.props.errors) {
          this.props.history.push(
            `/support_message/${this.props.supportMessage.id}/edit`
          );
        }
      });
    }
  };

  onClose = () => {
    this.props.history.push(`/support_messages`);
  };

  // general
  onChangeSubject = e => {
    this.props.dispatch(Actions.onChangeSubject(e.target.value));
  };

  onChangeDescription = e => {
    this.props.dispatch(Actions.onChangeDescription(e.target.value));
  };

  // comments
  onCreateComment = value => {
    this.props
      .dispatch(Actions.onCreateComment(this.props.match.params.id, value))
      .then(() => {
        if (!this.props.errors) {
          this.props.dispatch(
            Actions.fetchSupportMessageComments(this.props.match.params.id)
          );
        }
      });
  };

  // assignees
  onCreateAssignee = (value, type) => {
    this.props
      .dispatch(Actions.onCreateAssignee(this.props.match.params.id, value, type))
      .then(() => {
        if (!this.props.errors) {
          this.props.dispatch(
            Actions.fetchAssignees(this.props.match.params.id)
          );
        }
      });
  };

  onDeleteAssignee = id => {
    this.props
      .dispatch(Actions.onDeleteAssignee(this.props.match.params.id, id))
      .then(() => {
        if (!this.props.errors) {
          this.props.dispatch(
            Actions.fetchAssignees(this.props.match.params.id)
          );
        }
      });
  };

  // statuses
  onChangeStatus = value => {
    this.props.dispatch(Actions.onChangeStatus(value));
  };

  onChangeSupportMessageType = value => {
    this.props.dispatch(Actions.onChangeSupportMessageType(value));
  };

  render() {
    const { isLoading, isAssigneesLoading, isCommentsLoading, supportMessage } = this.props;
    return isLoading ? (
      <Spin />
    ) : (
      <SupportMessageForm
        isNew={this.state.isNew}
        supportMessage={supportMessage}
        onSave={this.onSave}
        onClose={this.onClose}
        // general
        onChangeSubject={this.onCreateComment}
        onChangeDescription={this.onCreateComment}
        // comments
        isCommentsLoading={isCommentsLoading}
        onCreateComment={this.onCreateComment}
        // statuses
        onChangeStatus={this.onChangeStatus}
        onChangeSupportMessageType={this.onChangeSupportMessageType}
         // assignees
         isAssigneesLoading={isAssigneesLoading}
         onCreateAssignee={this.onCreateAssignee}
         onDeleteAssignee={this.onDeleteAssignee}
      />
    );
  }
}

SupportMessage.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  supportMessage: PropTypes.object,
  isLoading: PropTypes.bool,
  errors: PropTypes.bool
};

const mapStateToProps = state => ({
  isLoading: state.supportMessages.isLoading,
  isAssigneesLoading: state.supportMessages.isAssigneesLoading,
  errors: state.supportMessages.errors,
  supportMessage: state.supportMessages.supportMessage,
  isCommentsLoading: state.supportMessages.isCommentsLoading
});

export default connect(mapStateToProps)(withRouter(SupportMessage));
