import api from "../api";

const Actions = {};

Actions.fetchNotices = () => {
  return (dispatch, getState) => {
    dispatch({ type: "FETCH_NOTICES_REQUEST" });
    const params = getState().notices.fetchParams;
    let users = [];
    let members = [];
    if (params.users) {
      users = params.users.map((user) => {
        if (user && user.id) {
          return user.id;
        }
      });
    }

    if (params.members) {
      members = params.members.map((member) => {
        if (member && member.id) {
          return member.id;
        }
      });
    }

    const data = {
      startDate: params.startDate,
      endDate: params.endDate,
      search: params.search,
      sort: params.sort,
      direction: params.direction,
      page: params.page,
      limit: params.limit,
      types: params.types,
      users: users,
      members: members
    };

    return api
      .fetch("/notices", data)
      .then((response) => {
        // console.log(response)
        dispatch({ type: "FETCH_NOTICES", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_NOTICES_FAILURE", response });
      });
  };
};

Actions.fetchDashboardNotices = () => {
  return (dispatch, getState) => {
    dispatch({ type: "DASHBOARD_NOTICES_FETCH_REQUEST" });
    const data = getState().notices.fetchParams;
    return api
      .fetch("/dashboard/notices", data)
      .then((response) => {
        // console.log(response)
        dispatch({ type: "DASHBOARD_NOTICES_FETCH", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "DASHBOARD_NOTICES_FETCH_FAILURE", response });
      });
  };
};

Actions.fetchContextNotices = (path) => {
  return (dispatch, getState) => {
    dispatch({ type: "FETCH_NOTICES_REQUEST" });
    return api
      .fetch(`/${path}`)
      .then((response) => {
        // console.log(response)
        dispatch({ type: "FETCH_NOTICES_SUCCEED", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_NOTICES_FAILURE", response });
      });
  };
};

Actions.onClearNotices = (path) => {
  return (dispatch) => {
    dispatch({ type: "POST_NOTICES_CLEAR_REQUEST" });

    return api
      .post(`/${path}/mark_all_as_read`)
      .then((response) => {
        api.openNotification(
          "success",
          "Уведомления",
          "Все комментарии отмечены как прочитанные!"
        );
        dispatch({ type: "POST_NOTICES_CLEAR", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Заявка", error);
          });
        }
        dispatch({
          type: "POST_NOTICES_CLEAR_FAILURE",
        });
      });
  };
};

Actions.onChangePage = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_NOTICES_CHANGE_PAGE",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onChangeLimit = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_NOTICES_CHANGE_LIMIT",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onSortBy = (field, direction) => {
  return (dispatch) => {
    dispatch({
      type: "NOTICES_SORT_BY",
      field: field,
      direction: direction,
    });
    return Promise.resolve();
  };
};

// filters
Actions.onSetFilters = () => {
  return (dispatch) => {
    dispatch({
      type: "NOTICES_SET_FILTERS",
    });
    return Promise.resolve();
  };
};

Actions.onClearFilters = () => {
  return (dispatch) => {
    dispatch({
      type: "NOTICES_CLEAR_FILTERS",
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterPeriod = (startDate, endDate) => {
  return (dispatch) => {
    dispatch({
      type: "NOTICES_FILTER_PERIOD",
      startDate: startDate,
      endDate: endDate,
    });
    return Promise.resolve();
  };
};


Actions.onChangeFilterTypes = (values) => {
  return (dispatch) => {
    dispatch({
      type: "NOTICES_FILTER_TYPES",
      values: values,
    });
    return Promise.resolve();
  };
};


Actions.onChangeFilterUsers = (values) => {
  return (dispatch) => {
    dispatch({
      type: "NOTICES_FILTER_USERS",
      values: values,
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterMembers = (values) => {
  return (dispatch) => {
    dispatch({
      type: "NOTICES_FILTER_MEMBERS",
      values: values,
    });
    return Promise.resolve();
  };
};

export default Actions;
