// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Form, Input, InputNumber, Col, Row } from "antd";
import SelectFetch from "../../../components/SelectFetch";

const FormItem = Form.Item;
const { TextArea } = Input;

class General extends Component {
  constructor(props, context) {
    super(props, context);
  }
  render() {
    const {
      isNew,
      order,
      getFieldDecorator,
      onChangeEntity,
      onChangeEntityService,
      onChangeSubject,
      onChangeDescription,
    } = this.props;

    return (
      <div>
        <Row gutter={16}>
          <Col md={{ span: 12 }} xs={{ span: 12 }}>
            <FormItem label={"Объект"}>
              {getFieldDecorator("entity", {
                initialValue: order.entity ? order.entity : null,
                rules: [
                  {
                    required: true,
                    message: "Пожалуйста, введите объект!",
                  },
                ],
              })(
                <SelectFetch
                  showSearch
                  placeholder="Объект"
                  onChange={onChangeEntity}
                  selected={order.entity ? order.entity : null}
                  url={`/entities/search/?${
                    order.customer ? `customer_id=${order.customer.id}` : null
                  }`}
                />
              )}
            </FormItem>
          </Col>
          <Col md={{ span: 12 }} xs={{ span: 12 }}>
            <FormItem label={"Раздел"}>
              {getFieldDecorator("entity_service", {
                initialValue: order.entity_service
                  ? order.entity_service
                  : null,
                rules: [
                  {
                    required: true,
                    message: "Пожалуйста, введите раздел!",
                  },
                ],
              })(
                <SelectFetch
                  disabled={order.entity ? false : true}
                  showSearch
                  placeholder="Раздел"
                  onChange={onChangeEntityService}
                  selected={order.entity_service ? order.entity_service : null}
                  url={
                    order.entity
                      ? `/entities/${order.entity.id}/entity_services`
                      : null
                  }
                />
              )}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <FormItem label={"Тема"}>
              {getFieldDecorator("subject", {
                onChange: onChangeSubject,
                initialValue: order.subject,
                rules: [
                  {
                    required: true,
                    message: "Пожалуйста, введите тему!",
                  },
                ],
              })(<TextArea autosize={{ minRows: 1, maxRows: 6 }} />)}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <FormItem label={"Описание"}>
              {getFieldDecorator("Описание", {
                onChange: onChangeDescription,
                initialValue: order.description,
              })(<TextArea autosize={{ minRows: 2, maxRows: 6 }} />)}
            </FormItem>
          </Col>
        </Row>
      </div>
    );
  }
}

General.propTypes = {
  entity: PropTypes.object,
  onChangeDate: PropTypes.func,
  onChangeAssignee: PropTypes.func,
  onChangeAgreement: PropTypes.func,
  onChangeAgreementDate: PropTypes.func,
  onChangeAdditional: PropTypes.func,
  onChangeComment: PropTypes.func,
  onChangeNotice: PropTypes.func,
};

export default General;
