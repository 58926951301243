// @flow
import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Row, Col, Table, Button } from "antd";
import update from "immutability-helper";

import moment from "moment";
import "moment/locale/ru";


class Schedules extends Component {

  render() {
    const { schedules, isSchedulesLoading } = this.props;

    const columns = [
      {
        title: "Тип",
        dataIndex: "schedule_type_locale",
        width: "25%",
        align: "center"
      },
      {
        title: "Наименование",
        dataIndex: "name",
        render: (name, record) => (
          <a onClick={this.onEditSchedule.bind(this, record)}>{name}</a>
        ),
        width: "35%",
        align: "center"
      },
      {
        title: "Начало",
        dataIndex: "start",
        render: start => moment(start).format("LLL"),
        width: "20%",
        align: "center"
      },
      {
        title: "Окончание",
        dataIndex: "finish",
        render: finish => moment(finish).format("LLL"),
        width: "20%",
        align: "center"
      }
    ];

    return (
      <div>
        <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
          <Col span={24}>
            <Table
              loading={isSchedulesLoading}
              size={"middle"}
              bordered
              pagination={false}
              columns={columns}
              dataSource={schedules}
              rowKey="id"
            />
          </Col>
        </Row>
      </div>
    );
  }
}

Schedules.propTypes = {
  orderSchedules: PropTypes.arrayOf(PropTypes.object),
  total: PropTypes.number,
  discount: PropTypes.object,
  shippingCost: PropTypes.number,
  amountDiscountCode: PropTypes.number,
  onRemoveVariant: PropTypes.func
};
export default Schedules;
