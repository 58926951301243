import React from "react";
import { Avatar, List } from "antd";
import classNames from "classnames";
import "./NoticeList.less";
import moment from "moment";
import "moment/locale/ru";

export default function NoticeList({
  data = [],
  onClick,
  title,
  locale,
  emptyText,
  emptyImage,
  onViewMore = null,
  onCreate = null,
  showClear = true,
  showOnViewMore = false,
  showOnCreate = false
}) {
  if (data.length === 0) {
    return (
      <React.Fragment>
        <div className={"notFound"}>
          {emptyImage ? <img src={emptyImage} alt="not found" /> : null}
          <div>{emptyText || locale.emptyText}</div>
        </div>
        <div className={"notice-list bottomBar"}>
        {showOnViewMore ? (
          <div onClick={onViewMore}>
            {locale.viewMore} {locale[title] || title}
          </div>
        ) : null}
          {showOnCreate ? (
            <div onClick={onCreate}>{locale.create}</div>
          ) : null}
        </div>
      </React.Fragment>
    );
  }
  return (
    <div>
      <List className={"notice-list"}>
        {data.map((item, i) => {
          const itemCls = classNames("notice-list item", {
            ["notice-list read"]: item.read
          });
          // eslint-disable-next-line no-nested-ternary
          const leftIcon = item.avatar ? (
            typeof item.avatar === "string" ? (
              <Avatar className={"notice-list avatar"} src={item.avatar} />
            ) : (
              <span className={"notice-list iconElement"}>{item.avatar}</span>
            )
          ) : null;

          return (
            <List.Item
              className={itemCls}
              key={item.key || i}
              onClick={() => onClick(item)}
            >
              <List.Item.Meta
                className={"notice-list meta"}
                avatar={leftIcon}
                title={
                  <div className={"notice-list title"}>
                    {item.title}
                    <div className={"notice-list extra"}>{item.extra}</div>
                  </div>
                }
                description={
                  <div>
                    <div
                      className={"notice-list description"}
                      title={item.description}
                    >
                      {item.description}
                    </div>
                    <div className={"notice-list datetime"}>
                      {moment(item.datetime).format("LLL")}
                    </div>
                  </div>
                }
              />
            </List.Item>
          );
        })}
      </List>
      <div className={"notice-list bottomBar"}>
        {showOnViewMore ? (
          <div onClick={onViewMore}>
            {locale.viewMore} {locale[title] || title}
          </div>
        ) : null}
        {showOnCreate ? (
          <div onClick={onCreate}>{locale.create}</div>
        ) : null}
      </div>
    </div>
  );
}
