import api from "../api";

const Actions = {};

Actions.fetchSupportMessages = () => {
  return (dispatch, getState) => {
    dispatch({ type: "FETCH_SUPPORT_MESSAGES_REQUEST" });
    const params = getState().supportMessages.fetchParams;
    return api
      .fetch("/support_messages", params)
      .then(response => {
        dispatch({ type: "FETCH_SUPPORT_MESSAGES", response });
      })
      .catch(response => {
        console.log(response.error);
        dispatch({ type: "FETCH_SUPPORT_MESSAGES_FAILURE", response });
      });
  };
};

Actions.fetchSupportMessage = (id = null) => {
  return dispatch => {
    dispatch({ type: "FETCH_SUPPORT_MESSAGE_REQUEST" });
    return api
      .fetch(`/support_messages/${id}`)
      .then(response => {
        dispatch({ type: "FETCH_SUPPORT_MESSAGE", response });
      })
      .catch(response => {
        if (response.response.status == 404) {
          window.location = "/notfound";
        }
        if (response.errors) {
          response.errors.map(error => {
            return api.openNotification("error", "Клиент", error);
          });
        }
        dispatch({ type: "FETCH_SUPPORT_MESSAGE_FAILURE", response });
      });
  };
};

// messages
Actions.onCreate = message => {
  return dispatch => {
    dispatch({ type: "POST_SUPPORT_MESSAGE_REQUEST" });

    const formData = new FormData();
    formData.append(
      `support_message[support_message_type_id]`,
      message.support_message_type ? message.support_message_type.id : null
    );
    formData.append(`support_message[subject]`, message.subject);
    formData.append(`support_message[description]`, message.description);
    formData.append(`location`, message.url);
    formData.append(`userAgent`, navigator.userAgent);

    if (message.attachments.length > 0) {
      message.attachments.map(item => {
        formData.append("files[]", item);
      })
    }
    
    return api
      .postFormData(`/support_messages`, formData)
      .then(response => {
        dispatch({ type: "POST_SUPPORT_MESSAGE_SUCCEED", response });
        api.openNotification("success", "Сообщение", "Успешно создано!");
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            api.openNotification("error", "Сообщение", error);
          });
        }
        dispatch({ type: "POST_SUPPORT_MESSAGE_FAILURE", response });
      });
  };
};

Actions.onSearch = value => {
  return dispatch => {
    dispatch({
      type: "REQUEST_SUPPORT_MESSAGE_SEARCH",
      value: value
    });
    return Promise.resolve();
  };
};

Actions.onChangePage = value => {
  return dispatch => {
    dispatch({
      type: "REQUEST_SUPPORT_MESSAGE_CHANGE_PAGE",
      value: value
    });
    return Promise.resolve();
  };
};

Actions.onChangeLimit = value => {
  return dispatch => {
    dispatch({
      type: "REQUEST_SUPPORT_MESSAGE_CHANGE_LIMIT",
      value: value
    });
    return Promise.resolve();
  };
};

Actions.onClearFilters = value => {
  return dispatch => {
    dispatch({
      type: "FEEDBACK_CLEAR_FILTERS",
      value: value
    });
    return Promise.resolve();
  };
};

Actions.onSortBy = (field, direction) => {
  return dispatch => {
    dispatch({
      type: "FEEDBACKS_SORT_BY",
      field: field,
      direction: direction
    });
    return Promise.resolve();
  };
};

export default Actions;
