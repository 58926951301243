// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Card, Row, Col, Descriptions } from "antd";
import Assignees from "../../../components/Assignees";
import Attachments from "./Attachments";
import moment from "moment";
import "moment/locale/ru";

class Statuses extends Component {
  render() {
    const {
      plan,
      start,
      finish,
      // assignees
      isAssigneesLoading,
      organisationAssignees,
      supervisorAssignees,
      customerAssignees,
      onCreateAssignee,
      onDeleteAssignee,
      // attachments
      attachments,
      isUploading,
      onDeleteAttachment,
      onUploadAttachment,
    } = this.props;

    return (
      <Card style={{ borderColor: "#1890ff" }}>
        <Row gutter={16}>
          <Descriptions bordered>
            <Descriptions.Item label="Плановые дата и время" span={3}>
              {plan ? moment(plan).format("lll") : null}
            </Descriptions.Item>
            <Descriptions.Item label="Начало выполнения работ" span={3}>
              {start ? moment(start).format("lll") : "не начались"}
            </Descriptions.Item>
            <Descriptions.Item label="Окончание выполнения работ" span={3}>
              {start ? moment(finish).format("lll") : "не закончены"}
            </Descriptions.Item>
          </Descriptions>
        </Row>
        <Row gutter={10}>
          <Col span={24}>
            <Assignees
              title={"Наблюдатели"}
              isReadOnly={true}
              isAssigneesLoading={isAssigneesLoading}
              assigneeType="supervisor"
              assignees={supervisorAssignees}
            />
          </Col>
        </Row>
        <Row gutter={10}>
          <Col span={24}>
            <Assignees
              isReadOnly={true}
              isAssigneesLoading={isAssigneesLoading}
              assigneeType="organisation"
              assignees={organisationAssignees}
            />
          </Col>
        </Row>
        <Row gutter={10}>
          <Col span={24}>
            <Assignees
              title={"От заказчика"}
              isReadOnly={false}
              isAssigneesLoading={isAssigneesLoading}
              assigneeType="customer"
              assignees={customerAssignees}
              onCreateAssignee={onCreateAssignee}
              onDeleteAssignee={onDeleteAssignee}
            />
          </Col>
        </Row>
        <Row gutter={10}>
          <Attachments
            isUploading={isUploading}
            attachments={attachments}
            onUploadAttachment={onUploadAttachment}
            onDeleteAttachment={onDeleteAttachment}
          />
        </Row>
      </Card>
    );
  }
}
Statuses.propTypes = {
  customer: PropTypes.object,
  getFieldDecorator: PropTypes.func,
  onAdressEdit: PropTypes.func,
  onChangePaymentType: PropTypes.func,
  onChangePaymentStatus: PropTypes.func,
  onChangeShippingStatus: PropTypes.func,
  onChangeShippingMethod: PropTypes.func,
};

export default Statuses;
