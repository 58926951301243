// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import {Comment, Tooltip, Avatar, Typography} from "antd";
import { Row, Col, Form, Input, Button } from "antd";
import moment from "moment";
import "moment/locale/ru";

const { TextArea } = Input;

const {Text} = Typography;

class Comments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comment: ""
    };
  }

  onSubmit = () => {
    const { comment, errors } = this.state;
    if (comment.length > 3) {
      this.props.onCreateComment(comment)
      if (!errors) {
        this.setState({comment: ""})
      }
    }
  };

  onChangeComment = e => {
    this.setState({ comment: e.target.value });
  };

  render() {
    const { comments, isCommentsLoading } = this.props;
    const { comment } = this.state;

    return (
      <Row gutter={{ md: 8, lg: 24, xl: 48 }} style={{ marginBottom: "10px" }}>
        <Col span={24}>
          {comments.map(item => {
            return (
              <Comment
                key={item.id}
                author={item.user ? item.user.name : item.member.name}
                avatar={<Avatar src={item.user ? item.user.avatar : item.member.avatar} alt="" />}
                datetime={<span>{moment(item.created_at).format("LLL")}</span>}
                content={
                  <Text>
                    <div dangerouslySetInnerHTML={{__html: item.comment}}/>
                  </Text>
                }
              />
            );
          })}
        </Col>
        <Col span={24}>
          <Form.Item>
            <TextArea
              rows={4}
              onChange={this.onChangeComment}
              value={comment}
            />
          </Form.Item>
          <Form.Item>
            <Button
              loading={isCommentsLoading}
              onClick={this.onSubmit}
              type="primary"
            >
              Добавить комментарий
            </Button>
          </Form.Item>
        </Col>
      </Row>
    );
  }
}

Comments.propTypes = {
  orderComments: PropTypes.arrayOf(PropTypes.object),
  total: PropTypes.number,
  discount: PropTypes.object,
  shippingCost: PropTypes.number,
  amountDiscountCode: PropTypes.number,
  onRemoveVariant: PropTypes.func
};
export default Comments;
