// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, Button, Form, Card } from "antd";
import Header from "./Header";

class ProfileForm extends Component {
  onSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onSave(values);
      }
    });
  };

  render() {
    const {
      isLoading,
      member,
      onClose,
      onChangeCurrentMemberName,
      onChangeCurrentMemberEmail,
      onUpdateCurrentMemberPassword,
      onChangeCurrentMemberPassword,
      onChangeCurrentMemberPasswordConfirmation,
      // notifications
      onChangeNotifyTickets,
      onChangeNotifyEntityTasks,
      onChangeNotifyOrders,
      onChangeNotifyOrderTasks,
      onChangeNotifyProjects,
    } = this.props;
    const { getFieldDecorator } = this.props.form;

    return (
      <Card
        loading={isLoading}
        title={
          <h2
            style={{ marginBottom: 0 }}
          >{`Пользователь #${`${member.name}`}`}</h2>
        }
        extra={
          <div>
            <Button onClick={onClose}>Закрыть</Button>
            <Button
              onClick={this.onSubmit}
              type="primary"
              ghost
              style={{ marginLeft: "8px" }}
            >
              Сохранить
            </Button>
          </div>
        }
      >
        <Form layout={"vertical"}>
          <Col xs={24} sm={24} md={24} lg={14} xl={14}>
            <Row>
              <Header
                name={member.name}
                email={member.email}
                password={member.password}
                password_confirmation={member.password_confirmation}
                getFieldDecorator={getFieldDecorator}
                onChangeCurrentMemberName={onChangeCurrentMemberName}
                onChangeEmail={onChangeCurrentMemberEmail}
                onChangeCurrentMemberPassword={onChangeCurrentMemberPassword}
                onChangePasswordConfirmation={
                  onChangeCurrentMemberPasswordConfirmation
                }
                onUpdateCurrentMemberPassword={onUpdateCurrentMemberPassword}
                // notifications
                notifyCommentSettings={
                  member.member_profile
                    ? member.member_profile.notify_comment_settings
                    : null
                }
                onChangeNotifyTickets={onChangeNotifyTickets}
                onChangeNotifyEntityTasks={onChangeNotifyEntityTasks}
                onChangeNotifyOrders={onChangeNotifyOrders}
                onChangeNotifyOrderTasks={onChangeNotifyOrderTasks}
                onChangeNotifyProjects={onChangeNotifyProjects}
              />
            </Row>
          </Col>
        </Form>
      </Card>
    );
  }
}

ProfileForm.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  member: PropTypes.object,
  onSave: PropTypes.func,
  isLoading: PropTypes.bool,
  router: PropTypes.object,
  getFieldDecorator: PropTypes.func,
  form: PropTypes.object,
  onChangeName: PropTypes.func
};

export default Form.create()(ProfileForm);
