// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { List, Checkbox, Button, Tooltip } from "antd";
import moment from "moment";
import "moment/locale/ru";

class TodoItems extends Component {
  render() {
    const {
      isTodoLoading,
      entityTaskTodoIdx,
      entityTaskTodoItems,
      onOpenComment,
    } = this.props;

    return (
      <List
        loading={isTodoLoading}
        size="small"
        dataSource={entityTaskTodoItems}
        renderItem={(item, idx) => (
          <List.Item
            actions={[
              <Checkbox disabled checked={item.completed_at ? true : false} />,
            ]}
          >
            <List.Item.Meta
              avatar={
                <Tooltip placement="rightTop" title={"Комментарий"}>
                  <Button
                    onClick={onOpenComment.bind(
                      this,
                      item,
                      entityTaskTodoIdx,
                      idx
                    )}
                    type="dashed"
                    shape="circle"
                    icon="form"
                  />
                </Tooltip>
              }
              title={item.todo_item.name}
              description={
                <React.Fragment>
                  <span>
                    {item.completed_at
                      ? `${
                          item.user ? item.user.name : item.member.name
                        }, ${moment(item.completed_at).format("LLL")}`
                      : null}
                  </span>
                  <br />
                  <span>{item.comment}</span>
                </React.Fragment>
              }
            />
          </List.Item>
        )}
      />
    );
  }
}

TodoItems.propTypes = {
  onCompleted: PropTypes.func,
};

export default TodoItems;
