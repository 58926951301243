// @flow
import React, { Component } from "react";
import {
  Popover,
  Table,
  Button,
  Tooltip,
  Card,
  Avatar,
  Row,
  Col,
  Divider,
  Rate,
} from "antd";
import moment from "moment";
import "moment/locale/ru";
import api from "../../api";

export default class Details extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      fetching: true,
    };
  }

  fetchUserInfo = async (id) => {
    // console.log(id)
    const response = await api.fetch(`/users/${id}/info`);
    const data = response.data;
    this.setState({ data, fetching: false });
  };

  render() {
    const { fetching, data } = this.state;
    const { title, userId } = this.props;
    return (
      <Popover
        placement="right"
        content={<UserCard loading={fetching} data={data} />}
        // title="Инфо"
        trigger="click"
      >
        <a onClick={this.fetchUserInfo.bind(this, userId)}>{title}</a>
      </Popover>
    );
  }
}

function UserCard({ loading = true, data = null }) {
  return loading ? null : (
    <Card
      size="small"
      loading={loading}
      bordered={false}
      style={{ width: 300 }}
    >
      <Row gutter={10} type="flex" justify="center">
        <Avatar size={74} src={data.avatar} />
      </Row>
      <Row gutter={16} type="flex" justify="center">
        <span style={{ fontSize: 16 }}>{data.name}</span>
      </Row>
      <Row gutter={10} type="flex" justify="center">
        <Rate disabled allowHalf defaultValue={parseFloat(data.rate)} />
      </Row>
      <Divider />
      <Row gutter={10} type="flex" justify="center">
        <Col span={24} style={{ textAlign: "center" }}>
          {data.title}
        </Col>
        <Col span={24} style={{ textAlign: "center" }}>
          {data.department}
        </Col>
        <Col span={24} style={{ textAlign: "center" }}>
          <a href={"mailto:" + data.email}>{data.email}</a>
        </Col>
        <Col span={24} style={{ textAlign: "center" }}>
          {data.phone}
        </Col>
      </Row>
    </Card>
  );
}
