import React from "react";
import { Row, Col, Popover, List, Avatar } from "antd";

export default function TaskDetails({
  loading = false,
  value = null,
  data = [],
  onChange = null,
}) {
  return (
    <Popover
      placement="right"
      content={<Details loading={loading} data={data} />}
      title={`Сотрудники (${data.length})`}
      trigger="click"
      onVisibleChange={onChange}
    >
      <span style={{ cursor: "pointer" }}>{value}</span>
    </Popover>
  );
}

function Details({ loading = false, data = null, path = null }) {
  return (
    <div style={{ width: 380, height: 400 }}>
      <List
        size="small"
        itemLayout="horizontal"
        dataSource={data}
        style={{ maxHeight: 400, overflowY: "scroll" }}
        renderItem={(item) => (
          <List.Item>
            <List.Item.Meta
              avatar={<Avatar src={item.avatar ? item.avatar : null} />}
              title={item.name}
              description={
                <div style={{display: "flex", flexDirection: "column"}}>
                  <span>{item.title}</span>
                  <span style={{fontSize: 12}}><a href={`mailto:${item.email}`}>{item.email}</a></span>
                  <span style={{fontSize: 12}}>{item.phone}</span>
                </div>
              }
            />
          </List.Item>
        )}
      />
    </div>
  );
}
