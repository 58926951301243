// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { List, Button, Avatar } from "antd";
import moment from "moment";
import "moment/locale/ru";

class PostsList extends Component {
  render() {
    const { isPostsLoading, posts, onOpenPost } = this.props;

    return (
      <List
        loading={isPostsLoading}
        itemLayout="horizontal"
        dataSource={posts}
        renderItem={item => (
          <List.Item style={{cursor: "pointer"}} onClick={onOpenPost.bind(this, item.id)}>
            <List.Item.Meta
              avatar={<Avatar src={item.user ? item.user.avatar : null} />}
              title={item.title}
              description={moment(item.published_at).format("LLL")}
            />
          </List.Item>
        )}
      />
    );
  }
}
PostsList.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.object)
};

export default PostsList;
