// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, Button, Form, Card, Tabs, Dropdown, Menu } from "antd";
import Statuses from "./Statuses";
import Header from "./Header";
import Stages from "./Stages";
import Comments from "./Comments";
import moment from "moment";
import "moment/locale/ru";
import Documents from "../Documents";

const { TabPane } = Tabs;

class ProjectForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      assignee: null,
    };
  }

  onSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }
      this.props.onSave(values);
    });
  };

  onChangeFooterTab = (key) => {
    
  };

  // actions
  onAction = (actionId) => {
    const { project, actions, onOpenTaskForm } = this.props;
    let idx = actions.findIndex(function (o) {
      return o.id == actionId;
    });
    if (idx === -1) {
      return null;
    }
    onOpenTaskForm(actions[idx], project.id);
  };

  ActionMenu = (actions) => {
    return (
      <Menu>
        {actions.map((action) => {
          return (
            <Menu.Item
              key={action.id}
              onClick={this.onAction.bind(this, action.id)}
            >
              {action.name}
            </Menu.Item>
          );
        })}
      </Menu>
    );
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      isNew,
      isLoading,
      errors,
      currentUser,
      project,
      onClose,
      // comments
      isCommentsLoading,
      onCreateComment,
      // assignees
      isAssigneesLoading,
      // stages
      isStagesLoading,
      // actions
      actions,
      onOpenTaskForm,
    } = this.props;

    const organisationAssignees = project.assignees.filter(function (item) {
      return item.assignee_type == "organisation";
    });

    const customerAssignees = project.assignees.filter(function (item) {
      return item.assignee_type == "customer";
    });

    // actions
    const projectActions = actions.filter(function (item) {
      return item.task_owner == "projects";
    });

    const stageActions = actions.filter(function (item) {
      return item.task_owner == "project_stages";
    });

    let actionsButton = null;

    if (projectActions.length > 0) {
      actionsButton = (
        <Dropdown key={99} overlay={this.ActionMenu(projectActions)}>
          <Button icon={"more"} style={{ marginLeft: "8px" }} />
        </Dropdown>
      );
    }

    return (
      <Form layout={"vertical"} onSubmit={this.onSubmit}>
        <Card
          loading={isLoading}
          title={
            <div>
              <h2 style={{ marginBottom: 0 }}>{`Проект #${project.number} от ${moment(project.created_at).format("LLL")}`}</h2>
              <span style={{ fontSize: "12px" }}>
                {project.status_locale ? project.status_locale : null}
              </span>
            </div>
          }
          extra={
            [
              <Button key={1} onClick={onClose}>Закрыть</Button>,
              actionsButton
            ]
          }
        >
          <Col xs={24} sm={24} md={24} lg={14} xl={14}>
            <Row>
              <Header
                isNew={isNew}
                getFieldDecorator={getFieldDecorator}
                project={project}
              />
            </Row>
            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Stages
                  stages={project.project_stages}
                  isLoading={isStagesLoading}
                  // actions
                  actions={stageActions}
                  onOpenTaskForm={onOpenTaskForm}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Tabs defaultActiveKey="1" onChange={this.onChangeFooterTab}>
                  <TabPane tab="Комментарии" key="1">
                    <Row>
                      <Comments
                        getFieldDecorator={getFieldDecorator}
                        isCommentsLoading={isCommentsLoading}
                        errors={errors}
                        onCreateComment={onCreateComment}
                        comments={project.comments}
                      />
                    </Row>
                  </TabPane>
                  <TabPane tab="Файлы" key="2">
                  <Documents />
                  </TabPane>
                </Tabs>
              </Col>
            </Row>
          </Col>

          <Col xs={0} sm={0} md={1} lg={1} xl={1} />
          <Col xs={24} sm={24} md={24} lg={9} xl={9}>
            <Tabs defaultActiveKey="1" onChange={this.callback}>
              <TabPane tab="Статусы" key="1">
                <Statuses
                  isNew={isNew}
                  getFieldDecorator={getFieldDecorator}
                  currentUser={currentUser}
                  projectId={project.id}
                  user={project.user}
                  member={project.member}
                  customer={project.customer}
                  status={project.status}
                  // assignees
                  organisationAssignees={organisationAssignees}
                  customerAssignees={customerAssignees}
                  isAssigneesLoading={isAssigneesLoading}
                />
              </TabPane>
            </Tabs>
          </Col>
        </Card>
      </Form>
    );
  }
}

ProjectForm.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  project: PropTypes.object,
  onSave: PropTypes.func,
  isLoading: PropTypes.bool,
  router: PropTypes.object,
  getFieldDecorator: PropTypes.func,
  form: PropTypes.object,
  fetchSuggestions: PropTypes.func,
  onChangeDate: PropTypes.func,
  onChangeUser: PropTypes.func,
  onChangeAgreement: PropTypes.func,
  onChangeAgreementDate: PropTypes.func,
  onChangeAdditional: PropTypes.func,
  onChangeComment: PropTypes.func,
  onChangeNotice: PropTypes.func,
  onChangePaymentType: PropTypes.func,
  onChangePaymentStatus: PropTypes.func,
  onChangeShippingStatus: PropTypes.func,
  onChangeShippingMethod: PropTypes.func,
  onAddVariant: PropTypes.func,
  onRemoveVariant: PropTypes.func,
  onChangeQuantity: PropTypes.func,
  onChangePrice: PropTypes.func,
};

export default Form.create()(ProjectForm);
