import update from "immutability-helper";

const initialState = {
  rateObject: { assignees: [] },
  errors: false,
  isLoading: true,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "FETCH_RATE_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_RATE":
      return {
        ...state,
        rateObject: action.response.data,
        isLoading: false,
      };
    case "FETCH_RATE_FAILURE":
      return {
        ...state,
        isLoading: true,
      };

    case "PATCH_RATE_REQUEST":
      return {
        ...state,
        errors: false,
      };
    case "PATCH_RATE":
      return {
        ...state,
        rateObject: action.response.data,
        errors: false,
      };

    case "PATCH_RATE_FAILURE":
      return {
        ...state,
        errors: true,
      };

    default:
      return state;
  }
}
