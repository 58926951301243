import api from "../api";
import {saveAs} from "file-saver";

const Actions = {};

Actions.fetchEntities = () => {
  return (dispatch, getState) => {
    dispatch({ type: "FETCH_ENTITIES_REQUEST" });
    const params = getState().entities.fetchParams;
    let users = [];
    let members = [];
    let regions = [];

    if (params.users) {
      users = params.users.map((user) => {
        if (user && user.id) {
          return user.id;
        }
      });
    }
    if (params.members) {
      members = params.members.map((member) => {
        if (member && member.id) {
          return member.id;
        }
      });
    }

    if (params.regions) {
      regions = params.regions.map((region) => {
        if (region && region.id) {
          return region.id;
        }
      });
    }

    const data = {
      search: params.search,
      sort: params.sort,
      direction: params.direction,
      page: params.page,
      limit: params.limit,
      regions: regions,
      users: users,
      members: members,
      active: params.active,
      entityType: params.entityType ? params.entityType.id : null,
    };

    return api
      .fetch("/entities", data)
      .then((response) => {
        dispatch({ type: "FETCH_ENTITIES", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_ENTITIES_FAILURE", response });
      });
  };
};

Actions.fetchEntity = (id = null) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_ENTITY_REQUEST" });
    return api
      .fetch(`/entities/${id}`)
      .then((response) => {
        dispatch({ type: "FETCH_ENTITY", response });
      })
      .catch((error) => {
        if (error.response.status == 404) {
          window.location = "/notfound";
        }
        if (error.errors) {
          error.errors.map((error) => {
            return api.openNotification("error", "Объект", error);
          });
        }
        dispatch({ type: "FETCH_ENTITY_FAILURE", error });
      });
  };
};

// entity_photos
Actions.uploadPhoto = (entityId, file) => {
  return (dispatch) => {
    dispatch({
      type: "ENTITY_PHOTO_UPLOAD",
    });

    const formData = new FormData();
    formData.append("file", file);
    return api
      .postFormData(`/entities/${entityId}/entity_photos`, formData)
      .then((response) => {
        api.openNotification(
          "success",
          "Объект",
          "Изображение успешно загружено!"
        );
        dispatch({ type: "ENTITY_PHOTO_UPLOAD_SUCCEED", response });
      })
      .catch((response) => {
        dispatch({
          type: "ENTITY_PHOTO_UPLOAD_FAILED",
        });
        dispatch({
          type: "ADD_NOTIFICATION",
          message: { label: response.error, type: "error" },
        });
      });
  };
};

Actions.fetchEntityImages = (id) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_ENTITY_PHOTOS_REQUEST" });
    api
      .fetch(`/entities/${id}/entity_photos`)
      .then((response) => {
        dispatch({ type: "FETCH_ENTITY_PHOTOS", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_ENTITY_PHOTOS_FAILURE", response });
      });
  };
};

Actions.onDeletePhoto = (id, uid) => {
  return (dispatch) => {
    dispatch({
      type: "ENTITY_PHOTO_DELETE_REQUEST",
    });
    return api
      .delete(`/entities/${id}/entity_photos/${uid}`)
      .then((response) => {
        api.openNotification(
          "success",
          "Объект",
          "Изображение успешно удалено!"
        );
        dispatch({ type: "ENTITY_PHOTO_DELETE_SUCCEED", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Объект", error);
          });
        }
        dispatch({ type: "ENTITY_PHOTO_DELETE_FAILED" });
      });
  };
};

// assignees
Actions.fetchAssignees = (id) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_ASSIGNEES_REQUEST" });
    api
      .fetch(`/entities/${id}/entity_assignees`)
      .then((response) => {
        dispatch({ type: "FETCH_ASSIGNEES", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_ASSIGNEES_FAILURE", response });
      });
  };
};

Actions.onCreateAssignee = (id, assignee, type) => {
  return (dispatch) => {
    dispatch({ type: "POST_ASSIGNEE_REQUEST" });
    return api
      .post(`/entities/${id}/entity_assignees`, {
        entity_assignee: {
          assignee_type: type,
          user_id:
            type == "organisation" || type == "supervisor" ? assignee.id : null,
          member_id:
            type == "organisation" || type == "supervisor" ? null : assignee.id,
        },
      })
      .then((response) => {
        dispatch({ type: "POST_ASSIGNEE", response });
        api.openNotification("success", "Ответственный", "Успешно добавлен!");
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Ответственный", error);
          });
        }
        dispatch({ type: "POST_ASSIGNEE_FAILURE", response });
      });
  };
};

Actions.onDeleteAssignee = (entity_id, id) => {
  return (dispatch) => {
    dispatch({
      type: "ASSIGNEE_DELETE_REQUEST",
    });
    return api
      .delete(`/entities/${entity_id}/entity_assignees/${id}`)
      .then((response) => {
        api.openNotification("success", "Ответственный", "Успешно удален!");
        dispatch({ type: "ASSIGNEE_DELETE_SUCCEED", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Ответственный", error);
          });
        }
        dispatch({ type: "ASSIGNEE_DELETE_FAILED" });
      });
  };
};

// entity_schemas
Actions.fetchEntitySchemas = (id) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_ENTITY_SCHEMAS_REQUEST" });
    api
      .fetch(`/entities/${id}/entity_schemas`)
      .then((response) => {
        // console.log(response)
        dispatch({ type: "FETCH_ENTITY_SCHEMAS", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_ENTITY_SCHEMAS_FAILURE", response });
      });
  };
};

Actions.onUploadEntitySchema = (entity_id, file) => {
  return (dispatch) => {
    dispatch({
      type: "ENTITY_SCHEMA_UPLOAD",
    });
    const formData = new FormData();
    formData.append("file", file);
    return api
      .postFormData(`/entities/${entity_id}/entity_schemas`, formData)
      .then((response) => {
        api.openNotification("success", "Файл", "Успешно загружен!");
        dispatch({ type: "ENTITY_SCHEMA_UPLOAD_SUCCEED", response });
      })
      .catch((response) => {
        dispatch({
          type: "ENTITY_SCHEMA_UPLOAD_FAILED",
        });
        dispatch({
          type: "ADD_NOTIFICATION",
          message: { label: response.error, type: "error" },
        });
      });
  };
};

Actions.onDeleteEntitySchema = (entity_id, id) => {
  return (dispatch) => {
    dispatch({
      type: "ENTITY_SCHEMA_DELETE_REQUEST",
    });
    return api
      .delete(`/entities/${entity_id}/entity_schemas/${id}`)
      .then((response) => {
        api.openNotification("success", "Файл", "Успешно удален!");
        dispatch({ type: "ENTITY_SCHEMA_DELETE_SUCCEED", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Файл", error);
          });
        }
        dispatch({ type: "ENTITY_SCHEMA_DELETE_FAILED" });
      });
  };
};

// attachments
Actions.fetchAttachments = (id) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_ATTACHMENT_ENTITY_REQUEST" });
    api
      .fetch(`/entities/${id}/entity_attachments`)
      .then((response) => {
        // console.log(response)
        dispatch({ type: "FETCH_ENTITY_ATTACHMENTS", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_ENTITY_ATTACHMENTS_FAILURE", response });
      });
  };
};

Actions.onUploadAttachment = (id, file, storage_type) => {
  return (dispatch) => {
    dispatch({
      type: "ENTITY_ATTACHMENT_UPLOAD",
    });
    // console.log(file);
    const formData = new FormData();

    formData.append("content_type", file.type);
    formData.append("attachment", file);
    formData.append("storage_type", "internal");
    return api
      .postFormData(`/entities/${id}/entity_attachments`, formData)
      .then((response) => {
        api.openNotification("success", "Файл", "Успешно загружен!");
        dispatch({ type: "ENTITY_ATTACHMENT_UPLOAD_SUCCEED", response });
      })
      .catch((response) => {
        dispatch({
          type: "ENTITY_ATTACHMENT_UPLOAD_FAILED",
        });
        dispatch({
          type: "ADD_NOTIFICATION",
          message: { label: response.error, type: "error" },
        });
      });
  };
};

Actions.onSaveAttachment = (id, data) => {
  return (dispatch) => {
    dispatch({
      type: "ENTITY_ATTACHMENT_UPLOAD",
    });

    return api
      .post(`/entities/${id}/entity_attachments`, data)
      .then((response) => {
        api.openNotification("success", "ссылка", "Успешно создана!");
        dispatch({ type: "ENTITY_ATTACHMENT_UPLOAD_SUCCEED", response });
      })
      .catch((response) => {
        dispatch({
          type: "ENTITY_ATTACHMENT_UPLOAD_FAILED",
        });
        dispatch({
          type: "ADD_NOTIFICATION",
          message: { label: response.error, type: "error" },
        });
      });
  };
};

Actions.onDeleteAttachment = (entity_task_id, id) => {
  return (dispatch) => {
    dispatch({
      type: "ATTACHMENT_DELETE_REQUEST",
    });
    return api
      .delete(`/entities/${entity_task_id}/entity_attachments/${id}`)
      .then((response) => {
        api.openNotification("success", "Файл", "Успешно удален!");
        dispatch({ type: "ATTACHMENT_DELETE_SUCCEED", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Файл", error);
          });
        }
        dispatch({ type: "ATTACHMENT_DELETE_FAILED" });
      });
  };
};

// entity_tasks
Actions.fetchEntityTasks = (entity_id, startDate, endDate) => {
  return (dispatch, getState) => {
    dispatch({ type: "FETCH_ENTITY_TASKS_REQUEST" });
    const params = {
      entity_id: entity_id,
      startDate: startDate,
      endDate: endDate,
    };
    api
      .fetch("/entity_tasks", params)
      .then((response) => {
        dispatch({ type: "FETCH_ENTITY_TASKS", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_ENTITY_TASKS_FAILURE", response });
      });
  };
};

// tickets
Actions.fetchTickets = (entity_id) => {
  return (dispatch, getState) => {
    dispatch({ type: "FETCH_ENTITY_TICKETS_REQUEST" });

    let params = getState().entities.ticketFetchParams;

    let data = {
      entity_id: entity_id,
      startCreatedDate: params.startCreatedDate,
      endCreatedDate: params.endCreatedDate,
      categories: params.categories,
      services: params.services,
      search: params.search,
      sort: params.sort,
      direction: params.direction,
      page: params.page,
      limit: params.limit,
    };

    api
      .fetch("/tickets", data)
      .then((response) => {
        dispatch({ type: "FETCH_ENTITY_TICKETS", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_ENTITY_TICKETS_FAILURE", response });
      });
  };
};

Actions.onChangeTicketPage = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_ENTITY_TICKET_CHANGE_PAGE",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onChangeTicketLimit = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_ENTITY_TICKET_CHANGE_LIMIT",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onChangeTicketCategories = (categories) => {
  return (dispatch) => {
    let categoryIds = categories.map((category) => category.id);

    dispatch({
      type: "REQUEST_ENTITY_CHANGE_TICKET_CATEGORIES",
      value: categoryIds,
    });
    return Promise.resolve();
  };
};

Actions.onChangeTicketServices = (services) => {
  return (dispatch) => {
    let serviceIds = services.map((service) => service.id);

    dispatch({
      type: "REQUEST_ENTITY_CHANGE_TICKET_SERVICES",
      value: serviceIds,
    });
    return Promise.resolve();
  };
};

Actions.onChangeTicketPeriod = (period) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_ENTITY_CHANGE_TICKET_PERIOD",
      value: period,
    });
    return Promise.resolve();
  };
};

Actions.onDownloadTicketReport = (entity_id) => {
  return (dispatch, getState) => {
    let params = getState().entities.ticketFetchParams;

    let data = {
      entity_id: entity_id,
      startCreatedDate: params.startCreatedDate,
      endCreatedDate: params.endCreatedDate,
      categories: params.categories,
      services: params.services,
      search: params.search,
      sort: params.sort,
      direction: params.direction,
      page: params.page,
      limit: 1000,
    };

    let fileName = params.startCreatedDate ?
      `tickets ${params.startCreatedDate}-${params.endCreatedDate}.xlsx` :
      'tickets.xlsx'

    api
      .fetchBlob('/tickets.xlsx', data)
      .then((response) => {
        //console.log(response)
        saveAs(response, `${fileName}`);
      })
      .catch((response) => {
        console.log(response.error);
      });
  };
};


Actions.fetchOrders = (entity_id) => {
  return (dispatch, getState) => {
    let params = getState().entities.orderFetchParams;

    let data = {
      entity_id: entity_id,
      startDate: params.startDate,
      endDate: params.endDate,
      services: params.services,
      search: params.search,
      sort: params.sort,
      direction: params.direction,
      page: params.page,
      limit: params.limit,
    };

    api
      .fetch("/orders", data)
      .then((response) => {
        dispatch({ type: "FETCH_ORDERS", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_ORDERS_FAILURE", response });
      });
  };
};

Actions.onChangeOrderPage = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_ENTITY_ORDER_CHANGE_PAGE",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onChangeOrderLimit = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_ENTITY_ORDER_CHANGE_LIMIT",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onChangeOrderServices = (services) => {
  return (dispatch) => {
    let serviceIds = services.map((service) => service.id);

    dispatch({
      type: "REQUEST_ENTITY_CHANGE_ORDER_SERVICES",
      value: serviceIds,
    });
    return Promise.resolve();
  };
};

Actions.onChangeOrderPeriod = (period) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_ENTITY_CHANGE_ORDER_PERIOD",
      value: period,
    });
    return Promise.resolve();
  };
};

Actions.onDownloadOrderReport = (entity_id) => {
  return (dispatch, getState) => {
    let params = getState().entities.orderFetchParams;

    let data = {
      entity_id: entity_id,
      startDate: params.startDate,
      endDate: params.endDate,
      services: params.services,
      search: params.search,
      sort: params.sort,
      direction: params.direction,
      page: params.page,
      limit: 1000,
    };

    let fileName = params.startDate ?
      `orders ${params.startDate}-${params.endDate}.xlsx` :
      "orders.xlsx"

    api
      .fetchBlob('/orders.xlsx', data)
      .then((response) => {
        //console.log(response)
        saveAs(response, `${fileName}`);
      })
      .catch((response) => {
        console.log(response.error);
      });
  };
};


Actions.fetchProjects = (entity_id, startDate, endDate) => {
  return (dispatch, getState) => {
    dispatch({ type: "FETCH_PROJECTS_REQUEST" });
    const params = {
      entity_id: entity_id,
      startDate: startDate,
      endDate: endDate,
    };
    api
      .fetch("/projects", params)
      .then((response) => {
        dispatch({ type: "FETCH_PROJECTS", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_PROJECTS_FAILURE", response });
      });
  };
};

// notes
Actions.fetchEntityNotes = (id) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_ENTITY_NOTES_REQUEST" });
    api
      .fetch(`/entities/${id}/entity_notes`)
      .then((response) => {
        dispatch({ type: "FETCH_ENTITY_NOTES", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Примечание", error);
          });
        }
        dispatch({ type: "FETCH_ENTITY_NOTES_FAILURE", response });
      });
  };
};

Actions.onCreateNote = (id, value) => {
  return (dispatch, getState) => {
    dispatch({ type: "POST_ENTITY_NOTE_REQUEST" });
    return api
      .post(`/entities/${id}/entity_notes`, {
        entity_note: { note: value },
      })
      .then((response) => {
        dispatch({ type: "POST_ENTITY_NOTE", response });
        api.openNotification("success", "Примечание", "Успешно создано!");
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Примечание", error);
          });
        }
        dispatch({ type: "POST_ENTITY_NOTE_FAILURE", response });
      });
  };
};

Actions.onSearch = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_ENTITY_SEARCH",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onChangePage = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_ENTITY_CHANGE_PAGE",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onChangeLimit = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_ENTITY_CHANGE_LIMIT",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterActive = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_ENTITIES_FILTER_ACTIVE",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onSortBy = (field, direction) => {
  return (dispatch) => {
    dispatch({
      type: "ENTITIES_SORT_BY",
      field: field,
      direction: direction,
    });
    return Promise.resolve();
  };
};

// filters
Actions.onSetFilters = () => {
  return (dispatch) => {
    dispatch({
      type: "ENTITIES_SET_FILTERS",
    });
    return Promise.resolve();
  };
};

Actions.onClearFilters = () => {
  return (dispatch) => {
    dispatch({
      type: "ENTITIES_CLEAR_FILTERS",
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterUsers = (values) => {
  return (dispatch) => {
    dispatch({
      type: "ENTITIES_FILTER_USERS",
      values: values,
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterMembers = (values) => {
  return (dispatch) => {
    dispatch({
      type: "ENTITIES_FILTER_MEMBERS",
      values: values,
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterRegions = (values) => {
  return (dispatch) => {
    dispatch({
      type: "ENTITIES_FILTER_REGIONS",
      values: values,
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterEntityType = (value) => {
  return (dispatch) => {
    dispatch({
      type: "ENTITIES_FILTER_ENTITY_TYPE",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterActive = (value) => {
  return (dispatch) => {
    dispatch({
      type: "ENTITIES_FILTER_ACTIVE",
      value: value,
    });
    return Promise.resolve();
  };
};

export default Actions;
