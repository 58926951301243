// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import update from "immutability-helper";
import Actions from "../../../actions/orders";
import TaskActions from "../../../actions/tasks";
import { Spin } from "antd";
import OrderForm from "./Form";

class Order extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNew: false,
      tempAttachments: [],
    };
  }

  componentWillMount = () => {
    // console.log("componentWillMount");
    if (this.props.match.params.id) {
      this.props
        .dispatch(Actions.fetchOrder(this.props.match.params.id))
        .then(() => {
          this.props.dispatch(
            TaskActions.fetchTaskActions({ taskOwners: [3] })
          );
        });
    } else {
      this.setState({ isNew: true }, () => {
        this.props.dispatch(Actions.onNew());
      });
    }
  };

  componentDidUpdate(prevProps) {
    if (this.props.location.key !== prevProps.location.key) {
      if (this.props.match.params.id) {
        this.setState({ isNew: false });
      }
    }
  }

  onSave = () => {
    const { tempAttachments } = this.state;
    if (this.props.match.params.id) {
      this.props.dispatch(Actions.onUpdate(this.props.match.params.id));
    } else {
      this.props.dispatch(Actions.onCreate(tempAttachments)).then(() => {
        if (!this.props.errors) {
          this.props.history.push(`/orders/${this.props.order.id}/edit`);
        }
      });
    }
  };

  onClose = () => {
    this.props.history.push(`/orders`);
  };

  // general
  onChangeNumber = (value) => {
    if (value != this.props.order.number) {
      this.props.dispatch(Actions.onUpdate(this.props.match.params.id, value));
    }
  };

  onChangePriority = (value) => {
    this.props.dispatch(Actions.onChangePriority(value));
  };

  onChangeCustomer = (value) => {
    this.props.dispatch(Actions.onChangeCustomer(value));
  };

  onChangeEntity = (value) => {
    this.props.dispatch(Actions.onChangeEntity(value));
  };
  onChangeEntityService = (value) => {
    this.props.dispatch(Actions.onChangeEntityService(value));
  };

  onChangeAmount = (value) => {
    this.props.dispatch(Actions.onChangeAmount(value));
  };

  onChangeSubject = (e) => {
    this.props.dispatch(Actions.onChangeSubject(e.target.value));
  };

  onChangeDescription = (e) => {
    this.props.dispatch(Actions.onChangeDescription(e.target.value));
  };

  // comments
  onCreateComment = (comment, mentioned) => {
    this.props.dispatch(Actions.onCreateComment(this.props.match.params.id, comment, mentioned))
      .then(() => {
        if (!this.props.errors) {
          this.props.dispatch(
            Actions.fetchOrderComments(this.props.match.params.id)
          );
        }
      });
  };

  // proposals
  onDeleteProposal = (id) => {
    this.props
      .dispatch(Actions.onDeleteProposal(this.props.match.params.id, id))
      .then(() => {
        if (!this.props.errors) {
          this.props.dispatch(
            Actions.fetchProposals(this.props.match.params.id)
          );
        }
      });
  };

  onUploadProposal = (file) => {
    this.props
      .dispatch(Actions.onUploadProposal(this.props.match.params.id, file))
      .then(() => {
        if (!this.props.errors) {
          this.props.dispatch(
            Actions.fetchProposals(this.props.match.params.id)
          );
        }
      });
  };

  // attachments
  onDeleteAttachment = (id) => {
    this.props
      .dispatch(Actions.onDeleteAttachment(this.props.match.params.id, id))
      .then(() => {
        if (!this.props.errors) {
          this.props.dispatch(
            Actions.fetchAttachments(this.props.match.params.id)
          );
        }
      });
  };

  onUploadAttachment = (file) => {
    this.props
      .dispatch(Actions.onUploadAttachment(this.props.match.params.id, file))
      .then(() => {
        if (!this.props.errors) {
          this.props.dispatch(
            Actions.fetchAttachments(this.props.match.params.id)
          );
        }
      });
  };

  // preUploads
  onTempUpload = (attachment) => {
    this.setState({
      tempAttachments: update(this.state.tempAttachments, {
        $push: [attachment],
      }),
    });
  };

  onDeleteTempAttachment = (id) => {
    let idx = this.state.tempAttachments.findIndex(function (o) {
      return o.id == id;
    });
    if (idx === -1) {
      return null;
    }

    this.setState({
      tempAttachments: update(this.state.tempAttachments, {
        $splice: [[idx, 1]],
      }),
    });
  };

  // assignees
  onCreateAssignee = (value, type) => {
    this.props
      .dispatch(
        Actions.onCreateAssignee(this.props.match.params.id, value, type)
      )
      .then(() => {
        if (!this.props.errors) {
          this.props.dispatch(
            Actions.fetchAssignees(this.props.match.params.id)
          );
        }
      });
  };

  onDeleteAssignee = (id) => {
    this.props
      .dispatch(Actions.onDeleteAssignee(this.props.match.params.id, id))
      .then(() => {
        if (!this.props.errors) {
          this.props.dispatch(
            Actions.fetchAssignees(this.props.match.params.id)
          );
        }
      });
  };

  // tasks
  onCreateOrderTask = (item) => {
    this.props
      .dispatch(Actions.onCreateOrderTask(this.props.match.params.id, item))
      .then(() => {
        if (!this.props.errors) {
          this.props.dispatch(Actions.fetchOrderTasks(this.props.match.params.id));
        }
      });
  };

  // schedules
  fetchSchedules = () => {
    this.props.dispatch(Actions.fetchSchedules(this.props.match.params.id));
  };

  onSaveSchedule = (item) => {
    if (item.id) {
      this.props
        .dispatch(Actions.onUpdateSchedule(this.props.match.params.id, item))
        .then(() => {
          if (!this.props.errors) {
            this.props.dispatch(
              Actions.fetchSchedules(this.props.match.params.id)
            );
          }
        });
    } else {
      this.props
        .dispatch(Actions.onCreateSchedule(this.props.match.params.id, item))
        .then(() => {
          if (!this.props.errors) {
            this.props.dispatch(
              Actions.fetchSchedules(this.props.match.params.id)
            );
          }
        });
    }
  };

  onDeleteSchedules = (ids) => {
    ids.map((id) => {
      this.props.dispatch(Actions.onDeleteSchedule(id)).then(() => {
        this.props.dispatch(Actions.fetchSchedules(this.props.match.params.id));
      });
    });
  };

  // tasks
  fetchTasks = () => {
    this.props.dispatch(Actions.fetchTasks(this.props.match.params.id));
  };

  // actions
  onOpenTaskForm = (action, id) => {
    const { order } = this.props;
    this.props.dispatch(TaskActions.fetchNew(action.id, order.id));
  };

  render() {
    const { tempAttachments } = this.state;
    const {
      currentMember,
      isLoading,
      isAssigneesLoading,
      isLoadingProposals,
      isAttachmentLoading,
      isSchedulesLoading,
      isCommentsLoading,
      order,
      // order_tasks
      isOrderTasksLoading,
      // tasks
      isTasksLoading,
      tasks,
      // actions
      actions,
      isTaskActionsLoading,
    } = this.props;
    return isLoading ? (
      <Spin />
    ) : (
      <OrderForm
        currentMember={currentMember}
        isNew={this.state.isNew}
        order={order}
        onSave={this.onSave}
        onClose={this.onClose}
        // general
        onChangeNumber={this.onChangeNumber}
        onChangeCustomer={this.onChangeCustomer}
        onChangeEntity={this.onChangeEntity}
        onChangeEntityService={this.onChangeEntityService}
        onChangeAmount={this.onChangeAmount}
        onChangeSubject={this.onChangeSubject}
        onChangeDescription={this.onChangeDescription}
        onChangePriority={this.onChangePriority}
        // comments
        isCommentsLoading={isCommentsLoading}
        onCreateComment={this.onCreateComment}
        // statuses
        isAssigneesLoading={isAssigneesLoading}
        onDeleteAssignee={this.onDeleteAssignee}
        // proposals
        isLoadingProposals={isLoadingProposals}
        onDeleteProposal={this.onDeleteProposal}
        onUploadProposal={this.onUploadProposal}
        // attachments
        isAttachmentLoading={isAttachmentLoading}
        onDeleteAttachment={this.onDeleteAttachment}
        onUploadAttachment={this.onUploadAttachment}
        // preUpload
        tempAttachments={tempAttachments}
        onTempUpload={this.onTempUpload}
        onDeleteTempAttachment={this.onDeleteTempAttachment}
        // assignees
        onCreateAssignee={this.onCreateAssignee}
        onDeleteAssignee={this.onDeleteAssignee}
        // tasks
        isOrderTasksLoading={isOrderTasksLoading}
        onCreateOrderTask={this.onCreateOrderTask}
        // schedules
        isSchedulesLoading={isSchedulesLoading}
        fetchSchedules={this.fetchSchedules}
        onSaveSchedule={this.onSaveSchedule}
        onDeleteSchedules={this.onDeleteSchedules}
        // tasks
        isTasksLoading={isTasksLoading}
        tasks={tasks}
        fetchTasks={this.fetchTasks}
        // actions
        actions={actions}
        isTaskActionsLoading={isTaskActionsLoading}
        onOpenTaskForm={this.onOpenTaskForm}
      />
    );
  }
}

Order.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  order: PropTypes.object,
  isLoading: PropTypes.bool,
  errors: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  currentMember: state.session.currentMember,
  isLoading: state.orders.isLoading,
  errors: state.orders.errors,
  order: state.orders.order,
  isLoadingProposals: state.orders.isLoadingProposals,
  isAttachmentLoading: state.orders.isAttachmentLoading,
  isAssigneesLoading: state.orders.isAssigneesLoading,
  isOrderTasksLoading: state.orders.isOrderTasksLoading,
  isSchedulesLoading: state.orders.isSchedulesLoading,
  isCommentsLoading: state.orders.isCommentsLoading,
  // tasks
  isTasksLoading: state.orders.isTasksLoading,
  tasks: state.orders.tasks,
  // actions
  actions: state.tasks.actions,
  isTaskActionsLoading: state.tasks.isLoading,
});

export default connect(mapStateToProps)(withRouter(Order));
