// @flow
import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./login.css";
import { Row, Col, Form, Icon, Input, Button } from "antd";

const FormItem = Form.Item;

class LoginForm extends Component {
  onSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onSubmit(values);
      }
    });
  };

  render() {
    const { submitting } = this.props;
    const { getFieldDecorator } = this.props.form;

    return (
      <div className="loginpagewrap">
        <div className="loginWrap">
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{textAlign: "center"}}>
              <h2 style={{ textAlign: "center" }}>Авторизация</h2>
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form onSubmit={this.onSubmit}>
                <Row>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <FormItem>
                      {getFieldDecorator("email", {
                        rules: [
                          {
                            required: true,
                            message: "Пожалуйста, введите логин!",
                          },
                        ],
                      })(
                        <Input
                          prefix={
                            <Icon
                              type="user"
                              style={{ color: "rgba(0,0,0,.25)" }}
                            />
                          }
                          placeholder="пользователь"
                        />
                      )}
                    </FormItem>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <FormItem>
                      {getFieldDecorator("password", {
                        rules: [
                          {
                            required: true,
                            message: "Пожалуйста, введите пароль!",
                          },
                        ],
                      })(
                        <Input
                          prefix={
                            <Icon
                              type="lock"
                              style={{ color: "rgba(0,0,0,.25)" }}
                            />
                          }
                          type="password"
                          placeholder="пароль"
                        />
                      )}
                    </FormItem>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <FormItem>
                      <Button
                        type="submit"
                        disabled={submitting}
                        htmlType="submit"
                        className="login-form-button"
                      >
                        {submitting ? "Вход..." : "Войти"}
                      </Button>
                    </FormItem>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
          <Row type="flex" justify="center">
          <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{textAlign: "center"}}>
            <span><Link to={`/auth/recovery`}>Забыли пароль?</Link></span>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default Form.create()(LoginForm);
