// @flow
import React, { Component } from "react";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Actions from "../../actions/rates";
import RateForm from "./Form";
import {
  Layout,
  Row,
  Card,
  Col,
  Form,
  Icon,
  Input,
  Button,
  Divider,
  List,
  Avatar,
  Typography,
  Descriptions,
  Rate,
} from "antd";

const FormItem = Form.Item;
const { Title } = Typography;
const { TextArea } = Input;

const desc = ["очень плохо", "плохо", "удовлетворительно", "хорошо", "отлично"];

class RateObject extends Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      rate: 0,
      comment: null,
    };
  }

  componentWillMount = () => {
    if (this.props.match.params.id) {
      this.props.dispatch(
        Actions.fetchRate("ticket_rates", this.props.match.params.id)
      );
    }
  };

  onSave = () => {
    const { rate, comment } = this.state;
    this.props
      .dispatch(
        Actions.onSave(
          "ticket_rates",
          this.props.match.params.id,
          rate,
          comment
        )
      )
      .then(() => {
        if (!this.props.errors) {
          this.props.history.push(`/rates/finish`);
        }
      });
  };

  onChangeRate = (value) => {
    this.setState({ rate: value });
  };

  onChangeComment = (e) => {
    this.setState({ comment: e.target.value });
  };

  render() {
    const { rate, comment } = this.state;
    const { isLoading, rateObject } = this.props;
    const { getFieldDecorator } = this.props.form;

    return (
      <Layout
        style={{
          height: "100vh",
        }}
      >
        <RateForm
          isLoading={isLoading}
          rateObject={rateObject}
          rate={rate}
          comment={comment}
          onSave={this.onSave}
          onChangeRate={this.onChangeRate}
          onChangeComment={this.onChangeComment}
        />
      </Layout>
    );
  }
}

RateObject.propTypes = {
  dispatch: PropTypes.func,
};

const mapStateToProps = (state) => ({
  errors: state.rates.errors,
  isLoading: state.rates.errors,
  rateObject: state.rates.rateObject,
});

export default connect(mapStateToProps)(withRouter(Form.create()(RateObject)));
