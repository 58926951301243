// @flow
import React, { Component, lazy, Suspense } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Actions from "../../../actions/tasks";
import { Spin } from "antd";

const Form = lazy(() => import("./Form"));
const renderLoader = () => <Spin />;

class Task extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNew: false,
    };
  }

  // componentWillMount = () => {
  //   this.props.dispatch(Actions.onChangeVisible());
  // };

  onSave = () => {
      this.props.dispatch(Actions.onCreate()).then(() => {
        if (!this.props.errors) {
          this.props.dispatch(Actions.fetchTasks());
        }
      });
  };

  onCancel = () => {
    this.props.dispatch(Actions.onCancelModalForm());
  };

  onChangeStatus = (value) => {
    this.props.dispatch(Actions.onChangeStatus(value));
  };

  onChangeSubject = (e) => {
    this.props.dispatch(Actions.onChangeSubject(e.target.value));
  };

  onChangeDescription = (e) => {
    this.props.dispatch(Actions.onChangeDescription(e.target.value));
  };

  onChangeDueBy = (value) => {
    this.props.dispatch(Actions.onChangeDueBy(value));
  };

  onChangeCustomer = (value) => {
    this.props.dispatch(Actions.onChangeCustomer(value));
  };

  // assignees
  onAddAssignee = (assignee) => {
    this.props.dispatch(Actions.onAddAssignee(assignee));
  };

  onDeleteAssignee = (id) => {
    this.props.dispatch(Actions.onDestroyAssignee(id));
  }

  // fields
  onChangeField = (name, value) => {
    this.props.dispatch(Actions.onChangeField(name, value));
  };

  render() {
    const { isVisible, task } = this.props;
    return (
      <Suspense fallback={renderLoader()}>
      <Form
        visible={isVisible}
        task={task}
        onSave={this.onSave}
        onCancel={this.onCancel}
        onChangeStatus={this.onChangeStatus}
        onChangeSubject={this.onChangeSubject}
        onChangeDescription={this.onChangeDescription}
        onChangeDueBy={this.onChangeDueBy}
        onChangeCustomer={this.onChangeCustomer}
        // assignees
        onAddAssignee={this.onAddAssignee}
        onDeleteAssignee={this.onDeleteAssignee}
        // fields
        onChangeField={this.onChangeField}
      />
      </Suspense>
    );
  }
}

Task.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  task: PropTypes.object,
  isLoading: PropTypes.bool,
  errors: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isVisible: state.tasks.isVisible,
  errors: state.tasks.errors,
  task: state.tasks.task,
});

export default connect(mapStateToProps)(withRouter(Task));
