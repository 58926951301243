import update from "immutability-helper";

const initialState = {
  currentMember: {},
  errors: false,
  isLoading: true,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "FETCH_CURRENT_MEMBER_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_CURRENT_MEMBER":
      return {
        ...state,
        currentMember: action.response.data,
        isLoading: false,
      };
    case "PATCH_CURRENT_MEMBER_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "PATCH_CURRENT_MEMBER":
      return {
        ...state,
        currentMember: action.response.data,
        isLoading: false,
      };

    case "PATCH_CURRENT_MEMBER_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true,
      };
    case "PATCH_PASSWORD_CURRENT_MEMBER_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "PATCH_PASSWORD_CURRENT_MEMBER":
      return {
        ...state,
        isLoading: false,
      };
    case "PATCH_PASSWORD_CURRENT_MEMBER_FAILURE":
      return {
        ...state,
        isLoading: false,
      };
    case "CURRENT_MEMBER_CHANGE_NAME":
      return update(state, {
        currentMember: { name: { $set: action.value } },
      });

    case "CURRENT_MEMBER_CHANGE_EMAIL":
      return update(state, {
        currentMember: { email: { $set: action.value } },
      });

    case "CURRENT_MEMBER_CHANGE_PASSWORD":
      return update(state, {
        currentMember: { password: { $set: action.value } },
      });

    case "CURRENT_MEMBER_CHANGE_PASSWORD_CONFIRMATION":
      return update(state, {
        currentMember: { password_confirmation: { $set: action.value } },
      });

    // notifications
    case "CURRENT_MEMBER_CHANGE_NOTIFY_COMMENT_SETTINGS_TICKETS":
      return update(state, {
        currentMember: {
          member_profile: {
            notify_comment_settings: {
              tickets: {
                $set: !state.currentMember.member_profile
                  .notify_comment_settings.tickets,
              },
            },
          },
        },
      });

    case "CURRENT_MEMBER_CHANGE_NOTIFY_COMMENT_SETTINGS_ENTITY_TASKS":
      return update(state, {
        currentMember: {
          member_profile: {
            notify_comment_settings: {
              entity_tasks: {
                $set: !state.currentMember.member_profile
                  .notify_comment_settings.entity_tasks,
              },
            },
          },
        },
      });

    case "CURRENT_MEMBER_CHANGE_NOTIFY_COMMENT_SETTINGS_ORDERS":
      return update(state, {
        currentMember: {
          member_profile: {
            notify_comment_settings: {
              orders: {
                $set: !state.currentMember.member_profile
                  .notify_comment_settings.orders,
              },
            },
          },
        },
      });

    case "CURRENT_MEMBER_CHANGE_NOTIFY_COMMENT_SETTINGS_ORDER_TASKS":
      return update(state, {
        currentMember: {
          member_profile: {
            notify_comment_settings: {
              order_tasks: {
                $set: !state.currentMember.member_profile
                  .notify_comment_settings.order_tasks,
              },
            },
          },
        },
      });

    case "CURRENT_MEMBER_CHANGE_NOTIFY_COMMENT_SETTINGS_PROJECTS":
      return update(state, {
        currentMember: {
          member_profile: {
            notify_comment_settings: {
              projects: {
                $set: !state.currentMember.member_profile.notify_comment_settings
                  .projects,
              },
            },
          },
        },
      });

    default:
      return state;
  }
}
