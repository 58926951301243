import update from "immutability-helper";

const initialState = {
  supportMessages: [],
  supportMessage: { user: null, comments: [], attachments: [], assignees: [] },
  meta: {
    total_pages: 1,
    current_page: 1,
    total_count: 1
  },
  fetchParams: {
    active: true,
    search: null,
    sort: null,
    direction: null,
    page: 1,
    limit: 15
  },
  errors: false,
  isLoading: true,
  isUploading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case "FETCH_SUPPORT_MESSAGES_REQUEST":
      return {
        ...state,
        isLoading: true
      };
    case "FETCH_SUPPORT_MESSAGES":
      return {
        ...state,
        supportMessages: action.response.data,
        meta: action.response.meta,
        isLoading: false
      };
    case "FETCH_SUPPORT_MESSAGE_REQUEST":
      return {
        ...state,
        isLoading: true
      };
    case "FETCH_SUPPORT_MESSAGE":
      return {
        ...state,
        supportMessage: action.response.data,
        isLoading: false
      };
    case "PATCH_SUPPORT_MESSAGE_REQUEST":
      return {
        ...state,
        isLoading: true,
        errors: false
      };
    case "PATCH_SUPPORT_MESSAGE":
      return {
        ...state,
        supportMessage: action.response.data,
        isLoading: false,
        errors: false
      };

    case "PATCH_SUPPORT_MESSAGE_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true
      };

    case "POST_SUPPORT_MESSAGE_REQUEST":
      return {
        ...state,
        errors: false,
        isLoading: true
      };
    case "POST_SUPPORT_MESSAGE":
      return {
        ...state,
        errors: false,
        supportMessage: action.response.data,
        isLoading: false
      };

    case "POST_SUPPORT_MESSAGE_FAILURE":
      return {
        ...state,
        errors: true,
        isLoading: false
      };

    case "DELETE_SUPPORT_MESSAGE_REQUEST":
      return {
        ...state,
        isLoading: true,
        errors: false
      };
    case "DELETE_SUPPORT_MESSAGE":
      return {
        ...state,
        supportMessages: action.response.data,
        isLoading: false,
        errors: false
      };
    case "DELETE_SUPPORT_MESSAGE_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true
      };


    default:
      return state;
  }
}
