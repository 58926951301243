// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import Actions from "../../../actions/orderTasks";
import FaqActions from "../../../actions/faqs";
import List from "./List";

class OrderTasks extends Component {
  componentWillMount = () => {
    this.props.dispatch(Actions.onSetFilters()).then(() => {
      this.props.dispatch(Actions.fetchOrderTasks());
    });
  };

  onSearch = e => {
    this.props
      .dispatch(Actions.onSearch(e.target.value))
      .then(() => this.props.dispatch(Actions.fetchOrderTasks()));
  };

  onChangePage = value => {
    this.props.dispatch(Actions.onChangePage(value)).then(() =>
      this.props.dispatch(Actions.fetchOrderTasks()).then(() => {
        localStorage.setItem(
          "orderTasksFilters",
          JSON.stringify(this.props.fetchParams)
        );
      })
    );
  };

  onChangeLimit = (current, size) => {
    this.props.dispatch(Actions.onChangeLimit(size)).then(() =>
      this.props.dispatch(Actions.fetchOrderTasks()).then(() => {
        localStorage.setItem(
          "orderTasksFilters",
          JSON.stringify(this.props.fetchParams)
        );
      })
    );
  };

  onSortBy = (field, direction) => {
    this.props.dispatch(Actions.onSortBy(field, direction)).then(() =>
      this.props.dispatch(Actions.fetchOrderTasks()).then(() => {
        localStorage.setItem(
          "orderTasksFilters",
          JSON.stringify(this.props.fetchParams)
        );
      })
    );
  };

  // filters
  onClearFilters = () => {
    this.props.dispatch(Actions.onClearFilters()).then(() => {
      localStorage.setItem(
        "orderTasksFilters",
        JSON.stringify(this.props.fetchParams)
      );
    });
  };

  onChangeFilterStatus = value => {
    this.props.dispatch(Actions.onChangeFilterStatus(value)).then(() =>
      this.props.dispatch(Actions.fetchOrderTasks()).then(() => {
        localStorage.setItem(
          "orderTasksFilters",
          JSON.stringify(this.props.fetchParams)
        );
      })
    );
  };

  onChangeFilterTaskType = values => {
    this.props.dispatch(Actions.onChangeFilterTaskType(values)).then(() =>
      this.props.dispatch(Actions.fetchOrderTasks()).then(() => {
        localStorage.setItem(
          "orderTasksFilters",
          JSON.stringify(this.props.fetchParams)
        );
      })
    );
  };

  onChangeFilterPeriod = (value, dateString) => {
    this.props
      .dispatch(Actions.onChangeFilterPeriod(dateString[0], dateString[1]))
      .then(() =>
        this.props.dispatch(Actions.fetchOrderTasks()).then(() => {
          localStorage.setItem(
            "orderTasksFilters",
            JSON.stringify(this.props.fetchParams)
          );
        })
      );
  };

  onChangeFilterUsers = values => {
    this.props.dispatch(Actions.onChangeFilterUsers(values)).then(() =>
      this.props.dispatch(Actions.fetchOrderTasks()).then(() => {
        localStorage.setItem(
          "orderTasksFilters",
          JSON.stringify(this.props.fetchParams)
        );
      })
    );
  };

  onChangeFilterMembers = (values) => {
    this.props.dispatch(Actions.onChangeFilterMembers(values)).then(() =>
      this.props.dispatch(Actions.fetchOrderTasks()).then(() => {
        localStorage.setItem(
          "orderTasksFilters",
          JSON.stringify(this.props.fetchParams)
        );
      })
    );
  };


  // notices
  onClearNotices = () => {
    this.props.dispatch(Actions.onClearNotices());
  };

  // faqs
  onFaqToggle = () => {
    this.props.dispatch(FaqActions.onDrawerToggle()).then(() => {
      this.props.dispatch(FaqActions.fetchFaqs(21, 1));
    });
  };

  render() {
    const { isLoading, orderTasks, meta, fetchParams, notices } = this.props;
    return (
      <List
        loading={isLoading}
        orderTasks={orderTasks}
        meta={meta}
        fetchParams={fetchParams}
        notices={notices}
        onSearch={this.onSearch}
        onChangePage={this.onChangePage}
        onChangeLimit={this.onChangeLimit}
        onSortBy={this.onSortBy}
        // filters
        onClearFilters={this.onClearFilters}
        onChangeFilterStatus={this.onChangeFilterStatus}
        onChangeFilterTaskType={this.onChangeFilterTaskType}
        onChangeFilterPeriod={this.onChangeFilterPeriod}
        onChangeFilterUsers={this.onChangeFilterUsers}
        onChangeFilterMembers={this.onChangeFilterMembers}
        onChangeFilterOrderUsers={this.onChangeFilterOrderUsers}
        // notices
        onClearNotices={this.onClearNotices}
        // faqs
        onFaqToggle={this.onFaqToggle}
      />
    );
  }
}

const mapStateToProps = state => ({
  isLoading: state.orderTasks.isLoading,
  orderTasks: state.orderTasks.orderTasks,
  meta: state.orderTasks.meta,
  fetchParams: state.orderTasks.fetchParams,
  notices: state.orderTasks.notices
});

export default connect(mapStateToProps)(OrderTasks);
