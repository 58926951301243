// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Actions from "../../../actions/projects";
import TaskActions from "../../../actions/tasks";
import LoadingSpin from "../../../components/LoadingSpin";
import ProjectForm from "./Form";

class Project extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNew: false,
    };
  }

  componentWillMount = () => {
    // console.log("componentWillMount");
    if (this.props.match.params.id) {
      this.props.dispatch(Actions.fetchProject(this.props.match.params.id)).then(() => {
        this.props.dispatch(TaskActions.fetchTaskActions({taskOwners: [1,2]}));
      });
    } else {
      this.setState({ isNew: true }, () => {
        this.props.dispatch(Actions.onNew());
      });
    }
  };

  onClose = () => {
    this.props.history.push(`/projects`);
  };

  // comments
  onCreateComment = (value) => {
    this.props
      .dispatch(Actions.onCreateComment(this.props.match.params.id, value))
      .then(() => {
        if (!this.props.errors) {
          this.props.dispatch(
            Actions.fetchProjectComments(this.props.match.params.id)
          );
        }
      });
  };

  // attachments
  onDeleteAttachment = (id) => {
    this.props
      .dispatch(Actions.onDeleteAttachment(this.props.match.params.id, id))
      .then(() => {
        if (!this.props.errors) {
          this.props.dispatch(
            Actions.fetchAttachments(this.props.match.params.id)
          );
        }
      });
  };

  onUploadAttachment = (file) => {
    this.props
      .dispatch(Actions.onUploadAttachment(this.props.match.params.id, file))
      .then(() => {
        if (!this.props.errors) {
          this.props.dispatch(
            Actions.fetchAttachments(this.props.match.params.id)
          );
        }
      });
  };

  // actions
  onOpenTaskForm = (action, id) => {
    const { project } = this.props;
    this.props.dispatch(TaskActions.fetchNew(action.id, project.id));
  };

  render() {
    const {
      currentUser,
      isLoading,
      errors,
      isAssigneesLoading,
      isAttachmentLoading,
      isCommentsLoading,
      project,
      projectActivities,
      isActivitiesLoading,
      isStagesLoading,
      // actions
      actions,
      isTaskActionsLoading,
    } = this.props;
    return isLoading || errors ? (
      <LoadingSpin />
    ) : (
      <ProjectForm
        isNew={this.state.isNew}
        currentUser={currentUser}
        project={project}
        onSave={this.onSave}
        onClose={this.onClose}
        // comments
        isCommentsLoading={isCommentsLoading}
        onCreateComment={this.onCreateComment}
        // attachments
        isAttachmentLoading={isAttachmentLoading}
        onDeleteAttachment={this.onDeleteAttachment}
        onUploadAttachment={this.onUploadAttachment}
        // assignees
        isAssigneesLoading={isAssigneesLoading}
        // activities
        projectActivities={projectActivities}
        isActivitiesLoading={isActivitiesLoading}
        fetchProjectActivities={this.fetchProjectActivities}
        // stages
        isStagesLoading={isStagesLoading}
        // actions
        actions={actions}
        isTaskActionsLoading={isTaskActionsLoading}
        onOpenTaskForm={this.onOpenTaskForm}
      />
    );
  }
}

Project.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  project: PropTypes.object,
  isLoading: PropTypes.bool,
  errors: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  currentUser: state.session.currentUser,
  isLoading: state.projects.isLoading,
  errors: state.projects.errors,
  project: state.projects.project,
  isLoadingProposals: state.projects.isLoadingProposals,
  isAttachmentLoading: state.projects.isAttachmentLoading,
  isAssigneesLoading: state.projects.isAssigneesLoading,
  isTasksLoading: state.projects.isTasksLoading,
  isSchedulesLoading: state.projects.isSchedulesLoading,
  isCommentsLoading: state.projects.isCommentsLoading,
  projectActivities: state.projects.projectActivities,
  isActivitiesLoading: state.projects.isActivitiesLoading,
  isStagesLoading: state.projects.isStagesLoading,
  // actions
  actions: state.tasks.actions,
  isTaskActionsLoading: state.tasks.isLoading,
});

export default connect(mapStateToProps)(withRouter(Project));
