// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Form, Input, Col, Row, DatePicker } from "antd";
import SelectFetch from "../../../components/SelectFetch";
import SelectMultiFetch from "../../../components/SelectMultiFetch";
import moment from "moment";
import "moment/locale/ru";

const FormItem = Form.Item;
const { TextArea } = Input;

class General extends Component {
  constructor(props, context) {
    super(props, context);
  }
  render() {
    const {
      isNew,
      ticket,
      getFieldDecorator,
      onChangeCustomer,
      onChangeEntity,
      onChangeEntityService,
      onChangeSubject,
      onChangeDescription,
      onChangeCategory,
      onChangeServices,
    } = this.props;

    return (
      <div>
        <Row gutter={16}>
          <Col md={{ span: 12 }} xs={{ span: 12 }}>
            <FormItem label={"Объект"}>
              {getFieldDecorator("entity", {
                initialValue: ticket.entity ? ticket.entity : null,
                rules: [
                  {
                    required: true,
                    message: "Пожалуйста, введите объект!",
                  },
                ],
              })(
                <SelectFetch
                  showSearch
                  placeholder="Объект"
                  onChange={onChangeEntity}
                  selected={ticket.entity ? ticket.entity : null}
                  url={`/entities/search/?${
                    ticket.customer ? `customer_id=${ticket.customer.id}` : null
                  }`}
                />
              )}
            </FormItem>
          </Col>
          <Col md={{ span: 12 }} xs={{ span: 12 }}>
            <FormItem label={"Раздел"}>
              {getFieldDecorator("category", {
                initialValue: ticket.category ? ticket.category : null,
                rules: [
                  {
                    required: true,
                    message: "Пожалуйста, введите раздел!",
                  },
                ],
              })(
                <SelectFetch
                  disabled={ticket.entity ? false : true}
                  showSearch
                  showSubtitle
                  placeholder="Раздел"
                  onChange={onChangeEntityService}
                  selected={
                    ticket.entity_service ? ticket.entity_service : null
                  }
                  url={ticket.entity ? `/entities/${ticket.entity.id}/entity_services`: null}
                />
              )}
            </FormItem>
          </Col>
        </Row>
        {/* <Row gutter={16}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <FormItem label={"Инциденты"}>
              {getFieldDecorator("services", {
                initialValue: ticket.services ? ticket.services : [],
                rules: [
                  {
                    required: true,
                    message: "Пожалуйста, введите инциденты!",
                  },
                ],
              })(
                <SelectMultiFetch
                  disabled={ticket.entity_service ? false : true}
                  showSearch
                  showSubtitle={true}
                  placeholder="Инциденты"
                  onChange={onChangeServices}
                  selected={ticket.services ? ticket.services : []}
                  url={`/services/search/?category_id=${
                    ticket.category ? ticket.category.id : null
                  }`}
                />
              )}
            </FormItem>
          </Col>
        </Row> */}
        <Row gutter={16}>
          <Col span={24}>
            <FormItem label={"Тема"}>
              {getFieldDecorator("subject", {
                onChange: onChangeSubject,
                initialValue: ticket.subject,
                rules: [
                  {
                    required: true,
                    message: "Пожалуйста, введите тему!",
                  },
                ],
              })(<TextArea autosize={{ minRows: 1, maxRows: 6 }} />)}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <FormItem label={"Описание"}>
              {getFieldDecorator("Описание", {
                onChange: onChangeDescription,
                initialValue: ticket.description,
              })(<TextArea autosize={{ minRows: 2, maxRows: 6 }} />)}
            </FormItem>
          </Col>
        </Row>
      </div>
    );
  }
}

General.propTypes = {
  ticket: PropTypes.object,
  onChangeCustomer: PropTypes.func,
  onChangeEntity: PropTypes.func,
  onChangeEntityService: PropTypes.func,
  onChangeStatus: PropTypes.func,
  onChangepPiority: PropTypes.func,
  onChangeSubject: PropTypes.func,
  onChangeDescription: PropTypes.func,
};

export default General;
