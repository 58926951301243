// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, Card, Form, Select } from "antd";
import User from "./User";
import Documents from "./Documents";
import Assignees from "../../../components/Assignees";

const FormItem = Form.Item;
const Option = Select.Option;

class Statuses extends Component {
  render() {
    const {
      getFieldDecorator,
      user,
      member,
      attachments,
      status,
      supportMessageType,
      onChangeStatus,
      onChangeSupportMessageType,
      // assignees
      isAssigneesLoading,
      onCreateAssignee,
      onDeleteAssignee,
      organisationAssignees
    } = this.props;

    return (
      <Card style={{ borderColor: "#1890ff" }}>
        <Row gutter={10}>
          <Col span={24}>
          <FormItem label={"Статус"}>
            {getFieldDecorator("status", {
              initialValue: status,
              onChange: onChangeStatus,
              rules: [
                {
                  required: true,
                  message: "Введите статус!"
                }
              ]
            })(
              <Select
                placeholder="Статус"
                optionFilterProp="children"
                filterOption={false}
              >
                <Option value={"pending"}>новое</Option>
                <Option value={"processing"}>в работе</Option>
                <Option value={"closed"}>закрыто</Option>
              </Select>
            )}
          </FormItem>
          </Col>
          <Col span={24}>
          <FormItem label={"Тип"}>
            {getFieldDecorator("supportMessageType", {
              initialValue: supportMessageType,
              onChange: onChangeSupportMessageType,
              rules: [
                {
                  required: true,
                  message: "Введите тип!"
                }
              ]
            })(
              <Select
                placeholder="Тип"
                optionFilterProp="children"
                filterOption={false}
              >
                <Option value={"empty"}>нет</Option>
                <Option value={"question"}>вопрос по работе системы</Option>
                <Option value={"failure"}>ошибка</Option>
                <Option value={"proposal"}>предложение по улучшению</Option>
                <Option value={"service"}>системное</Option>
              </Select>
            )}
          </FormItem>
        </Col>
        </Row>
        <Row gutter={10}>
          <Col span={24}>
            <User user={user ? user : member} />
          </Col>
        </Row>
        <Row gutter={10}>
          <Col span={24}>
            <Assignees
              isReadOnly={false}
              isAssigneesLoading={isAssigneesLoading}
              assigneeType="organisation"
              assignees={organisationAssignees}
              onCreateAssignee={onCreateAssignee}
              onDeleteAssignee={onDeleteAssignee}
            />
          </Col>
        </Row>
        <Row gutter={10}>
          <Documents attachments={attachments} />
        </Row>
      </Card>
    );
  }
}
Statuses.propTypes = {
  entity: PropTypes.object,
  getFieldDecorator: PropTypes.func
};

export default Statuses;
