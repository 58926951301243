// @flow
import React, {Component} from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {
  Badge,
  Button,
  Card,
  Col,
  DatePicker,
  Icon,
  Pagination,
  Row,
  Select,
  Spin,
  Table,
  Tag,
  Tooltip,
  Typography
} from "antd";
import AssigneesCell from "../../../components/AssigneesCell";
import SelectMultiFetch from "../../../components/SelectMultiFetch";

import moment from "moment";
import "moment/locale/ru";

const { Paragraph } = Typography;
const { RangePicker } = DatePicker;

function statuses(status) {
  switch (status) {
    case "pended":
      return "error";
    case "accepted":
      return "processing";
    case "closed":
      return "success";
    default:
      return "default";
  }
}

class Tickets extends Component {
  render() {
    const {
      isLoading,
      fetchParams,
      onChangePage,
      onChangeLimit,
      meta,
      tickets,
      onChangePeriod,
      onChangeCategories,
      onChangeServices,
      onDownloadReport,
    } = this.props;

    const columns = [
      {
        title: "Статус Приоритет Замена",
        dataIndex: "status",
        render: (status, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>
              <Badge
                status={statuses(record.status.id)}
                text={record.status.name}
              />
            </span>
            <span style={{ opacity: "0.7", fontSize: "12px" }}>
              {record.ticket_priority ? record.ticket_priority.name : null}
            </span>
            <span style={{ opacity: "0.7", fontSize: "12px" }}>
              {record.is_replaced ? "замена" : null}
            </span>
          </div>
        ),
        key: "status",
        align: "center",
        width: "10%",
      },
      {
        title: "#",
        dataIndex: "number",
        render: (number, record) => (
          <Link to={`/tickets/${record.id}/edit`}>{number}</Link>
        ),
        key: "number",
        align: "center",
        width: "10%",
      },
      {
        title: "Дата",
        dataIndex: "created_at",
        render: (created_at) => moment(created_at).format("L"),
        key: "created_at",
        align: "center",
        width: "10%",
      },
      {
        title: "Выполнение",
        dataIndex: "finish",
        render: (finish) => (finish ? moment(finish).format("L") : null),
        key: "finish",
        align: "center",
        width: "10%",
      },
      {
        title: "Тема / Раздел",
        dataIndex: "subject",
        render: (subject, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>
              <Paragraph ellipsis={{ rows: 3, expandable: true }}>
                {subject}
              </Paragraph>
            </span>
            <span>
              {record.category ?
                <Tag key={record.category.id} color="#108ee9">
                  {record.category.name}
                </Tag> :
                record.entity_service ?
                  <Tag key={record.entity_service.id} color="#108ee9">
                    {record.entity_service.name}
                  </Tag> :
                  null}
            </span>
          </div>
        ),
        align: "left",
        width: "23%",
      },
      {
        title: "Ответственный",
        dataIndex: "assignees",
        render: (assignees, record) => <AssigneesCell assignees={assignees} />,
        align: "center",
        width: "18%",
      },
    ];

    let startCreatedDate = fetchParams.startCreatedDate;
    let endCreatedDate = fetchParams.endCreatedDate;
    let services = fetchParams.services;

    return (
      <React.Fragment>
        <Card size="small" title="Фильтры">
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <SelectMultiFetch
                style={{ width: "100%" }}
                showSearch
                showSubtitle
                placeholder="Раздел"
                onChange={onChangeServices}
                selected={services ? services : []}
                url={`/services/search/?`}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={9} xl={9}>
              <RangePicker
                showTime={false}
                format={"DD.MM.YYYY"}
                placeholder={[
                  "начало (дата создания)",
                  "окончание (дата создания)",
                ]}
                style={{ width: "100%" }}
                value={[
                  startCreatedDate ? moment(startCreatedDate, "DD.MM.YYYY") : null,
                  endCreatedDate ? moment(endCreatedDate, "DD.MM.YYYY") : null,
                ]}
                onChange={onChangePeriod}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={9} xl={9}>
              <Tooltip placement="bottom" title={"Выгрузить в Excel"}>
                <Button key="excelButton" type="dashed" onClick={onDownloadReport}>
                  <Icon type="file-excel" style={{color: "green"}}/>
                  Выгрузить
                </Button>
              </Tooltip>
            </Col>
          </Row>
        </Card>
        <Row gutter={{ md: 8, lg: 24, xl: 48 }} style={{ paddingTop: 10 }}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Table
              loading={isLoading}
              size={"middle"}
              bordered
              pagination={false}
              columns={columns}
              dataSource={tickets}
              rowKey="id"
            />
          </Col>
          <Col
            span={24}
            style={{
              paddingTop: "15px",
              paddingBottom: "15px",
              textAlign: "right",
            }}
          >
            <Pagination
              showSizeChanger
              pageSize={fetchParams.limit}
              current={meta.current_page}
              onChange={onChangePage}
              onShowSizeChange={onChangeLimit}
              total={meta.total_count}
            />
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

Tickets.propTypes = {
  orderTickets: PropTypes.arrayOf(PropTypes.object),
  total: PropTypes.number,
  discount: PropTypes.object,
  shippingCost: PropTypes.number,
  amountDiscountCode: PropTypes.number,
  onRemoveVariant: PropTypes.func,
};
export default Tickets;
