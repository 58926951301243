// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Modal } from "antd";

class ModalForm extends Component {

  render() {
    const { visible, loading, faq, onOk, onCancel } = this.props;

    return (
      <React.Fragment>
        <Modal
          loading={loading}
          width={800}
          title={faq.title}
          style={{ top: 20 }}
          visible={visible}
          onOk={onOk}
          onCancel={onCancel}
          destroyOnClose
        >
          <div
            style={{ overflow: "scroll" }}
            dangerouslySetInnerHTML={{ __html: faq.body }}
          ></div>
        </Modal>
      </React.Fragment>
    );
  }
}
ModalForm.propTypes = {
  faq: PropTypes.object,
};

export default ModalForm;
