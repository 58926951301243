// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, Descriptions, Typography, Form, Checkbox } from "antd";
import moment from "moment";
import "moment/locale/ru";
import _ from "lodash/array";
import User from "./User";
import Documents from "./Documents";
import Assignees from "../../../components/Assignees";
import SelectFetch from "../../../components/SelectFetch";

const FormItem = Form.Item;
const { Paragraph } = Typography;

class StatusesInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAddingAssignee: false,
      assignee: null,
    };
  }

  onUpload = ({ onSuccess, onError, file }) => {
    this.props.onUploadAttachment(file, 2);
  };

  render() {
    const {
      getFieldDecorator,
      currentMember,
      isNew,
      ticket,
      isSupplier,
      isUploading,
      onDeleteAttachment,
      onChangeNumber,
      // assignees
      organisationAssignees,
      supervisorAssignees,
      companyAssignees,
      customerAssignees,
      isAssigneesLoading,
      onDeleteAssignee,
      onCreateAssignee,
      // statuses
      onChangeStatus,
      onChangeReplaced,
      onChangeOrdered,
      // documents
      attachments,
    } = this.props;

    let isStatusVisible = false;
    let index = customerAssignees.findIndex(function (o) {
      return o.member.id == currentMember.member_id;
    });

    if (index != -1) {
      isStatusVisible = true;
    }

    if (ticket.member && ticket.member.id == currentMember.member_id) {
      isStatusVisible = true;
    }

    return (
      <React.Fragment>
        <Row gutter={10}>
          {ticket.is_ticket_replace_available ? (
            <Col span={12}>
              <FormItem>
                {getFieldDecorator("isReplaced", {
                  onChange: onChangeReplaced,
                  valuePropName: "checked",
                  initialValue: ticket.is_replaced,
                })(<Checkbox>Требуется замена</Checkbox>)}
              </FormItem>
            </Col>
          ) : null}
          <Col span={12}>
            <FormItem>
              {getFieldDecorator("isOrdered", {
                onChange: onChangeOrdered,
                valuePropName: "checked",
                initialValue: ticket.is_ordered,
              })(<Checkbox>Перенесено в заказ</Checkbox>)}
            </FormItem>
          </Col>

          <Col span={12}>
            <FormItem label={"Статус"}>
              {getFieldDecorator("status", {
                initialValue: ticket.status ? ticket.status : null,
              })(
                <SelectFetch
                  placeholder="Статус"
                  onChange={onChangeStatus}
                  selected={ticket.status ? ticket.status : null}
                  url={`/tickets/${ticket.id}/statuses`}
                />
              )}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col span={24}>
            <Descriptions bordered>
              <Descriptions.Item label="Номер" span={3}>
                <Paragraph editable={{ onChange: onChangeNumber }}>
                  {ticket.number}
                </Paragraph>
              </Descriptions.Item>
              <Descriptions.Item label="Плановая дата завершения заявки" span={3}>
                {ticket.due_by ? moment(ticket.due_by).format("lll") : null}
              </Descriptions.Item>
              <Descriptions.Item label="Приоритет" span={3}>
                {ticket.ticket_priority ? ticket.ticket_priority.name : null}
              </Descriptions.Item>
              <Descriptions.Item label="Тип инцидента" span={3}>
                {ticket.incident_locale ? ticket.incident_locale : null}
              </Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>

        <Row gutter={10}>
          <Col span={24}>
            <User user={ticket.user ? ticket.user : ticket.member} />
          </Col>
        </Row>

        <Row gutter={10}>
          {isSupplier ? (
            <Col span={24}>
              <Assignees
                isReadOnly={true}
                isAssigneesLoading={isAssigneesLoading}
                assigneeType="company"
                assignees={companyAssignees}
                onCreateAssignee={onCreateAssignee}
                onDeleteAssignee={onDeleteAssignee}
              />
            </Col>
          ) : (
            <Col span={24}>
              <Assignees
                isReadOnly={true}
                isAssigneesLoading={isAssigneesLoading}
                assigneeType="organisation"
                assignees={organisationAssignees}
                onCreateAssignee={onCreateAssignee}
                onDeleteAssignee={onDeleteAssignee}
              />
            </Col>
          )}
        </Row>
        <Row gutter={10}>
          <Col span={24}>
            <Assignees
              title={"От заказчика"}
              isReadOnly={isNew ? true : false}
              isAssigneesLoading={isAssigneesLoading}
              assigneeType="customer"
              assignees={customerAssignees}
              onCreateAssignee={onCreateAssignee}
              onDeleteAssignee={onDeleteAssignee}
            />
          </Col>
        </Row>
        {ticket.is_ticket_replace_available ? (
          ticket.is_replaced ? (
            <Row gutter={10}>
              <Documents
                title={"Дефектовочный акт"}
                onUpload={this.onUpload}
                isUploading={isUploading}
                attachments={attachments}
                onDeleteAttachment={onDeleteAttachment}
              />
            </Row>
          ) : null
        ) : null}
      </React.Fragment>
    );
  }
}
StatusesInfo.propTypes = {
  invoice: PropTypes.object,
  getFieldDecorator: PropTypes.func,
};

export default StatusesInfo;
