// @flow
import React, { Component } from "react";
import { Row, Col, Popover, Table } from "antd";
import { Link } from "react-router-dom";
import moment from "moment";
import "moment/locale/ru";
import api from "../../../api";

export default class Comments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      fetching: true,
    };
  }

  fetchComments = async (userId, rate, startDate, endDate) => {
    const response = await api.fetch(`/reports/employee_rate_comments`, {
      user_id: userId,
      rate: rate,
      startDate: startDate,
      endDate: endDate,
    });
    const data = response.data;
    this.setState({
      data: data,
      fetching: false,
    });
  };

  render() {
    const { fetching, data } = this.state;
    const { count, userId, rate, startDate, endDate } = this.props;

    const content = (
      <div style={{ width: 560 }}>
        <CommentsTable loading={fetching} data={data} />
      </div>
    );

    return (
      <Popover
        placement="left"
        content={content}
        title="Комментарии"
        trigger="click"
      >
        <a
          onClick={this.fetchComments.bind(
            this,
            userId,
            rate,
            startDate,
            endDate
          )}
        >
          {count}
        </a>
      </Popover>
    );
  }
}

function CommentsTable({ loading = true, data = [] }) {
  const columns = [
    {
      title: "#",
      dataIndex: "number",
      render: (number, record) => (
        <Link to={`/${record.path}/${record.ticket_id}/edit`} target="_blank">
          {number}
        </Link>
      ),

      key: "number",
      align: "center",
      width: "20%",
    },
    {
      title: "Пользователь",
      dataIndex: "name",
      key: "name",
      align: "center",
      width: "30%",
    },
    {
      title: "Комментарий",
      dataIndex: "comment",
      key: "comment",
      align: "center",
      width: "50%",
    },
  ];

  return (
    <Row style={{ width: 560 }}>
      <Col span={24}>
        <Table
          rowClassName={"progress-row"}
          size={"small"}
          loading={loading}
          columns={columns}
          dataSource={data}
          pagination={false}
          rowKey="id"
        />
      </Col>
    </Row>
  );
}
