import api from "../api";

const Actions = {};

Actions.fetchEntityTasks = () => {
  return (dispatch, getState) => {
    dispatch({ type: "FETCH_ENTITY_TASKS_REQUEST" });
    let users = [];
    let members = [];
    const params = getState().entityTasks.fetchParams;
    if (params.users) {
      users = params.users.map(user => {
        if (user && user.id) {
          return user.id;
        }
      });
    }
    if (params.members) {
      members = params.members.map(member => {
        if (member && member.id) {
          return member.id;
        }
      });
    }
    const data = {
      startDate: params.startDate,
      endDate: params.endDate,
      search: params.search,
      sort: params.sort,
      direction: params.direction,
      page: params.page,
      limit: params.limit,
      status: params.status,
      users: users,
      members: members
    };

    return api
      .fetch("/entity_tasks", data)
      .then(response => {
        dispatch({ type: "FETCH_ENTITY_TASKS", response });
      })
      .catch(response => {
        console.log(response.error);
        dispatch({ type: "FETCH_ENTITY_TASKS_FAILURE", response });
      });
  };
};

Actions.fetchEntityTask = (id = null) => {
  return dispatch => {
    dispatch({ type: "FETCH_ENTITY_TASK_REQUEST" });
    return api
      .fetch(`/entity_tasks/${id}`)
      .then(response => {
        dispatch({ type: "FETCH_ENTITY_TASK", response });
      })
      .catch(error => {
        if (error.response.status == 404) {
          window.location = "/notfound";
        }
        if (error.errors) {
          error.errors.map(error => {
            return api.openNotification("error", "Регламент", error);
          });
        }
        dispatch({ type: "FETCH_ENTITY_TASK_FAILURE", error });
      });
  };
};

// comments
Actions.fetchEntityTaskComments = id => {
  return dispatch => {
    dispatch({ type: "FETCH_ENTITY_TASK_COMMENTS_REQUEST" });
    api
      .fetch(`/entity_tasks/${id}/entity_task_comments`)
      .then(response => {
        dispatch({ type: "FETCH_ENTITY_TASK_COMMENTS", response });
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            return api.openNotification("error", "Заявка", error);
          });
        }
        dispatch({ type: "FETCH_ENTITY_TASK_COMMENTS_FAILURE", response });
      });
  };
};

Actions.onCreateComment = (id, value, mentioned) => {
  return (dispatch, getState) => {
    dispatch({ type: "POST_ENTITY_TASK_COMMENT_REQUEST" });

    const mentioned_user_ids = mentioned.users
      ?.map((it) => it.id)
      ?.map((it) => parseInt(it) || null)
      ?.filter((it) => it !== null);
    const mentioned_member_ids = mentioned.members
      ?.map((it) => it.id)
      ?.map((it) => parseInt(it) || null)
      ?.filter((it) => it !== null);

    return api
      .post(`/entity_tasks/${id}/entity_task_comments`, {
        entity_task_comment: {
          comment: value,
          mentioned_user_ids: mentioned_user_ids,
          mentioned_member_ids: mentioned_member_ids,
        }
      })
      .then(response => {
        dispatch({ type: "POST_ENTITY_TASK_COMMENT", response });
        api.openNotification("success", "Комментарий", "Успешно создан!");
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            return api.openNotification("error", "Комментарий", error);
          });
        }
        dispatch({ type: "POST_ENTITY_TASK_COMMENT_FAILURE", response });
      });
  };
};

// assignees
Actions.fetchAssignees = id => {
  return dispatch => {
    dispatch({ type: "FETCH_ASSIGNEES_REQUEST" });
    api
      .fetch(`/entity_tasks/${id}/entity_task_assignees`)
      .then(response => {
        dispatch({ type: "FETCH_ASSIGNEES", response });
      })
      .catch(response => {
        console.log(response.error);
        dispatch({ type: "FETCH_ASSIGNEES_FAILURE", response });
      });
  };
};

Actions.onCreateAssignee = (id, assignee, type) => {
  return (dispatch) => {
    dispatch({ type: "POST_ASSIGNEE_REQUEST" });
    return api
      .post(`/entity_tasks/${id}/entity_task_assignees`, {
        entity_task_assignee: {
          assignee_type: type,
          user_id:
            type == "organisation" || type == "supervisor" ? assignee.id : null,
          member_id:
            type == "organisation" || type == "supervisor" ? null : assignee.id,
        },
      })
      .then((response) => {
        dispatch({ type: "POST_ASSIGNEE", response });
        api.openNotification("success", "Ответственный", "Успешно добавлен!");
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Ответственный", error);
          });
        }
        dispatch({ type: "POST_ASSIGNEE_FAILURE", response });
      });
  };
};

Actions.onDeleteAssignee = (id, assignee_id) => {
  return (dispatch) => {
    dispatch({
      type: "ASSIGNEE_DELETE_REQUEST",
    });
    return api
      .delete(`/entity_tasks/${id}/entity_task_assignees/${assignee_id}`)
      .then((response) => {
        api.openNotification("success", "Ответственный", "Успешно удален!");
        dispatch({ type: "ASSIGNEE_DELETE_SUCCEED", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Ответственный", error);
          });
        }
        dispatch({ type: "ASSIGNEE_DELETE_FAILED" });
      });
  };
};

// attachments
Actions.fetchAttachments = id => {
  return dispatch => {
    dispatch({ type: "FETCH_ATTACHMENTS_REQUEST" });
    api
      .fetch(`/entity_tasks/${id}/entity_task_attachments`)
      .then(response => {
        // console.log(response)
        dispatch({ type: "FETCH_ATTACHMENTS", response });
      })
      .catch(response => {
        console.log(response.error);
        dispatch({ type: "FETCH_ATTACHMENTS_FAILURE", response });
      });
  };
};

Actions.onUploadAttachment = (id, file) => {
  return dispatch => {
    dispatch({
      type: "ATTACHMENT_UPLOAD"
    });
    // console.log(file);
    const formData = new FormData();

    formData.append("content_type", file.type);
    formData.append("attachment", file);
    return api
      .postFormData(`/entity_tasks/${id}/entity_task_attachments`, formData)
      .then(response => {
        api.openNotification("success", "Файл", "Успешно загружен!");
        dispatch({ type: "ATTACHMENT_UPLOAD_SUCCEED", response });
      })
      .catch(response => {
        dispatch({
          type: "ATTACHMENT_UPLOAD_FAILED"
        });
        dispatch({
          type: "ADD_NOTIFICATION",
          message: { label: response.error, type: "error" }
        });
      });
  };
};

Actions.onDeleteAttachment = (entity_task_id, id) => {
  return dispatch => {
    dispatch({
      type: "ATTACHMENT_DELETE_REQUEST"
    });
    return api
      .delete(`/entity_tasks/${entity_task_id}/entity_task_attachments/${id}`)
      .then(response => {
        api.openNotification("success", "Файл", "Успешно удален!");
        dispatch({ type: "ATTACHMENT_DELETE_SUCCEED", response });
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            api.openNotification("error", "Файл", error);
          });
        }
        dispatch({ type: "ATTACHMENT_DELETE_FAILED" });
      });
  };
};

// events
Actions.fetchEvents = (startDate, endDate) => {
  return (dispatch, getState) => {
    dispatch({ type: "FETCH_ENTITY_TASKS_EVENTS_REQUEST" });
    const params = { startDate: startDate, endDate: endDate };
    api
      .fetch("/entity_tasks/events", params)
      .then(response => {
        dispatch({ type: "FETCH_ENTITY_TASKS_EVENTS", response });
      })
      .catch(response => {
        console.log(response.error);
        dispatch({ type: "FETCH_ENTITY_TASKS_EVENTS_FAILURE", response });
      });
  };
};

Actions.onEntityTaskTodoSaveComment = (
  entityTaskTodoId,
  id,
  entityTaskTodoIdx,
  idx,
  value
) => {
  return (dispatch) => {
    dispatch({ type: "POST_ENTITY_TASK_TODO_COMMENT_REQUEST" });
    const data = { entity_task_todo_item: { comment: value } };
    return api
      .patch(
        `/entity_task_todos/${entityTaskTodoId}/entity_task_todo_items/${id}`,
        data
      )
      .then((response) => {
        dispatch({
          type: "POST_ENTITY_TASK_TODO_COMMENT",
          response: response,
          entityTaskTodoIdx: entityTaskTodoIdx,
          idx: idx,
        });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Регламент", error);
          });
        }
        dispatch({ type: "POST_ENTITY_TASK_TODO_COMMENT_FAILURE", response });
      });
  };
};

Actions.onSearch = value => {
  return dispatch => {
    dispatch({
      type: "REQUEST_ENTITY_TASK_SEARCH",
      value: value
    });
    return Promise.resolve();
  };
};

Actions.onChangePage = value => {
  return dispatch => {
    dispatch({
      type: "REQUEST_ENTITY_TASK_CHANGE_PAGE",
      value: value
    });
    return Promise.resolve();
  };
};

Actions.onChangeLimit = value => {
  return dispatch => {
    dispatch({
      type: "REQUEST_ENTITY_TASK_CHANGE_LIMIT",
      value: value
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterActive = value => {
  return dispatch => {
    dispatch({
      type: "REQUEST_ENTITY_TASKS_FILTER_ACTIVE",
      value: value
    });
    return Promise.resolve();
  };
};

Actions.onSortBy = (field, direction) => {
  return dispatch => {
    dispatch({
      type: "ENTITY_TASKS_SORT_BY",
      field: field,
      direction: direction
    });
    return Promise.resolve();
  };
};

// tasks
Actions.fetchTasks = (id) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_ENTITY_TASK_TASKS_REQUEST" });
    return api
      .fetch(`/entity_tasks/${id}/tasks`)
      .then((response) => {
        dispatch({ type: "FETCH_ENTITY_TASK_TASKS", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Регламент", error);
          });
        }
        dispatch({
          type: "FETCH_ENTITY_TASK_TASKS_FAILURE",
        });
      });
  };
};

// filters
Actions.onSetFilters = () => {
  return dispatch => {
    dispatch({
      type: "ENTITY_TASKS_SET_FILTERS"
    });
    return Promise.resolve();
  };
};

Actions.onClearFilters = value => {
  return dispatch => {
    dispatch({
      type: "ENTITY_TASKS_CLEAR_FILTERS",
      value: value
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterStatus = value => {
  return dispatch => {
    dispatch({
      type: "ENTITY_TASKS_FILTER_STATUS",
      value: value
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterPeriod = (startDate, endDate) => {
  return dispatch => {
    dispatch({
      type: "ENTITY_TASKS_FILTER_PERIOD",
      startDate: startDate,
      endDate: endDate
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterUsers = (values) => {
  return dispatch => {
    dispatch({
      type: "ENTITY_TASKS_FILTER_USERS",
      values: values,
    });
    return Promise.resolve();
  };
};

// notices
Actions.onClearNotices = () => {
  return dispatch => {
    dispatch({ type: "POST_ENTITY_TASK_MARK_ALL_AS_READ_REQUEST" });

    return api
      .post(`/entity_task_comment_marks/mark_all_as_read`)
      .then(response => {
        api.openNotification(
          "success",
          "Регламент",
          "Все комментарии отмечены как прочитанные!"
        );
        dispatch({ type: "POST_ENTITY_TASK_MARK_ALL_AS_READ", response });
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            api.openNotification("error", "Заявка", error);
          });
        }
        dispatch({
          type: "POST_ENTITY_TASK_MARK_ALL_AS_READ_FAILURE"
        });
      });
  };
};

export default Actions;
