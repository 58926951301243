// @flow
import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Row, Col, Table, Button, Tabs, Progress, Dropdown, Menu } from "antd";
import update from "immutability-helper";

import moment from "moment";
import "moment/locale/ru";

const { TabPane } = Tabs;

class Stages extends Component {
  // actions
  ActionMenu = (record) => {
    const { actions } = this.props;
    return (
      <Menu>
        {actions.map((action) => {
          return (
            <Menu.Item
              key={action.id}
              onClick={this.onAction.bind(this, action.id, record.id)}
            >
              {action.name}
            </Menu.Item>
          );
        })}
      </Menu>
    );
  };

  onAction = (actionId, id) => {
    const { actions, onOpenTaskForm } = this.props;
    let idx = actions.findIndex(function (o) {
      return o.id == actionId;
    });
    if (idx === -1) {
      return null;
    }
    onOpenTaskForm(actions[idx], id);
  };

  render() {
    const { actions, stages, isLoading } = this.props;

    const columns = [
      {
        title: "Выполнение",
        dataIndex: "complete",
        render: (complete, record) => (
          <Progress percent={Number(complete)} size="small" />
        ),
        width: "20%",
        align: "center",
      },
      {
        title: "Наименование",
        dataIndex: "project_stage",
        render: (project_stage, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span style={{ fontSize: 12 }}>{project_stage.name}</span>
            <span style={{ fontSize: 12 }}>{record.description}</span>
          </div>
        ),
        width: "35%",
        align: "center",
      },
      {
        title: "План",
        dataIndex: "start",
        render: (start, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span style={{ fontSize: 12 }}>
              {start ? moment(start).format("L") : "-"}
            </span>
            <span style={{ fontSize: 12 }}>
              {moment(record.plan_start).format("L")}
            </span>
          </div>
        ),
        width: "17%",
        align: "center",
      },
      {
        title: "Факт",
        dataIndex: "finish",
        render: (finish, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span style={{ fontSize: 12 }}>
              {finish ? moment(finish).format("L") : "-"}
            </span>
            <span style={{ fontSize: 12 }}>
              {moment(record.plan_finish).format("L")}
            </span>
          </div>
        ),
        width: "17%",
        align: "center",
      },
      {
        title: "#",
        dataIndex: "actions",
        render: (actions, record) =>
          actions ? (
            <Dropdown overlay={this.ActionMenu(record)}>
              <Button icon={"more"} />
            </Dropdown>
          ) : null,
        width: "6%",
        align: "center",
      },
    ];

    return (
      <Tabs defaultActiveKey="1" onChange={this.callback}>
        <TabPane tab="Этапы" key="1">
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col span={24}>
              <Table
                loading={isLoading}
                size={"small"}
                pagination={false}
                columns={columns}
                dataSource={stages}
                rowKey="id"
              />
            </Col>
          </Row>
        </TabPane>
      </Tabs>
    );
  }
}

Stages.propTypes = {
  orderStages: PropTypes.arrayOf(PropTypes.object),
  total: PropTypes.number,
  discount: PropTypes.object,
  shippingCost: PropTypes.number,
  amountDiscountCode: PropTypes.number,
  onRemoveVariant: PropTypes.func,
};
export default Stages;
