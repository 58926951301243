import React, {Fragment, PureComponent} from "react";
import ReactDOM from "react-dom";
import {withRouter} from "react-router-dom";
import {Badge, Button, Spin} from "antd";
import classNames from "classnames";
import HeaderDropdown from "../HeaderDropdown";
import NoticeList from "./NoticeList";

// import "./index.less";

class NoticeIcon extends PureComponent {

  static defaultProps = {
    onItemClick: () => {
    },
    onPopupVisibleChange: () => {
    },
    onViewMore: () => {
    },
    onClear: () => {
    },
    loading: false,
    clearClose: false,
    locale: {
      emptyText: "Нет уведомлений",
      clear: "Открыть",
      viewMore: "Открыть",
      create: "Создать"
    },
    emptyImage: null
  };

  state = {
    visible: false
  };

  onAchive = () => {
    this.props.history.push(`/notices`);
  }

  onItemClick = (item) => {
    const {onItemClick} = this.props;
    if (onItemClick) {
      onItemClick(item);
    }
  };

  onClear = () => {
    const {onClear} = this.props;
    if (onClear) {
      this.setState({visible: false}, () => {
        onClear();
      })
    }
  };

  onTabChange = tabType => {
    const {onTabChange} = this.props;
    onTabChange(tabType);
  };

  onCreate = (tabProps, event) => {
    const {onCreate} = tabProps;
    // console.log(tabProps)
    onCreate(tabProps, event);
  };

  getNotificationBox() {
    const {
      loading,
      locale,
      list,
      emptyText,
      emptyImage,
      showClear,
      showOnCreate,
      showOnViewMore,
      onClear
    } = this.props;

    const pane = (
      <NoticeList
        data={list}
        emptyImage={emptyImage}
        emptyText={emptyText}
        locale={locale}
        onAchive={this.onAchive}
        onClick={item => this.onItemClick(item)}
        onClear={this.onClear}
        showClear={showClear}
        showOnViewMore={showOnViewMore}
        showOnCreate={showOnCreate}
      />
    );

    return (
      <Fragment>
        <Spin spinning={loading} delay={0}>
          {pane}
        </Spin>
      </Fragment>
    );
  }

  handleVisibleChange = visible => {
    const {onPopupVisibleChange} = this.props;
    this.setState({visible});
    onPopupVisibleChange(visible);
  };

  render() {
    const {className, count, popupVisible, bell} = this.props;
    const {visible} = this.state;
    const noticeButtonClass = classNames(className, "action noticeButton");
    const notificationBox = this.getNotificationBox();
    const NoticeBellIcon = <Button icon="bell"/>;
    const trigger = (
      <span
        className={classNames(noticeButtonClass, {opened: visible})}
        style={{paddingRight: 20}}
      >
        <Badge className={"badge"}
               style={{boxShadow: "none"}}
               count={count}
               overflowCount={19}>
          {NoticeBellIcon}
        </Badge>
      </span>
    );
    if (!notificationBox) {
      return trigger;
    }
    const popoverProps = {};
    if ("popupVisible" in this.props) {
      popoverProps.visible = popupVisible;
    }
    return (
      <HeaderDropdown
        placement="bottomRight"
        overlay={notificationBox}
        overlayClassName={"popoverButton"}
        trigger={["click"]}
        visible={visible}
        onVisibleChange={this.handleVisibleChange}
        {...popoverProps}
        ref={node => (this.popover = ReactDOM.findDOMNode(node))} // eslint-disable-line
      >
        {trigger}
      </HeaderDropdown>
    );
  }
}

export default withRouter(NoticeIcon);
