// @flow
import React, { Component } from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Row,
  Card,
  Col,
  Form,
  Icon,
  Input,
  Button,
  Divider,
  List,
  Avatar,
  Typography,
  Descriptions,
  Rate,
} from "antd";

const FormItem = Form.Item;
const { TextArea } = Input;

const desc = ["очень плохо", "плохо", "удовлетворительно", "хорошо", "отлично"];

class RateForm extends Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  render() {
    const {
      isLoading,
      rateObject,
      rate,
      comment,
      onSave,
      onChangeRate,
      onChangeComment,
    } = this.props;
    const { getFieldDecorator } = this.props.form;

    const organisationAssignees = rateObject.assignees.filter(function (item) {
      return item.assignee_type == "organisation";
    });

    return (
      <Row
        type="flex"
        justify="center"
        align="middle"
        style={{
          height: "100vh",
        }}
      >
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form layout={"vertical"} onSubmit={this.onSubmit}>
            <Card style={{ borderColor: "#1890ff" }} loading={isLoading}>
              <Row>
                <Divider style={{opacity: 1}}>
                  <Link to={`/tickets/${rateObject.id}/edit`}>{rateObject.title}</Link>
                </Divider>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Descriptions bordered>
                    <Descriptions.Item label="Объект" span={3}>
                      {rateObject.entity ? rateObject.entity.name : null}
                    </Descriptions.Item>
                    <Descriptions.Item label="Адрес" span={3}>
                      {rateObject.address}
                    </Descriptions.Item>
                    <Descriptions.Item label="Тема" span={3}>
                      {rateObject.subject ? rateObject.subject : null}
                    </Descriptions.Item>
                    <Descriptions.Item label="Описание" span={3}>
                      {rateObject.description ? rateObject.description : null}
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
              </Row>
              <Divider>Исполнители</Divider>
              <Row>
                <List
                  grid={{ gutter: 16, column: 2 }}
                  itemLayout="horizontal"
                  dataSource={organisationAssignees}
                  renderItem={(item) => (
                    <List.Item>
                      <List.Item.Meta
                        avatar={
                          <Avatar src={item.user ? item.user.avatar : null} />
                        }
                        title={item.user.name}
                        description={`${item.user.title}`}
                      />
                    </List.Item>
                  )}
                />
              </Row>
              <Divider>Рейтинг</Divider>
              <Row type="flex" justify="center" align="middle">
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  style={{ textAlign: "center" }}
                >
                  <Rate
                    style={{ fontSize: 40 }}
                    tooltips={desc}
                    allowHalf
                    value={
                      rateObject.is_rated ? parseFloat(rateObject.rate) : rate
                    }
                    onChange={onChangeRate}
                    disabled={rateObject.is_rated ? true : false}
                  />
                </Col>
                <Col span={24}>
                  <FormItem label={"Комментарий"}>
                    {getFieldDecorator("comment", {
                      onChange: onChangeComment,
                      initialValue: rateObject.is_rated
                        ? rateObject.comment
                        : comment,
                    })(
                      <TextArea
                        autoSize={{ minRows: 2, maxRows: 6 }}
                        disabled={rateObject.is_rated ? true : false}
                      />
                    )}
                  </FormItem>
                </Col>
                {rateObject.is_rated ? null : (
                  <Col span={24} style={{ textAlign: "center" }}>
                    <Button
                      type="primary"
                      size={"large"}
                      disabled={rate > 0 ? false : true}
                      onClick={onSave}
                    >
                      Отправить
                    </Button>
                  </Col>
                )}
              </Row>
            </Card>
          </Form>
        </Col>
      </Row>
    );
  }
}

RateForm.propTypes = {
  dispatch: PropTypes.func,
};

export default withRouter(Form.create()(RateForm));
