import update from "immutability-helper";
import _ from "lodash/array";

const initialState = {
  tasks: [],
  task: { assignees: [], fields: [] },
  actions: [],
  taskTimings: [],
  notices: [],
  activities: [],
  unread: 0,
  meta: {
    total_pages: 1,
    current_page: 1,
    total_count: 1,
  },
  fetchParams: {
    startDate: null,
    endDate: null,
    startFinishDate: null,
    endFinishDate: null,
    search: null,
    sort: null,
    direction: null,
    page: 1,
    limit: 15,
    status: null,
    taskUsers: null,
    users: null,
    supervisorUsers: null,
    templates: null,
  },
  errors: false,
  isLoading: true,
  isVisible: false,
  isCommentsLoading: false,
  isAttachmentLoading: false,
  isTimingLoading: false,
  isTodoLoading: false,
  isActivitiesLoading: true,
};

function loadLocalFilters(fetchParams) {
  let localFetchParams = null;
  const tasksFilters = localStorage.getItem("tasksFilters");
  if (tasksFilters) {
    localFetchParams = JSON.parse(tasksFilters);
  } else {
    localStorage.setItem("tasksFilters", JSON.stringify(fetchParams));
  }

  return localFetchParams;
}

export default function (state = initialState, action) {
  let localFetchParams = null;
  let idx = -1;
  switch (action.type) {
    case "FETCH_TASKS_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_TASKS":
      return {
        ...state,
        tasks: action.response.data,
        meta: action.response.meta,
        notices: action.response.notices,
        unread: action.response.unread,
        isLoading: false,
      };
    case "FETCH_TASK_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_TASK":
      return {
        ...state,
        task: action.response.data,
        isLoading: false,
      };

    case "FETCH_TASK_NEW_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_TASK_NEW":
      return {
        ...state,
        task: action.response.data,
        isLoading: false,
        isVisible: true,
      };

    case "FETCH_TASK_NEW_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true,
      };

    case "FETCH_TASK_ACTIONS_REQUEST":
      return {
        ...state,
        isTaskActionsLoading: false,
        errors: false,
      };
    case "FETCH_TASK_ACTIONS":
      return {
        ...state,
        actions: action.response.data,
        isTaskActionsLoading: true,
        errors: false,
      };
    case "FETCH_TASK_ACTIONS_FAILURE":
      return {
        ...state,
        isTaskActionsLoading: false,
        errors: true,
      };

    case "PATCH_TASK_REQUEST":
      return {
        ...state,
        isLoading: true,
        errors: false,
      };
    case "PATCH_TASK":
      return {
        ...state,
        task: action.response.data,
        isLoading: false,
        errors: false,
      };

    case "PATCH_TASK_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true,
      };

    case "TASK_REQUEST":
      return {
        ...state,
        errors: false,
        isLoading: true,
      };
    case "TASK_SUCCEED":
      return {
        ...state,
        errors: false,
        // task: action.response.data,
        isLoading: false,
        isVisible: false,
      };

    case "TASK_FAILURE":
      return {
        ...state,
        errors: true,
        isLoading: false,
      };

    case "DELETE_TASK_REQUEST":
      return {
        ...state,
        isLoading: true,
        errors: false,
      };
    case "DELETE_TASK":
      return {
        ...state,
        isLoading: false,
        errors: false,
      };
    case "DELETE_TASK_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true,
      };

    case "REQUEST_TASK_SEARCH":
      return update(state, {
        fetchParams: { search: { $set: action.value }, page: { $set: 1 } },
      });

    case "REQUEST_TASK_CHANGE_PAGE":
      return update(state, {
        fetchParams: { page: { $set: action.value } },
      });

    case "REQUEST_TASK_CHANGE_LIMIT":
      return update(state, {
        fetchParams: { limit: { $set: action.value }, page: { $set: 1 } },
      });

    case "REQUEST_TASKS_FILTER_ACTIVE":
      return update(state, {
        fetchParams: { active: { $set: action.value } },
      });

    case "TASKS_SORT_BY":
      return update(state, {
        fetchParams: {
          sort: { $set: action.field },
          direction: { $set: action.direction },
        },
      });

    case "TASK_NEW":
      return update(state, {
        task: {
          task_template: { $set: action.value },
          status: { $set: "pending" },
          due_by: { $set: null },
          subject: { $set: null },
          description: { $set: null },
          owner_id: { $set: action.id },
          assignees: { $set: [] },
        },
        isVisible: { $set: false },
      });

    case "TASK_OPEN_MODAL_FORM":
      let assignees = [];
      if (action.template.assignee_fill_type == 3) {
        assignees = action.template.assignees;
      }
      if (action.template.assignee_fill_type == 2) {
        assignees = action.assignees;
      }
      return update(state, {
        task: {
          task_template: { $set: action.template },
          status: { $set: "pending" },
          due_by: { $set: null },
          subject: { $set: null },
          description: { $set: null },
          owner_id: { $set: action.id },
          assignees: { $set: assignees },
        },
        isVisible: { $set: true },
      });

    case "TASK_CANCEL_MODAL_FORM":
      return update(state, {
        isVisible: { $set: false },
      });

    case "TASK_CLOSE_MODAL_FORM":
      return update(state, {
        isVisible: { $set: false },
      });

    case "TASK_CHANGE_STATUS":
      return update(state, {
        task: { status: { $set: action.value } },
      });

    case "TASK_CHANGE_DUE_BY":
      return update(state, {
        task: { due_by: { $set: action.value } },
      });

    case "TASK_CHANGE_SUBJECT":
      return update(state, {
        task: { subject: { $set: action.value } },
      });

    case "TASK_CHANGE_DESCRIPTION":
      return update(state, {
        task: { description: { $set: action.value } },
      });

    case "TASK_CHANGE_CUSTOMER":
      return update(state, {
        task: { customer: { $set: action.value } },
      });

    case "TASK_ADD_ASSIGNEE":
      return update(state, {
        task: { assignees: { $push: [action.value] } },
      });

    case "TASK_DESTROY_ASSIGNEE":
      idx = state.task.assignees.findIndex(function (o) {
        return o.id == action.id;
      });
      if (idx === -1) {
        return null;
      }

      return update(state, {
        task: {
          assignees: { $splice: [[idx, 1]] },
        },
      });

    // assignees
    case "FETCH_ASSIGNEES_REQUEST":
      return {
        ...state,
        isAssigneesLoading: true,
      };
    case "FETCH_ASSIGNEES":
      return update(state, {
        task: { assignees: { $set: action.response.data } },
        isAssigneesLoading: { $set: false },
      });

    case "FETCH_ASSIGNEES_FAILURE":
      return {
        ...state,
        errors: true,
        isAssigneesLoading: false,
      };

    case "POST_ASSIGNEE_REQUEST":
      return {
        ...state,
        errors: false,
        isAssigneesLoading: true,
      };
    case "POST_ASSIGNEE":
      return {
        ...state,
        errors: false,
        isAssigneesLoading: false,
      };

    case "POST_ASSIGNEE_FAILURE":
      return {
        ...state,
        errors: true,
        isAssigneesLoading: false,
      };

    // activities
    case "FETCH_TASK_ACTIVITIES_REQUEST":
      return {
        ...state,
        isActivitiesLoading: true,
      };
    case "FETCH_TASK_ACTIVITIES":
      return update(state, {
        activities: { $set: action.response.data },
        isActivitiesLoading: { $set: false },
        errors: { $set: false },
      });

    case "FETCH_TASK_ACTIVITIES_FAILURE":
      return {
        ...state,
        errors: true,
        isActivitiesLoading: false,
      };

    // comments
    case "FETCH_TASK_COMMENTS_REQUEST":
      return {
        ...state,
        isCommentsLoading: true,
      };
    case "FETCH_TASK_COMMENTS":
      return update(state, {
        task: { comments: { $set: action.response.data } },
        isCommentsLoading: { $set: false },
      });

    case "FETCH_TASK_COMMENTS_FAILURE":
      return {
        ...state,
        errors: true,
        isCommentsLoading: false,
      };

    case "POST_TASK_COMMENT_REQUEST":
      return {
        ...state,
        errors: false,
        isCommentsLoading: true,
      };
    case "POST_TASK_COMMENT":
      return {
        ...state,
        errors: false,
        isCommentsLoading: false,
      };

    case "POST_TASK_COMMENT_FAILURE":
      return {
        ...state,
        errors: true,
        isCommentsLoading: false,
      };

    // attachments
    case "FETCH_PROJECT_ATTACHMENTS_REQUEST":
      return {
        ...state,
        isAttachmentLoading: true,
      };

    case "FETCH_PROJECT_ATTACHMENTS":
      return update(state, {
        task: { attachments: { $set: action.response.data } },
        isAttachmentLoading: { $set: false },
      });
    case "FETCH_PROJECT_ATTACHMENTS_FAILURE":
      return {
        ...state,
        errors: true,
        isAttachmentLoading: false,
      };

    case "ATTACHMENT_UPLOAD":
      return update(state, {
        isAttachmentLoading: { $set: true },
      });

    case "ATTACHMENT_UPLOAD_SUCCEED":
      return update(state, {
        task: { attachments: { $set: action.response.data } },
        errors: { $set: false },
        isAttachmentLoading: { $set: false },
      });

    case "ATTACHMENT_UPLOAD_FAILED":
      return update(state, {
        errors: { $set: true },
        isAttachmentLoading: { $set: false },
      });

    case "ATTACHMENT_DELETE_REQUEST":
      return {
        ...state,
        errors: false,
        isAttachmentLoading: true,
      };
    case "ATTACHMENT_DELETE_SUCCEED":
      return update(state, {
        task: { attachments: { $set: action.response.data } },
        errors: { $set: false },
        isAttachmentLoading: { $set: false },
      });

    case "ATTACHMENT_DELETE_FAILED":
      return {
        ...state,
        errors: true,
        isAttachmentLoading: false,
      };

    // task_timing
    case "FETCH_TASK_TIMINGS_REQUEST":
      return {
        ...state,
        isTimingLoading: true,
      };
    case "FETCH_TASK_TIMINGS":
      return update(state, {
        taskTimings: { $set: action.response.data },
        isTimingLoading: { $set: false },
        errors: { $set: false },
      });

    case "FETCH_TASK_TIMINGS_FAILURE":
      return {
        ...state,
        errors: true,
        isTimingLoading: false,
      };

    case "POST_TASK_TIMING_REQUEST":
      return {
        ...state,
        errors: false,
        isTimingLoading: true,
      };
    case "POST_TASK_TIMING":
      return update(state, {
        taskTimings: { $set: action.response.data },
        isTimingLoading: { $set: false },
        errors: { $set: false },
      });

    case "POST_TASK_TIMING_FAILURE":
      return {
        ...state,
        errors: true,
        isTimingLoading: false,
      };

    case "PATCH_TASK_TIMING_REQUEST":
      return {
        ...state,
        errors: false,
        isTimingLoading: true,
      };
    case "PATCH_TASK_TIMING":
      return update(state, {
        taskTimings: { $set: action.response.data },
        isTimingLoading: { $set: false },
        errors: { $set: false },
      });

    case "PATCH_TASK_TIMING_FAILURE":
      return {
        ...state,
        errors: true,
        isTimingLoading: false,
      };

    case "POST_TASK_START_TIMING_REQUEST":
      return {
        ...state,
        errors: false,
      };
    case "POST_TASK_START_TIMING":
      idx = state.tasks.findIndex(function (o) {
        return o.id == action.id;
      });
      if (idx === -1) {
        return null;
      }
      return update(state, {
        tasks: { [idx]: { start: { $set: false } } },
        errors: { $set: false },
      });

    case "POST_TASK_START_TIMING_FAILURE":
      return {
        ...state,
        errors: true,
      };

    case "PATCH_TASK_PAUSE_TIMING_REQUEST":
      return {
        ...state,
        errors: false,
      };
    case "PATCH_TASK_PAUSE_TIMING":
      idx = state.tasks.findIndex(function (o) {
        return o.id == action.id;
      });
      if (idx === -1) {
        return null;
      }
      return update(state, {
        tasks: { [idx]: { start: { $set: true } } },
        errors: { $set: false },
      });

    case "PATCH_TASK_PAUSE_TIMING_FAILURE":
      return {
        ...state,
        errors: true,
      };

    // todos
    case "POST_TASK_TODO_COMPLETED_REQUEST":
      return {
        ...state,
        isTodoLoading: true,
      };

    case "POST_TASK_TODO_COMPLETED":
      return update(state, {
        task: {
          task_todos: {
            [action.taskTodoIdx]: {
              task_todo_items: {
                [action.idx]: { $set: action.response.data },
              },
            },
          },
        },
        isTodoLoading: { $set: false },
        errors: { $set: false },
      });

    case "POST_TASK_TODO_COMPLETED_FAILURE":
      return {
        ...state,
        errors: true,
        isTodoLoading: false,
      };

    // fields
    case "TASK_CHANGE_FIELD":
      const idx = _.findIndex(state.task.fields, ["name", action.name]);
      if (idx === -1) {
        return null;
      }
      return update(state, {
        task: { fields: { [idx]: { value: { $set: action.value } } } },
      });

    // filters
    case "TASKS_CLEAR_FILTERS":
      localStorage.removeItem("ordersFilters");

      return update(state, {
        fetchParams: {
          status: { $set: null },
          users: { $set: null },
          members: { $set: null },
        },
      });

    case "TASKS_SET_FILTERS":
      localFetchParams = loadLocalFilters(state.fetchParams);
      if (localFetchParams) {
        localFetchParams.search = null;
        return {
          ...state,
          fetchParams: localFetchParams,
        };
      } else {
        return {
          ...state,
        };
      }

    case "TASKS_FILTER_PERIOD":
      return update(state, {
        fetchParams: {
          startDate: { $set: action.startDate },
          endDate: { $set: action.endDate },
          page: { $set: 1 },
        },
      });

    case "TASKS_FILTER_FINISH_PERIOD":
      return update(state, {
        fetchParams: {
          startFinishDate: { $set: action.startDate },
          endFinishDate: { $set: action.endDate },
          page: { $set: 1 },
        },
      });

    case "TASKS_FILTER_STATUS":
      return update(state, {
        fetchParams: {
          status: { $set: action.values.length > 0 ? action.values : null },
          page: { $set: 1 },
        },
      });

    case "TASKS_FILTER_TEMPLATES":
      return update(state, {
        fetchParams: {
          templates: { $set: action.values.length > 0 ? action.values : null },
          page: { $set: 1 },
        },
      });

    case "TASKS_FILTER_TASK_USERS":
      return update(state, {
        fetchParams: {
          taskUsers: { $set: action.values.length > 0 ? action.values : null },
          page: { $set: 1 },
        },
      });

    case "TASKS_FILTER_USERS":
      return update(state, {
        fetchParams: {
          users: { $set: action.values.length > 0 ? action.values : null },
          page: { $set: 1 },
        },
      });

    case "TASKS_FILTER_SUPERVISOR_USERS":
      return update(state, {
        fetchParams: {
          supervisorUsers: {
            $set: action.values.length > 0 ? action.values : null,
          },
          page: { $set: 1 },
        },
      });

    // notices
    case "POST_TASK_MARK_ALL_AS_READ_REQUEST":
      return {
        ...state,
        errors: false,
      };
    case "POST_TASK_MARK_ALL_AS_READ":
      return {
        ...state,
        notices: action.response.notices,
        unread: action.response.unread,
        errors: false,
      };

    case "POST_TASK_MARK_ALL_AS_READ_FAILURE":
      return {
        ...state,
        errors: true,
      };

    default:
      return state;
  }
}
