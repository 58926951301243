// @flow
import React from "react";
import { Spin, Row, Col } from "antd";

const LoadingSpin = () => (
  <Row
    type="flex"
    justify="center"
    align="middle"
    style={{ minHeight: "50vh" }}
  >
    <Col span={4} offset={4}>
      <Spin />
    </Col>
  </Row>
);

export default LoadingSpin;
