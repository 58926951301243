// @flow
import React from 'react';
import { Link } from "react-router-dom";
import { Result } from 'antd';

const NotFound = () =>
<Result
    status="404"
    title="404"
    subTitle="Кажется, страница которую вы ищете, не существует."
    extra={<Link to="/">Домой</Link>}
  />

export default NotFound;