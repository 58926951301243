import api from "../api";

const Actions = {};

Actions.fetchSettings = () => {
  return (dispatch) => {
    dispatch({ type: "FETCH_SETTINGS_REQUEST" });
    return api
      .fetch(`/settings/`)
      .then((response) => {
        dispatch({ type: "FETCH_SETTINGS", response });
      })
      .catch((error) => {
        if (error.errors) {
          error.errors.map((error) => {
            return api.openNotification("error", "Настройки", error);
          });
        }
        dispatch({ type: "FETCH_SETTINGS_FAILURE", error });
      });
  };
};

export default Actions;
