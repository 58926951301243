// @flow
import React, { Component } from "react";
import { Row, Col, Checkbox, Divider } from "antd";

class Notifications extends Component {
  
  render() {
    const {
      notifyCommentSettings,
      onChangeNotifyTickets,
      onChangeNotifyEntityTasks,
      onChangeNotifyOrders,
      onChangeNotifyOrderTasks,
      onChangeNotifyProjects
    } = this.props;

    return (
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Divider>Уведомления на Email о новых комментариях</Divider>
        </Col>
        <Col xs={24} sm={24} md={24} lg={4} xl={4}>
          <Checkbox
            checked={notifyCommentSettings.tickets}
            onChange={onChangeNotifyTickets}
          >
            Заявки
          </Checkbox>
        </Col>
        <Col xs={24} sm={24} md={24} lg={4} xl={4}>
          <Checkbox
            checked={notifyCommentSettings.entity_tasks}
            onChange={onChangeNotifyEntityTasks}
          >
            Регламент
          </Checkbox>
        </Col>
        <Col xs={24} sm={24} md={24} lg={4} xl={4}>
          <Checkbox
            checked={notifyCommentSettings.orders}
            onChange={onChangeNotifyOrders}
          >
            Заказы
          </Checkbox>
        </Col>
        <Col xs={24} sm={24} md={24} lg={4} xl={4}>
          <Checkbox
            checked={notifyCommentSettings.order_tasks}
            onChange={onChangeNotifyOrderTasks}
          >
            Задания
          </Checkbox>
        </Col>
        <Col xs={24} sm={24} md={24} lg={4} xl={4}>
          <Checkbox
            checked={notifyCommentSettings.projects}
            onChange={onChangeNotifyProjects}
          >
            Проекты
          </Checkbox>
        </Col>
      </Row>
    );
  }
}

Notifications.propTypes = {};

export default Notifications;
