// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Actions from "../../../../actions/projects";
import DocumentsList from "./List";

class Documents extends Component {
  constructor(props) {
    super(props);
  }

  componentWillMount = () => {
    this.props.dispatch(Actions.fetchAttachments(this.props.match.params.id));
  };

  onDeleteAttachment = (id) => {
    this.props
      .dispatch(Actions.onDeleteAttachment(this.props.match.params.id, id))
      .then(() => {
        if (!this.props.errors) {
          this.props.dispatch(
            Actions.fetchAttachments(this.props.match.params.id)
          );
        }
      });
  };

  onUploadAttachment = (file) => {
    this.props
      .dispatch(Actions.onUploadAttachment(this.props.match.params.id, file))
      .then(() => {
        if (!this.props.errors) {
          this.props.dispatch(
            Actions.fetchAttachments(this.props.match.params.id)
          );
        }
      });
  };

  render() {
    const { isLoading, isUploading, project } = this.props;
    return (
      <DocumentsList
        isLoading={isLoading}
        isUploading={isUploading}
        documents={project.attachments}
        onUpload={this.onUploadAttachment}
        onDelete={this.onDeleteAttachment}
      />
    );
  }
}

Documents.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  requestForProposal: PropTypes.object,
  isLoading: PropTypes.bool,
  errors: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isLoading: state.projects.isLoading,
  isUploading: state.projects.isUploading,
  errors: state.projects.errors,
  project: state.projects.project,
});

export default connect(mapStateToProps)(withRouter(Documents));
