import React, { Component } from "react";
import { Progress, Popover, Table } from "antd";
import moment from "moment";
import "moment/locale/ru";
import api from "../../api";

function progressInfo(progress) {
  const percent = Number(
    ((progress.current / progress.steps) * 100).toFixed(0)
  );
  return <Progress percent={percent} size="small" />;
}

export default class OrderProgress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      fetching: true,
    };
  }

  fetchOrderProgress = async (id) => {
    // console.log(id)
    const response = await api.fetch(`/orders/${id}/progress`);
    const data = response.data;
    this.setState({ data, fetching: false });
  };


  render() {
    const { fetching, data } = this.state;
    const { orderId, progress } = this.props;

    const percent = Number(
      ((progress.current / progress.steps) * 100).toFixed(0)
    );

    return (
      <Popover
        placement="right"
        content={<ProgressInfo loading={fetching} data={data} />}
        title="Задачи"
        trigger="click"
      >
        <Progress
          percent={percent}
          size="small"
          style={{ cursor: "pointer" }}
          onClick={this.fetchOrderProgress.bind(this, orderId)}
        />
      </Popover>
    );
  }
}

function ProgressInfo({ loading = false, data = null }) {
  const columns = [
    {
      title: "Выполнение",
      dataIndex: "progress",
      render: (progress, record) => progressInfo(record.progress),
      key: "status",
      align: "center",
      width: "40%",
    },
    {
      title: "Тип",
      dataIndex: "task_type",
      render: (progress, record) => record.task_type_locale,
      key: "task_type",
      align: "center",
      width: "15%",
    },
    {
      title: "Начало",
      dataIndex: "start",
      render: (start) => (start ? moment(start).format("L") : null),
      key: "start",
      align: "center",
      width: "15%",
    },
    {
      title: "Окончание",
      dataIndex: "finish",
      render: (finish) => (finish ? moment(finish).format("L") : null),
      key: "finish",
      align: "center",
      width: "15%",
    },
    {
      title: "План",
      dataIndex: "due_by",
      render: (due_by) => (due_by ? moment(due_by).format("L") : null),
      key: "due_by",
      align: "center",
      width: "15%",
    },
  ];

  return (
    <div style={{ width: 640 }}>
      <Table
        rowClassName={"progress-row"}
        size={"small"}
        loading={loading}
        // showHeader={false}
        columns={columns}
        dataSource={data}
        pagination={false}
        rowKey="id"
      />
    </div>
  );
}
