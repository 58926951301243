import { notification } from "antd";
import axios from "axios";
axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response.data;
  },
  function (error) {
    // Do something with response error
    return Promise.reject(error);
  }
);

let API = "";
if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  API = "http://localhost:3000/api/client";
} else {
  // API = "https://api.terra-security.ru/api/client";
  // API = "https://apitest.mysmartservice.com/api/client";
  if (window.location.hostname == "www.lktest.mysmartservice.com" || window.location.hostname == "lktest.mysmartservice.com") {
    API = "https://apitest.mysmartservice.com/api/client";
  } else {
    API = "https://api.terra-security.ru/api/client";
  }
}

function headers() {
  const bearerToken = localStorage.getItem("token");
  // console.log(bearerToken)

  return {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
    Authorization: `Bearer: ${bearerToken}`,
  };
}

function headersBlob() {
  const bearerToken = localStorage.getItem("token");
  return {
    Accept: "application/vnd.ms-excel",
    "Content-Type": "application/vnd.ms-excel",
    Authorization: `Bearer: ${bearerToken}`
  };
}

function parseResponse(response) {
  return response.json().then((json) => {
    if (!response.ok) {
      return Promise.reject(json);
    }
    return json;
  });
}

export default {
  openNotification(type, message = "Объект", description = "") {
    notification[type]({
      placement: "bottomLeft",
      message: message,
      description: description,
    });
  },

  handleError(error, message, redirect = false) {
    if (error.response) {
      if (error.response.status == 401) {
        if (error.response.data) {
          error.response.data.errors.map(error => {
            return notification["error"]({
              placement: "bottomLeft",
              message: message,
              description: error
            });
          });
          if (redirect) {
            window.location = "/notauthorized";
          }
        }
      } else if (error.response.status == 404) {
        window.location = "/notfound";
      } else if (error.response.status == 500) {
        return notification["error"]({
          placement: "bottomLeft",
          message: message,
          description: "Ошибка сервера. Обратитесь к администратору!"
        });
      } else {
        console.log(error.response.status);
      }
    } else if (error.request) {
      console.log(error.request);
    } else {
      console.log("Error", error.message);
    }
  },

  fetch(url, params = {}) {
    return axios.get(`${API}${url}`, {
      params: params,
      headers: headers(),
    });
  },

  fetchBlob(url, params = {}) {
    return axios.get(`${API}${url}`, {
      params: params,
      responseType: "blob",
      headers: headersBlob()
    });
  },

  post(url, data) {
    const body = JSON.stringify(data);
    return fetch(`${API}${url}`, {
      method: "POST",
      headers: headers(),
      credentials: "same-origin",
      body,
    }).then(parseResponse);
  },

  postFormData(url, data) {
    const bearerToken = localStorage.getItem("token");
    return fetch(`${API}${url}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer: ${bearerToken}`,
      },
      credentials: "same-origin",
      body: data,
    }).then(parseResponse);
  },

  patch(url, data) {
    const body = JSON.stringify(data);
    return fetch(`${API}${url}`, {
      method: "PUT",
      headers: headers(),
      body,
    }).then(parseResponse);
  },

  patchFormData(url, data) {
    const bearerToken = localStorage.getItem("token");
    return fetch(`${API}${url}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer: ${bearerToken}`,
      },
      credentials: "same-origin",
      body: data,
    }).then(parseResponse);
  },

  delete(url) {
    return fetch(`${API}${url}`, {
      method: "DELETE",
      headers: headers(),
    }).then(parseResponse);
  },

  logout(url) {
    return fetch(`${API}${url}`, {
      method: "DELETE",
      headers: headers(),
      credentials: "same-origin",
    }).then();
  },
};
