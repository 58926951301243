// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import {Comment, Tooltip, Avatar, Typography} from "antd";
import { Row, Col, Form, Input, Button } from "antd";
import moment from "moment";
import "moment/locale/ru";
import ReactQuill from "react-quill";
import QuillMention from 'quill-mention'
import api from "../../../api";

ReactQuill.Quill.register({ 'modules/mention': QuillMention });

const { TextArea } = Input;

const {Text} = Typography;

class Comments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comment: "",
      users: [],
      members: [],
      mentioned_users: [],
      mentioned_members: [],
      mentioned_emails: [],
    };

    this.editorRef = React.createRef();
  }

  componentDidMount() {
    const qlToolbarElement = document.querySelector('.ql-toolbar');
    if (qlToolbarElement) {
      qlToolbarElement.style.display = 'none';
    }

    const qlContainerElement = document.querySelector('.ql-container');
    if (qlContainerElement) {
      qlContainerElement.style.borderTop = '1px';
      qlContainerElement.style.borderTopColor = '#ccc'
      qlContainerElement.style.borderTopStyle = 'solid';
    }
  }

  fetchMentions = async (searchTerm, renderList) => {
    const responseUsers = await api.fetch(`/users/search/?`, {
      q: searchTerm,
    });

    const responseMembers = await api.fetch(`/members/search/?`, {
      customer_id: this.props.customer?.id,
      q: searchTerm,
    });

    const mentions = [];
    mentions.push({
      id: 'mentioned-email',
      type: 'email',
      name: `На внешний email: ${searchTerm}`,
      value: searchTerm,
    });

    responseUsers.data.forEach((user) => {
      mentions.push({
        id: user.id,
        type: 'user',
        name: user.name,
        value: user.name,
      })
    });

    responseMembers.data.forEach((member) => {
      mentions.push({
        id: member.id,
        type: 'member',
        name: member.name,
        value: member.name,
      })
    });

    renderList(mentions);
  };

  renderMention = (mention) => {
    if (mention.type === 'email') {
      return `<em>${mention.name}</em>`;
    }

    return mention.name;
  };

  selectMention = (item, insertItem) => {
    if (item.value === null || item.value.trim() === '') {
      return;
    }

    if (item.type === 'user') {
      this.setState({
        mentioned_users: [...this.state.mentioned_users, item],
      });
    } else if (item.type === 'member') {
      this.setState({
        mentioned_members: [...this.state.mentioned_members, item],
      });
    } else if (item.type === 'email') {
      this.setState({
        mentioned_emails: [...this.state.mentioned_emails, item],
      });
    }

    insertItem(item);
  };

  onChangeComment = (content, delta, source, editor) => {
    this.setState({
      comment: content,
    });
  };

  onSubmitComment = () => {
    let {
      errors,
      comment,
      mentioned_users,
      mentioned_members,
      mentioned_emails,
    } = this.state;

    if (comment.length > 3) {
      this.props.onCreateComment(
        comment,
        {
          users: mentioned_users,
          members: mentioned_members,
          emails: mentioned_emails,
        });

      if (!errors) {
        this.setState({
          comment: "",
          mentioned_users: [],
          mentioned_members: [],
          mentioned_emails: [],
        });
      }
    }
  };

  render() {
    const { comments, isCommentsLoading } = this.props;
    const { comment } = this.state;

    const qlToolbarElement = document.querySelector('.ql-toolbar');
    if (qlToolbarElement) {
      qlToolbarElement.style.display = 'none';
    }

    const qlContainerElement = document.querySelector('.ql-container');
    if (qlContainerElement) {
      qlContainerElement.style.borderTop = '1px';
      qlContainerElement.style.borderTopColor = '#ccc'
      qlContainerElement.style.borderTopStyle = 'solid';
    }

    return (
      <Row gutter={{ md: 8, lg: 24, xl: 48 }} style={{ marginBottom: "10px" }}>
        <Col span={24}>
          {comments.map(item => {
            return (
              <Comment
                key={item.id}
                author={item.user ? item.user.name : item.member.name}
                avatar={<Avatar src={item.user ? item.user.avatar : item.member.avatar} alt="Han Solo" />}
                datetime={<span>{moment(item.created_at).format("LLL")}</span>}
                content={
                  <Text>
                    <div dangerouslySetInnerHTML={{__html: item.comment}}/>
                  </Text>
                }
              />
            );
          })}
        </Col>
        <Col span={24}>
          <Row className={'comment-editor'}
               style={{
                 marginBottom: 16,
               }}>
            <ReactQuill ref={(it) => this.editorRef = it}
                        bounds={'.comment-editor'}
                        theme={"snow"}
                        placeholder={"Используйте @@ для выбора пользователя"}
                        value={comment}
                        modules={{
                          toolbar: {
                            container: [],
                          },
                          mention: {
                            mentionDenotationChars: ['@@'],
                            allowedChars: /^\S*$/,
                            dataAttributes: ['id', 'type', 'name', 'value', 'disabled'],
                            source: this.fetchMentions,
                            renderItem: this.renderMention,
                            onSelect: this.selectMention,
                            positioningStrategy: 'fixed',
                          },
                          history: {
                            delay: 1000,
                            maxStack: 100,
                            userOnly: false
                          },
                        }}
                        onChange={this.onChangeComment}/>
          </Row>
          <Form.Item>
            <Button
              loading={isCommentsLoading}
              onClick={this.onSubmitComment}
              type="primary"
            >
              Добавить комментарий
            </Button>
          </Form.Item>
        </Col>
      </Row>
    );
  }
}

Comments.propTypes = {
  orderComments: PropTypes.arrayOf(PropTypes.object),
  total: PropTypes.number,
  discount: PropTypes.object,
  shippingCost: PropTypes.number,
  amountDiscountCode: PropTypes.number,
  onRemoveVariant: PropTypes.func
};
export default Comments;
