// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Row, Col, Button, Form, Card, Menu, Dropdown, Icon, Tabs } from "antd";
import Statuses from "./Statuses";
import Activities from "./Activities";
import Comments from "./Comments";
import General from "./General";
import GeneralInfo from "./GeneralInfo";
import Tasks from "../../../components/Tasks";
import moment from "moment";
import "moment/locale/ru";

const { TabPane } = Tabs;

class TicketForm extends Component {
  onSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }
      this.props.onSave(values);
    });
  };

  handleMenuClick = (e) => {
    console.log("click", e);
  };

  callback = (key) => {
    if (key == "2") {
      this.props.fetchRequestForTicketActivities();
    }

    if (key == "3") {
      this.props.fetchTasks();
    }
  };

  // actions
  onAction = (actionId) => {
    const { ticket, actions, onOpenTaskForm } = this.props;
    let idx = actions.findIndex(function (o) {
      return o.id == actionId;
    });
    if (idx === -1) {
      return null;
    }
    onOpenTaskForm(actions[idx], ticket.id);
  };

  ActionMenu = (actions) => {
    return (
      <Menu>
        {actions.map((action) => {
          return (
            <Menu.Item
              key={action.id}
              onClick={this.onAction.bind(this, action.id)}
            >
              {action.name}
            </Menu.Item>
          );
        })}
      </Menu>
    );
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      currentMember,
      isNew,
      isLoading,
      isCommentsLoading,
      isUploading,
      isAssigneesLoading,
      errors,
      ticket,
      onSave,
      onClose,
      onChangeCustomer,
      onChangeEntity,
      onChangeEntityService,
      onChangeCategory,
      onChangeServices,
      onChangeStatus,
      onChangePiority,
      onChangeIncident,
      onChangeAmount,
      onChangeSubject,
      onChangeDescription,
      onChangeDueBy,
      onCreateComment,
      onDeleteAttachment,
      onUploadAttachment,
      // preUpload
      tempAttachments,
      onTempUpload,
      onDeleteTempAttachment,
      // assignees
      onCreateAssignee,
      onDeleteAssignee,
      onCreateReplacementTicket,
      onChangeReplacedStatus,
      ticketActivities,
      isActivitiesLoading,
      // tasks
      isTasksLoading,
      tasks,
      // actions
      actions,
    } = this.props;

    const menu = (
      <Menu>
        <Menu.Item key="1" onClick={onCreateReplacementTicket}>
          <Icon type="copy" />
          Требуется замена
        </Menu.Item>
      </Menu>
    );

    // actions
    let actionsButton = null;

    if (actions.length > 0) {
      actionsButton = (
        <Dropdown key={99} overlay={this.ActionMenu(actions)}>
          <Button icon={"more"} style={{ marginLeft: "8px" }} />
        </Dropdown>
      );
    }

    const organisationAssignees = ticket.assignees.filter(function (item) {
      return item.assignee_type == "organisation";
    });

    const supervisorAssignees = ticket.assignees.filter(function (item) {
      return item.assignee_type == "supervisor";
    });

    const companyAssignees = ticket.assignees.filter(function (item) {
      return item.assignee_type == "company";
    });

    const customerAssignees = ticket.assignees.filter(function (item) {
      return item.assignee_type == "customer";
    });


    return (
      <Form layout={"vertical"} onSubmit={this.onSubmit}>
        <Card
          loading={isLoading}
          title={
            <div>
              <h2 style={{ marginBottom: 0 }}>
                {`Заявка #${isNew ? "новая" : ticket.number} от ${moment(
                  ticket.created_at
                ).format("LLL")}`}
              </h2>
            </div>
          }
          extra={[
            <Button onClick={onClose}>Закрыть</Button>,
            isNew ? (
              <Button
                onClick={this.onSubmit}
                type="primary"
                ghost
                style={{ marginLeft: "8px" }}
              >
                Сохранить
              </Button>
            ) : null,
            actionsButton,
          ]}
        >
          <Col xs={24} sm={24} md={24} lg={13} xl={13}>
            <Row>
              {isNew ? (
                <General
                  isNew={isNew}
                  getFieldDecorator={getFieldDecorator}
                  onChangeCustomer={onChangeCustomer}
                  onChangeEntity={onChangeEntity}
                  onChangeEntityService={onChangeEntityService}
                  onChangeCategory={onChangeCategory}
                  onChangeServices={onChangeServices}
                  onChangeSubject={onChangeSubject}
                  onChangeDescription={onChangeDescription}
                  ticket={ticket}
                />
              ) : (
                <GeneralInfo ticket={ticket} />
              )}
            </Row>
            <Row>
              {isNew ? null : (
                <Comments
                  getFieldDecorator={getFieldDecorator}
                  isCommentsLoading={isCommentsLoading}
                  errors={errors}
                  onCreateComment={onCreateComment}
                  comments={ticket.comments}
                />
              )}
            </Row>
          </Col>
          <Col xs={0} sm={0} md={1} lg={1} xl={1} />
          <Col xs={24} sm={24} md={24} lg={10} xl={10}>
            <Row>
              <Card style={{ borderColor: "#1890ff" }} loading={isLoading}>
                <Tabs defaultActiveKey="1" onChange={this.callback}>
                  <TabPane tab="Статусы" key="1">
                    <Statuses
                      currentMember={currentMember}
                      isNew={isNew}
                      ticketId={ticket.id}
                      isLoading={isLoading}
                      isUploading={isUploading}
                      getFieldDecorator={getFieldDecorator}
                      is_replaced={ticket.is_replaced}
                      isTicketReplaceAvailable={ticket.is_ticket_replace_available}
                      isSupplier={ticket.is_supplier}
                      dueBy={ticket.due_by}
                      user={ticket.user}
                      member={ticket.member}
                      assignees={ticket.assignees}
                      status={ticket.status}
                      ticketPriority={ticket.ticket_priority}
                      incident={ticket.incident}
                      amount={ticket.amount}
                      onChangeStatus={onChangeStatus}
                      onChangePiority={onChangePiority}
                      onChangeIncident={onChangeIncident}
                      onChangeAmount={onChangeAmount}
                      onChangeDueBy={onChangeDueBy}
                      // preUpload
                      tempAttachments={tempAttachments}
                      onTempUpload={onTempUpload}
                      onDeleteTempAttachment={onDeleteTempAttachment}
                      // assignees
                      organisationAssignees={organisationAssignees}
                      supervisorAssignees={supervisorAssignees}
                      customerAssignees={customerAssignees}
                      companyAssignees={companyAssignees}
                      isAssigneesLoading={isAssigneesLoading}
                      onDeleteAssignee={onDeleteAssignee}
                      onCreateAssignee={onCreateAssignee}
                    />
                  </TabPane>
                  
                </Tabs>
              </Card>
            </Row>
          </Col>
        </Card>
      </Form>
    );
  }
}

TicketForm.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  ticket: PropTypes.object,
  onSave: PropTypes.func,
  isLoading: PropTypes.bool,
  router: PropTypes.object,
  getFieldDecorator: PropTypes.func,
  form: PropTypes.object,
};

export default Form.create()(TicketForm);
