import { combineReducers } from 'redux';
import dashboard from './dashboard';
import settings from './settings';
import session from './session';
import currentMember from './currentMember';
import customer from './customer';
import members from './members';
import tickets from './tickets';
import entities from './entities';
import entityTasks from './entityTasks';
import orders from './orders';
import orderTasks from './orderTasks';
import projects from './projects';
import supportMessages from './supportMessages';
import notices from './notices';
import posts from './posts';
import faqs from './faqs';
import reports from './reports';
import rates from './rates';
import tasks from './tasks';

const appReducer = combineReducers({
  dashboard,
  settings,
  session,
  currentMember,
  customer,
  members,
  tickets,
  entities,
  entityTasks,
  orders,
  orderTasks,
  projects,
  supportMessages,
  notices,
  posts,
  faqs,
  reports,
  rates,
  tasks
});

export default function (state, action) {
  if (action.type === 'LOGOUT') {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
}