// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, Button, Form, Card, Tabs, Menu, Dropdown } from "antd";
import Documents from "../../../components/Documents";
import Statuses from "./Statuses";
import General from "./General";
import GeneralInfo from "./GeneralInfo";
import Comments from "./Comments";
import OrderTasks from "./OrderTasks";
import Schedules from "./Schedules";
import Uploads from "../../../components/Uploads";
import Tasks from "../../../components/Tasks";
import moment from "moment";
import "moment/locale/ru";

const { TabPane } = Tabs;

class OrderForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeKey: "1",
      assignee: null,
    };
  }

  onSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }
      this.props.onSave(values);
    });
  };

  onChange = (activeKey) => {
    this.setState({ activeKey });
    if (activeKey == 3) {
      this.props.fetchSchedules();
    }
  };

  callback = (key) => {
    if (key == "2") {
      this.props.fetchTasks();
    }
  };

  // actions
  onAction = (actionId) => {
    const { order, actions, onOpenTaskForm } = this.props;
    let idx = actions.findIndex(function (o) {
      return o.id == actionId;
    });
    if (idx === -1) {
      return null;
    }
    onOpenTaskForm(actions[idx], order.id);
  };

  ActionMenu = (actions) => {
    return (
      <Menu>
        {actions.map((action) => {
          return (
            <Menu.Item
              key={action.id}
              onClick={this.onAction.bind(this, action.id)}
            >
              {action.name}
            </Menu.Item>
          );
        })}
      </Menu>
    );
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      currentMember,
      isNew,
      isLoading,
      errors,
      order,
      onClose,
      // general
      onChangeNumber,
      onChangeEntity,
      onChangeEntityService,
      onChangePriority,
      onChangeAmount,
      onChangeSubject,
      onChangeDescription,
      // comments
      isCommentsLoading,
      onCreateComment,
      // assignees
      isAssigneesLoading,
      onCreateAssignee,
      onDeleteAssignee,
      // proposals
      isLoadingProposals,
      onDeleteProposal,
      onUploadProposal,
      // preUpload
      tempAttachments,
      onTempUpload,
      onDeleteTempAttachment,
      // attachments
      isAttachmentLoading,
      onDeleteAttachment,
      onUploadAttachment,
      // order_tasks
      isOrderTasksLoading,
      onCreateOrderTask,
      // schedules
      isSchedulesLoading,
      onSaveSchedule,
      onDeleteSchedules,
      // tasks
      isTasksLoading,
      tasks,
      // actions
      actions,
    } = this.props;

    const organisationAssignees = order.assignees.filter(function (item) {
      return item.assignee_type == "organisation";
    });

    const customerAssignees = order.assignees.filter(function (item) {
      return item.assignee_type == "customer";
    });

    // actions
    let actionsButton = null;

    if (actions.length > 0) {
      actionsButton = (
        <Dropdown key={99} overlay={this.ActionMenu(actions)}>
          <Button icon={"more"} style={{ marginLeft: "8px" }} />
        </Dropdown>
      );
    }

    return (
      <Form layout={"vertical"} onSubmit={this.onSubmit}>
        <Card
          loading={isLoading}
          title={
            <h2 style={{ marginBottom: 0 }}>{`Заказ #${
              isNew ? "новый" : order.number
            } от ${moment(order.created_at).format("LLL")}`}</h2>
          }
          extra={[
            <Button key={1} onClick={onClose}>
              Закрыть
            </Button>,
            isNew ? (
              <Button
                onClick={this.onSubmit}
                type="primary"
                ghost
                style={{ marginLeft: "8px" }}
              >
                Сохранить
              </Button>
            ) : null,
            actionsButton,
          ]}
        >
          <Col xs={24} sm={24} md={24} lg={14} xl={14}>
            <Row>
              {isNew ? (
                <General
                  isNew={isNew}
                  getFieldDecorator={getFieldDecorator}
                  order={order}
                  onChangeEntity={onChangeEntity}
                  onChangeEntityService={onChangeEntityService}
                  onChangePriority={onChangePriority}
                  onChangeAmount={onChangeAmount}
                  onChangeSubject={onChangeSubject}
                  onChangeDescription={onChangeDescription}
                />
              ) : (
                <GeneralInfo order={order} />
              )}
            </Row>
            <Row>
              <Col span={24}>
                <Tabs defaultActiveKey="1" onChange={this.onChange}>
                  <TabPane tab="Комментарии" key="1">
                    <Row>
                      {isNew ? null : (
                        <Comments
                          getFieldDecorator={getFieldDecorator}
                          isCommentsLoading={isCommentsLoading}
                          errors={errors}
                          onCreateComment={onCreateComment}
                          comments={order.comments}
                        />
                      )}
                    </Row>
                  </TabPane>
                  {order.is_order_tasks_available ? (
                    <TabPane tab="Задачи" key="2">
                      <Row>
                        {isNew ? null : (
                          <OrderTasks
                            getFieldDecorator={getFieldDecorator}
                            isOrderTasksLoading={isOrderTasksLoading}
                            errors={errors}
                            onCreateOrderTask={onCreateOrderTask}
                            orderTasks={order.order_tasks}
                          />
                        )}
                      </Row>
                    </TabPane>
                  ) : null}
                  <TabPane tab="График работ" key="3">
                    <Row>
                      {isNew ? null : (
                        <Schedules
                          getFieldDecorator={getFieldDecorator}
                          isSchedulesLoading={isSchedulesLoading}
                          errors={errors}
                          onSaveSchedule={onSaveSchedule}
                          onDeleteSchedules={onDeleteSchedules}
                          schedules={order.schedules}
                        />
                      )}
                    </Row>
                  </TabPane>
                  {isNew ? (
                    <TabPane tab="Файлы" key="4">
                      <Row>
                        <Uploads
                          title={"Файлы"}
                          currentMember={currentMember}
                          onUpload={onTempUpload}
                          isUploading={isAttachmentLoading}
                          attachments={tempAttachments}
                          onDeleteAttachment={onDeleteTempAttachment}
                        />
                      </Row>
                    </TabPane>
                  ) : (
                    <TabPane tab="Файлы" key="4">
                      <Row>
                        <Documents
                          currentUser={currentMember}
                          title={""}
                          isUploading={isAttachmentLoading}
                          attachments={order.attachments}
                          onUploadAttachment={onUploadAttachment}
                          onDeleteAttachment={onDeleteAttachment}
                        />
                      </Row>
                    </TabPane>
                  )}
                </Tabs>
              </Col>
            </Row>
          </Col>

          <Col xs={0} sm={0} md={1} lg={1} xl={1} />
          <Col xs={24} sm={24} md={24} lg={9} xl={9}>
            <Tabs defaultActiveKey="1" onChange={this.callback}>
              <TabPane tab="Статусы" key="1">
                <Statuses
                  isNew={isNew}
                  getFieldDecorator={getFieldDecorator}
                  due_by={order.due_by}
                  user={order.user}
                  member={order.member}
                  customer={order.customer}

                  // priority
                  priority={order.priority}
                  onChangePriority={onChangePriority}
                  priority_locale={order.priority_locale}

                  // numbers
                  number={order.number}
                  number_int={order.number_int}
                  status_locale={order.status_locale}
                  onChangeNumber={onChangeNumber}
                  organisationAssignees={organisationAssignees}
                  customerAssignees={customerAssignees}
                  isAssigneesLoading={isAssigneesLoading}
                  onDeleteAssignee={onDeleteAssignee}
                  onCreateAssignee={onCreateAssignee}
                  // proposals
                  isLoadingProposals={isLoadingProposals}
                  attachments={order.proposals}
                  onUploadProposal={onUploadProposal}
                  onDeleteProposal={onDeleteProposal}
                />
              </TabPane>
              {isNew ? null : (
                <TabPane tab="Задачи" key="2">
                  <Tasks isLoading={isTasksLoading} tasks={tasks} />
                </TabPane>
              )}
            </Tabs>
          </Col>
        </Card>
      </Form>
    );
  }
}

OrderForm.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  order: PropTypes.object,
  onSave: PropTypes.func,
  isLoading: PropTypes.bool,
  router: PropTypes.object,
  getFieldDecorator: PropTypes.func,
  form: PropTypes.object,
  fetchSuggestions: PropTypes.func,
  onChangeDate: PropTypes.func,
  onChangeUser: PropTypes.func,
  onChangeAgreement: PropTypes.func,
  onChangeAgreementDate: PropTypes.func,
  onChangeAdditional: PropTypes.func,
  onChangeComment: PropTypes.func,
  onChangeNotice: PropTypes.func,
  onChangePaymentType: PropTypes.func,
  onChangePaymentStatus: PropTypes.func,
  onChangeShippingStatus: PropTypes.func,
  onChangeShippingMethod: PropTypes.func,
  onAddVariant: PropTypes.func,
  onRemoveVariant: PropTypes.func,
  onChangeQuantity: PropTypes.func,
  onChangePrice: PropTypes.func,
};

export default Form.create()(OrderForm);
