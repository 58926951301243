// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Table, Typography, Tag } from "antd";
import { Row, Col } from "antd";
import ProjectProgress from "../../../components/ProjectProgress";
import AssigneesCell from "../../../components/AssigneesCell";

import moment from "moment";
import "moment/locale/ru";
import accounting from "accounting";


class Projects extends Component {
  render() {
    const { isLoading, projects } = this.props;

    const columns = [
        {
          title: "Договор / Статус",
          dataIndex: "number",
          render: (number, record) => (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span>
                <Link to={`/projects/${record.id}/edit`}>{number}</Link>
              </span>
              <span style={{ fontSize: 12 }}>
                <ProjectProgress
                  title={record.status_locale}
                  projectId={record.id}
                />
              </span>
            </div>
          ),
          key: "number",
          align: "center",
          width: "20%",
        },
        {
          title: "Дата создания",
          dataIndex: "created_at",
          render: (created_at) => moment(created_at).format("L"),
          sorter: true,
          sortDirections: ["ascend", "descend"],
          key: "created_at",
          align: "center",
          width: "10%",
        },
        {
          title: "Дата принятия",
          dataIndex: "finish",
          render: (finish, record) => null,
          sorter: true,
          sortDirections: ["ascend", "descend"],
          key: "finish",
          align: "center",
          width: "10%",
        },
        {
          title: "Сумма",
          dataIndex: "amount",
          render: (amount) => accounting.formatNumber(amount, 0, " "),
          sorter: true,
          sortDirections: ["ascend", "descend"],
          key: "amount",
          align: "center",
          width: "10%",
        },
        {
          title: "Тип / Описание",
          dataIndex: "project_type",
          render: (project_type, record) => (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span>{project_type ? project_type.name : null}</span>
              <span style={{ opacity: "0.7" }}>{record.description}</span>
            </div>
          ),
          key: "project_type",
          align: "center",
          width: "25%",
        },
        {
          title: "Ответственный",
          dataIndex: "assignees",
          render: (assignees, record) => <AssigneesCell assignees={assignees} />,
          align: "center",
          width: "25%",
        },
      ];

    return (
      <div>
        <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
          <Col span={24}>
            <Table
              loading={isLoading}
              size={"middle"}
              bordered
              pagination={false}
              columns={columns}
              dataSource={projects}
              rowKey="id"
            />
          </Col>
        </Row>
      </div>
    );
  }
}

Projects.propTypes = {
  orderProjects: PropTypes.arrayOf(PropTypes.object),
  total: PropTypes.number,
  discount: PropTypes.object,
  shippingCost: PropTypes.number,
  amountDiscountCode: PropTypes.number,
  onRemoveVariant: PropTypes.func,
};
export default Projects;
