// @flow
import React, { Component } from "react";
import { Row, Col, Modal, Button, Form, Input } from "antd";

const FormItem = Form.Item;

class Attachment extends React.Component {
  onSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }
      this.props.onSave(values);
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      visible,
      onCancel,
      attachment,
      onChangeName,
      onChangeDescription,
    } = this.props;

    return (
      <Modal
        title={"Новая ссылка"}
        width={700}
        destroyOnClose
        visible={visible}
        onOk={this.onSubmit}
        onCancel={onCancel}
      >
        <Form layout={"vertical"}>
          <Row gutter={16}>
            <Col span={24}>
              <FormItem label={"Ссылка"}>
                {getFieldDecorator("name", {
                  onChange: onChangeName,
                  initialValue: attachment ? attachment.name : null,
                  rules: [
                    {
                      required: true,
                      message: "Введите описание!",
                    },
                  ],
                })(<Input />)}
              </FormItem>
            </Col>
            <Col span={24}>
              <FormItem label={"Описание"}>
                {getFieldDecorator("description", {
                  onChange: onChangeDescription,
                  initialValue: attachment ?  attachment.description : null,
                  rules: [
                    {
                      required: true,
                      message: "Введите ссылку!",
                    },
                  ],
                })(<Input />)}
              </FormItem>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
}

export default Form.create()(Attachment);
