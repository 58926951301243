// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { List, Tag, Avatar } from "antd";
import moment from "moment";
import "moment/locale/ru";

function faqTag(tags) {
  return tags.map((tag) => {
    return (
      <Tag key={tag.id} color="#108ee9">
        {tag.name}
      </Tag>
    );
  });
}

class FaqsList extends Component {
  render() {
    const { isFaqsLoading, faqs, onOpenFaq } = this.props;

    return (
      <List
        loading={isFaqsLoading}
        itemLayout="vertical"
        dataSource={faqs}
        renderItem={(item) => (
          <List.Item
            style={{ cursor: "pointer" }}
            onClick={onOpenFaq.bind(this, item.id)}
            actions={[<span>{faqTag(item.tags)}</span>]}
          >
            <List.Item.Meta
              title={item.title}
              description={`Опубликовано: ${moment(item.published_at).format("LLL")}`}
            />
          </List.Item>
        )}
      />
    );
  }
}
FaqsList.propTypes = {
  faqs: PropTypes.arrayOf(PropTypes.object),
};

export default FaqsList;
