import update from "immutability-helper";

const initialState = {
  settings: { is_ticket_logs: false, is_task_logs: false },
  errors: false,
  isLoading: true,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "FETCH_SETTINGS_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_SETTINGS":
      return {
        ...state,
        settings: action.response.data,
        isLoading: false,
      };
    case "FETCH_SETTINGS_FAILURE":
      return {
        ...state,
        isLoading: true,
      };

    default:
      return state;
  }
}
