// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import Actions from "../../../actions/notices";
import List from "./List";

class Faqs extends Component {
  componentWillMount = () => {
    this.props.dispatch(Actions.onSetFilters()).then(() => {
      this.props.dispatch(Actions.fetchNotices());
    });
  };

  componentDidUpdate(prevProps) {
    if (this.props.location.key !== prevProps.location.key) {
      this.props.dispatch(Actions.onSetFilters()).then(() => {
        this.props.dispatch(Actions.fetchNotices());
      });
    }
  }

  onChangePage = (value) => {
    this.props
      .dispatch(Actions.onChangePage(value))
      .then(() => this.props.dispatch(Actions.fetchNotices()));
  };

  onChangeLimit = (current, size) => {
    this.props
      .dispatch(Actions.onChangeLimit(size))
      .then(() => this.props.dispatch(Actions.fetchNotices()));
  };

  onSortBy = (field, direction) => {
    this.props
      .dispatch(Actions.onSortBy(field, direction))
      .then(() => this.props.dispatch(Actions.fetchNotices()));
  };

  // filters
  onClearFilters = () => {
    this.props.dispatch(Actions.onClearFilters()).then(() =>
      this.props.dispatch(Actions.fetchNotices()).then(() => {
        localStorage.setItem(
          "noticesFilters",
          JSON.stringify(this.props.fetchParams)
        );
      })
    );
  };

  onChangeFilterPeriod = (value, dateString) => {
    this.props
      .dispatch(Actions.onChangeFilterPeriod(dateString[0], dateString[1]))
      .then(() => this.props.dispatch(Actions.fetchNotices()))
      .then(() => {
        localStorage.setItem(
          "ticketsFilters",
          JSON.stringify(this.props.fetchParams)
        );
      });
  };

  onChangeFilterTypes = (values) => {
    this.props.dispatch(Actions.onChangeFilterTypes(values)).then(() => {
      this.props.dispatch(Actions.fetchNotices()).then(() => {
        localStorage.setItem(
          "noticesFilters",
          JSON.stringify(this.props.fetchParams)
        );
      });
    });
  };

  onChangeFilterUsers = (values) => {
    let users = [];
    values.map((value) => {
      users.push({ id: String(value.id), name: String(value.name) });
    });
    // console.log(values)
    this.props.dispatch(Actions.onChangeFilterUsers(users)).then(() =>
      this.props.dispatch(Actions.fetchNotices()).then(() => {
        localStorage.setItem(
          "noticesFilters",
          JSON.stringify(this.props.fetchParams)
        );
      })
    );
  };

  onChangeFilterMembers = (values) => {
    let members = [];
    values.map((value) => {
      members.push({ id: String(value.id), name: String(value.name) });
    });
    // console.log(values)
    this.props.dispatch(Actions.onChangeFilterMembers(members)).then(() =>
      this.props.dispatch(Actions.fetchNotices()).then(() => {
        localStorage.setItem(
          "noticesFilters",
          JSON.stringify(this.props.fetchParams)
        );
      })
    );
  };

  render() {
    const { isLoading, notices, meta, fetchParams } = this.props;
    return (
      <List
        loading={isLoading}
        notices={notices}
        meta={meta}
        fetchParams={fetchParams}
        onSearch={this.onSearch}
        onChangePage={this.onChangePage}
        onChangeLimit={this.onChangeLimit}
        onSortBy={this.onSortBy}
        // filters
        onClearFilters={this.onClearFilters}
        onChangeFilterPeriod={this.onChangeFilterPeriod}
        onChangeFilterTypes={this.onChangeFilterTypes}
        onChangeFilterUsers={this.onChangeFilterUsers}
        onChangeFilterMembers={this.onChangeFilterMembers}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.notices.isLoading,
  notices: state.notices.archiveNotices,
  meta: state.notices.meta,
  fetchParams: state.notices.fetchParams,
});

export default connect(mapStateToProps)(Faqs);
