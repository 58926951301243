// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Comment, Tooltip, Avatar } from "antd";
import { Row, Col, Form, Input, Button } from "antd";
import moment from "moment";
import "moment/locale/ru";

const { TextArea } = Input;

class Notes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      note: ""
    };
  }

  onSubmit = () => {
    const { note, errors } = this.state;
    if (note.length > 3) {
      this.props.onCreateNote(note)
      if (!errors) {
        this.setState({note: ""})
      }
    }
  };

  onChangeComment = e => {
    this.setState({ note: e.target.value });
  };

  render() {
    const { notes, isLoading } = this.props;
    const { note } = this.state;

    return (
      <Row gutter={{ md: 8, lg: 24, xl: 48 }} style={{ marginBottom: "10px" }}>
        <Col span={24}>
          {notes.map(item => {
            return (
              <Comment
                key={item.id}
                author={item.user ? item.user.name : item.member.name}
                avatar={<Avatar src={item.user ? item.user.avatar : item.member.avatar} alt="" />}
                content={<p>{item.note}</p>}
                datetime={
                  <Tooltip
                    title={moment(item.created_at).format(
                      "YYYY-MM-DD HH:mm:ss"
                    )}
                  >
                    <span>{moment(item.created_at).fromNow()}</span>
                  </Tooltip>
                }
              />
            );
          })}
        </Col>
        <Col span={24}>
          <Form.Item>
            <TextArea
              rows={4}
              onChange={this.onChangeComment}
              value={note}
            />
          </Form.Item>
          <Form.Item>
            <Button
              loading={isLoading}
              onClick={this.onSubmit}
              type="primary"
            >
              Добавить примечание
            </Button>
          </Form.Item>
        </Col>
      </Row>
    );
  }
}

Notes.propTypes = {
  orderNotes: PropTypes.arrayOf(PropTypes.object),
  total: PropTypes.number,
  discount: PropTypes.object,
  shippingCost: PropTypes.number,
  amountDiscountCode: PropTypes.number,
  onRemoveVariant: PropTypes.func
};
export default Notes;
