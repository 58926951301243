// @flow
import React, { Component } from "react";
import { Row, Col, List, Avatar } from "antd";

class Header extends Component {
  render() {
    const { members } = this.props;

    return (
      <React.Fragment>
        <Row gutter={16}>
          <Col span={24}>
            <List
              grid={{ gutter: 10, column: 3 }}
              dataSource={members}
              renderItem={item => (
                <List.Item key={item.id} style={{paddingBottom: 15}}>
                  <List.Item.Meta
                    avatar={
                      <Avatar src={item.avatar ? item.avatar : null} />
                    }
                    title={item.name}
                    description={`${item.title}`}
                  />
                </List.Item>
              )}
            />
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

Header.propTypes = {};

export default Header;
