import React, { Component } from "react";
import { Row, Col, Modal, Button } from "antd";

class HelpMenu extends React.Component {
  render() {
    const { visible, onCancel, onOpenHelp, onOpenSupport, onOpenNews, onOpenSupportMessage } = this.props;
    return (
      <Modal
        title="Поддержка"
        visible={visible}
        onCancel={onCancel}
        footer={null}
      >
        <Row
          gutter={{ md: 8, lg: 24, xl: 48 }}
          type="flex"
          justify="space-around"
          align="middle"
        >
          <Col span={8}>
            <Button style={{ width: "100%" }} onClick={onOpenHelp}>База знаний</Button>
          </Col>
          <Col span={8}>
            <Button style={{ width: "100%" }} onClick={onOpenSupport}>Обращения</Button>
          </Col>
          <Col span={8}>
            <Button style={{ width: "100%" }} onClick={onOpenNews}>Новости</Button>
          </Col>
        </Row>
        <Row
          gutter={{ md: 8, lg: 24, xl: 48 }}
          type="flex"
          justify="space-around"
          align="middle"
          style={{paddingTop: 10}}
        >
          <Col span={24}>
            <Button style={{ width: "100%" }} onClick={onOpenSupportMessage}>Написать в поддержку</Button>
          </Col>
        </Row>
      </Modal>
    );
  }
}
export default HelpMenu;
