// @flow
import React from "react";
import { Rate, Popover, Table, Row, Col, Button } from "antd";
import moment from "moment";
import "moment/locale/ru";

export default function RaitingProjectProgress({
  data = [],
  onOpenRateForm,
  ticketId,
}) {
  const currentRates =
    data.length > 0
      ? data.filter(function (record) {
          return parseFloat(record.rate) > 0;
        })
      : [];
      const avgRate =
      currentRates.length > 0
        ? Math.round((currentRates.reduce((sum, record) => sum + parseFloat(record.rate), 0) /
          currentRates.length) * 2) / 2
        : 0;

  return (
    <Popover
      placement="right"
      content={
        <Datails
          data={data}
          onOpenRateForm={onOpenRateForm}
          ticketId={ticketId}
        />
      }
      title="Расшифровка"
      trigger="click"
    >
      <span>
        <Rate disabled style={{ fontSize: 12 }} allowHalf value={avgRate} />
      </span>
    </Popover>
  );
}

function Datails({ data = null, onOpenRateForm, ticketId }) {
  const columns = [
    {
      title: "Оценка",
      dataIndex: "rate",
      render: (rate, record) => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span>
            <Rate
              disabled
              style={
                record.is_auto
                  ? { fontSize: 12, color: "#108ee9" }
                  : { fontSize: 12 }
              }
              allowHalf
              value={parseFloat(rate)}
            />
          </span>
        </div>
      ),
      key: "rate",
      align: "center",
      width: "20%",
    },
    {
      title: "Комментарий",
      dataIndex: "comment",
      render: (comment, record) => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span style={{ opacity: "0.7" }}>{comment}</span>
        </div>
      ),
      key: "comment",
      align: "center",
      width: "40%",
    },
    {
      title: "Выслано",
      dataIndex: "sent_at",
      render: (sent_at) => (sent_at ? moment(sent_at).format("LLL") : null),
      key: "sent_at",
      align: "center",
      width: "20%",
    },
    {
      title: "Оценено",
      dataIndex: "rated_at",
      render: (rated_at) => (rated_at ? moment(rated_at).format("LLL") : null),
      key: "rated_at",
      align: "center",
      width: "20%",
    },
  ];

  return (
    <React.Fragment>
      <Row style={{ width: 590 }}>
        <Col span={24}>
          <Table
            rowClassName={"progress-row"}
            size={"small"}
            // showHeader={false}
            columns={columns}
            dataSource={data}
            pagination={false}
            rowKey="id"
          />
        </Col>
      </Row>
      <Row style={{ paddingTop: 10 }}>
        <Col span={24}>
          <Button onClick={onOpenRateForm.bind(this, ticketId)}>
            Добавить оценку
          </Button>
        </Col>
      </Row>
    </React.Fragment>
  );
}
