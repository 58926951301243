import update from "immutability-helper";
import moment from "moment";
import "moment/locale/ru";

const initialState = {
  entityTasks: [],
  entityTask: {
    entity: { id: null },
    customer: { id: null },
    comments: [],
    assignees: [],
    entity_task_todos: [],
  },
  notices: [],
  tasks: [],
  actions: [],
  events: [],
  meta: {
    total_pages: 1,
    current_page: 1,
    total_count: 1,
  },
  fetchParams: {
    startDate: moment().startOf("month").format("DD.MM.YYYY"),
    endDate: moment().endOf("month").format("DD.MM.YYYY"),
    search: null,
    sort: null,
    direction: null,
    page: 1,
    limit: 15,
    status: null,
    users: null,
  },
  errors: false,
  isLoading: true,
  isCommentsLoading: false,
  isAssigneesLoading: false,
  isEventsLoading: false,
  isTodoLoading: false,
  isTasksLoading: true,
};

function loadLocalFilters(fetchParams) {
  let localFetchParams = null;
  const localFilters = localStorage.getItem("entityTasksFilters");
  if (localFilters) {
    localFetchParams = JSON.parse(localFilters);
  } else {
    localStorage.setItem("entityTasksFilters", JSON.stringify(fetchParams));
  }

  return localFetchParams;
}

export default function (state = initialState, action) {
  let localFetchParams = null;
  switch (action.type) {
    case "FETCH_ENTITY_TASKS_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_ENTITY_TASKS":
      return {
        ...state,
        entityTasks: action.response.data,
        meta: action.response.meta,
        notices: action.response.notices,
        actions: action.response.actions,
        isLoading: false,
      };
    case "FETCH_ENTITY_TASK_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_ENTITY_TASK":
      return {
        ...state,
        entityTask: action.response.data,
        isLoading: false,
      };
    case "PATCH_ENTITY_TASK_REQUEST":
      return {
        ...state,
        isLoading: false,
        errors: false,
      };
    case "PATCH_ENTITY_TASK":
      return {
        ...state,
        isLoading: false,
        errors: false,
      };

    case "PATCH_ENTITY_TASK_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true,
      };

    case "REQUEST_ENTITY_TASK_SEARCH":
      return update(state, {
        fetchParams: { search: { $set: action.value }, page: { $set: 1 } },
      });

    case "REQUEST_ENTITY_TASK_CHANGE_PAGE":
      return update(state, {
        fetchParams: { page: { $set: action.value } },
      });

    case "REQUEST_ENTITY_TASK_CHANGE_LIMIT":
      return update(state, {
        fetchParams: { limit: { $set: action.value }, page: { $set: 1 } },
      });

    case "ENTITY_TASKS_SORT_BY":
      return update(state, {
        fetchParams: {
          sort: { $set: action.field },
          direction: { $set: action.direction },
        },
      });

    // comments
    case "FETCH_ENTITY_TASK_COMMENTS_REQUEST":
      return {
        ...state,
        isCommentsLoading: true,
      };
    case "FETCH_ENTITY_TASK_COMMENTS":
      return update(state, {
        entityTask: { comments: { $set: action.response.data } },
        isCommentsLoading: { $set: false },
      });

    case "FETCH_ENTITY_TASK_COMMENTS_FAILURE":
      return {
        ...state,
        errors: true,
        isCommentsLoading: false,
      };

    case "POST_ENTITY_TASK_COMMENT_REQUEST":
      return {
        ...state,
        errors: false,
        isCommentsLoading: true,
      };
    case "POST_ENTITY_TASK_COMMENT":
      return {
        ...state,
        errors: false,
        isCommentsLoading: false,
      };

    case "POST_ENTITY_TASK_COMMENT_FAILURE":
      return {
        ...state,
        errors: true,
        isCommentsLoading: false,
      };

    // assignees
    case "FETCH_ASSIGNEES_REQUEST":
      return {
        ...state,
        isAssigneesLoading: true,
      };
    case "FETCH_ASSIGNEES":
      return update(state, {
        entityTask: { assignees: { $set: action.response.data } },
        isAssigneesLoading: { $set: false },
      });

    case "FETCH_ASSIGNEES_FAILURE":
      return {
        ...state,
        errors: true,
        isAssigneesLoading: false,
      };

    case "POST_ASSIGNEE_REQUEST":
      return {
        ...state,
        errors: false,
        isAssigneesLoading: true,
      };
    case "POST_ASSIGNEE":
      return {
        ...state,
        errors: false,
        isAssigneesLoading: false,
      };

    case "POST_ASSIGNEE_FAILURE":
      return {
        ...state,
        errors: true,
        isAssigneesLoading: false,
      };

    // attachments
    case "FETCH_ATTACHMENTS":
      return update(state, {
        entityTask: { attachments: { $set: action.response.data } },
        isUploading: { $set: false },
      });
    case "FETCH_ATTACHMENT_REQUEST":
      return {
        ...state,
        isUploading: true,
      };

    case "ATTACHMENT_UPLOAD":
      return update(state, {
        isUploading: { $set: true },
      });

    case "ATTACHMENT_UPLOAD_SUCCEED":
      return update(state, {
        entityTask: { attachments: { $set: action.response.data } },
        errors: { $set: false },
        isUploading: { $set: false },
      });

    case "ATTACHMENT_UPLOAD_FAILED":
      return update(state, {
        errors: { $set: true },
        isUploading: { $set: false },
      });

    case "ATTACHMENT_DELETE_REQUEST":
      return {
        ...state,
        errors: false,
        isUploading: true,
      };
    case "ATTACHMENT_DELETE_SUCCEED":
      return {
        ...state,
        errors: false,
        isUploading: false,
      };
    case "ATTACHMENT_DELETE_FAILED":
      return {
        ...state,
        errors: true,
        isUploading: false,
      };

    // events
    case "FETCH_ENTITY_TASKS_EVENTS_REQUEST":
      return {
        ...state,
        isEventsLoading: true,
      };
    case "FETCH_ENTITY_TASKS_EVENTS":
      return {
        ...state,
        events: action.response.data,
        isEventsLoading: false,
      };

    case "POST_ENTITY_TASK_TODO_COMMENT_REQUEST":
      return {
        ...state,
        isTodoLoading: true,
      };

    case "POST_ENTITY_TASK_TODO_COMMENT":
      return update(state, {
        entityTask: {
          entity_task_todos: {
            [action.entityTaskTodoIdx]: {
              entity_task_todo_items: {
                [action.idx]: { $set: action.response.data },
              },
            },
          },
        },
        isTodoLoading: { $set: false },
        errors: { $set: false },
      });

    case "POST_ENTITY_TASK_TODO_COMMENT_FAILURE":
      return {
        ...state,
        errors: true,
        isTodoLoading: false,
      };

    // tasks
    case "FETCH_ENTITY_TASK_TASKS_REQUEST":
      return {
        ...state,
        isTasksLoading: true,
      };
    case "FETCH_ENTITY_TASK_TASKS":
      return update(state, {
        tasks: { $set: action.response.data },
        isTasksLoading: { $set: false },
        errors: { $set: false },
      });

    case "FETCH_ENTITY_TASK_TASKS_FAILURE":
      return {
        ...state,
        errors: true,
        isTasksLoading: false,
      };

    // filters
    case "ENTITY_TASKS_SET_FILTERS":
      localFetchParams = loadLocalFilters(state.fetchParams);
      if (localFetchParams) {
        localFetchParams.search = null;
        return {
          ...state,
          fetchParams: localFetchParams,
        };
      } else {
        return {
          ...state,
        };
      }

    case "ENTITY_TASKS_CLEAR_FILTERS":
      return update(state, {
        fetchParams: {
          status: { $set: null },
          users: { $set: null },
          page: { $set: 1 },
        },
      });

    case "ENTITY_TASKS_FILTER_STATUS":
      return update(state, {
        fetchParams: {
          status: { $set: action.value },
          page: { $set: 1 },
        },
      });

    case "ENTITY_TASKS_FILTER_PERIOD":
      return update(state, {
        fetchParams: {
          startDate: { $set: action.startDate },
          endDate: { $set: action.endDate },
          page: { $set: 1 },
        },
      });

    case "ENTITY_TASKS_FILTER_USERS":
      return update(state, {
        fetchParams: {
          users: { $set: action.values.length > 0 ? action.values : null },
          page: { $set: 1 },
        },
      });

    // notices
    case "POST_ENTITY_TASK_MARK_ALL_AS_READ_REQUEST":
      return {
        ...state,
        errors: false,
      };
    case "POST_ENTITY_TASK_MARK_ALL_AS_READ":
      return {
        ...state,
        errors: false,
        notices: action.response.data,
      };

    case "POST_ENTITY_TASK_MARK_ALL_AS_READ_FAILURE":
      return {
        ...state,
        errors: true,
      };

    default:
      return state;
  }
}
