// @flow
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  Table,
  Button,
  PageHeader,
  Badge,
  Tooltip,
  Icon,
  Typography,
  Tag,
} from "antd";
import { Pagination } from "antd";
import { Row, Col, Input, Rate } from "antd";

import PropTypes from "prop-types";
import AssigneesCell from "../../../components/AssigneesCell";
import ExpandDescription from "./ExpandDescription";
import NoticeIcon from "../../../components/NoticeIcon";
import Rating from "./Rating";
import RateForm from "./RateForm";

import moment from "moment";
import "moment/locale/ru";
import Filter from "./Filter";

const Search = Input.Search;
const { Paragraph } = Typography;

function statuses(status) {
  switch (status) {
    case "pending":
      return "error";
    case "processing":
      return "processing";
    case "closed":
      return "success";
    default:
      return "processing";
  }
}

function serviceTag(service) {
  return (
    <Tag key={service.id} color="#108ee9">
      {service.name}
    </Tag>
  );
}

class TicketsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
      isFilterVisible: false,
      rate: {},
    };
  }

  onFilterToggle = () => {
    this.setState({ isFilterVisible: !this.state.isFilterVisible });
  };

  onChangeTable = (pagination, filters, sorter) => {
    let direction = "desc";
    if (sorter.order === "descend") {
      direction = "desc";
    } else if (sorter.order === "ascend") {
      direction = "asc";
    }
    this.props.onSortBy(sorter.field, direction);
  };

  renderExpandedRow = (record) => {
    return (
      <ExpandDescription
        created_at={record.created_at}
        status_locale={record.status_locale}
        priority_locale={record.priority_locale}
        incident_locale={record.incident_locale}
        description={record.description}
      />
    );
  };

  onItemClick = (item) => {
    this.props.history.push(`/tickets/${item.parent_id}/edit`);
  };

  onClearNotices = () => {
    this.props.onClearNotices();
  };

  renderRate = (record) => {
    const rated = record.rates.filter(function (item) {
      return parseFloat(item.rate) > 0;
    });

    if (rated.length > 0) {
      return <Rating data={record.rates} onOpenRateForm={this.props.onOpenRateForm} ticketId={record.id} />;
    }

    if (rated.length == 0 && record.finish) {
      return (
        <span onClick={this.props.onOpenRateForm.bind(this, record.id)}>
          <Rate disabled style={{ fontSize: 12 }} allowHalf value={0} />
        </span>
      );
    }

    if (!record.finish) {
      return (
        <span>
          <Rate disabled style={{ fontSize: 12 }} allowHalf value={0} />
        </span>
      );
    }
  };

  render() {
    const { isFilterVisible } = this.state;
    const {
      loading,
      tickets,
      meta,
      fetchParams,
      notices,
      onSearch,
      onChangePage,
      onChangeLimit,
      // filters
      onClearFilters,
      onChangeFilterStatus,
      onChangeFilterReplaced,
      onChangeFilterOrdered,
      onChangeFilterCreatedPeriod,
      onChangeFilterPeriod,
      onChangeFilterUsers,
      onChangeFilterMembers,
      onChangeFilterPriorities,
      onChangeFilterRated,
      downloadReport,
      // rate
      isVisible,
      rate,
      onOpenRateForm,
      onSaveRate,
      onCancelRate,
      onChangeRate,
      onChangeComment,
    } = this.props;

    const columns = [
      {
        title: "Статус Приоритет Замена",
        dataIndex: "status",
        render: (status, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>
              <Badge
                status={statuses(record.status.id)}
                text={record.status.name}
              />
            </span>
            <span style={{ opacity: "0.7", fontSize: "12px" }}>
              {record.ticket_priority ? record.ticket_priority.name : null}
            </span>
            <span style={{ opacity: "0.7", fontSize: "12px" }}>
              {record.is_replaced ? "замена" : null}
            </span>
          </div>
        ),
        key: "status",
        align: "center",
        width: "10%",
      },
      {
        title: "#",
        dataIndex: "number",
        render: (number, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>
              <Link to={`/tickets/${record.id}/edit`}>{number}</Link>
            </span>
            <span>{this.renderRate(record)}</span>
          </div>
        ),
        sorter: true,
        sortDirections: ["ascend", "descend"],
        key: "number",
        align: "center",
        width: "10%",
      },
      {
        title: "Дата",
        dataIndex: "created_at",
        render: (created_at) => moment(created_at).format("L"),
        sorter: true,
        sortDirections: ["ascend", "descend"],
        key: "created_at",
        align: "center",
        width: "10%",
      },
      {
        title: "Выполнить до",
        dataIndex: "finish",
        render: (finish) => (finish ? moment(finish).format("L") : null),
        key: "finish",
        align: "center",
        width: "10%",
      },
      {
        title: "Объект",
        dataIndex: "entity",
        render: (entity, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>
              <Link to={`/entities/${entity.id}/edit`}>
                {entity ? entity.name : null}
              </Link>
            </span>
            <span style={{ opacity: "0.7" }}>
              {record.entity ? record.entity.address : null}
            </span>
          </div>
        ),
        align: "center",
        width: "20%",
      },
      {
        title: "Тема / Раздел",
        dataIndex: "subject",
        render: (subject, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>
              <Paragraph ellipsis={{ rows: 3, expandable: true }}>
                {subject}
              </Paragraph>
            </span>
            <span>
              {record.category
                ? serviceTag(record.category)
                : record.entity_service
                ? serviceTag(record.entity_service)
                : null}
            </span>
          </div>
        ),
        align: "left",
        width: "23%",
      },
      {
        title: "Ответственный",
        dataIndex: "assignees",
        render: (assignees, record) => <AssigneesCell assignees={assignees} />,
        align: "center",
        width: "18%",
      },
    ];

    const faqButton = (
      <Button
        type={"ghost"}
        key="7"
        icon="question"
        onClick={this.props.onFaqToggle}
      />
    );

    let mainButton = (
      <Link
        key="1"
        className={"ant-btn ant-btn-primary ant-btn-background-ghost"}
        to={{ pathname: `/tickets/new` }}
      >
        Создать
      </Link>
    );

    let excelButton = (
      <Tooltip placement="bottom" title={"Выгрузить в Excel"}>
        <Button key="excelButton" type="dashed" onClick={downloadReport}>
          <Icon type="file-excel" style={{ color: "green" }} />
          Выгрузить
        </Button>
      </Tooltip>
    );

    let filterButton = (
      <Button
        type={
          fetchParams.status ||
          fetchParams.replaced_status ||
          fetchParams.startDate ||
          fetchParams.startCreatedDate ||
          fetchParams.is_rated !== null ||
          fetchParams.users ||
          fetchParams.members
            ? "primary"
            : "ghost"
        }
        key="3"
        icon="filter"
        onClick={this.onFilterToggle}
      />
    );

    const unread = notices.filter(function (item) {
      return item.read == false;
    });

    const noticeIcon = (
      <NoticeIcon
        key="5"
        count={unread.length}
        onItemClick={this.onItemClick}
        list={notices}
        showClear
        onClear={this.onClearNotices}
        emptyText={"новых комментариев нет"}
      />
    );

    const totalInfo = `Всего: ${meta.total_count}`;

    return (
      <PageHeader
        className="page-header-with-notices"
        onBack={() => window.history.back()}
        title={`Заявки`}
        extra={[
          faqButton,
          noticeIcon,
          <Search
            allowClear
            key="77"
            placeholder="поиск..."
            value={fetchParams.search}
            onChange={onSearch}
            style={{ width: 250 }}
          />,
          mainButton,
          excelButton,
          filterButton,
        ]}
        footer={
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col span={24}>
              <Table
                // bordered
                size={"middle"}
                loading={loading}
                pagination={false}
                columns={columns}
                dataSource={tickets}
                expandedRowRender={(record) => this.renderExpandedRow(record)}
                onChange={this.onChangeTable}
                rowKey="id"
              />
            </Col>
            <Col
              span={24}
              style={{
                paddingTop: "15px",
                paddingBottom: "15px",
                textAlign: "right",
              }}
            >
              <Pagination
                showSizeChanger
                pageSize={fetchParams.limit}
                current={meta.current_page}
                onChange={onChangePage}
                onShowSizeChange={onChangeLimit}
                showTotal={(total) => totalInfo}
                total={meta.total_count}
              />
            </Col>
          </Row>
        }
      >
        <Filter
          visible={isFilterVisible}
          status={fetchParams.status}
          isReplaced={fetchParams.is_replaced}
          isOrdered={fetchParams.is_ordered}
          isRated={fetchParams.is_rated}
          startDate={fetchParams.startDate}
          endDate={fetchParams.endDate}
          startCreatedDate={fetchParams.startCreatedDate}
          endCreatedDate={fetchParams.endCreatedDate}
          users={fetchParams.users}
          members={fetchParams.members}
          priorities={fetchParams.priorities}
          onClose={this.onFilterToggle}
          onClearFilters={onClearFilters}
          onChangeStatus={onChangeFilterStatus}
          onChangeReplaced={onChangeFilterReplaced}
          onChangeOrdered={onChangeFilterOrdered}
          onChangeCreatedPeriod={onChangeFilterCreatedPeriod}
          onChangePeriod={onChangeFilterPeriod}
          onChangeUsers={onChangeFilterUsers}
          onChangeMembers={onChangeFilterMembers}
          onChangePriorities={onChangeFilterPriorities}
          onChangeRated={onChangeFilterRated}
        />
        <RateForm
          visible={isVisible}
          data={rate}
          comment={null}
          onSave={onSaveRate}
          onCancel={onCancelRate}
          onChangeRate={onChangeRate}
          onChangeComment={onChangeComment}
        />
      </PageHeader>
    );
  }
}

TicketsList.propTypes = {
  tickets: PropTypes.arrayOf(PropTypes.object),
  meta: PropTypes.object,
  fetchParams: PropTypes.object,
};

export default withRouter(TicketsList);
