// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import Actions from "../../../actions/supportMessages";
import SupportMessagesListView from "./ListView";

class SupportMessages extends Component {
  componentWillMount = () => {
    this.props.dispatch(Actions.onClearFilters()).then(() => {
      this.props.dispatch(Actions.fetchSupportMessages());
    });
  };

  onDelete = ids => {
    ids.map(id => {
      this.props.dispatch(Actions.onDelete(id)).then(() => {
        this.props.dispatch(Actions.fetchSupportMessages());
      });
    });
  };

  onSearch = e => {
    this.props
      .dispatch(Actions.onSearch(e.target.value))
      .then(() => this.props.dispatch(Actions.fetchSupportMessages()));
  };

  onChangePage = value => {
    this.props
      .dispatch(Actions.onChangePage(value))
      .then(() => this.props.dispatch(Actions.fetchSupportMessages()));
  };

  onChangeLimit = (current, size) => {
    this.props
      .dispatch(Actions.onChangeLimit(size))
      .then(() => this.props.dispatch(Actions.fetchSupportMessages()));
  };

  onSortBy = (field, direction) => {
    this.props
      .dispatch(Actions.onSortBy(field, direction))
      .then(() => this.props.dispatch(Actions.fetchSupportMessages()));
  };

  render() {
    const {
      currentMember,
      isLoading,
      supportMessages,
      meta,
      fetchParams
    } = this.props;
    return (
      <SupportMessagesListView
        currentMember={currentMember}
        loading={isLoading}
        supportMessages={supportMessages}
        meta={meta}
        fetchParams={fetchParams}
        onSearch={this.onSearch}
        onChangePage={this.onChangePage}
        onChangeLimit={this.onChangeLimit}
        onSortBy={this.onSortBy}
      />
    );
  }
}

const mapStateToProps = state => ({
  currentMember: state.session.currentMember,
  isLoading: state.supportMessages.isLoading,
  supportMessages: state.supportMessages.supportMessages,
  meta: state.supportMessages.meta,
  fetchParams: state.supportMessages.fetchParams
});

export default connect(mapStateToProps)(SupportMessages);
