// @flow
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { Table, Button, PageHeader, Typography, Tag } from "antd";
import { Pagination } from "antd";
import { Row, Col, Input } from "antd";

import PropTypes from "prop-types";
import AssigneesCell from "../../../components/AssigneesCell";
import accounting from "accounting";
import ProjectProgress from "../../../components/ProjectProgress";

import NoticeIcon from "../../../components/NoticeIcon";

import moment from "moment";
import "moment/locale/ru";
import Filter from "./Filter";

const Search = Input.Search;

class ProjectsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projectProgress: false,
      isFilterVisible: false,
    };
  }


  onChangeTable = (pagination, filters, sorter) => {
    let direction = "desc";
    if (sorter.project === "descend") {
      direction = "desc";
    } else if (sorter.project === "ascend") {
      direction = "asc";
    }
    this.props.onSortBy(sorter.field, direction);
  };

  onItemClick = (item) => {
    this.props.history.push(`/projects/${item.parent_id}/edit`);
  };

  onFilterToggle = () => {
    this.setState({ isFilterVisible: !this.state.isFilterVisible });
  };

  onClearNotices = () => {
    this.props.onClearNotices();
  };

  render() {
    const {
      isFilterVisible,
    } = this.state;
    const {
      currentMember,
      loading,
      projects,
      meta,
      fetchParams,
      notices,
      onSearch,
      onChangePage,
      onChangeLimit,
      onSortBy,
      // filters
      onClearFilters,
      onChangeFilterStatus,
      onChangeFilterPeriod,
      onChangeFilterPeriodFinish,
      onChangeFilterUsers,
      onChangeFilterMembers,
    } = this.props;

    const columns = [
      {
        title: "Договор / Статус",
        dataIndex: "number",
        render: (number, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>
              <Link to={`/projects/${record.id}/edit`}>{number}</Link>
            </span>
            <span style={{ fontSize: 12 }}>
              <ProjectProgress
                title={record.status_locale}
                projectId={record.id}
              />
            </span>
          </div>
        ),
        key: "number",
        align: "center",
        width: "10%",
      },
      {
        title: "Дата создания",
        dataIndex: "created_at",
        render: (created_at) => moment(created_at).format("L"),
        sorter: true,
        sortDirections: ["ascend", "descend"],
        key: "created_at",
        align: "center",
        width: "10%",
      },
      {
        title: "Дата принятия",
        dataIndex: "finish",
        render: (finish, record) => null,
        sorter: true,
        sortDirections: ["ascend", "descend"],
        key: "finish",
        align: "center",
        width: "10%",
      },
      {
        title: "Сумма",
        dataIndex: "amount",
        render: (amount) => accounting.formatNumber(amount, 0, " "),
        sorter: true,
        sortDirections: ["ascend", "descend"],
        key: "amount",
        align: "center",
        width: "10%",
      },
      {
        title: "Клиент / Объект",
        dataIndex: "customer",
        render: (customer, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span style={{ opacity: "0.7" }}>
              {record.entity ? record.entity.name : null}
            </span>
            <span style={{ opacity: "0.7" }}>
              {record.entity ? record.entity.address : null}
            </span>
          </div>
        ),
        align: "center",
        width: "25%",
      },
      {
        title: "Тип / Описание",
        dataIndex: "project_type",
        render: (project_type, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>{project_type ? project_type.name : null}</span>
            <span style={{ opacity: "0.7" }}>{record.description}</span>
          </div>
        ),
        key: "project_type",
        align: "center",
        width: "20%",
      },
      {
        title: "Ответственный",
        dataIndex: "assignees",
        render: (assignees, record) => <AssigneesCell assignees={assignees} />,
        align: "center",
        width: "15%",
      },
    ];

    const faqButton = (
      <Button
        type={"ghost"}
        key="7"
        icon="question"
        onClick={this.props.onFaqToggle}
      />
    );

    let filterButton = (
      <Button
        type={
          fetchParams.startDate ||
          fetchParams.startFinish ||
          fetchParams.status ||
          fetchParams.users ||
          fetchParams.members
            ? "primary"
            : "ghost"
        }
        key="filterButton"
        icon="filter"
        onClick={this.onFilterToggle}
      />
    );

    const unread =  notices.filter(function(item) {
      return item.read == false;
    });

    const noticeIcon = (
      <NoticeIcon
        key="noticeIcon"
        count={unread.length}
        onItemClick={this.onItemClick}
        list={notices}
        showClear
        onClear={this.onClearNotices}
        emptyText={"новых комментариев нет"}
      />
    );

    return (
      <PageHeader
        className="page-header-with-notices"
        onBack={() => window.history.back()}
        title={`Проекты`}
        subTitle={
          fetchParams.startDate && fetchParams.endDate
            ? `c ${fetchParams.startDate} по ${fetchParams.endDate}`
            : null
        }
        extra={[
          faqButton,
          noticeIcon,
          <Search
            allowClear
            key="1"
            placeholder="поиск..."
            value={fetchParams.search}
            onChange={onSearch}
            style={{ width: 250 }}
          />,
          filterButton,
        ]}
        footer={
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col span={24}>
              <Table
                size={"middle"}
                loading={loading}
                pagination={false}
                columns={columns}
                dataSource={projects}
                onChange={this.onChangeTable}
                rowKey="id"
              />
            </Col>
            <Col
              span={24}
              style={{
                paddingTop: "15px",
                paddingBottom: "15px",
                textAlign: "right",
              }}
            >
              <Pagination
                showSizeChanger
                pageSize={fetchParams.limit}
                current={meta.current_page}
                onChange={onChangePage}
                onShowSizeChange={onChangeLimit}
                total={meta.total_count}
              />
            </Col>
          </Row>
        }
      >
        <Filter
          currentUser={currentMember}
          visible={isFilterVisible}
          startDate={fetchParams.startDate}
          endDate={fetchParams.endDate}
          startFinish={fetchParams.startFinish}
          endFinish={fetchParams.endFinish}
          status={fetchParams.status}
          users={fetchParams.users}
          members={fetchParams.members}
          onClose={this.onFilterToggle}
          onClearFilters={onClearFilters}
          onChangeStatus={onChangeFilterStatus}
          onChangePeriod={onChangeFilterPeriod}
          onChangePeriodFinish={onChangeFilterPeriodFinish}
          onChangeUsers={onChangeFilterUsers}
          onChangeMembers={onChangeFilterMembers}
        />
      </PageHeader>
    );
  }
}

ProjectsList.propTypes = {
  projects: PropTypes.arrayOf(PropTypes.object),
  meta: PropTypes.object,
  fetchParams: PropTypes.object,
};

export default withRouter(ProjectsList);
