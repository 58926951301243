// @flow
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Row, Col, PageHeader, List, Button, Avatar } from "antd";

import PropTypes from "prop-types";
import moment from "moment";
import "moment/locale/ru";

import Filter from "./Filter";


class NoticesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFilterVisible: false,
    };
  }

  onFilterToggle = () => {
    this.setState({ isFilterVisible: !this.state.isFilterVisible });
  };

  render() {
    const { isFilterVisible } = this.state;
    const {
      loading,
      notices,
      meta,
      fetchParams,
      onSearch,
      onChangePage,
      onChangeLimit,
      onSortBy,
      // filters
      onClearFilters,
      onChangeFilterTypes,
      onChangeFilterUsers,
      onChangeFilterMembers,
      onChangeFilterPeriod,
    } = this.props;

    let filterButton = (
      <Button
        type={
          fetchParams.startDate ||
          fetchParams.types ||
          fetchParams.users ||
          fetchParams.members
            ? "primary"
            : "ghost"
        }
        key="filter-btn"
        icon="filter"
        onClick={this.onFilterToggle}
      />
    );

    return (
      <PageHeader
        onBack={() => window.history.back()}
        title={`Уведомления`}
        extra={[filterButton]}
        footer={
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col
              span={24}
              style={{
                paddingTop: "15px",
                paddingBottom: "15px",
              }}
            >
              <List
                loading={loading}
                itemLayout="vertical"
                size="small"
                pagination={{
                  pageSize: fetchParams.limit,
                  current: meta.current_page,
                  onChange: onChangePage,
                  onShowSizeChange: onChangeLimit,
                  total: meta.total_count,
                }}
                dataSource={notices}
                footer={null}
                renderItem={(item) => (
                  <List.Item
                    key={item.id}
                    actions={[
                      <span style={{ textAlign: "right" }}>{item.title}</span>,
                    ]}
                    extra={[moment(item.datetime).format("L")]}
                  >
                    <List.Item.Meta
                      avatar={<Avatar src={item.avatar} />}
                      title={
                        <Link to={`/${item.path}/${item.parent_id}/edit`}>
                          {item.extra}
                        </Link>
                      }
                      description={item.description}
                    />
                  </List.Item>
                )}
              />
            </Col>
          </Row>
        }
      >
        <Filter
          visible={isFilterVisible}
          users={fetchParams.users}
          members={fetchParams.members}
          startDate={fetchParams.startDate}
          endDate={fetchParams.endDate}
          types={fetchParams.types}
          onClearFilters={onClearFilters}
          onChangeTypes={onChangeFilterTypes}
          onChangeUsers={onChangeFilterUsers}
          onChangeMembers={onChangeFilterMembers}
          onChangePeriod={onChangeFilterPeriod}
        />
        </PageHeader>
    );
  }
}

NoticesList.propTypes = {
  notices: PropTypes.arrayOf(PropTypes.object),
  meta: PropTypes.object,
  fetchParams: PropTypes.object,
};

export default NoticesList;
