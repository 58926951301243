// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Col, Row, Descriptions } from "antd";

class GeneralInfo extends Component {
  constructor(props, context) {
    super(props, context);
  }
  render() {
    const { order } = this.props;

    return (
      <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
        <Col span={24}>
          <Row gutter={16}>
            <Descriptions bordered>
              <Descriptions.Item label="Объект" span={3}>
                {order.entity ? (
                  <Link to={`/entities/${order.entity.id}/edit`}>
                    {order.entity.name}
                  </Link>
                ) : null}
              </Descriptions.Item>
              <Descriptions.Item label="Адрес" span={3}>
                {order.entity ? order.entity.address : null}
              </Descriptions.Item>
              <Descriptions.Item label="Раздел" span={3}>
                {order.entity_service ? order.entity_service.name : null}
              </Descriptions.Item>
              <Descriptions.Item label="Тема" span={3}>
                {order.subject ? order.subject : null}
              </Descriptions.Item>
              <Descriptions.Item label="Описание" span={3}>
                {order.description ? order.description : null}
              </Descriptions.Item>
            </Descriptions>
          </Row>
        </Col>
      </Row>
    );
  }
}

GeneralInfo.propTypes = {
  order: PropTypes.object,
};

export default GeneralInfo;
