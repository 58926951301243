// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import update from "immutability-helper";
import Actions from "../../../actions/tickets";
import TaskActions from "../../../actions/tasks";
import { Spin } from "antd";
import Form from "./Form";
import FormInfo from "./FormInfo";
import moment from "moment";
import "moment/locale/ru";

class Ticket extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNew: false,
      tempAttachments: [],
    };
  }

  componentWillMount = () => {
    if (this.props.match.params.id) {
      this.props.dispatch(Actions.fetchTicket(this.props.match.params.id));
    } else {
      this.setState({ isNew: true }, () => {
        this.props.dispatch(Actions.onNew());
      });
    }
  };

  componentDidUpdate(prevProps) {
    if (this.props.location.key !== prevProps.location.key) {
      if (!this.props.match.params.id) {
        this.setState({ isNew: true });
      } else {
        this.setState({ isNew: false }, () => {
          this.props.dispatch(Actions.fetchTicket(this.props.match.params.id));
        });
      }
    }
  }

  onSave = () => {
    const { tempAttachments } = this.state;
    if (this.props.match.params.id) {
      this.props.dispatch(Actions.onUpdate(this.props.match.params.id));
    } else {
      this.props.dispatch(Actions.onCreate(tempAttachments)).then(() => {
        if (!this.props.errors) {
          this.setState({ isNew: false }, () => {
            this.props.history.push(`/tickets`);
          });
        }
      });
    }
  };

  onClose = () => {
    this.props.history.push(`/tickets`);
  };

  onCreateReplacementTicket = () => {
    const { ticket } = this.props;
    const data = {
      ticket: {
        is_replaced: true,
        base_ticket: { id: this.props.match.params.id, number: ticket.number },
        status: "pended",
        priority: "high",
        incident: "by_ticket",
        due_by: moment(),
        customer: ticket.customer,
        entity: ticket.entity,
        entity_service: ticket.entity_service,
        user: ticket.user,
        assignees: ticket.assignees,
        amount: 0,
        subject: `Замена по заявке #${ticket.number}.`,
        description:
          ticket.comments.length > 0 ? ticket.comments[1].comment : null,
        defect_act: { url: null },
        comments: [],
        attachments: [],
        category: ticket.category,
        services: ticket.services,
      },
    };

    this.props.dispatch(Actions.onCreateReplacementTicket(data)).then(() => {
      this.props.history.push(`/tickets/new`);
    });
  };

  onChangeNumber = (value) => {
    if (value != this.props.ticket.number) {
      this.props.dispatch(Actions.onUpdate(this.props.match.params.id, value));
    }
  };

  onChangeCustomer = (value) => {
    this.props.dispatch(Actions.onChangeCustomer(value));
  };

  onChangeEntity = (value) => {
    this.props.dispatch(Actions.onChangeEntity(value));
  };

  onChangeEntityService = (value) => {
    this.props.dispatch(Actions.onChangeEntityService(value));
  };

  onChangeCategory = (value) => {
    this.props.dispatch(Actions.onChangeCategory(value));
  };

  onChangeServices = (values) => {
    this.props.dispatch(Actions.onChangeServices(values));
  };

  onChangePiority = (value) => {
    this.props.dispatch(Actions.onChangePiority(value));
  };

  onChangeIncident = (value) => {
    this.props.dispatch(Actions.onChangeIncident(value));
  };

  onChangeAmount = (value) => {
    this.props.dispatch(Actions.onChangeAmount(value));
  };

  onChangeSubject = (e) => {
    this.props.dispatch(Actions.onChangeSubject(e.target.value));
  };

  onChangeDescription = (e) => {
    this.props.dispatch(Actions.onChangeDescription(e.target.value));
  };

  onChangeDueBy = (value) => {
    this.props.dispatch(Actions.onChangeDueBy(value));
  };

  onCreateComment = (comment, mentioned) => {
    this.props.dispatch(Actions.onCreateComment(this.props.match.params.id, comment, mentioned))
      .then(() => {
        if (!this.props.errors) {
          this.props.dispatch(
            Actions.fetchTicketComments(this.props.match.params.id)
          );
        }
      });
  };

  // attachments
  onDeleteAttachment = (id) => {
    this.props
      .dispatch(Actions.onDeleteAttachment(this.props.match.params.id, id))
      .then(() => {
        if (!this.props.errors) {
          this.props.dispatch(
            Actions.fetchAttachments(this.props.match.params.id)
          );
        }
      });
  };

  onUploadAttachment = (file, attachmentType) => {
    this.props
      .dispatch(Actions.onUploadAttachment(this.props.match.params.id, file, attachmentType))
      .then(() => {
        if (!this.props.errors) {
          this.props.dispatch(
            Actions.fetchAttachments(this.props.match.params.id)
          );
        }
      });
  };

  // preUploads
  onTempUpload = (attachment) => {
    this.setState({
      tempAttachments: update(this.state.tempAttachments, {
        $push: [attachment],
      }),
    });
  };

  onDeleteTempAttachment = (id) => {
    let idx = this.state.tempAttachments.findIndex(function (o) {
      return o.id == id;
    });
    if (idx === -1) {
      return null;
    }

    this.setState({
      tempAttachments: update(this.state.tempAttachments, {
        $splice: [[idx, 1]],
      }),
    });
  };

  // assignees
  onCreateAssignee = (value, type) => {
    this.props
      .dispatch(
        Actions.onCreateAssignee(this.props.match.params.id, value, type)
      )
      .then(() => {
        if (!this.props.errors) {
          this.props.dispatch(
            Actions.fetchAssignees(this.props.match.params.id)
          );
        }
      });
  };

  onDeleteAssignee = (id) => {
    this.props
      .dispatch(Actions.onDeleteAssignee(this.props.match.params.id, id))
      .then(() => {
        if (!this.props.errors) {
          this.props.dispatch(
            Actions.fetchAssignees(this.props.match.params.id)
          );
        }
      });
  };

  // statuses
  onResetStatus = () => {
    this.props.dispatch(Actions.onResetStatus(this.props.match.params.id));
  };

  onChangeStatus = (value) => {
    this.props.dispatch(
      Actions.onChangeStatus(this.props.match.params.id, value.id)
    );
  };

  onChangeReplaced = (e) => {
    this.props.dispatch(
      Actions.onChangeReplaced(this.props.match.params.id, e.target.checked)
    );
  };

  onChangeOrdered = (e) => {
    this.props.dispatch(
      Actions.onChangeOrdered(this.props.match.params.id, e.target.checked)
    );
  };

  // activities
  fetchRequestForTicketActivities = () => {
    this.props.dispatch(
      Actions.fetchRequestForTicketActivities(this.props.match.params.id)
    );
  };

  // tasks
  fetchTasks = () => {
    this.props.dispatch(Actions.fetchTasks(this.props.match.params.id));
  };

  // actions
  onOpenTaskForm = (action, id) => {
    const { ticket } = this.props;
    let assignees = [];
    ticket.assignees
      .filter(function (item) {
        return (
          item.assignee_type == "organisation" ||
          item.assignee_type == "customer"
        );
      })
      .map((item) => {
        assignees.push({
          id: item.user ? item.user.id : item.member.id,
          name: item.user ? item.user.name : item.member.name,
          avatar: item.user ? item.user.avatar : item.member.avatar,
          assignee_type: item.assignee_type,
        });
      });
    this.props.dispatch(TaskActions.onOpenModalForm(action, id, assignees));
  };

  render() {
    const { tempAttachments } = this.state;
    const {
      currentMember,
      isLoading,
      isCommentsLoading,
      isUploading,
      isDefectActUploading,
      isAssigneesLoading,
      errors,
      ticket,
      ticketActivities,
      isActivitiesLoading,
      // tasks
      isTasksLoading,
      tasks,
      settings,
      // actions
      actions,
    } = this.props;
    return this.state.isNew ? (
      <Form
        currentMember={currentMember}
        isNew={this.state.isNew}
        isLoading={isLoading}
        isUploading={isUploading}
        isDefectActUploading={isDefectActUploading}
        isCommentsLoading={isCommentsLoading}
        isAssigneesLoading={isAssigneesLoading}
        isActivitiesLoading={isActivitiesLoading}
        errors={errors}
        ticket={ticket}
        ticketActivities={ticketActivities}
        onSave={this.onSave}
        onClose={this.onClose}
        onChangeCustomer={this.onChangeCustomer}
        onChangeEntity={this.onChangeEntity}
        onChangeEntityService={this.onChangeEntityService}
        onChangeCategory={this.onChangeCategory}
        onChangeServices={this.onChangeServices}
        onChangePiority={this.onChangePiority}
        onChangeIncident={this.onChangeIncident}
        onChangeAmount={this.onChangeAmount}
        onChangeSubject={this.onChangeSubject}
        onChangeDescription={this.onChangeDescription}
        onChangeDueBy={this.onChangeDueBy}
        onCreateComment={this.onCreateComment}
        onDeleteAttachment={this.onDeleteAttachment}
        onUploadAttachment={this.onUploadAttachment}
        // preUpload
        tempAttachments={tempAttachments}
        onTempUpload={this.onTempUpload}
        onDeleteTempAttachment={this.onDeleteTempAttachment}
        // assignees
        onCreateAssignee={this.onCreateAssignee}
        onDeleteAssignee={this.onDeleteAssignee}
        onCreateReplacementTicket={this.onCreateReplacementTicket}
        // statuses
        onResetStatus={this.onResetStatus}
        onChangeStatus={this.onChangeStatus}
        onChangeReplaced={this.onChangeReplaced}
        onChangeOrdered={this.onChangeOrdered}
        fetchRequestForTicketActivities={this.fetchRequestForTicketActivities}
        // tasks
        isTasksLoading={isTasksLoading}
        tasks={tasks}
        fetchTasks={this.fetchTasks}
        // actions
        actions={actions}
        onOpenTaskForm={this.onOpenTaskForm}
      />
    ) : (
      <FormInfo
        currentMember={currentMember}
        isNew={this.state.isNew}
        isLoading={isLoading}
        isUploading={isUploading}
        isDefectActUploading={isDefectActUploading}
        isCommentsLoading={isCommentsLoading}
        isAssigneesLoading={isAssigneesLoading}
        isActivitiesLoading={isActivitiesLoading}
        errors={errors}
        ticket={ticket}
        ticketActivities={ticketActivities}
        onSave={this.onSave}
        onClose={this.onClose}
        onChangeNumber={this.onChangeNumber}
        onChangeCustomer={this.onChangeCustomer}
        onChangeEntity={this.onChangeEntity}
        onChangeEntityService={this.onChangeEntityService}
        onChangePiority={this.onChangePiority}
        onChangeIncident={this.onChangeIncident}
        onChangeAmount={this.onChangeAmount}
        onChangeSubject={this.onChangeSubject}
        onChangeDescription={this.onChangeDescription}
        onChangeDueBy={this.onChangeDueBy}
        onCreateComment={this.onCreateComment}
        onDeleteAttachment={this.onDeleteAttachment}
        onUploadAttachment={this.onUploadAttachment}
        onCreateAssignee={this.onCreateAssignee}
        onDeleteAssignee={this.onDeleteAssignee}
        onCreateReplacementTicket={this.onCreateReplacementTicket}
        // statuses
        settings={settings}
        onResetStatus={this.onResetStatus}
        onChangeStatus={this.onChangeStatus}
        onChangeReplaced={this.onChangeReplaced}
        onChangeOrdered={this.onChangeOrdered}
        fetchRequestForTicketActivities={this.fetchRequestForTicketActivities}
        // tasks
        isTasksLoading={isTasksLoading}
        tasks={tasks}
        fetchTasks={this.fetchTasks}
        // actions
        actions={actions}
        onOpenTaskForm={this.onOpenTaskForm}
      />
    );
  }
}

Ticket.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  ticket: PropTypes.object,
  isLoading: PropTypes.bool,
  errors: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  currentMember: state.session.currentMember,
  isLoading: state.tickets.isLoading,
  isCommentsLoading: state.tickets.isCommentsLoading,
  isUploading: state.tickets.isUploading,
  isDefectActUploading: state.tickets.isDefectActUploading,
  isAssigneesLoading: state.tickets.isAssigneesLoading,
  errors: state.tickets.errors,
  ticket: state.tickets.ticket,
  ticketActivities: state.tickets.ticket_activities,
  isActivitiesLoading: state.tickets.isActivitiesLoading,
  // tasks
  isTasksLoading: state.tickets.isTasksLoading,
  tasks: state.tickets.tasks,
  // settings
  settings: state.settings.settings,
  // actions
  actions: state.tasks.actions,
  isTaskActionsLoading: state.tasks.isLoading,
});

export default connect(mapStateToProps)(withRouter(Ticket));
