import React, { Component } from "react";
import { Row, Col, Form, Modal, Rate, Button, Input } from "antd";

const FormItem = Form.Item;
const { TextArea } = Input;

const desc = ["очень плохо", "плохо", "удовлетворительно", "хорошо", "отлично"];

class RateForm extends React.Component {
  onSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }
      this.props.onSave();
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      visible,
      data,
      onChangeRate,
      onChangeComment,
      onCancel,
    } = this.props;
    return (
      <Modal
        destroyOnClose
        title="Оценка"
        visible={visible}
        onOk={this.onSubmit}
        onCancel={onCancel}
        footer={[
          <Button key="back" onClick={onCancel}>
            Отменить
          </Button>,
          <Button
            disabled={parseFloat(data.rate) > 0 ? false : true}
            key="submit"
            type="primary"
            onClick={this.onSubmit}
          >
            ОК
          </Button>,
        ]}
      >
        <Form layout={"vertical"}>
          <Row type="flex" justify="center" align="middle">
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              style={{ textAlign: "center" }}
            >
              <Rate
                style={{ fontSize: 40 }}
                tooltips={desc}
                allowHalf
                value={data.rate}
                onChange={onChangeRate}
              />
            </Col>
            <Col span={24}>
              <FormItem label={"Комментарий"}>
                {getFieldDecorator("comment", {
                  onChange: onChangeComment,
                  initialValue: data.comment,
                })(<TextArea autoSize={{ minRows: 2, maxRows: 6 }} />)}
              </FormItem>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
}

export default Form.create()(RateForm);
