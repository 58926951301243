import React, { Component } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { Modal, Button } from "antd";
import moment from "moment";
import "moment/locale/ru";
import "react-big-calendar/lib/css/react-big-calendar.css";

const localizer = momentLocalizer(moment);

const messages = {
  today: "сегодня",
  previous: "пред.",
  next: "след.",
  month: "месяц",
  week: "неделя",
  day: "день",
  agenda: "список",
  event: "событие",
  showMore: total => `+ (${total}) событий еще`
};

function eventStyle (event, start , end, isSelected, ) {
  return {
    style: {
      backgroundColor: event.color
    }
  }
}

class RBCalendar extends Component {
  

  render() {
    const { events, onNavigate, onSelectEvent } = this.props;
    const views = ['month', 'week', 'day'];

    return (
      <Calendar
        selectable={true}
        localizer={localizer}
        defaultDate={moment()
          .startOf("month")
          .toDate()}
        defaultView="month"
        views={views}
        // onView={view => {
        //   this.setState({ view });
        // }}
        culture="ru"
        messages={messages}
        events={events}
        // onSelectEvent={event => info(event)}
        eventPropGetter={eventStyle}
        onSelectEvent={onSelectEvent}
        onNavigate={onNavigate}
        style={{ height: "80vh" }}
      />
    );
  }
}

export default RBCalendar;