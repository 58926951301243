// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Col, Row, Descriptions, Checkbox } from "antd";
import EntityOpeningHours from "../../../components/EntityOpeningHours";

class GeneralInfo extends Component {
  constructor(props, context) {
    super(props, context);
  }
  render() {
    const { ticket } = this.props;

    return (
      <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
        <Col span={24}></Col>
        <Col span={24}>
          <Row gutter={16}>
            <Descriptions bordered>
              <Descriptions.Item
                label={
                  ticket.entity ? (
                    <EntityOpeningHours
                      title={"Объект"}
                      entityId={ticket.entity.id}
                    />
                  ) : (
                    "Объект"
                  )
                }
                span={3}
              >
                {ticket.entity ? (
                  <Link to={`/entities/${ticket.entity.id}/edit`}>
                    {ticket.entity.name}
                  </Link>
                ) : null}
              </Descriptions.Item>
              <Descriptions.Item label="Адрес" span={3}>
                {ticket.address}
              </Descriptions.Item>
              <Descriptions.Item label="Примечания" span={3}>
                {ticket.entity_notes}
              </Descriptions.Item>
              <Descriptions.Item label="Раздел" span={3}>
                {ticket.entity_service ? ticket.entity_service.name : null}
              </Descriptions.Item>
              <Descriptions.Item label="Тема" span={3}>
                {ticket.subject ? ticket.subject : null}
              </Descriptions.Item>
              <Descriptions.Item label="Описание" span={3}>
                {ticket.description ? ticket.description : null}
              </Descriptions.Item>
            </Descriptions>
          </Row>
        </Col>
      </Row>
    );
  }
}

GeneralInfo.propTypes = {
  order: PropTypes.object,
};

export default GeneralInfo;
