// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, List, Avatar, Divider, Tooltip, Button } from "antd";

import "moment/locale/ru";
import _ from "lodash/array";
import SelectFetch from "../../../components/SelectFetch";


class Assignees extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAddingAssignee: false,
      assignee: null
    };
  }

  onOpenAddingAssignee = () => {
    this.setState({ isAddingAssignee: true });
  };

  onCloseAddingAssignee = () => {
    this.setState({ isAddingAssignee: false });
  };

  onSelectAssignee = value => {
    this.setState({ assignee: value });
  };

  onChangeAssignees = () => {
    const { assignee } = this.state;
    const { assigneeType, assignees } = this.props;
    let idx = -1;
    if (assigneeType == "organisation") {
      idx = _.findIndex(assignees, function(o) {
        return o.user.id == assignee.id;
      });
    } else {
      idx = _.findIndex(assignees, function(o) {
        return o.member.id == assignee.id;
      });
    }

    if (assignee && idx == -1) {
      this.props.onCreateAssignee(this.state.assignee, assigneeType);
      this.setState({ isAddingAssignee: false, assignee: null });
    }
  };

  render() {
    const { isAddingAssignee, assignee } = this.state;
    const {
      isReadOnly,
      customer,
      isAssigneesLoading,
      assigneeType,
      assignees,
      onDeleteAssignee
    } = this.props;

    return (
      <React.Fragment>
        <Divider>
          {`${assigneeType == "organisation" ? "Исполнители" : "От заказчика"}`}{" "}
          {isReadOnly ? null : (
            <Tooltip placement="bottom" title={"Добавить ответственного"}>
              <Button
                type="dashed"
                shape="circle"
                icon="plus"
                onClick={this.onOpenAddingAssignee}
              />
            </Tooltip>
          )}
        </Divider>
        {isAddingAssignee ? (
          <Row gutter={10} type="flex" justify="center">
            <Col span={24}>
              <SelectFetch
                showSearch
                placeholder="Клиент"
                onChange={this.onSelectAssignee}
                selected={assignee ? assignee : null}
                url={`${
                  assigneeType == "organisation"
                    ? `/users/search/?`
                    : `/members/search/?customer_id=${
                        customer ? customer.id : null
                      }`
                }`}
              />
            </Col>
            <Col span={24} style={{ paddingTop: "10px" }}>
              <Row gutter={10} type="flex" justify="center">
                <Button
                  type="danger"
                  ghost
                  onClick={this.onCloseAddingAssignee}
                >
                  Отменить
                </Button>

                <Button
                  type="primary"
                  ghost
                  style={{ marginLeft: "10px" }}
                  onClick={this.onChangeAssignees}
                >
                  Добавить
                </Button>
              </Row>
            </Col>
          </Row>
        ) : null}
        <List
          loading={isAssigneesLoading}
          itemLayout="horizontal"
          dataSource={assignees}
          renderItem={item => (
            <List.Item
              actions={[
                <Button
                  disabled={isReadOnly}
                  type="dashed"
                  shape="circle"
                  icon="close"
                  onClick={onDeleteAssignee.bind(this, item.id)}
                />
              ]}
            >
              <List.Item.Meta
                avatar={
                  <Avatar
                    src={
                      assigneeType == "organisation"
                        ? item.user
                          ? item.user.avatar
                          : null
                        : item.member
                        ? item.member.avatar
                        : null
                    }
                  />
                }
                title={
                  assigneeType == "organisation"
                    ? item.user.name
                    : item.member.name
                }
                description={`${
                  assigneeType == "organisation"
                    ? item.user.title
                    : item.member.title
                }`}
              />
            </List.Item>
          )}
        />
      </React.Fragment>
    );
  }
}
Assignees.propTypes = {
  users: PropTypes.arrayOf(PropTypes.object)
};

export default Assignees;
