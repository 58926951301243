// @flow
import React, { Component } from "react";
import { Row, Col, Form, Input, Tabs, Button } from "antd";
import Notifications from "./Notifications";

const TabPane = Tabs.TabPane;
const FormItem = Form.Item;

class Header extends Component {
  render() {
    const {
      name,
      email,
      password,
      password_confirmation,
      getFieldDecorator,
      onChangeCurrentMemberName,
      onChangeEmail,
      onChangeCurrentMemberPassword,
      onChangePasswordConfirmation,
      onUpdateCurrentMemberPassword,
      // notifications
      notifyCommentSettings,
      onChangeNotifyTickets,
      onChangeNotifyEntityTasks,
      onChangeNotifyOrders,
      onChangeNotifyOrderTasks,
      onChangeNotifyProjects,
    } = this.props;

    return (
      <React.Fragment>
        <Row gutter={16}>
          <Tabs defaultActiveKey={"1"}>
            <TabPane tab="Основные данные" key="1">
              <Col span={12}>
                <FormItem label={"Имя"}>
                  {getFieldDecorator("first_name", {
                    onChange: onChangeCurrentMemberName,
                    initialValue: name,
                    rules: [
                      {
                        required: true,
                        message: "Введите имя!"
                      }
                    ]
                  })(<Input />)}
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label={"Email"}>
                  {getFieldDecorator("email", {
                    onChange: onChangeEmail,
                    initialValue: email,
                    rules: [
                      {
                        required: true,
                        message: "Введите email!"
                      }
                    ]
                  })(<Input />)}
                </FormItem>
              </Col>
            </TabPane>

            <TabPane tab="Смена пароля" key="2">
              <Row gutter={16}>
                <Col span={12}>
                  <FormItem label="Пароль">
                    {getFieldDecorator("password", {
                      onChange: onChangeCurrentMemberPassword,
                      initialValue: password
                    })(<Input />)}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="Подтверждение пароля">
                    {getFieldDecorator("password_confirmation", {
                      onChange: onChangePasswordConfirmation,
                      initialValue: password_confirmation
                    })(<Input />)}
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Button onClick={onUpdateCurrentMemberPassword} type="primary">
                    Сохранить пароль
                  </Button>
                </Col>
              </Row>
            </TabPane>
            <TabPane tab="Уведомления" key="3">
              <Notifications
                notifyCommentSettings={notifyCommentSettings}
                onChangeNotifyTickets={onChangeNotifyTickets}
                onChangeNotifyEntityTasks={onChangeNotifyEntityTasks}
                onChangeNotifyOrders={onChangeNotifyOrders}
                onChangeNotifyOrderTasks={onChangeNotifyOrderTasks}
                onChangeNotifyProjects={onChangeNotifyProjects}
              />
            </TabPane>
          </Tabs>
        </Row>
      </React.Fragment>
    );
  }
}

Header.propTypes = {};

export default Header;
