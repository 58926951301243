// @flow
import React, { Component, lazy, Suspense } from "react";
import { connect } from "react-redux";
import Actions from "../../../actions/reports";
import { Spin } from "antd";

const Data = lazy(() => import("./Data"));
const renderLoader = () => <Spin />;

class Report extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCountry: false,
      region: { id: 42, name: "Москва" },
      city: { id: 1, name: "Москва" },
      entityType: null,
      height: 0,
      center: { lat: 55.75761, lng: 37.61172 },
      zoom: 11,
    };
    this.updateHeight = this.updateHeight.bind(this);
  }

  componentWillMount = () => {
    this.props.dispatch(Actions.fetchEntities());
    this.updateHeight();
    window.addEventListener("resize", this.updateHeight);
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateHeight);
  }

  updateHeight() {
    this.setState({ height: window.innerHeight });
  }

  onSelectCountry = (e) => {
    const { region, city, entityType } = this.state;
    if (e.target.checked) {
      this.setState(
        {
          isCountry: true,
          region: null,
          city: null,
          center: {
            lat: 55.753215,
            lng: 37.622504,
          },
          zoom: 6,
        },
        () => {
          this.props.dispatch(Actions.fetchEntities(null, null));
        }
      );
    } else {
      this.setState(
        {
          isCountry: false,
          region: { id: 42, name: "Москва" },
          city: { id: 1, name: "Москва" },
          center: {
            lat: 55.753215,
            lng: 37.622504,
          },
          zoom: 11,
        },
        () => {
          this.props.dispatch(
            Actions.fetchEntities(
              region ? region.id : null,
              city ? city.id : null,
              entityType ? entityType.id : null
            )
          );
        }
      );
    }
  };

  onSelectRegion = (region) => {
    const { entityType } = this.state;
    this.setState(
      {
        region: region,
        city: null,
        center: {
          lat: parseFloat(region.extra.lat),
          lng: parseFloat(region.extra.lon),
        },
        zoom: 9,
      },
      () => {
        this.props.dispatch(
          Actions.fetchEntities(
            region ? region.id : null,
            null,
            entityType ? entityType.id : null
          )
        );
      }
    );
  };

  onSelectCity = (city) => {
    const { region, entityType } = this.state;
    this.setState(
      {
        city: city,
        center: {
          lat: parseFloat(city.extra.lat),
          lng: parseFloat(city.extra.lon),
        },
        zoom: 11,
      },
      () => {
        this.props.dispatch(
          Actions.fetchEntities(
            region ? region.id : null,
            city ? city.id : null,
            entityType ? entityType.id : null
          )
        );
      }
    );
  };

  onSelectEntityType = (entityType) => {
    console.log(entityType)
    const { region, city } = this.state;
    this.setState(
      {
        entityType: entityType,
      },
      () => {
        this.props.dispatch(
          Actions.fetchEntities(
            region ? region.id : null,
            city ? city.id : null,
            entityType ? entityType.id : null
          )
        );
      }
    );
  };

  onPointClick = (id) => {
    const { points } = this.props;
    let idx = points.findIndex(function (o) {
      return o.id == id;
    });
    if (idx > -1) {
      this.setState({
        center: {
          lat: parseFloat(points[idx].lat),
          lng: parseFloat(points[idx].lon),
        },
        // zoom: 16
      });
    }
  };

  render() {
    const {
      height,
      isCountry,
      region,
      city,
      entityType,
      center,
      zoom,
    } = this.state;
    const { isLoading, points } = this.props;
    return (
      <Suspense fallback={renderLoader()}>
        <Data
          height={height}
          isCountry={isCountry}
          region={region}
          city={city}
          entityType={entityType}
          center={center}
          zoom={zoom}
          isLoading={isLoading}
          points={points}
          onSelectCountry={this.onSelectCountry}
          onSelectRegion={this.onSelectRegion}
          onSelectCity={this.onSelectCity}
          onSelectEntityType={this.onSelectEntityType}
          onPointClick={this.onPointClick}
        />
      </Suspense>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.reports.isLoading,
  points: state.reports.points,
});

export default connect(mapStateToProps)(Report);
