// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Card, Col, Row, DatePicker, Select, Button, Icon, Form } from "antd";
import moment from "moment";
import "moment/locale/ru";
import SelectMultiFetch from "../../../components/SelectMultiFetch";
import SelectFetch from "../../../components/SelectFetch";

const { RangePicker } = DatePicker;
const { Option, OptGroup } = Select;
const FormItem = Form.Item;

class Filter extends Component {
  render() {
    const {
      visible,
      startDate,
      endDate,
      types,
      users,
      members,
      onChangePeriod,
      onChangeUsers,
      onChangeMembers,
      onChangeTypes,
    } = this.props;
    const { getFieldDecorator } = this.props.form;

    return visible ? (
      <Card size="small" title="Фильтры">
        <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
          <Col xs={24} sm={24} md={12} lg={8} xl={8}>
            <FormItem label={"Дата создания (комментария)"}>
              {getFieldDecorator("start", {
                onChange: onChangePeriod,
                initialValue: [
                  startDate ? moment(startDate, "DD.MM.YYYY") : null,
                  endDate ? moment(endDate, "DD.MM.YYYY") : null,
                ],
              })(
                <RangePicker
                  showTime={false}
                  format={"DD.MM.YYYY"}
                  placeholder={["начало", "окончание"]}
                  style={{ width: "100%" }}
                />
              )}
            </FormItem>
          </Col>

          <Col xs={24} sm={24} md={12} lg={8} xl={8}>
            <FormItem label={"Тип"}>
              {getFieldDecorator("types", {
                onChange: onChangeTypes,
                initialValue: types ? types : [],
              })(
                <Select
                  mode={"multiple"}
                  style={{ width: "100%" }}
                  placeholder="Тип"
                  optionFilterProp="children"
                  filterOption={false}
                >
                  <Option value={"tickets"}>заявки</Option>
                  <Option value={"entity_tasks"}>регламент</Option>
                  <Option value={"orders"}>заказы</Option>
                  <Option value={"order_tasks"}>задачи по заказам</Option>
                  <Option value={"projects"}>проекты</Option>
                  <Option value={"tasks"}>задачи</Option>
                </Select>
              )}
            </FormItem>
          </Col>

          <Col xs={24} sm={24} md={24} lg={8} xl={8}>
            <FormItem label={"Комментатор"}>
              {getFieldDecorator("users", {
                initialValue: users ? users : [],
              })(
                <SelectMultiFetch
                  showSearch
                  placeholder="Комментатор"
                  onChange={onChangeUsers}
                  selected={users ? users : []}
                  url={"/users/search/?all='true'"}
                />
              )}
            </FormItem>
          </Col>
          {/* <Col xs={24} sm={24} md={24} lg={8} xl={8}>
            <FormItem label={"Комментатор (заказчик)"}>
              {getFieldDecorator("members", {
                initialValue: members ? members : [],
              })(
                <SelectMultiFetch
                  showSearch
                  placeholder="Комментатор"
                  onChange={onChangeMembers}
                  selected={members ? members : []}
                  url={"/members/search/?"}
                />
              )}
            </FormItem>
          </Col> */}
        </Row>
      </Card>
    ) : null;
  }
}

Filter.propTypes = {
  entity: PropTypes.object,
};

export default Form.create()(Filter);
