// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { List, Badge } from "antd";
import moment from "moment";
import "moment/locale/ru";

import "moment/locale/ru";
import _ from "lodash/array";

function statuses(status) {
  switch (status) {
    case "pending":
      return "error";
    case "processing":
      return "processing";
    case "finished":
      return "success";
    default:
      return "default";
  }
}

class Assignees extends Component {
  render() {
    const { isLoading, tasks } = this.props;

    return (
      <React.Fragment>
        <List
          loading={isLoading}
          itemLayout="horizontal"
          dataSource={tasks}
          renderItem={(item) => (
            <List.Item
              actions={[
                <span style={{ fontSize: 12 }}>
                  {moment(item.due_by).format("LLL")}
                </span>,
              ]}
            >
              <List.Item.Meta
                avatar={<Badge status={statuses(item.status)} />}
                title={
                  <Link target={"_blank"} to={`/tasks/${item.id}/edit`}>
                    {item.subject}
                  </Link>
                }
                description={item.description}
              />
            </List.Item>
          )}
        />
      </React.Fragment>
    );
  }
}
Assignees.propTypes = {
  users: PropTypes.arrayOf(PropTypes.object),
};

export default Assignees;
