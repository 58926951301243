// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Row,
  Col,
  List,
  Card,
  Select,
  Form,
  Divider,
  Button,
  Avatar,
  Tooltip
} from "antd";

import Assignees from "../../../components/Assignees";

class Statuses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAddingAssignee: false,
      assignee: null
    };
  }

  render() {
    const {
      isNew,
      customer,
      organisationAssignees,
      supervisorAssignees,
      customerAssignees,
      isAssigneesLoading,
      onCreateAssignee,
      onDeleteAssignee
    } = this.props;

    return (
      <Card style={{ borderColor: "#1890ff" }}>
        <Row gutter={10}>
        <Col span={24}>
            <Assignees
              title={"Наблюдатели"}
              isReadOnly={true}
              isAssigneesLoading={isAssigneesLoading}
              assigneeType="supervisor"
              customer={customer}
              assignees={supervisorAssignees}
              onCreateAssignee={onCreateAssignee}
              onDeleteAssignee={onDeleteAssignee}
            />
          </Col>
          <Col span={24}>
            <Assignees
              isReadOnly={true}
              isAssigneesLoading={isAssigneesLoading}
              assigneeType="organisation"
              customer={customer}
              assignees={organisationAssignees}
              onCreateAssignee={onCreateAssignee}
              onDeleteAssignee={onDeleteAssignee}
            />
          </Col>
          <Col span={24}>
            <Assignees
              title={"От заказчика"}
              isReadOnly={false}
              isAssigneesLoading={isAssigneesLoading}
              assigneeType="customer"
              customer={customer}
              assignees={customerAssignees}
              onCreateAssignee={onCreateAssignee}
              onDeleteAssignee={onDeleteAssignee}
            />
          </Col>
        </Row>
      </Card>
    );
  }
}
Statuses.propTypes = {
  entity: PropTypes.object,
  getFieldDecorator: PropTypes.func
};

export default Statuses;
