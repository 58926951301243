// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Col, Row, Upload, Icon, Modal } from "antd";

const { confirm } = Modal;

class Gallery extends Component {
  state = {
    uid: null,
    previewVisible: false,
    previewImage: "",
    fileList: [],
    isUploading: false
  };

  onDelete = (file) => {
    const { onDeletePhoto } = this.props;
    confirm({
      title: "Файл",
      content: "Удалить файл?",
      onOk() {
        onDeletePhoto(file);
      },
      onCancel() {
        // console.log("Cancel");
      },
    });
  };

  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = file => {
    this.setState({
      uid: file.uid,
      previewImage: file.url || `${file.asset.standart.url}`,
      previewVisible: true
    });
  };

  onUpload = ({ onSuccess, onError, file }) => {
    this.props.uploadPhoto(file);
  };

  render() {
    const { images, onDeletePhoto } = this.props;
    const { previewVisible, previewImage } = this.state;
    const uploadButton = (
      <div>
        <Icon type="plus" />
        <div className="ant-upload-text">Загрузить</div>
      </div>
    );

    return (
      <Row gutter={16}>
        <Col span={24}>
          <div className="clearfix">
            <Upload
              listType="picture-card"
              fileList={images}
              onPreview={this.handlePreview}
              onRemove={this.onDelete}
              customRequest={this.onUpload}
            >
              {uploadButton}
            </Upload>
            <Modal
              width={960}
              visible={previewVisible}
              footer={null}
              onCancel={this.handleCancel}
            >
              <img alt="example" style={{ width: "100%" }} src={previewImage} />
            </Modal>
          </div>
        </Col>
      </Row>
    );
  }
}

Gallery.propTypes = {
  product: PropTypes.object,
  onClose: PropTypes.func,
  onChangeAssets: PropTypes.func,
  onDeletePhoto: PropTypes.func,
  uploadAsset: PropTypes.func,
  images: PropTypes.array
};

export default Gallery;
