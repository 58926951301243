// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import Actions from "../../../actions/faqs";
import List from "./List";

class Faqs extends Component {
  componentWillMount = () => {
    this.props.dispatch(Actions.fetchFaqs());
  };

  onChangePage = value => {
    this.props
      .dispatch(Actions.onChangePage(value))
      .then(() => this.props.dispatch(Actions.fetchFaqs()));
  };

  onChangeLimit = (current, size) => {
    this.props
      .dispatch(Actions.onChangeLimit(size))
      .then(() => this.props.dispatch(Actions.fetchFaqs()));
  };

  onSortBy = (field, direction) => {
    this.props
      .dispatch(Actions.onSortBy(field, direction))
      .then(() => this.props.dispatch(Actions.fetchFaqs()));
  };

  fetchFaq = (id) => {
    this.props.dispatch(Actions.fetchFaq(id));
  };


  render() {
    const { isLoading, isFaqLoading, faqs, faq, meta, fetchParams } = this.props;
    return (
      <List
        loading={isLoading}
        isFaqLoading={isFaqLoading}
        faqs={faqs}
        faq={faq}
        meta={meta}
        fetchParams={fetchParams}
        onSearch={this.onSearch}
        onChangePage={this.onChangePage}
        onChangeLimit={this.onChangeLimit}
        onSortBy={this.onSortBy}
        fetchFaq={this.fetchFaq}
      />
    );
  }
}

const mapStateToProps = state => ({
  isLoading: state.faqs.isLoading,
  isFaqLoading: state.faqs.isFaqLoading,
  faqs: state.faqs.faqs,
  faq: state.faqs.faq,
  meta: state.faqs.meta,
  fetchParams: state.faqs.fetchParams
});

export default connect(mapStateToProps)(Faqs);
