// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, List, Avatar, Divider, Tooltip, Button } from "antd";

import "moment/locale/ru";
import _ from "lodash/array";
import SelectFetch from "../SelectFetch";
import UserInfo from "../UserInfo";
import MemberInfo from "../MemberInfo";
import AdminInfo from "../AdminInfo";
import EmployeeInfo from "../EmployeeInfo";

class Assignees extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAddingAssignee: false,
      assignee: null,
    };
  }

  static defaultProps = {
    title: "Ответственные",
  };

  onOpenAddingAssignee = () => {
    this.setState({ isAddingAssignee: true });
  };

  onCloseAddingAssignee = () => {
    this.setState({ isAddingAssignee: false });
  };

  onSelectAssignee = (value) => {
    this.setState({ assignee: value });
  };

  onChangeAssignees = () => {
    const { assignee } = this.state;
    const { assigneeType, assignees } = this.props;
    let idx = -1;
    if (assigneeType == "organisation") {
      idx = _.findIndex(assignees, function (o) {
        return (
          o.user.id == assignee.id && assignee.assignee_type == "organisation"
        );
      });
    }

    if (assigneeType == "customer") {
      idx = _.findIndex(assignees, function (o) {
        return o.member.id == assignee.id;
      });
    }

    if (assigneeType == "company") {
      idx = _.findIndex(assignees, function (o) {
        return o.employee.id == assignee.id;
      });
    }

    if (assigneeType == "supervisor") {
      idx = _.findIndex(assignees, function (o) {
        return (
          o.user.id == assignee.id && assignee.assignee_type == "supervisor"
        );
      });
    }

    if (assignee && idx == -1) {
      this.props.onCreateAssignee(this.state.assignee, assigneeType);
      this.setState({ isAddingAssignee: false, assignee: null });
    }
  };

  render() {
    const { isAddingAssignee, assignee } = this.state;
    const {
      isReadOnly,
      title,
      isAssigneesLoading,
      assigneeType,
      customer,
      company,
      assignees,
      onDeleteAssignee,
    } = this.props;

    let url = '/users/search/?'

    if (assigneeType == "customer") {
      url = customer ? `/members/search/?customer_id=${customer.id}` : `/members/search/?`
    }

    if (assigneeType == "company") {
      url = company ? `/employees/search/?company_id=${company.id}` : `/employees/search/?`
    }

    return (
      <React.Fragment>
        <Divider>
          {title} {""}
          {isReadOnly ? null : (
            <Tooltip placement="bottom" title={"Добавить ответственного"}>
              <Button
                type="dashed"
                shape="circle"
                icon="plus"
                onClick={this.onOpenAddingAssignee}
              />
            </Tooltip>
          )}
        </Divider>
        {isAddingAssignee ? (
          <Row gutter={10} type="flex" justify="center">
            <Col span={24}>
              <SelectFetch
                showSearch
                placeholder="Клиент"
                onChange={this.onSelectAssignee}
                selected={assignee ? assignee : null}
                url={url}
              />
            </Col>
            <Col span={24} style={{ paddingTop: "10px" }}>
              <Row gutter={10} type="flex" justify="center">
                <Button
                  type="danger"
                  ghost
                  onClick={this.onCloseAddingAssignee}
                >
                  Отменить
                </Button>

                <Button
                  type="primary"
                  ghost
                  style={{ marginLeft: "10px" }}
                  onClick={this.onChangeAssignees}
                >
                  Добавить
                </Button>
              </Row>
            </Col>
          </Row>
        ) : null}
        <List
          loading={isAssigneesLoading}
          itemLayout="horizontal"
          dataSource={assignees}
          renderItem={(item) => {
            let title,
              description,
              avatar = null;

            if (item.admin) {
              title = (
                <AdminInfo title={item.admin.name} adminId={item.admin.id} />
              );
              description = item.admin.title;
              avatar = item.admin.avatar;
            }

            if (item.user) {
              title = <UserInfo title={item.user.name} userId={item.user.id} />;
              description = item.user.title;
              avatar = item.user.avatar;
            }

            if (item.member) {
              title = (
                <MemberInfo
                  title={item.member.name}
                  memberId={item.member.id}
                />
              );
              description = item.member.title;
              avatar = item.member.avatar;
            }

            if (item.employee) {
              title = (
                <EmployeeInfo
                  title={item.employee.name}
                  employeeId={item.employee.id}
                />
              );
              description = item.employee.title;
              avatar = item.employee.avatar;
            }

            return (
              <List.Item
                actions={
                  isReadOnly
                    ? null
                    : [
                        <Button
                          type="dashed"
                          shape="circle"
                          icon={'close'}
                          onClick={onDeleteAssignee.bind(this, item.id)}
                        />,
                      ]
                }
              >
                <List.Item.Meta
                  avatar={<Avatar src={avatar} />}
                  title={title}
                  description={description}
                />
              </List.Item>
            );
          }}
        />
      </React.Fragment>
    );
  }
}
Assignees.propTypes = {
  users: PropTypes.arrayOf(PropTypes.object),
};

export default Assignees;
