import api from "../api";

const Actions = {};

Actions.fetchRate = (url = null, uuid = null) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_RATE_REQUEST" });
    return api
      .fetch(`/${url}/${uuid}`)
      .then((response) => {
        dispatch({ type: "FETCH_RATE", response });
      })
      .catch((error) => {
        if (error.errors) {
          error.errors.map((error) => {
            return api.openNotification("error", "Настройки", error);
          });
        }
        dispatch({ type: "FETCH_RATE_FAILURE", error });
      });
  };
};

Actions.onSave = (url = null, uuid = null, rate = null, comment = null) => {
  return (dispatch) => {
    dispatch({ type: "PATCH_RATE_REQUEST" });
    const params = {
        ticket_rate: { rate: rate, comment: comment },
    };
    return api
      .patch(`/${url}/${uuid}`, params)
      .then((response) => {
        dispatch({ type: "PATCH_RATE", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Рейтинг", error);
          });
        }
        dispatch({ type: "PATCH_RATE_FAILURE", response });
      });
  };
};

export default Actions;
