// @flow
import React, { Component } from "react";
import { withRouter } from 'react-router'
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Actions from "../../actions/session";
import LoginForm from "../../components/LoginForm";


class Login extends Component {
  static contextTypes = {
    router: PropTypes.object
  };

  handleLogin = data => {
    const { history: { push } } = this.props;
    this.props.dispatch(Actions.login(data, push));
  };

  render() {
    return <LoginForm errors={this.props.errors} onSubmit={this.handleLogin} />;
  }
}

Login.propTypes = {
  dispatch: PropTypes.func
};

const mapStateToProps = state => ({
  errors: state.session.errors
});

export default connect(mapStateToProps)(withRouter(Login));
