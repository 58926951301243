// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Col, Row, Form, DatePicker, Select, InputNumber, Drawer } from "antd";
import moment from "moment";
import "moment/locale/ru";
import SelectMultiFetch from "../../../components/SelectMultiFetch";
import SelectFetch from "../../../components/SelectFetch";

const { RangePicker } = DatePicker;
const Option = Select.Option;
const FormItem = Form.Item;

class Filter extends Component {

  render() {
    const {
      visible,
      fetchParams,
      period,
      threshold,
      onClose,
      onChangeDateRange,
      onChangePeriod,
      onChangeRoles,
      onChangeThreshold,
      onChangeDepartments
    } = this.props;

    const dateFormat = "DD.MM.YYYY HH:mm";
    const { getFieldDecorator } = this.props.form;

    return (
      <Drawer
        width={540}
        visible={visible}
        title="Фильтры"
        placement={"right"}
        closable={false}
        onClose={onClose}
      >
        <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <FormItem label={"Период"}>
              <RangePicker
                style={{ width: "100%" }}
                value={[
                  moment(fetchParams.startDate, dateFormat),
                  moment(fetchParams.endDate, dateFormat),
                ]}
                format={dateFormat}
                onChange={onChangeDateRange}
                showTime={{
                  hideDisabledOptions: true,
                  defaultValue: [
                    moment("00:00:00", "HH:mm:ss"),
                    moment("23:59:59", "HH:mm:ss"),
                  ],
                }}
              />
            </FormItem>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <FormItem label={"Период"}>
              <Select
                style={{ width: "100%" }}
                value={period}
                placeholder="Период"
                optionFilterProp="children"
                filterOption={false}
                onChange={onChangePeriod}
              >
                <Option value={0}>произвольный период</Option>
                <Option value={1}>этот месяц</Option>
                <Option value={2}>этот квартал</Option>
                <Option value={3}>этот год</Option>
                <Option value={4}>прошлый месяц</Option>
                <Option value={5}>прошлый квартал</Option>
                <Option value={6}>прошлый год</Option>
              </Select>
            </FormItem>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <FormItem label={"Роль"}>
              <Select
                mode={"multiple"}
                style={{ width: "100%" }}
                value={fetchParams.roles ? fetchParams.roles : []}
                placeholder="Роль"
                filterOption={false}
                onChange={onChangeRoles}
              >
                <Option value={3}>инженер</Option>
                <Option value={4}>менеджер</Option>
                <Option value={2}>руководитель</Option>
                <Option value={1}>администратор</Option>
              </Select>
            </FormItem>
          </Col>

          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <FormItem label={"Порог"}>
              {getFieldDecorator("threshold", {
                onChange: onChangeThreshold,
                initialValue: threshold * 100,
              })(
                <InputNumber
                  style={{ width: "100%" }}
                  min={0}
                  max={100}
                  formatter={(value) => `${value}%`}
                  parser={(value) => value.replace("%", "")}
                />
              )}
            </FormItem>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <FormItem label={"Подразделение"}>
              {getFieldDecorator("departments", {
                initialValue: fetchParams.departments ? fetchParams.departments : [],
              })(
                <SelectMultiFetch
                  showSearch
                  placeholder="Подразделение"
                  onChange={onChangeDepartments}
                  selected={fetchParams.departments ? fetchParams.departments : []}
                  url={"/departments/search/?"}
                />
              )}
            </FormItem>
          </Col>
        </Row>
      </Drawer>
    );
  }
}

Filter.propTypes = {
  entity: PropTypes.object,
};

export default Form.create()(Filter);
