// @flow
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Actions from "../../actions/dashboard";
import Container from "./Container";
import moment from "moment";
import "moment/locale/ru";

class Dashboard extends Component {
  componentWillMount = () => {
    // this.props.dispatch(Actions.fetchCalendarEvents());
  };

  onDrawerToggle = () => {
    this.props.dispatch(Actions.onDrawerToggle()).then(() => {
      if (this.props.isDrawerVisible) {
        this.props.dispatch(Actions.fetchPosts());
      }
    });
  };

  onSelectEvent = (id, eventType) => {
    // console.log(id, eventType)
    this.props.dispatch(Actions.fetchEvent(id, eventType));
  };
  // calendar
  onChangeEventType = (e) => {
    this.props
      .dispatch(Actions.onChangeEventType(e.target.value))
      .then(() => this.props.dispatch(Actions.fetchCalendarEvents()));
  };

  onCalendarNavigate = (day, view) => {
    let startDate = moment(day).startOf("month").format("YYYY-MM-DD");
    let endDate = moment(day).endOf("month").format("YYYY-MM-DD");
    this.setState({ listView: false }, () => {
      this.props
        .dispatch(Actions.onChangeDate(startDate, endDate))
        .then(() => this.props.dispatch(Actions.fetchCalendarEvents()));
    });
  };

  // statistic
  fetchTasks = () => {
    this.props.dispatch(Actions.fetchTasks());
  };

  fetchStatistic = (params) => {
    this.props.dispatch(Actions.fetchStatistic(params)).then(() => {
      this.props.dispatch(Actions.fetchOrders(params));
      this.props.dispatch(Actions.fetchIntimeStatistic());
    });
  };

  onChangeTasksAssigneeType = (value) => {
    this.props.dispatch(Actions.onChangeTasksAssigneeType(value)).then(() => {
      this.props.dispatch(Actions.fetchTasks());
    });
  };

  onChangeStatisticDateRange = (dates) => {
    const startDate = moment(dates[0]).format("L");
    const endDate = moment(dates[1]).format("L");

    this.props
      .dispatch(Actions.onChangeStatisticDateRange(startDate, endDate))
      .then(() => {
        this.props.dispatch(Actions.fetchStatistic()).then(() => {
          this.props.dispatch(Actions.fetchOrders());
          this.props.dispatch(Actions.fetchIntimeStatistic());
        });
      });
  };

  render() {
    const {
      currentMember,
      isCalendarLoading,
      calendarEvents,
      isEventLoading,
      selectedEvent,
      fetchParams,
      // statistic
      fetchStatisticParams,
      isStatisticLoading,
      isTasksLoading,
      tasks,
      countOverdueTasks,
      tasksStatistic,
      taskSeries,
      fetchTasksParams,
      // projects
      projectSeries,
      projectsStatistic,
      // orders
      ordersStatistic,
      // tickets
      ticketSeries,
      // entity_tasks
      entityTaskSeries,
      // intime_statistic
      isIntimeStatisticLoading,
      intimeStatistic,
      // services
      services,
    } = this.props;
    return (
      <Fragment>
        <Container
          onDrawerToggle={this.onDrawerToggle}
          onCalendarNavigate={this.onCalendarNavigate}
          isCalendarLoading={isCalendarLoading}
          calendarEvents={calendarEvents}
          onChangeEventType={this.onChangeEventType}
          onSelectEvent={this.onSelectEvent}
          isEventLoading={isEventLoading}
          selectedEvent={selectedEvent}
          fetchParams={fetchParams}
          fetchParams={fetchParams}
          // statistic
          fetchStatisticParams={fetchStatisticParams}
          fetchTasks={this.fetchTasks}
          fetchStatistic={this.fetchStatistic}
          isStatisticLoading={isStatisticLoading}
          isTasksLoading={isTasksLoading}
          tasks={tasks}
          countOverdueTasks={countOverdueTasks}
          onChangeTasksAssigneeType={this.onChangeTasksAssigneeType}
          tasksStatistic={tasksStatistic}
          taskSeries={taskSeries}
          fetchTasksParams={fetchTasksParams}
          onChangeStatisticDateRange={this.onChangeStatisticDateRange}
          onChangeStatisticPeriod={this.onChangeStatisticPeriod}
          onChangeStatisticDepartments={this.onChangeStatisticDepartments}
          // projects
          projectSeries={projectSeries}
          projectsStatistic={projectsStatistic}
          // orders
          ordersStatistic={ordersStatistic}
          // tickets
          ticketSeries={ticketSeries}
          // entity_tasks
          entityTaskSeries={entityTaskSeries}
          // intime_statistic
          isIntimeStatisticLoading={isIntimeStatisticLoading}
          intimeStatistic={intimeStatistic}
          // services
          services={services}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  currentMember: state.session.currentMember,
  isDrawerVisible: state.dashboard.isDrawerVisible,
  isCalendarLoading: state.dashboard.isCalendarLoading,
  calendarEvents: state.dashboard.calendarEvents,
  isEventLoading: state.dashboard.isEventLoading,
  selectedEvent: state.dashboard.selectedEvent,
  fetchParams: state.dashboard.fetchParams,
  // statistic
  fetchStatisticParams: state.dashboard.fetchStatisticParams,
  isStatisticLoading: state.dashboard.isStatisticLoading,
  isTasksLoading: state.dashboard.isTasksLoading,
  tasks: state.dashboard.tasks,
  countOverdueTasks: state.dashboard.countOverdueTasks,
  tasksStatistic: state.dashboard.tasksStatistic,
  taskSeries: state.dashboard.taskSeries,
  fetchTasksParams: state.dashboard.fetchTasksParams,
  // projects
  projectSeries: state.dashboard.projectSeries,
  projectsStatistic: state.dashboard.projectsStatistic,
  // orders
  ordersStatistic: state.dashboard.ordersStatistic,
  // tickets
  ticketSeries: state.dashboard.ticketSeries,
  // entity_tasks
  entityTaskSeries: state.dashboard.entityTaskSeries,
  // intime_statistic
  isIntimeStatisticLoading: state.dashboard.isIntimeStatisticLoading,
  intimeStatistic: state.dashboard.intimeStatistic,
  // services
  services: state.settings.services,
});

export default connect(mapStateToProps)(Dashboard);
