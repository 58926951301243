// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import Actions from "../../../actions/posts";
import List from "./List";

class Posts extends Component {
  componentWillMount = () => {
    this.props.dispatch(Actions.fetchPosts());
  };

  onChangePage = value => {
    this.props
      .dispatch(Actions.onChangePage(value))
      .then(() => this.props.dispatch(Actions.fetchPosts()));
  };

  onChangeLimit = (current, size) => {
    this.props
      .dispatch(Actions.onChangeLimit(size))
      .then(() => this.props.dispatch(Actions.fetchPosts()));
  };

  onSortBy = (field, direction) => {
    this.props
      .dispatch(Actions.onSortBy(field, direction))
      .then(() => this.props.dispatch(Actions.fetchPosts()));
  };

  fetchPost = (id) => {
    this.props.dispatch(Actions.fetchPost(id));
  };

  render() {
    const { isLoading, posts, post, meta, fetchParams } = this.props;
    return (
      <List
        loading={isLoading}
        posts={posts}
        post={post}
        meta={meta}
        fetchParams={fetchParams}
        onSearch={this.onSearch}
        onChangePage={this.onChangePage}
        onChangeLimit={this.onChangeLimit}
        onSortBy={this.onSortBy}
        fetchPost={this.fetchPost}
      />
    );
  }
}

const mapStateToProps = state => ({
  isLoading: state.posts.isLoading,
  posts: state.posts.posts,
  post: state.posts.post,
  meta: state.posts.meta,
  fetchParams: state.posts.fetchParams
});

export default connect(mapStateToProps)(Posts);
