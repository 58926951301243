import React from "react";
import { Row, Col, Popover, Table, Typography } from "antd";
import { Link } from "react-router-dom";
import moment from "moment";
import "moment/locale/ru";
import accounting from "accounting";

const { Paragraph } = Typography;

export default function TaskDetails({
  loading = false,
  value = null,
  data = [],
  path = null,
  onChange = null,
}) {
  return (
    <Popover
      placement="right"
      content={<Details loading={loading} data={data} path={path} />}
      title={`Расшифровка (${data.length})`}
      trigger="click"
      onVisibleChange={onChange}
    >
      <span style={{ cursor: "pointer" }}>{value}</span>
    </Popover>
  );
}

function Details({ loading = false, data = null, path = null  }) {
  const columns = [
    {
      title: "#",
      dataIndex: "number",
      render: (number, record) => (
        <Link to={`/${path}/${record.id}/edit`} target="_blank">
          {record.id}
        </Link>
      ),

      key: "number",
      align: "center",
      width: "15%",
    },
    {
      title: "Дата создания",
      dataIndex: "created_at",
      render: (created_at) => moment(created_at).format("LLL"),
      key: "created_at",
      align: "center",
      width: "20%",
    },
    {
      title: "Выполнить до",
      dataIndex: "plan",
      render: (plan) => plan ? moment(plan).format("LLL") : null,
      key: "plan",
      align: "center",
      width: "20%",
    },
    {
      title: "Тема",
      dataIndex: "subject",
      render: (subject, record) => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span>
          {subject}
          </span>
        </div>
      ),
      key: "subject",
      align: "center",
      width: "50%",
    },
  ];

  return (
    <div style={{ width: 640 }}>
      <Table
        rowClassName={"progress-row"}
        size={"small"}
        loading={loading}
        // showHeader={false}
        columns={columns}
        dataSource={data}
        pagination={false}
        rowKey="id"
        scroll={{ y: 340 }}
      />
    </div>
  );
}
