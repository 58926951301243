// @flow
import React, { Component } from "react";
import {
  Popover,
  Table,
  Button,
  Tooltip,
  Card,
  Avatar,
  Row,
  Col,
  Divider,
  Rate,
} from "antd";
import api from "../../api";

const memberStyle = { color: "#000000a6", cursor: "pointer" };

export default class Details extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      fetching: true,
    };
  }

  fetchEmployeeInfo = async (id) => {
    // console.log(id)
    const response = await api.fetch(`/employees/${id}/info`);
    const data = response.data;
    this.setState({ data, fetching: false });
  };

  render() {
    const { fetching, data } = this.state;
    const { title, employeeId } = this.props;
    return (
      <Popover
        placement="right"
        content={<AssigneeCard loading={fetching} data={data} />}
        // title="Инфо"
        trigger="click"
      >
        <a style={memberStyle} onClick={this.fetchEmployeeInfo.bind(this, employeeId)}>{title}</a>
      </Popover>
    );
  }
}

function AssigneeCard({ loading = true, data = null }) {
  return loading ? null : (
    <Card
      size="small"
      loading={loading}
      bordered={false}
      style={{ width: 300 }}
    >
      <Row gutter={10} type="flex" justify="center">
        <Avatar size={74} src={data.avatar ? data.avatar : null} />
      </Row>
      <Row gutter={16} type="flex" justify="center">
        <span style={{ fontSize: 16 }}>{data.name}</span>
      </Row>
      <Row gutter={16} type="flex" justify="center">
        <span style={{ fontSize: 16 }}>{data.customer}</span>
      </Row>
      <Divider />
      <Row gutter={10} type="flex" justify="center">
        <Col span={24} style={{ textAlign: "center" }}>
          {data.title}
        </Col>
        <Col span={24} style={{ textAlign: "center" }}>
          <a href={"mailto:" + data.email}>{data.email}</a>
        </Col>
        <Col span={24} style={{ textAlign: "center" }}>
          {data.phone}
        </Col>
      </Row>
    </Card>
  );
}
