const rules = {
  manager: {
    static: [
      "dashboard:view",
    ],
    dynamic: {
      "documents:delete": ({ memberId, ownerId }) => {
        if (!memberId || !ownerId) return false;
        return memberId === ownerId;
      },
      "tasks:description": ({ memberId, ownerId }) => {
        if (!memberId || !ownerId) return false;
        return memberId === ownerId;
      }
    }
  }
};

export default rules;