import React, { PureComponent, Fragment } from 'react';
import ReactDOM from 'react-dom';
import { Icon, Tabs, Badge, Spin } from 'antd';
import classNames from 'classnames';
import HeaderDropdown from '../HeaderDropdown';
import NoticeList from './NoticeList';
import './index.less';

const { TabPane } = Tabs;

export default class NoticeIcon extends PureComponent {
  static Tab = TabPane;

  static defaultProps = {
    onItemClick: () => {},
    onPopupVisibleChange: () => {},
    onTabChange: () => {},
    onViewMore: () => {},
    onCreate: () => {},
    loading: false,
    clearClose: false,
    locale: {
      emptyText: 'Нет уведомлений',
      clear: 'Открыть',
      viewMore: 'Открыть',
      create: 'Создать',
    },
    emptyImage: null,
  };

  state = {
    visible: false,
  };

  onItemClick = (item, tabProps) => {
    const { onItemClick } = tabProps;
    if (onItemClick) {
      // console.log(item)
      onItemClick(item);
    }
  };

  onViewMore = (tabProps) => {
    const { onViewMore } = tabProps;
    if (onViewMore) {
      onViewMore();
    }
  };

  onTabChange = tabType => {
    const { onTabChange } = this.props;
    onTabChange(tabType);
  };

  onCreate = (tabProps, event) => {
    const { onCreate } = tabProps;
    // console.log(tabProps)
    onCreate(tabProps, event);
  };

  getNotificationBox() {
    const { children, loading, locale } = this.props;
    if (!children) {
      return null;
    }
    const panes = React.Children.map(children, child => {
      const { list, title, count, emptyText, emptyImage, showClear, showOnCreate, showOnViewMore } = child.props;
      const len = list && list.length ? list.length : 0;
      const msgCount = count || count === 0 ? count : len;
      const localeTitle = locale[title] || title;
      const tabTitle = msgCount > 0 ? `${localeTitle} (${msgCount})` : localeTitle;
      return (
        <TabPane tab={tabTitle} key={title}>
          <NoticeList
            data={list}
            emptyImage={emptyImage}
            emptyText={emptyText}
            locale={locale}
            onViewMore={() => this.onViewMore(child.props)}
            onClick={item => this.onItemClick(item, child.props)}
            onCreate={event => this.onCreate(child.props, event)}
            showClear={showClear}
            showOnViewMore={showOnViewMore}
            showOnCreate={showOnCreate}
            title={title}
          />
        </TabPane>
      );
    });
    return (
      <Fragment>
        <Spin spinning={loading} delay={0}>
          <Tabs className={"tabs"} onChange={this.onTabChange}>
            {panes}
          </Tabs>
        </Spin>
      </Fragment>
    );
  }

  handleVisibleChange = visible => {
    const { onPopupVisibleChange } = this.props;
    this.setState({ visible });
    onPopupVisibleChange(visible);
  };

  render() {
    const { className, count, popupVisible, bell } = this.props;
    const { visible } = this.state;
    const noticeButtonClass = classNames(className, "action noticeButton");
    const notificationBox = this.getNotificationBox();
    const NoticeBellIcon = bell || <Icon type="bell" className={"icon"} />;
    const trigger = (
      <span className={classNames(noticeButtonClass, { opened: visible })}>
        <Badge count={count} style={{ boxShadow: 'none' }} className={"badge"}>
          {NoticeBellIcon}
        </Badge>
      </span>
    );
    if (!notificationBox) {
      return trigger;
    }
    const popoverProps = {};
    if ('popupVisible' in this.props) {
      popoverProps.visible = popupVisible;
    }
    return (
      <HeaderDropdown
        placement="bottomRight"
        overlay={notificationBox}
        overlayClassName={"popover"}
        trigger={['click']}
        visible={visible}
        onVisibleChange={this.handleVisibleChange}
        {...popoverProps}
        ref={node => (this.popover = ReactDOM.findDOMNode(node))} // eslint-disable-line
      >
        {trigger}
      </HeaderDropdown>
    );
  }
}