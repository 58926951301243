// @flow
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Row, Col, PageHeader, List, Tag } from "antd";

import PropTypes from "prop-types";
import ModalForm from "../../Faqs/Modal";
import moment from "moment";
import "moment/locale/ru";

function faqTag(tags) {
  return tags.map((tag) => {
    return (
      <Tag key={tag.id} color="#108ee9">
        {tag.name}
      </Tag>
    );
  });
}

class FaqsList extends Component {
  state = { visible: false };

  onOpenFaq = (id) => {
    this.setState(
      {
        visible: true,
      },
      () => {
        this.props.fetchFaq(id);
      }
    );
  };

  onOk = (e) => {
    this.setState({
      visible: false,
    });
  };

  onCancel = (e) => {
    this.setState({
      visible: false,
    });
  };

  render() {
    const {
      loading,
      isFaqLoading,
      faq,
      faqs,
      meta,
      fetchParams,
      onSearch,
      onChangePage,
      onChangeLimit,
      onSortBy,
    } = this.props;

    return (
      <PageHeader
        onBack={() => window.history.back()}
        title={`Помощь`}
        extra={[]}
        footer={
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col
              span={24}
              style={{
                paddingTop: "15px",
                paddingBottom: "15px",
              }}
            >
              <List
                itemLayout="vertical"
                size="large"
                pagination={{
                  defaultPageSize: fetchParams.limit,
                  defaultCurrent: meta.current_page,
                  onChange: onChangePage,
                  onShowSizeChange: onChangeLimit,
                  total: meta.total_count,
                }}
                dataSource={faqs}
                footer={null}
                renderItem={(item) => (
                  <List.Item
                    key={item.id}
                    actions={[
                      <span style={{ textAlign: "right" }}>
                        {faqTag(item.tags)}
                      </span>,
                    ]}
                    extra={[moment(item.created_at).format("L")]}
                  >
                    <List.Item.Meta
                      title={
                        <a onClick={this.onOpenFaq.bind(this, item.id)}>
                          {item.title}
                        </a>
                      }
                    />
                    {item.published_at
                      ? `Опубликовано: ${moment(item.published_at).format(
                          "LLL"
                        )} ${item.admin ? item.admin.name : null}`
                      : null}
                  </List.Item>
                )}
              />
            </Col>
            <ModalForm
              faq={faq}
              loading={isFaqLoading}
              visible={this.state.visible}
              onOk={this.onOk}
              onCancel={this.onCancel}
            />
          </Row>
        }
      />
    );
  }
}

FaqsList.propTypes = {
  orders: PropTypes.arrayOf(PropTypes.object),
  meta: PropTypes.object,
  fetchParams: PropTypes.object,
};

export default FaqsList;
