// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import update from "immutability-helper";
import Actions from "../../../actions/reports";
import DataList from "./DataList";

import moment from "moment";
import "moment/locale/ru";

class Report extends Component {
  constructor(props) {
    super(props);
    this.state = {
      period: 0,
    };
    this.updateHeight = this.updateHeight.bind(this);
  }

  componentWillMount = () => {
    this.props.dispatch(Actions.fetchOrganisation());
    this.updateHeight();
    window.addEventListener("resize", this.updateHeight);
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateHeight);
  }

  updateHeight() {
    this.setState({ height: window.innerHeight });
  }

  onDetails = (department_id, type) => {
    this.props.dispatch(Actions.fetchOrganisationDetails(department_id));
  };

  render() {
    const { height } = this.state;
    const { isLoading, organisation, employees, isLoadingDetails, details } = this.props;
    return (
      <DataList
        height={height}
        loading={isLoading}
        organisation={organisation}
        employees={employees}
        isLoadingDetails={isLoadingDetails}
        details={details}
        onDetails={this.onDetails}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.reports.isLoading,
  organisation: state.reports.organisation,
  employees: state.reports.employees,
  isLoadingDetails: state.reports.isLoadingDetails,
  details: state.reports.details,
});

export default connect(mapStateToProps)(Report);
