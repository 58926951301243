// @flow
import React, { Component, Fragment } from "react";
import { Row, Col, Button, Card, Drawer } from "antd";
import Posts from "./Posts";

class Sidebar extends Component {
  render() {
    const {
      isDrawerVisible,
      onDrawerToggle,
      isPostsLoading,
      fetchPost,
      posts,
      post
    } = this.props;

    return (
      <Drawer
        title="Новости"
        width={520}
        closable={false}
        onClose={onDrawerToggle}
        visible={isDrawerVisible}
      >
        <Posts
          isPostsLoading={isPostsLoading}
          posts={posts}
          fetchPost={fetchPost}
          post={post}
        />
      </Drawer>
    );
  }
}

export default Sidebar;
