// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import update from "immutability-helper";
import Actions from "../../../actions/tickets";
import FaqActions from "../../../actions/faqs";
import TicketsList from "./TicketsList";

class Tickets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
      rate: {},
    };
  }

  componentWillMount = () => {
    this.props.dispatch(Actions.onSetFilters()).then(() => {
      this.props.dispatch(Actions.fetchTickets());
    });
  };

  onSearch = (e) => {
    this.props
      .dispatch(Actions.onSearch(e.target.value))
      .then(() => this.props.dispatch(Actions.fetchTickets()));
  };

  onChangePage = (value) => {
    this.props
      .dispatch(Actions.onChangePage(value))
      .then(() => this.props.dispatch(Actions.fetchTickets()))
      .then(() => {
        localStorage.setItem(
          "ticketsFilters",
          JSON.stringify(this.props.fetchParams)
        );
      });
  };

  onChangeLimit = (current, size) => {
    this.props
      .dispatch(Actions.onChangeLimit(size))
      .then(() => this.props.dispatch(Actions.fetchTickets()))
      .then(() => {
        localStorage.setItem(
          "ticketsFilters",
          JSON.stringify(this.props.fetchParams)
        );
      });
  };

  onSortBy = (field, direction) => {
    this.props
      .dispatch(Actions.onSortBy(field, direction))
      .then(() => this.props.dispatch(Actions.fetchTickets()))
      .then(() => {
        localStorage.setItem(
          "ticketsFilters",
          JSON.stringify(this.props.fetchParams)
        );
      });
  };

  // filters
  onClearFilters = () => {
    this.props.dispatch(Actions.onClearFilters()).then(() => {
      localStorage.setItem(
        "ticketsFilters",
        JSON.stringify(this.props.fetchParams)
      );
    });
  };

  onChangeFilterStatus = (status) => {
    this.props
      .dispatch(Actions.onChangeFilterStatus(status))
      .then(() => this.props.dispatch(Actions.fetchTickets()))
      .then(() => {
        localStorage.setItem(
          "ticketsFilters",
          JSON.stringify(this.props.fetchParams)
        );
      });
  };

  onChangeFilterReplaced = (value) => {
    this.props
      .dispatch(Actions.onChangeFilterReplaced(value))
      .then(() => this.props.dispatch(Actions.fetchTickets()))
      .then(() => {
        localStorage.setItem(
          "ticketsFilters",
          JSON.stringify(this.props.fetchParams)
        );
      });
  };

  onChangeFilterOrdered = (value) => {
    this.props
      .dispatch(Actions.onChangeFilterOrdered(value))
      .then(() => this.props.dispatch(Actions.fetchTickets()))
      .then(() => {
        localStorage.setItem(
          "ticketsFilters",
          JSON.stringify(this.props.fetchParams)
        );
      });
  };

  onChangeFilterCreatedPeriod = (value, dateString) => {
    this.props
      .dispatch(
        Actions.onChangeFilterCreatedPeriod(dateString[0], dateString[1])
      )
      .then(() => this.props.dispatch(Actions.fetchTickets()))
      .then(() => {
        localStorage.setItem(
          "ticketsFilters",
          JSON.stringify(this.props.fetchParams)
        );
      });
  };

  onChangeFilterPeriod = (value, dateString) => {
    this.props
      .dispatch(Actions.onChangeFilterPeriod(dateString[0], dateString[1]))
      .then(() => this.props.dispatch(Actions.fetchTickets()))
      .then(() => {
        localStorage.setItem(
          "ticketsFilters",
          JSON.stringify(this.props.fetchParams)
        );
      });
  };

  onChangeFilterUsers = (values) => {
    this.props
      .dispatch(Actions.onChangeFilterUsers(values))
      .then(() => this.props.dispatch(Actions.fetchTickets()))
      .then(() => {
        localStorage.setItem(
          "ticketsFilters",
          JSON.stringify(this.props.fetchParams)
        );
      });
  };

  onChangeFilterMembers = (values) => {
    this.props
      .dispatch(Actions.onChangeFilterMembers(values))
      .then(() => this.props.dispatch(Actions.fetchTickets()))
      .then(() => {
        localStorage.setItem(
          "ticketsFilters",
          JSON.stringify(this.props.fetchParams)
        );
      });
  };

  onChangeFilterPriorities = (values) => {
    this.props
      .dispatch(Actions.onChangeFilterPriorities(values))
      .then(() => this.props.dispatch(Actions.fetchTickets()))
      .then(() => {
        localStorage.setItem(
          "ticketsFilters",
          JSON.stringify(this.props.fetchParams)
        );
      });
  };

  onChangeFilterRated = (value) => {
    this.props
      .dispatch(Actions.onChangeFilterRated(value))
      .then(() => this.props.dispatch(Actions.fetchTickets()))
      .then(() => {
        localStorage.setItem(
          "ticketsFilters",
          JSON.stringify(this.props.fetchParams)
        );
      });
  };

  // notices
  onClearNotices = () => {
    this.props.dispatch(Actions.onClearNotices());
  };

  // faqs
  onFaqToggle = () => {
    this.props.dispatch(FaqActions.onDrawerToggle()).then(() => {
      this.props.dispatch(FaqActions.fetchFaqs(2, 1));
    });
  };

  downloadReport = () => {
    let params = this.props.fetchParams;
    params.limit = 1000;
    Actions.downloadReport(
      params,
      "tickets.xlsx",
      params.startDate
        ? `tickets ${params.startDate}-${params.endDate}.xlsx`
        : "tickets.xlsx"
    );
  };

  // rate
  onOpenRateForm = (id) => {
    this.setState({ rate: { ticket_id: id, rate: 0, comment: null } }, () => {
      this.setState({ isVisible: true });
    });
  };

  onSaveRate = () => {
    const { rate } = this.state;
    this.props
      .dispatch(
        Actions.onCreateTicketRate(rate)
      )
      .then(() => {
        if (!this.props.errors) {
          this.setState(
            { rate: { ticket_id: null, rate: 0, comment: null } },
            () => {
              this.setState({ isVisible: false });
            }
          );
        }
      });
  };

  onCancelRate = () => {
    this.setState({ rate: { ticket_id: null, rate: 0, comment: null } }, () => {
      this.setState({ isVisible: false });
    });
  };

  onChangeRate = (value) => {
    this.setState({
      rate: update(this.state.rate, {
        rate: { $set: value },
      }),
    });
  };

  onChangeComment = (e) => {
    this.setState({
      rate: update(this.state.rate, {
        comment: { $set: e.target.value },
      }),
    });
  };

  render() {
    const { isVisible, rate } = this.state;
    const { isLoading, tickets, meta, fetchParams, notices } = this.props;
    return (
      <TicketsList
        loading={isLoading}
        tickets={tickets}
        meta={meta}
        fetchParams={fetchParams}
        notices={notices}
        onSearch={this.onSearch}
        onChangePage={this.onChangePage}
        onChangeLimit={this.onChangeLimit}
        onSortBy={this.onSortBy}
        // filters
        onClearFilters={this.onClearFilters}
        onChangeFilterStatus={this.onChangeFilterStatus}
        onChangeFilterReplaced={this.onChangeFilterReplaced}
        onChangeFilterOrdered={this.onChangeFilterOrdered}
        onChangeFilterCreatedPeriod={this.onChangeFilterCreatedPeriod}
        onChangeFilterPeriod={this.onChangeFilterPeriod}
        onChangeFilterUsers={this.onChangeFilterUsers}
        onChangeFilterMembers={this.onChangeFilterMembers}
        onChangeFilterPriorities={this.onChangeFilterPriorities}
        onChangeFilterRated={this.onChangeFilterRated}
        downloadReport={this.downloadReport}
        // notices
        onClearNotices={this.onClearNotices}
        // faqs
        onFaqToggle={this.onFaqToggle}
        // rate
        isVisible={isVisible}
        rate={rate}
        onOpenRateForm={this.onOpenRateForm}
        onSaveRate={this.onSaveRate}
        onCancelRate={this.onCancelRate}
        onChangeRate={this.onChangeRate}
        onChangeComment={this.onChangeComment}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.tickets.isLoading,
  tickets: state.tickets.tickets,
  meta: state.tickets.meta,
  fetchParams: state.tickets.fetchParams,
  notices: state.tickets.notices,
});

export default connect(mapStateToProps)(Tickets);
