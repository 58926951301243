import api from "../api";

const Actions = {};

function setCurrentMember(dispatch, response) {
  
    dispatch({
      type: "AUTHENTICATION_SUCCESS",
      response: response
    });
    localStorage.setItem("token", response.token);
    return Promise.resolve();
}

Actions.login = (data, push) => {
  return dispatch => {
    api
      .post("/members/auth_member", data)
      .then(response => {
        setCurrentMember(dispatch, response).then(() => { push("/"); });
      })
      .catch(response => {
        api.openNotification("error", "Логин", response.errors[0]);
        dispatch({ type: "AUTHENTICATION_FAILURE", response });
      });
  };
};

Actions.logout = router => {
  return dispatch => {
    return api.logout("/members/logout").then(() => {
      localStorage.removeItem("token");
      dispatch({ type: "LOGOUT" });
      window.location = "/auth/login";
    });
  };
};

Actions.authenticate = () => {
  return dispatch => {
    dispatch({ type: "AUTHENTICATION_REQUEST" });
    return api
      .post("/members/auth_member_refresh")
      .then(response => {
        setCurrentMember(dispatch, response);
      })
      .catch(response => {
        dispatch({ type: "AUTHENTICATION_FAILURE", response });
        localStorage.removeItem("token");
        window.location = "/auth/login";
      });
  };
};

Actions.unauthenticate = () => {
  return dispatch => {
    dispatch({ type: "AUTHENTICATION_FAILURE" });
  };
};

Actions.recovery = (data, push) => {
  return (dispatch) => {
    dispatch({ type: "RECOVERY_REQUEST" });
    const params = {
      member: {
        email: data.email
      },
    };

    return api
      .post("/members/recovery", params)
      .then((response) => {
        dispatch({ type: "RECOVERY_SUCCESS" });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map(error => {
            api.openNotification("error", "Восстановление", error);
          });
        }
        dispatch({ type: "RECOVERY_FAILURE", response });
      });
  };
};

Actions.reset = (data, token, push) => {
  return (dispatch) => {
    const params = {
      member: {
        password: data.password,
        password_confirmation: data.password_confirmation,
        reset_password_token: token,
      },
    };

    return api
      .patch("/members/reset", params)
      .then((response) => {
        api.openNotification("success", "Пароль", "Успешно изменен!");
        window.location = "/auth/login";
        // setCurrentUser(dispatch, response).then(() => { push("/"); });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map(error => {
            api.openNotification("error", "Пароль", error);
          });
        }
        dispatch({ type: "AUTHENTICATION_FAILURE", response });
      });
  };
};

export default Actions;
