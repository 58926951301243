// @flow
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Row, Col, PageHeader, List, Modal } from "antd";

import PropTypes from "prop-types";

import moment from "moment";
import "moment/locale/ru";

class PostsList extends Component {
  state = { visible: false };

  onOpen = (id) => {
    this.setState(
      {
        visible: true,
      },
      () => {
        this.props.fetchPost(id);
      }
    );
  };

  onOk = (e) => {
    this.setState({
      visible: false,
    });
  };

  onCancel = (e) => {
    this.setState({
      visible: false,
    });
  };

  render() {
    const {
      loading,
      isPostLoading,
      posts,
      post,
      meta,
      fetchParams,
      onSearch,
      onChangePage,
      onChangeLimit,
      onSortBy,
    } = this.props;

    return (
      <PageHeader
        onBack={() => window.history.back()}
        title={`Новости`}
        extra={[]}
        footer={
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col
              span={24}
              style={{
                paddingTop: "15px",
                paddingBottom: "15px",
              }}
            >
              <List
                loading={loading}
                itemLayout="vertical"
                size="large"
                pagination={{
                  defaultPageSize: fetchParams.limit,
                  defaultCurrent: meta.current_page,
                  onChange: onChangePage,
                  onShowSizeChange: onChangeLimit,
                  total: meta.total_count,
                }}
                dataSource={posts}
                footer={null}
                renderItem={(item) => (
                  <List.Item key={item.id} actions={[]} extra={[]}>
                    <List.Item.Meta
                      title={
                        <a onClick={this.onOpen.bind(this, item.id)}>
                          {item.title}
                        </a>
                      }
                    />
                    {item.published_at
                      ? `Опубликовано: ${moment(item.published_at).format(
                          "LLL"
                        )}`
                      : null}
                  </List.Item>
                )}
              />
            </Col>
            <Modal
              title={`#${post.id} от ${moment(post.published_at).format(
                "LLL"
              )}`}
              visible={this.state.visible}
              onOk={this.onOk}
              onCancel={this.onCancel}
            >
              <p>{post.title}</p>
              <div dangerouslySetInnerHTML={{ __html: post.body }}></div>
            </Modal>
          </Row>
        }
      />
    );
  }
}

PostsList.propTypes = {
  orders: PropTypes.arrayOf(PropTypes.object),
  meta: PropTypes.object,
  fetchParams: PropTypes.object,
};

export default PostsList;
