// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Descriptions } from "antd";

class General extends Component {
  constructor(props, context) {
    super(props, context);
  }
  render() {
    const {
      supportMessage
    } = this.props;

    return (
      <React.Fragment>
        <Descriptions bordered>
          <Descriptions.Item label="Тема" span={3}>
            {supportMessage ? supportMessage.subject : null}
          </Descriptions.Item>
          <Descriptions.Item label="Описание" span={3}>
            {supportMessage ? supportMessage.description : null}
          </Descriptions.Item>
        </Descriptions>
      </React.Fragment>
    );
  }
}

General.propTypes = {
  entity: PropTypes.object
};

export default General;
