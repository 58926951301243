// @flow
import React, { Component, Fragment } from "react";
import { Row, Col, Card, Empty } from "antd";





class Gantt extends Component {
  render() {
    const { currentUser } = this.props;
    return (
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Card>
            <Empty description={<span>В разработке</span>}></Empty>
          </Card>
        </Col>
      </Row>
    );
  }
}

export default Gantt;
