import React from "react";
import { withRouter } from "react-router-dom";
import { Layout, Menu, Icon } from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import update from "immutability-helper";
import Actions from "../../actions/session";
import NoticesActions from "../../actions/notices";
import FaqActions from "../../actions/faqs";
import DashboardActions from "../../actions/dashboard";
import SupportMessageActions from "../../actions/supportMessages";
import MainHeader from "./MainHeader";
import Sidebar from "./Sidebar";
import News from "./News";
import SupportMessage from "./SupportMessage";
import Task from "../Tasks/FormModal";
import HelpMenu from "./HelpMenu";
const { Sider, Content } = Layout;
const SubMenu = Menu.SubMenu;

const routes = [
  {
    path: "/",
    exact: true,
    key: 1,
    name: "Рабочий стол",
    icon: "dashboard",
    subroutes: [],
  },
  {
    path: "/tickets",
    key: 2,
    name: "Заявки",
    icon: "fire",
    subroutes: [],
  },
  {
    path: "/entity_tasks",
    key: 5,
    name: "Регламент",
    icon: "calendar",
    subroutes: [],
  },
  {
    path: null,
    key: 6,
    name: "Заказы",
    icon: "tags",
    subroutes: [
      {
        path: "/orders",
        key: 7,
        name: "Заказы",
        icon: "block",
      },
      {
        path: "/order_tasks",
        key: 8,
        name: "Задачи по заказам",
        icon: "bars",
      },
    ],
  },
  {
    path: "/projects",
    key: 90,
    name: "Проекты",
    icon: "project",
    subroutes: [],
  },
  {
    isActive: true,
    path: "/tasks",
    key: 100,
    name: "Задачи",
    icon: "check-square",
    subroutes: [],
  },
  {
    path: null,
    key: 10,
    name: "Справочники",
    icon: "setting",
    subroutes: [
      {
        path: "/entities",
        key: 12,
        name: "Объекты",
        icon: "appstore",
        subroutes: [],
      },
      {
        path: "/customer",
        key: 13,
        name: "Пользователи",
        icon: "block",
      },
      {
        path: "/employees",
        key: 67,
        name: "Структура организации",
        icon: "stock",
        subroutes: [],
      },
    ],
  },
  {
    path: null,
    key: 100,
    name: "Отчеты",
    icon: "bar-chart",
    subroutes: [
      {
        path: "/reports/plan",
        key: 101,
        name: "Выполнение плана",
        icon: "stock",
        subroutes: [],
      },
      {
        path: "/reports/daily",
        key: 102,
        name: "Ежедневный отчет",
        icon: "stock",
        subroutes: [],
      },
      {
        path: "/reports/employee_rates",
        key: 103,
        name: "Рейтинг исполнителей",
        icon: "stock",
        subroutes: [],
      },
    ],
  },
];

class MainLayout extends React.Component {
  constructor() {
    super();
    this.state = {
      isSidebarCollapsed: true,
      menuCollapsed: true,
      isHelpMenuVisible: false,
      isSupportMessageVisible: false,
      isSupportMessageUploading: false,
      message: {}
    };
  }

  componentWillMount = () => {
    this.props.dispatch(NoticesActions.fetchDashboardNotices());
  };

  componentDidMount() {
    // const isSidebarCollapsed = localStorage.getItem("isSidebarCollapsed");
    // if (isSidebarCollapsed) {
    //   this.setState({
    //     isSidebarCollapsed: isSidebarCollapsed.value
    //   });
    // }
  }

  toggle = () => {
    this.setState(
      {
        isSidebarCollapsed: !this.state.isSidebarCollapsed,
      },
      () => {
        localStorage.setItem(
          "isSidebarCollapsed",
          this.state.isSidebarCollapsed
        );
      }
    );
  };

  onLogout = () => {
    this.props.dispatch(Actions.logout());
  };

  onSaveFeedback = (message) => {
    this.props.dispatch(SupportMessageActions.onCreate(message));
  };

  fetchPost = (id) => {
    this.props.dispatch(DashboardActions.fetchPost(id));
  };

  onDrawerToggle = () => {
    this.props.dispatch(DashboardActions.onDrawerToggle());
  };

   // faqs
   fetchFaq = (id) => {
    this.props.dispatch(FaqActions.fetchFaq(id));
  };

  onFaqToggle = () => {
    this.props.dispatch(FaqActions.onDrawerToggle());
  };

  // helpmenu
  onShowHelpMenu = () => {
    this.setState({
      isHelpMenuVisible: true,
    });
  };

  onOkHelpMenu = (e) => {
    this.setState({
      isHelpMenuVisible: false,
    });
  };

  onCancelHelpMenu = (e) => {
    this.setState({
      isHelpMenuVisible: false,
    });
  };

  onOpenHelp = (e) => {
    this.setState({
      isHelpMenuVisible: false,
    },
    () => {
      this.props.history.push(`/faqs`);
    });
  };

  onOpenSupport = (e) => {
    this.setState({
      isHelpMenuVisible: false,
    },
    () => {
      this.props.history.push(`/support_messages`);
    });
  };

  onOpenNews = (e) => {
    this.setState({
      isHelpMenuVisible: false,
    },
    () => {
      this.props.history.push(`/news`);
    });
  };

  onOpenSupportMessage = (e) => {
    this.setState({
      isHelpMenuVisible: false,
    }, () => {
      this.onOpenFeedback()
    });
  };

  // support_message
  onOpenFeedback = () => {
    // console.log("onOpenFeedback")
    this.setState(
      {
        message: {
          support_message_type: null,
          url: window.location,
          attachments: []
        }
      },
      () => {
        this.setState({ isSupportMessageVisible: true });
      }
    );
  };

  onCancelFeedback = () => {
    this.setState({ isSupportMessageVisible: false });
  };

  onSaveFeedback = () => {
    this.setState({ isSupportMessageVisible: false }, () => {
      this.props.dispatch(SupportMessageActions.onCreate(this.state.message));
    });
  };

  onChangeFeedbackType = value => {
    this.setState({
      message: update(this.state.message, {
        support_message_type: { $set: value }
      })
    });
  };

  onChangeSubject = e => {
    this.setState({
      message: update(this.state.message, {
        subject: { $set: e.target.value }
      })
    });
  };

  onChangeDescription = e => {
    this.setState({
      message: update(this.state.message, {
        description: { $set: e.target.value }
      })
    });
  };

  onUploadAttachment = imageUrl => {
    // console.log(imageUrl)
    this.setState({
      message: update(this.state.message, {
        attachments: { $push: [imageUrl.file] }
      })
    });
  };

  onDeleteAttachment = idx => {
    this.setState({
      message: update(this.state.message, {
        attachments: { $splice: [[idx, 1]] }
      })
    });
  };

  render() {
    const {isHelpMenuVisible, isSupportMessageVisible, isSupportMessageUploading, message} = this.state;
    const {
      supportMessages,
      // posts
      isDrawerVisible,
      isPostsLoading,
      post,
      posts,
      // services
      isLoadingServices,
      services,
      // faqs
      isFaqVisible,
      isFaqsLoading,
      isFaqLoading,
      faq,
      faqs,
    } = this.props;

    return (
      <Layout>
        <Sider
          style={{
            overflow: "auto",
            height: "100vh",
            position: "fixed",
            left: 0,
          }}
          trigger={null}
          collapsible
          collapsed={this.state.isSidebarCollapsed}
        >
          <div className="logo" />
          <Menu
            theme="dark"
            collapsed={this.state.isSidebarCollapsed.toString()}
            mode="inline"
            defaultSelectedKeys={["1"]}
          >
            {routes.map((route, index) => {
              if (route.subroutes.length > 0) {
                return (
                  <SubMenu
                    key={route.key}
                    title={
                      <span>
                        <Icon type={route.icon} />
                        <span>{route.name}</span>
                      </span>
                    }
                  >
                    {route.subroutes.map((subroute, subindex) => {
                      return (
                        <Menu.Item key={subroute.key}>
                          <Link to={subroute.path} className="nav-text">
                            <Icon type={subroute.icon} />
                            <span>{subroute.name}</span>
                          </Link>
                        </Menu.Item>
                      );
                    })}
                  </SubMenu>
                );
              } else {
                return (
                  <Menu.Item key={route.key}>
                    <Link to={route.path} className="nav-text">
                      <Icon type={route.icon} />
                      <span>{route.name}</span>
                    </Link>
                  </Menu.Item>
                );
              }
            })}
          </Menu>
        </Sider>
        <div
          className="ant-layout-sider-help"
          style={this.state.isSidebarCollapsed ? { width: 80 } : { width: 200 }}
        >
          <i
            aria-label="icon: question-circle"
            className="anticon anticon-question-circle"
            onClick={this.onShowHelpMenu}
          >
            <svg
              viewBox="64 64 896 896"
              focusable="false"
              className=""
              data-icon="question-circle"
              width="1em"
              height="1em"
              fill="currentColor"
              aria-hidden="true"
            >
              <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path>
              <path d="M623.6 316.7C593.6 290.4 554 276 512 276s-81.6 14.5-111.6 40.7C369.2 344 352 380.7 352 420v7.6c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V420c0-44.1 43.1-80 96-80s96 35.9 96 80c0 31.1-22 59.6-56.1 72.7-21.2 8.1-39.2 22.3-52.1 40.9-13.1 19-19.9 41.8-19.9 64.9V620c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8v-22.7a48.3 48.3 0 0 1 30.9-44.8c59-22.7 97.1-74.7 97.1-132.5.1-39.3-17.1-76-48.3-103.3zM472 732a40 40 0 1 0 80 0 40 40 0 1 0-80 0z"></path>
            </svg>
          </i>
        </div>
        <Layout
          style={{
            minHeight: "100vh",
            overflowX: "hidden",
            marginLeft: this.state.isSidebarCollapsed ? 80 : 200,
          }}
        >
          <MainHeader
            isSidebarCollapsed={this.state.isSidebarCollapsed}
            currentMember={this.props.currentMember}
            onToggleSideBar={this.toggle}
            onLogout={this.onLogout}
            supportMessages={supportMessages}
            supportMessagesErrors={this.props.supportMessagesErrors}
            onSaveFeedback={this.onSaveFeedback}
          />
          <Content
            style={{
              margin: "80px 16px 16px 16px",
              // padding: 24,
              // background: "#fff",
              minHeight: 280,
            }}
          >
            {this.props.children}
          </Content>
          <News
            isDrawerVisible={isDrawerVisible}
            onDrawerToggle={this.onDrawerToggle}
            isPostsLoading={isPostsLoading}
            fetchPost={this.fetchPost}
            post={post}
            posts={posts}
          />
          <Sidebar
            isDrawerVisible={isFaqVisible}
            onDrawerToggle={this.onFaqToggle}
            isFaqsLoading={isFaqsLoading}
            isFaqLoading={isFaqLoading}
            fetchFaq={this.fetchFaq}
            faq={faq}
            faqs={faqs}
          />
          <HelpMenu
            visible={isHelpMenuVisible}
            onSubmit={this.onOkHelpMenu}
            onCancel={this.onCancelHelpMenu}
            onOpenHelp={this.onOpenHelp}
            onOpenSupport={this.onOpenSupport}
            onOpenNews={this.onOpenNews}
            onOpenSupportMessage={this.onOpenSupportMessage}
          />
          <SupportMessage
            visible={isSupportMessageVisible}
            message={message}
            onSave={this.onSaveFeedback}
            onCancel={this.onCancelFeedback}
            onChangeFeedbackType={this.onChangeFeedbackType}
            onChangeSubject={this.onChangeSubject}
            onChangeDescription={this.onChangeDescription}
            // attachments
            isUploading={isSupportMessageUploading}
            onUploadAttachment={this.onUploadAttachment}
            onDeleteAttachment={this.onDeleteAttachment}
          />
        </Layout>
        <Task />
      </Layout>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.session.isLoading,
  currentMember: state.session.currentMember,
  errors: state.session.errors,
  supportMessages: state.notices.supportMessages,
  supportMessagesErrors: state.supportMessages.errors,
  isDrawerVisible: state.dashboard.isDrawerVisible,
  isPostsLoading: state.dashboard.isPostsLoading,
  post: state.dashboard.post,
  posts: state.dashboard.posts,
  // faqs
  isFaqVisible: state.faqs.isDrawerVisible,
  isFaqsLoading: state.faqs.isFaqsLoading,
  isFaqLoading: state.faqs.isFaqLoading,
  faq: state.faqs.faq,
  faqs: state.faqs.faqs,
});

export default connect(mapStateToProps)(withRouter(MainLayout));
