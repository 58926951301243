// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, Button, Form, Card, Select, Divider } from "antd";
import moment from "moment";
import "moment/locale/ru";
import General from "./General";
import Info from "./Info";
import Statuses from "./Statuses";
import Comments from "./Comments";

class SupportMessageForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeKey: "1",
      assignee: null
    };
  }

  onSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }
      this.props.onSave(values);
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      isNew,
      isLoading,
      errors,
      supportMessage,
      onClose,
      // general
      onChangeSubject,
      onChangeDescription,
      // comments
      isCommentsLoading,
      onCreateComment,
      onChangeStatus,
      onChangeSupportMessageType,
      // assignees
      isAssigneesLoading,
      onCreateAssignee,
      onDeleteAssignee
    } = this.props;

    const organisationAssignees = supportMessage.assignees.filter(function(item) {
      return item.assignee_type == "organisation";
    });

    return (
      <Form layout={"vertical"} onSubmit={this.onSubmit}>
        <Card
          loading={isLoading}
          title={
            <h2 style={{ marginBottom: 0 }}>{`Сообщение в службу поддержки #${
              supportMessage.id
            } от ${moment(supportMessage.created_at).format("LLL")}`}</h2>
          }
          extra={
            <div>
              <Button onClick={onClose}>Закрыть</Button>
              <Button
                onClick={this.onSubmit}
                type="primary"
                ghost
                style={{ marginLeft: "8px" }}
              >
                Сохранить
              </Button>
            </div>
          }
        >
          <Col xs={24} sm={24} md={24} lg={14} xl={14}>
            <Row>
              <General
                isNew={isNew}
                getFieldDecorator={getFieldDecorator}
                supportMessage={supportMessage}
                onChangeSubject={onChangeSubject}
                onChangeDescription={onChangeDescription}
              />
            </Row>
            <Row>
              <Info
                info={supportMessage.info}
              />
            </Row>
            <Divider>Комментарии</Divider>
            <Row>
              <Comments
                getFieldDecorator={getFieldDecorator}
                isCommentsLoading={isCommentsLoading}
                errors={errors}
                onCreateComment={onCreateComment}
                comments={supportMessage.comments}
              />
            </Row>
          </Col>
          <Col xs={0} sm={0} md={1} lg={1} xl={1} />
          <Col xs={24} sm={24} md={24} lg={9} xl={9}>
            <Row>
              <Statuses
                getFieldDecorator={getFieldDecorator}
                user={supportMessage.user}
                member={supportMessage.member}
                attachments={supportMessage.attachments}
                // statuses
                status={supportMessage.status}
                supportMessageType={supportMessage.support_message_type}
                onChangeStatus={onChangeStatus}
                onChangeSupportMessageType={onChangeSupportMessageType}
                // assignees
                organisationAssignees={organisationAssignees}
                isAssigneesLoading={isAssigneesLoading}
                onDeleteAssignee={onDeleteAssignee}
                onCreateAssignee={onCreateAssignee}
              />
            </Row>
          </Col>
        </Card>
      </Form>
    );
  }
}

SupportMessageForm.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  supportMessage: PropTypes.object,
  onSave: PropTypes.func,
  isLoading: PropTypes.bool,
  router: PropTypes.object,
  getFieldDecorator: PropTypes.func,
  form: PropTypes.object
};

export default Form.create()(SupportMessageForm);
