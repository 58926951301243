// @flow
import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Row, Col, Table, Button } from "antd";
import update from "immutability-helper";

import moment from "moment";
import "moment/locale/ru";

class Tasks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [],
      isVisible: false,
      task: { assignees: [] }
    };
  }

  // assignees
  onAddAssignee = value => {
    this.setState({
      task: update(this.state.task, {
        assignees: { $push: [value] }
      })
    });
  };

  onDeleteAssignee = idx => {
    this.setState({
      task: update(this.state.task, {
        assignees: { $splice: [[idx, 1]] }
      })
    });
  };

  render() {
    const { orderTasks, isTasksLoading } = this.props;
    const { selectedRowKeys, isVisible, task } = this.state;

    const columns = [
      {
        title: "Номер",
        dataIndex: "number",
        render: (number, record) => (
          <Link to={`/order_tasks/${record.id}/edit`}>{number}</Link>
        ),
        width: "20%",
        align: "center"
      },
      {
        title: "Тип",
        dataIndex: "task_type_locale",
        width: "35%",
        align: "center"
      },
      {
        title: "Статус",
        dataIndex: "status",
        render: (status, record) => (status ? status.name : null),
        width: "20%",
        align: "center"
      },
      {
        title: "Выполнить до",
        dataIndex: "due_by",
        render: due_by => moment(due_by).format("LLL"),
        width: "25%",
        align: "center"
      }
    ];

    return (
      <div>
        <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
          <Col span={24}>
            <Table
              loading={isTasksLoading}
              size={"middle"}
              bordered
              pagination={false}
              columns={columns}
              dataSource={orderTasks}
              rowKey="id"
            />
          </Col>
        </Row>
      </div>
    );
  }
}

Tasks.propTypes = {
  orderTasks: PropTypes.arrayOf(PropTypes.object),
  total: PropTypes.number,
  discount: PropTypes.object,
  shippingCost: PropTypes.number,
  amountDiscountCode: PropTypes.number,
  onRemoveVariant: PropTypes.func
};
export default Tasks;
