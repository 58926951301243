// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, Card, Descriptions } from "antd";
import User from "./User";
import Documents from "./Documents";

class Statuses extends Component {
  render() {
    const {
      user,
      member,
      attachments,
      status,
      supportMessageType
    } = this.props;

    return (
      <Card style={{ borderColor: "#1890ff" }}>
        <Row gutter={10}>
          <Col span={24}>
            <Descriptions bordered>
              <Descriptions.Item label="Статус" span={3}>
                {status ? status : null}
              </Descriptions.Item>
              <Descriptions.Item label="Тип" span={3}>
                {supportMessageType ? supportMessageType.name : null}
              </Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col span={24}>
            <User user={user ? user : member} />
          </Col>
        </Row>
        <Row gutter={10}>
          <Documents attachments={attachments} />
        </Row>
      </Card>
    );
  }
}
Statuses.propTypes = {
  entity: PropTypes.object,
  getFieldDecorator: PropTypes.func
};

export default Statuses;
