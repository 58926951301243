import api from "../api";
const Actions = {};

//posts
Actions.fetchPosts = () => {
  return (dispatch, getState) => {
    dispatch({ type: "FETCH_POSTS_REQUEST" });
    api
      .fetch("/dashboard/posts")
      .then(response => {
        dispatch({ type: "FETCH_POSTS", response });
      })
      .catch(response => {
        console.log(response.error);
        dispatch({ type: "FETCH_POSTS_FAILURE", response });
      });
  };
};

Actions.fetchPost = (id) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_POST_REQUEST" });
    api
      .fetch(`/posts/${id}`)
      .then(response => {
        dispatch({ type: "FETCH_POST", response });
      })
      .catch(response => {
        console.log(response.error);
        dispatch({ type: "FETCH_POST_FAILURE", response });
      });
  };
};

// calendar
Actions.fetchCalendarEvents = () => {
  return (dispatch, getState) => {
    dispatch({ type: "FETCH_CALENDAR_EVENTS_REQUEST" });
    const params = getState().dashboard.fetchParams;
    api
      .fetch("/dashboard/calendar_events", params)
      .then(response => {
        dispatch({ type: "FETCH_CALENDAR_EVENTS", response });
      })
      .catch(response => {
        console.log(response.error);
        dispatch({ type: "FETCH_CALENDAR_EVENTS_FAILURE", response });
      });
  };
};

Actions.fetchEvent = (id, eventType) => {
  return (dispatch, getState) => {
    dispatch({ type: "FETCH_EVENT_REQUEST" });
    api
      .fetch(`/${eventType}/${id}/event`)
      .then(response => {
        dispatch({ type: "FETCH_EVENT", response });
      })
      .catch(response => {
        console.log(response.error);
        dispatch({ type: "FETCH_EVENT_FAILURE", response });
      });
  };
};

Actions.onChangeDate = (startDate, endDate) => {
  return dispatch => {
    dispatch({
      type: "DASHBOARD_CHANGE_DATE",
      startDate: startDate,
      endDate: endDate
    });
    return Promise.resolve();
  };
};

Actions.onChangeEventType = (value) => {
  return dispatch => {
    dispatch({
      type: "DASHBOARD_CHANGE_EVENT_TYPE",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onDrawerToggle = () => {
  return dispatch => {
    dispatch({ type: "DRAWER_TOGGLE" });
    return Promise.resolve();
  };
  
};

// statistic
Actions.fetchTasks = () => {
  return (dispatch, getState) => {
    dispatch({ type: "FETCH_DASHBOARD_TASKS_REQUEST" });
    const params = getState().dashboard.fetchTasksParams;
    return api
      .fetch("/dashboard/tasks", params)
      .then((response) => {
        dispatch({ type: "FETCH_DASHBOARD_TASKS", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_DASHBOARD_TASKS_FAILURE", response });
      });
  };
};

Actions.fetchStatistic = () => {
  return (dispatch, getState) => {
    dispatch({ type: "FETCH_DASHBOARD_STATISTIC_REQUEST" });
    let departments = [];
    const params = getState().dashboard.fetchStatisticParams;
    if (params.departments) {
      departments = params.departments.map((department) => {
        if (department && department.id) {
          return department.id;
        }
      });
    }

    const data = {
      startDate: params.startDate,
      endDate: params.endDate,
      departments: departments,
    };

    return api
      .fetch("/dashboard/statistic", data)
      .then((response) => {
        dispatch({ type: "FETCH_DASHBOARD_STATISTIC", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_DASHBOARD_STATISTIC_FAILURE", response });
      });
  };
};

Actions.fetchOrders = () => {
  return (dispatch, getState) => {
    dispatch({ type: "FETCH_DASHBOARD_ORDERS_STATISTIC_REQUEST" });
    let departments = [];
    const params = getState().dashboard.fetchStatisticParams;
    if (params.departments) {
      departments = params.departments.map((department) => {
        if (department && department.id) {
          return department.id;
        }
      });
    }

    const data = {
      startDate: params.startDate,
      endDate: params.endDate,
      departments: departments,
    };

    return api
      .fetch("/dashboard/orders_statistic", data)
      .then((response) => {
        dispatch({ type: "FETCH_DASHBOARD_ORDERS_STATISTIC", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_DASHBOARD_ORDERS_STATISTIC_FAILURE", response });
      });
  };
};

Actions.fetchIntimeStatistic = () => {
  return (dispatch, getState) => {
    dispatch({ type: "FETCH_DASHBOARD_INTIME_STATISTIC_REQUEST" });
    let departments = [];
    const params = getState().dashboard.fetchStatisticParams;
    if (params.departments) {
      departments = params.departments.map((department) => {
        if (department && department.id) {
          return department.id;
        }
      });
    }

    const data = {
      startDate: params.startDate,
      endDate: params.endDate,
      departments: departments,
    };

    return api
      .fetch("/dashboard/intime_statistic", data)
      .then((response) => {
        dispatch({ type: "FETCH_DASHBOARD_INTIME_STATISTIC", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_DASHBOARD_INTIME_STATISTIC_FAILURE", response });
      });
  };
};

Actions.onChangeStatisticDateRange = (startDate, endDate) => {
  return (dispatch) => {
    dispatch({
      type: "DASHBOARD_CHANGE_STATISTIC_DATE_RANGE",
      startDate: startDate,
      endDate: endDate,
    });
    return Promise.resolve();
  };
};

Actions.onChangeStatisticDepartments = (values) => {
  return (dispatch) => {
    dispatch({
      type: "DASHBOARD_CHANGE_STATISTIC_DEPARTMENTS",
      values: values,
    });
    return Promise.resolve();
  };
};

Actions.onChangeTasksAssigneeType = (value) => {
  return (dispatch) => {
    dispatch({
      type: "DASHBOARD_CHANGE_TASKS_ASSIGNEE_TYPE",
      value: value,
    });
    return Promise.resolve();
  };
};

export default Actions;
