// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import update from "immutability-helper";
import Actions from "../../../actions/reports";
import DataList from "./DataList";

import moment from "moment";
import "moment/locale/ru";

class Report extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fetchParams: {
        startDate: moment().startOf("month").format("DD.MM.YYYY HH:mm"),
        endDate: moment().endOf("day").format("DD.MM.YYYY HH:mm"),
        roles: null,
        departments: null,
        page: 1,
        limit: 100,
      },
      period: 0,
      height: 0,
      threshold: 0.5,
    };
    this.updateHeight = this.updateHeight.bind(this);
  }

  componentWillMount = () => {
    // console.log("Test")
    this.props.dispatch(Actions.fetchPlan(this.state.fetchParams));
    this.updateHeight();
    window.addEventListener("resize", this.updateHeight);
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateHeight);
  }

  updateHeight() {
    this.setState({ height: window.innerHeight });
  }

  onChangeDateRange = (dates) => {
    this.state.fetchParams.startDate = moment(dates[0]).format(
      "DD.MM.YYYY HH:mm"
    );
    this.state.fetchParams.endDate = moment(dates[1]).format(
      "DD.MM.YYYY HH:mm"
    );
    this.setState(
      { fetchParams: this.state.fetchParams, period: 0 },
      function () {
        this.props.dispatch(Actions.fetchPlan(this.state.fetchParams));
      }
    );
  };

  onChangePeriod = (value) => {
    let startDate = null;
    let endDate = null;
    switch (value) {
      case 0:
        startDate = moment()
          .add(-1, "months")
          .add(1, "days")
          .format("DD.MM.YYYY HH:mm");
        endDate = moment().endOf("day").format("DD.MM.YYYY HH:mm");
        break;
      case 1:
        startDate = moment().startOf("months").format("DD.MM.YYYY HH:mm");
        endDate = moment().endOf("day").format("DD.MM.YYYY HH:mm");
        break;
      case 2:
        startDate = moment().startOf("quarter").format("DD.MM.YYYY HH:mm");
        endDate = moment().endOf("day").format("DD.MM.YYYY HH:mm");
        break;
      case 3:
        startDate = moment().startOf("years").format("DD.MM.YYYY HH:mm");
        endDate = moment().endOf("day").format("DD.MM.YYYY HH:mm");
        break;
      case 4:
        startDate = moment()
          .add(-1, "months")
          .startOf("month")
          .format("DD.MM.YYYY HH:mm");
        endDate = moment()
          .add(-1, "months")
          .endOf("month")
          .format("DD.MM.YYYY HH:mm");
        break;
      case 5:
        startDate = moment()
          .add(-1, "quarters")
          .startOf("quarter")
          .format("DD.MM.YYYY HH:mm");
        endDate = moment()
          .add(-1, "quarters")
          .endOf("quarter")
          .format("DD.MM.YYYY HH:mm");
        break;
      case 6:
        startDate = moment()
          .add(-1, "years")
          .startOf("year")
          .format("DD.MM.YYYY HH:mm");
        endDate = moment()
          .add(-1, "years")
          .endOf("year")
          .format("DD.MM.YYYY HH:mm");
        break;
      default:
        startDate = moment()
          .add(-1, "months")
          .add(1, "days")
          .format("DD.MM.YYYY HH:mm");
        endDate = moment().format("DD.MM.YYYY HH:mm");
    }
    this.state.fetchParams.startDate = startDate;
    this.state.fetchParams.endDate = endDate;
    this.setState(
      { fetchParams: this.state.fetchParams, period: value },
      function () {
        this.props.dispatch(Actions.fetchPlan(this.state.fetchParams));
      }
    );
  };

  onChangeRoles = (values) => {
    // console.log(values)
    this.setState(
      {
        fetchParams: update(this.state.fetchParams, {
          roles: { $set: values },
        }),
      },
      () => {
        this.props.dispatch(Actions.fetchPlan(this.state.fetchParams));
      }
    );
  };

  onChangeThreshold = (value) => {
    this.setState({ threshold: value / 100 });
  };

  onChangeDepartments = (values) => {
    const departments = values.map((value) => {
      return value.id;
    });

    this.setState(
      {
        fetchParams: update(this.state.fetchParams, {
          departments: { $set: departments.length > 0 ? departments : null },
        }),
      },
      () => {
        this.props.dispatch(Actions.fetchPlan(this.state.fetchParams));
      }
    );
  };
  

  render() {
    const { isLoading, tickets, meta, totals } = this.props;
    const { fetchParams, period, height, threshold } = this.state;
    return (
      <DataList
        height={height}
        threshold={threshold}
        loading={isLoading}
        tickets={tickets}
        meta={meta}
        totals={totals}
        fetchParams={fetchParams}
        period={period}
        onChangeDateRange={this.onChangeDateRange}
        onChangePeriod={this.onChangePeriod}
        onChangeRoles={this.onChangeRoles}
        onChangeThreshold={this.onChangeThreshold}
        onChangeDepartments={this.onChangeDepartments}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.reports.isLoading,
  tickets: state.reports.tickets,
  meta: state.reports.meta,
  totals: state.reports.totals,
});

export default connect(mapStateToProps)(Report);
