// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import update from "immutability-helper";
import {
  Row,
  Col,
  Card,
  List,
  Button,
  Upload,
  Popover,
  Tooltip,
 
} from "antd";

import Attachment from "./Attachment";

class DocumentsList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
      attachment: {},
    };
  }

  onAddAttachment = () => {
    this.setState(
      {
        attachment: {
          name: null,
          storage_type: 2,
          description: null,
        },
      },
      () => {
        this.setState({ isVisible: true });
      }
    );
  };

  onSave = () => {
    this.setState({ isVisible: false }, () => {
      this.props.onSave(this.state.attachment);
    });
  };

  onCancel = () => {
    this.setState({ isVisible: false });
  };

  onChangeName = (e) => {
    this.setState({
      attachment: update(this.state.attachment, {
        name: { $set: e.target.value },
      }),
    });
  };

  onChangeDescription = (e) => {
    this.setState({
      attachment: update(this.state.attachment, {
        description: { $set: e.target.value },
      }),
    });
  };

  render() {
    const { isVisible, attachment } = this.state;
    const { isLoading, documents } = this.props;

    return (
      <React.Fragment>
        <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
          <Col span={24}>
            <List
              loading={isLoading}
              dataSource={documents}
              renderItem={(item) => (
                <List.Item
                  actions={[
                    <Tooltip placement="bottom" title={"Удалить"}>
                      <Popover
                        content={
                          <Button
                            type="danger"
                            style={{ width: "100%" }}
                            onClick={this.props.onDelete.bind(this, item.id)}
                          >
                            Удалить
                          </Button>
                        }
                        title="Удаление файла"
                        trigger="click"
                      >
                        <Button type="dashed" shape="circle" icon="close" />
                      </Popover>
                    </Tooltip>,
                  ]}
                >
                  <List.Item.Meta
                    
                    title={
                      <a href={item.url} download={item.url} target="_blank">
                        {item.description}
                      </a>
                    }
                    
                  />
                </List.Item>
              )}
            ></List>
          </Col>
        </Row>
        <Row gutter={{ md: 8, lg: 24, xl: 48 }} style={{textAlign: "center"}}>
        <Button onClick={this.onAddAttachment}>Создать</Button>
        </Row>
        <Attachment
            visible={isVisible}
            attachment={attachment}
            onSave={this.onSave}
            onCancel={this.onCancel}
            onChangeName={this.onChangeName}
            onChangeDescription={this.onChangeDescription}
          />
      </React.Fragment>
    );
  }
}

DocumentsList.propTypes = {
  documents: PropTypes.arrayOf(PropTypes.object),
};
export default DocumentsList;
