// @flow
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { Table, Button, PageHeader } from "antd";
import { Pagination } from "antd";
import { Row, Col, Input, Tooltip, Dropdown, Menu, Typography } from "antd";

import PropTypes from "prop-types";
import AssigneesCell from "../../../components/AssigneesCell";
import canCheck from "../../../rules/canCheck";
import NoticeIcon from "../../../components/NoticeIcon";

import moment from "moment";
import "moment/locale/ru";
import Filter from "./Filter";

const Search = Input.Search;
const { Paragraph } = Typography;

class TasksList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [],
      isFilterVisible: false,
    };
  }

  onFilterToggle = () => {
    this.setState({ isFilterVisible: !this.state.isFilterVisible });
  };


  onChangeTable = (pagination, filters, sorter) => {
    let direction = "desc";
    if (sorter.order === "descend") {
      direction = "desc";
    } else if (sorter.order === "ascend") {
      direction = "asc";
    }
    this.props.onSortBy(sorter.field, direction);
  };

  onClearNotices = () => {
    this.props.onClearNotices();
  };

  onItemClick = (item) => {
    this.props.history.push(`/tasks/${item.parent_id}/edit`);
  };

  // actions
  onAction = (actionId) => {
    const { actions, onOpenTaskForm } = this.props;
    let idx = actions.findIndex(function (o) {
      return o.id == actionId;
    });
    if (idx === -1) {
      return null;
    }
    onOpenTaskForm(actions[idx]);
  };

  ActionMenu = (actions) => {
    return (
      <Menu>
        {actions.map((action) => {
          return (
            <Menu.Item
              key={`action-menu-${action.id}`}
              onClick={this.onAction.bind(this, action.id)}
            >
              {action.name}
            </Menu.Item>
          );
        })}
      </Menu>
    );
  };

  render() {
    const { isFilterVisible } = this.state;
    const {
      currentUser,
      loading,
      tasks,
      meta,
      actions,
      fetchParams,
      onSearch,
      onChangePage,
      onChangeLimit,
      onSortBy,
      // timing
      onStartTiming,
      onPauseTiming,
      // filters
      onClearFilters,
      onChangeFilterStatus,
      onChangeFilterTemplates,
      onChangeFilterTaskUsers,
      onChangeFilterUsers,
      onChangeFilterSupervisorUsers,
      onChangeFilterPeriod,
      onChangeFilterFinishPeriod,
      downloadReport,
      // notices
      unread,
      notices,
    } = this.props;

    
    const totalInfo = `Всего: ${meta.total_count}`;

    const columns = [
      {
        title: "Статус",
        dataIndex: "status_locale",
        key: "status_locale",
        align: "center",
        width: "10%",
      },
      {
        title: "#",
        dataIndex: "actions",
        render: (actions, record) =>
          record.has_timing ? (
            <Tooltip
              placement="bottom"
              title={record.start ? "Начать работы" : "Приостановить работы"}
            >
              {record.status == "processing" ? (
                record.start ? (
                  <Button
                    icon={"play-circle"}
                    type="primary"
                    onClick={onStartTiming.bind(this, record.id)}
                  />
                ) : (
                  <Button
                    icon={"pause-circle"}
                    type="danger"
                    onClick={onPauseTiming.bind(this, record.id)}
                  />
                )
              ) : null}
            </Tooltip>
          ) : null,
        width: "5%",
        align: "center",
      },
      {
        title: "Дата создания",
        dataIndex: "created_at",
        render: (created_at) => (
          <span style={{ fontSize: 12 }}>
            {moment(created_at).format("LLL")}
          </span>
        ),
        sorter: true,
        sortDirections: ["ascend", "descend"],
        key: "created_at",
        align: "center",
        width: "8%",
      },
      {
        title: "Выполнить до",
        dataIndex: "due_by",
        render: (due_by) => (
          <span style={{ fontSize: 12 }}>{moment(due_by).format("LLL")}</span>
        ),
        sorter: true,
        sortDirections: ["ascend", "descend"],
        key: "due_by",
        align: "center",
        width: "8%",
      },
      {
        title: "Номер",
        dataIndex: "details",
        render: (details, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>
              <Link to={`${record.parent}`} target="_blank">{details}</Link>
            </span>
          </div>),
        key: "details",
        align: "center",
        width: "10%",
      },
      {
        title: "Тип",
        dataIndex: "task_template",
        render: (task_template, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>
              {record.task_owner_locale}
            </span>
            <span style={{ fontSize: 12 }}>
              {record.name}
            </span>
          </div>
        ),
        align: "center",
        width: "10%",
      },
      {
        title: "Тема / Описание",
        dataIndex: "subject",
        render: (subject, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>
              <Link to={`/tasks/${record.id}/edit`}>{subject}</Link>
            </span>
            <span style={{ fontSize: 12 }}>
              <Paragraph ellipsis={{ rows: 3, expandable: true }}>
                {record.description}
              </Paragraph>
            </span>
          </div>
        ),
        key: "subject",
        align: "center",
        width: "34%",
      },
      {
        title: "Ответственный",
        dataIndex: "assignees",
        render: (assignees, record) => <AssigneesCell assignees={assignees} />,
        align: "center",
        width: "15%",
      },
    ];

    const faqButton = (
      <Button
        type={"ghost"}
        key="7"
        icon="question"
        onClick={this.props.onFaqToggle}
      />
    );

    let actionsButton = null;

    if (actions.length > 0) {
      actionsButton = (
        <Dropdown overlay={this.ActionMenu(actions)}>
          <Button>Создать</Button>
        </Dropdown>
      );
    }

    let mainButton = actionsButton
   


    const noticeIcon = (
      <NoticeIcon
        key="5"
        count={unread}
        onItemClick={this.onItemClick}
        list={notices}
        showClear
        onClear={this.onClearNotices}
        emptyText={"новых комментариев нет"}
      />
    );

    const search = (
      <Search
        allowClear
        key="99"
        placeholder="поиск..."
        value={fetchParams.search}
        onChange={onSearch}
        style={{ width: 250 }}
      />
    );

    let filterButton = (
      <Button
        type={
          fetchParams.startDate ||
          fetchParams.startFinishDate ||
          fetchParams.status ||
          fetchParams.templates ||
          fetchParams.taskUsers ||
          fetchParams.users ||
          fetchParams.supervisorUsers
            ? "primary"
            : "ghost"
        }
        key="4"
        icon="filter"
        onClick={this.onFilterToggle}
      />
    );

    return (
      <PageHeader
        className="page-header-with-notices"
        onBack={() => window.history.back()}
        title={`Задачи`}
        subTitle={
          fetchParams.startDate && fetchParams.endDate
            ? `c ${fetchParams.startDate} по ${fetchParams.endDate}`
            : null
        }
        extra={[faqButton, noticeIcon, search, mainButton, filterButton]}
        footer={
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col span={24}>
              <Table
                size={"middle"}
                loading={loading}
                pagination={false}
                columns={columns}
                dataSource={tasks}
                onChange={this.onChangeTable}
                rowKey="id"
              />
            </Col>
            <Col
              span={24}
              style={{
                paddingTop: "15px",
                paddingBottom: "15px",
                textAlign: "right",
              }}
            >
              <Pagination
                showSizeChanger
                pageSize={fetchParams.limit}
                current={meta.current_page}
                onChange={onChangePage}
                onShowSizeChange={onChangeLimit}
                showTotal={(total) => totalInfo}
                total={meta.total_count}
              />
            </Col>
          </Row>
        }
      >
        <Filter
          currentUser={currentUser}
          visible={isFilterVisible}
          status={fetchParams.status}
          taskUsers={fetchParams.taskUsers}
          users={fetchParams.users}
          supervisorUsers={fetchParams.supervisorUsers}
          startDate={fetchParams.startDate}
          endDate={fetchParams.endDate}
          startFinishDate={fetchParams.startFinishDate}
          endFinishDate={fetchParams.endFinishDate}
          templates={fetchParams.templates}
          onClose={this.onFilterToggle}
          onClearFilters={onClearFilters}
          onChangeStatus={onChangeFilterStatus}
          onChangeTemplates={onChangeFilterTemplates}
          onChangeTaskUsers={onChangeFilterTaskUsers}
          onChangeUsers={onChangeFilterUsers}
          onChangeSupervisorUsers={onChangeFilterSupervisorUsers}
          onChangePeriod={onChangeFilterPeriod}
          onChangeFinishPeriod={onChangeFilterFinishPeriod}
          downloadReport={downloadReport}
        />
      </PageHeader>
    );
  }
}

TasksList.propTypes = {
  projects: PropTypes.arrayOf(PropTypes.object),
  meta: PropTypes.object,
  fetchParams: PropTypes.object,
};

export default withRouter(TasksList);
