// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Descriptions, Col, Row } from "antd";
import accounting from "accounting";

class Header extends Component {
  constructor(props, context) {
    super(props, context);
  }
  render() {
    const { isNew, project } = this.props;

    return (
      <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
        <Col span={24}>
          <Row gutter={16}>
            <Descriptions bordered>
              <Descriptions.Item label="Объект" span={3}>
                {project.entity ? (
                  <Link to={`/entities/${project.entity.id}/edit`}>
                    {project.entity.name}
                  </Link>
                ) : null}
              </Descriptions.Item>
              <Descriptions.Item label="Адрес" span={3}>
                {project.entity ? project.entity.address : null}
              </Descriptions.Item>
              <Descriptions.Item label="Тип проекта" span={3}>
                {project.project_type ? project.project_type.name : null}
              </Descriptions.Item>
              <Descriptions.Item label="Сумма" span={3}>
                {accounting.formatNumber(project.amount, 0, " ")}
              </Descriptions.Item>
              <Descriptions.Item label="Описание" span={3}>
                {project.description ? project.description : null}
              </Descriptions.Item>
            </Descriptions>
          </Row>
        </Col>
      </Row>
    );
  }
}

Header.propTypes = {
  entity: PropTypes.object,
  onChangeDate: PropTypes.func,
  onChangeAssignee: PropTypes.func,
  onChangeAgreement: PropTypes.func,
  onChangeAgreementDate: PropTypes.func,
  onChangeAdditional: PropTypes.func,
  onChangeComment: PropTypes.func,
  onChangeNotice: PropTypes.func,
};

export default Header;
