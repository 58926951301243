// @flow
import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Row, Col, Result, Button } from "antd";

class Finish extends Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  render() {
    return (
      <Row
        type="flex"
        justify="center"
        align="middle"
        style={{
          height: "100vh",
        }}
      >
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Result
            status="success"
            title="Спасибо за оценку!"
            // subTitle="Order number: 2017182818828182881 Cloud server configuration takes 1-5 minutes, please wait."
            extra={[
                <Button type="primary" key="console"><Link to="/">Домой</Link></Button>
            ]}
          />
        </Col>
      </Row>
    );
  }
}

Finish.propTypes = {
  dispatch: PropTypes.func,
};

export default Finish;
