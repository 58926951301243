import api from "../api";
const Actions = {};

//faqs
Actions.fetchFaqs = (contex, subcontext) => {
  return (dispatch, getState) => {
    dispatch({ type: "FETCH_FAQS_REQUEST" });
    const params = {
      contex: contex,
      subcontext: subcontext,
    };
    api
      .fetch("/faqs", params)
      .then((response) => {
        dispatch({ type: "FETCH_FAQS", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_FAQS_FAILURE", response });
      });
  };
};

Actions.fetchFaq = (id) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_FAQ_REQUEST" });
    api
      .fetch(`/faqs/${id}`)
      .then((response) => {
        dispatch({ type: "FETCH_FAQ", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_FAQ_FAILURE", response });
      });
  };
};

Actions.onDrawerToggle = () => {
  return (dispatch) => {
    dispatch({ type: "FAQ_DRAWER_TOGGLE" });
    return Promise.resolve();
  };
};

export default Actions;
