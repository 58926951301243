import update from "immutability-helper";
import moment from "moment";
import "moment/locale/ru";

const initialState = {
  calendarEvents: { entity_tasks: [], order_tasks: [], tickets: [] },
  post: { id: null, published_at: null, title: null, body: null },
  posts: [],
  postCount: 0,
  selectedEvent: null,
  tasks: [],
  countOverdueTasks: 0,
  taskSeries: [],
  projectSeries: [],
  ticketSeries: [],
  entityTaskSeries: [],
  tasksStatistic: {
    total: 0,
    pending: 0,
    processing: 0,
    finished: 0,
    canceled: 0,
    intime: 0,
    overdue: 0,
  },
  projectsStatistic: {
    total: 0,
    pending: 0,
    processing: 0,
    finished: 0,
    canceled: 0,
    intime: 0,
  },
  ordersStatistic: {
    total: 0,
    pending: 0,
    processing: 0,
    finished: 0,
    canceled: 0,
    intime: 0,
  },
  intimeStatistic: { tickets: [], entity_tasks: [], tasks: [] },
  fetchParams: {
    startDate: null,
    endDate: null,
    entityTasks: true,
    orderTasks: true,
    tickets: true,
  },
  fetchTasksParams: {
    startDate: moment().startOf("month").add(-1, "months").format("DD.MM.YYYY"),
    endDate: moment().format("DD.MM.YYYY"),
    assigneeType: "current",
    page: 1,
    limit: 15,
  },
  fetchStatisticParams: {
    startDate: moment().startOf("month").add(-1, "months").format("DD.MM.YYYY"),
    endDate: moment().format("DD.MM.YYYY"),
    departments: null,
  },
  isDrawerVisible: false,
  isPostLoading: false,
  isPostsLoading: false,
  isCalendarLoading: false,
  isGanttLoading: false,
  isEventLoading: false,
  isStatisticLoading: true,
  isOrderStatisticLoading: true,
  isTasksLoading: true,
  isIntimeStatisticLoading: true,
  errors: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    // posts
    case "FETCH_POSTS_REQUEST":
      return {
        ...state,
        isPostsLoading: true,
        errors: false,
      };
    case "FETCH_POSTS":
      return {
        ...state,
        posts: action.response.data,
        postCount: action.response.postCount,
        isPostsLoading: false,
        errors: false,
      };

    case "FETCH_POSTS_FAILURE":
      return {
        ...state,
        isPostsLoading: false,
        errors: true,
      };

    case "FETCH_POST_REQUEST":
      return {
        ...state,
        isPostLoading: true,
        errors: false,
      };
    case "FETCH_POST":
      return {
        ...state,
        post: action.response.data,
        isPostLoading: false,
        errors: false,
      };

    case "FETCH_POST_FAILURE":
      return {
        ...state,
        isPostLoading: false,
        errors: true,
      };

    // calendar
    case "FETCH_CALENDAR_EVENTS_REQUEST":
      return {
        ...state,
        isCalendarLoading: true,
        errors: false,
      };
    case "FETCH_CALENDAR_EVENTS":
      return {
        ...state,
        calendarEvents: action.response.data,
        isCalendarLoading: false,
        errors: false,
      };

    case "FETCH_CALENDAR_EVENTS":
      return {
        ...state,
        calendarEvents: { entity_tasks: [], order_tasks: [], tickets: [] },
        isCalendarLoading: false,
        errors: true,
      };

    case "FETCH_EVENT_REQUEST":
      return {
        ...state,
        isEventLoading: true,
        errors: false,
      };
    case "FETCH_EVENT":
      return {
        ...state,
        selectedEvent: action.response.data,
        isEventLoading: false,
        errors: false,
      };

    case "FETCH_EVENT_FAILURE":
      return {
        ...state,
        selectedEvent: null,
        isEventLoading: false,
        errors: true,
      };

    case "DASHBOARD_CHANGE_DATE":
      return update(state, {
        calendarEvents: { entity_tasks: [], order_tasks: [], tickets: [] },
        fetchParams: {
          startDate: { $set: action.startDate },
          endDate: { $set: action.endDate },
        },
      });

    case "DASHBOARD_CHANGE_EVENT_TYPE":
      return update(state, {
        calendarEvents: { entity_tasks: [], order_tasks: [], tickets: [] },
        fetchParams: {
          [action.value]: { $set: !state.fetchParams[action.value] },
        },
      });

    case "DRAWER_TOGGLE":
      return {
        ...state,
        isDrawerVisible: !state.isDrawerVisible,
      };

    // statistic
    case "FETCH_DASHBOARD_TASKS_REQUEST":
      return {
        ...state,
        isTasksLoading: true,
        errors: false,
      };
    case "FETCH_DASHBOARD_TASKS":
      return {
        ...state,
        tasks: action.response.tasks,
        countOverdueTasks: action.response.count_overdue_tasks,
        isTasksLoading: false,
        errors: false,
      };

    case "FETCH_DASHBOARD_TASKS_FAILURE":
      return {
        ...state,
        tasks: [],
        isTasksLoading: false,
        errors: true,
      };

    case "FETCH_DASHBOARD_STATISTIC_REQUEST":
      return {
        ...state,
        isStatisticLoading: true,
        errors: false,
      };
    case "FETCH_DASHBOARD_STATISTIC":
      return {
        ...state,
        tasksStatistic: action.response.statistic,
        taskSeries: action.response.series,
        projectsStatistic: action.response.project_statistic,
        projectSeries: action.response.project_series,
        ticketSeries: action.response.ticket_series,
        entityTaskSeries: action.response.entity_tasks_series,
        isStatisticLoading: false,
        errors: false,
      };

    case "FETCH_DASHBOARD_STATISTIC_FAILURE":
      return {
        ...state,
        isStatisticLoading: false,
        errors: true,
      };

    case "FETCH_DASHBOARD_ORDERS_STATISTIC_REQUEST":
      return {
        ...state,
        isOrderStatisticLoading: true,
        errors: false,
      };

    case "FETCH_DASHBOARD_ORDERS_STATISTIC":
      return {
        ...state,
        ordersStatistic: action.response.statistic,
        isOrderStatisticLoading: false,
        errors: false,
      };

    case "FETCH_DASHBOARD_ORDERS_STATISTIC_FAILURE":
      return {
        ...state,
        isOrderStatisticLoading: false,
        errors: true,
      };

    case "FETCH_DASHBOARD_INTIME_STATISTIC_REQUEST":
      return {
        ...state,
        isIntimeStatisticLoading: true,
        errors: false,
      };

    case "FETCH_DASHBOARD_INTIME_STATISTIC":
      return {
        ...state,
        intimeStatistic: action.response.data,
        isIntimeStatisticLoading: false,
        errors: false,
      };

    case "FETCH_DASHBOARD_INTIME_STATISTIC_FAILURE":
      return {
        ...state,
        isIntimeStatisticLoading: false,
        errors: true,
      };

    case "DASHBOARD_CHANGE_STATISTIC_DATE_RANGE":
      return update(state, {
        fetchStatisticParams: {
          startDate: { $set: action.startDate },
          endDate: { $set: action.endDate },
        },
        fetchStatisticParams: {
          startDate: { $set: action.startDate },
          endDate: { $set: action.endDate },
        },
      });

    case "DASHBOARD_CHANGE_STATISTIC_DEPARTMENTS":
      return update(state, {
        fetchStatisticParams: {
          departments: {
            $set: action.values.length > 0 ? action.values : null,
          },
        },
      });

    case "DASHBOARD_CHANGE_TASKS_ASSIGNEE_TYPE":
      return update(state, {
        fetchTasksParams: {
          assigneeType: { $set: action.value },
        },
      });

    default:
      return state;
  }
}
