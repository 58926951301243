// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Actions from "../../../actions/entityTasks";
import TaskActions from "../../../actions/tasks";
import { Spin } from "antd";
import Form from "./Form";
import moment from "moment";
import "moment/locale/ru";

class EntityTask extends Component {
  componentWillMount = () => {
    if (this.props.match.params.id) {
      this.props.dispatch(Actions.fetchEntityTask(this.props.match.params.id)).then(() => {
        this.props.dispatch(TaskActions.fetchTaskActions({taskOwners: [5]}));
      });
    }
  };

  onClose = () => {
    this.props.history.push(`/entity_tasks`);
  };

  // comments
  onCreateComment = (comment, mentioned) => {
    this.props.dispatch(Actions.onCreateComment(this.props.match.params.id, comment, mentioned))
      .then(() => {
        if (!this.props.errors) {
          this.props.dispatch(
            Actions.fetchEntityTaskComments(this.props.match.params.id)
          );
        }
      });
  };

  // assignees
  onCreateAssignee = (value, type) => {
    this.props
      .dispatch(
        Actions.onCreateAssignee(this.props.match.params.id, value, type)
      )
      .then(() => {
        if (!this.props.errors) {
          this.props.dispatch(
            Actions.fetchAssignees(this.props.match.params.id)
          );
        }
      });
  };

  onDeleteAssignee = (id) => {
    this.props
      .dispatch(Actions.onDeleteAssignee(this.props.match.params.id, id))
      .then(() => {
        if (!this.props.errors) {
          this.props.dispatch(
            Actions.fetchAssignees(this.props.match.params.id)
          );
        }
      });
  };

  // attachments
  onDeleteAttachment = (id) => {
    this.props
      .dispatch(Actions.onDeleteAttachment(this.props.match.params.id, id))
      .then(() => {
        if (!this.props.errors) {
          this.props.dispatch(
            Actions.fetchAttachments(this.props.match.params.id)
          );
        }
      });
  };

  onUploadAttachment = (file) => {
    this.props
      .dispatch(Actions.onUploadAttachment(this.props.match.params.id, file))
      .then(() => {
        if (!this.props.errors) {
          this.props.dispatch(
            Actions.fetchAttachments(this.props.match.params.id)
          );
        }
      });
  };

  // tasks
  fetchTasks = () => {
    this.props.dispatch(Actions.fetchTasks(this.props.match.params.id));
  };

  // actions
  onOpenTaskForm = (action, id) => {
    const { entityTask } = this.props;
    this.props.dispatch(TaskActions.fetchNew(action.id, entityTask.id));
  };

  // todos
  onEntityTaskTodoSaveComment = (entityTaskTodoId, id, entityTaskTodoIdx, idx, comment) => {
    this.props.dispatch(
      Actions.onEntityTaskTodoSaveComment(entityTaskTodoId, id, entityTaskTodoIdx, idx, comment)
    );
  } 

  render() {
    const {
      isLoading,
      isUploading,
      isAssigneesLoading,
      entityTask, 
      // todos
      isTodoLoading,
      // tasks
      isTasksLoading,
      tasks,
      // actions
      actions,
      isTaskActionsLoading,
    } = this.props;
    return isLoading ? (
      <Spin />
    ) : (
      <Form
        entityTask={entityTask}
        onClose={this.onClose}
        // comments
        onCreateComment={this.onCreateComment}
        // assignees
        isAssigneesLoading={isAssigneesLoading}
        onCreateAssignee={this.onCreateAssignee}
        onDeleteAssignee={this.onDeleteAssignee}
        // attachments
        isUploading={isUploading}
        onDeleteAttachment={this.onDeleteAttachment}
        onUploadAttachment={this.onUploadAttachment}
        // todos
        isTodoLoading={isTodoLoading}
        onEntityTaskTodoSaveComment={this.onEntityTaskTodoSaveComment}
        // tasks
        isTasksLoading={isTasksLoading}
        tasks={tasks}
        fetchTasks={this.fetchTasks}
        // actions
        actions={actions}
        isTaskActionsLoading={isTaskActionsLoading}
        onOpenTaskForm={this.onOpenTaskForm}
      />
    );
  }
}

EntityTask.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  entityTask: PropTypes.object,
  isLoading: PropTypes.bool,
  errors: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isLoading: state.entityTasks.isLoading,
  isUploading: state.tickets.isUploading,
  errors: state.entityTasks.errors,
  entityTask: state.entityTasks.entityTask,
  isAssigneesLoading: state.entityTasks.isAssigneesLoading,
  // todos
  isTodoLoading: state.entityTasks.isTodoLoading,
  // tasks
  isTasksLoading: state.entityTasks.isTasksLoading,
  tasks: state.entityTasks.tasks,
  // actions
  actions: state.tasks.actions,
  isTaskActionsLoading: state.tasks.isLoading,
});

export default connect(mapStateToProps)(withRouter(EntityTask));
