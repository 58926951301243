// @flow
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Table, Button, PageHeader, Badge, Select, Icon } from "antd";
import { Pagination } from "antd";
import { Row, Col, Input } from "antd";

import PropTypes from "prop-types";

import moment from "moment";
import "moment/locale/ru";

const Search = Input.Search;
const Option = Select.Option;

function statuses(status) {
  switch (status) {
    case "pending":
      return "error";
    case "processing":
      return "processing";
    case "closed":
      return "success";
    default:
      return "default";
  }
}

class SupportMessagesList extends Component {
  onChangeTable = (pagination, filters, sorter) => {
    let direction = "desc";
    if (sorter.order === "descend") {
      direction = "desc";
    } else if (sorter.order === "ascend") {
      direction = "asc";
    }
    this.props.onSortBy(sorter.field, direction);
  };

  render() {
    const {
      loading,
      currentUser,
      supportMessages,
      meta,
      fetchParams,
      onSearch,
      onChangePage,
      onChangeLimit,
      onSortBy
    } = this.props;

    const columns = [
      {
        title: "#",
        dataIndex: "id",
        render: (id, record) => (
          <Link to={`/support_messages/${record.id}`}>{id}</Link>
        ),
        width: "5%",
        align: "center"
      },
      {
        title: "Статус",
        dataIndex: "status",
        render: (status, record) => (
          <Badge status={statuses(record.status)} text={record.status_locale} />
        ),
        filterIcon: filtered => (
          <Icon
            type="filter"
            style={{
              color: fetchParams.status ? "#1890ff" : undefined
            }}
          />
        ),
        key: "status",
        align: "center",
        width: "10%"
      },
      {
        title: "Тип",
        dataIndex: "support_message_type",
        render: (support_message_type) => support_message_type ? support_message_type.name : null,
        key: "support_message_type",
        width: "10%",
        align: "center",
      },
      {
        title: "Дата",
        dataIndex: "created_at",
        render: created_at => moment(created_at).format("L"),
        sorter: true,
        sortDirections: ["ascend", "descend"],
        key: "created_at",
        align: "center",
        width: "10%"
      },
      {
        title: "Тема",
        dataIndex: "subject",
        render: (subject, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>{subject}</span>
          </div>
        ),
        align: "left",
        width: "50%"
      },
      {
        title: "Отправитель",
        dataIndex: "user",
        render: (user, record) => (
          <span style={{ opacity: "0.7" }}>
            {user ? user.name : record.member.name}
          </span>
        ),
        align: "center",
        width: "15%"
      }
    ];

    return (
      <PageHeader
        onBack={() => window.history.back()}
        title={`Сообщения в службу поддержки`}
        extra={[
          <Search
            key="3"
            placeholder="поиск..."
            value={fetchParams.search}
            onChange={onSearch}
            style={{ width: 250 }}
          />
        ]}
        footer={
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col span={24}>
              <Table
                bordered
                size={"middle"}
                loading={loading}
                pagination={false}
                columns={columns}
                dataSource={supportMessages}
                onChange={this.onChangeTable}
                rowKey="id"
              />
            </Col>
            <Col
              span={24}
              style={{ paddingTop: "15px", paddingBottom: "15px" }}
            >
              {/* <Pagination
                showSizeChanger
                pageSize={fetchParams.limit}
                current={meta.current_page}
                onChange={onChangePage}
                onShowSizeChange={onChangeLimit}
                total={meta.total_count}
              /> */}
            </Col>
          </Row>
        }
      />
    );
  }
}

SupportMessagesList.propTypes = {
  orders: PropTypes.arrayOf(PropTypes.object),
  meta: PropTypes.object,
  fetchParams: PropTypes.object
};

export default SupportMessagesList;
