import api from "../api";

const Actions = {};

Actions.fetchPosts = () => {
  return (dispatch, getState) => {
    dispatch({ type: "FETCH_POSTS_REQUEST" });
    const params = getState().posts.fetchParams;
    api
      .fetch("/posts", params)
      .then(response => {
        dispatch({ type: "FETCH_POSTS", response });
      })
      .catch(response => {
        console.log(response.error);
        dispatch({ type: "FETCH_POSTS_FAILURE", response });
      });
  };
};

Actions.fetchPost = (id = null) => {
  return dispatch => {
    dispatch({ type: "FETCH_POST_REQUEST" });
    api
      .fetch(`/posts/${id}`)
      .then(response => {
        dispatch({ type: "FETCH_POST", response });
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            return api.openNotification("error", "Клиент", error);
          });
        }
        dispatch({ type: "FETCH_POST_FAILURE", response });
      });
  };
};

Actions.onNew = () => {
  return (dispatch, getState) => {
    const currentUser = getState().session.currentUser;
    dispatch({
      type: "POST_NEW",
      user: currentUser
    });
  };
};

Actions.onUpdate = id => {
  return (dispatch, getState) => {
    dispatch({ type: "PATCH_POST_REQUEST" });
    const post = getState().posts.post;
    const data = {
      post: {
        post_type: post.post_type,
        post_available: post.post_available,
        title: post.title,
        body: post.body
      }
    };

    api
      .patch(`/posts/${id}`, data)
      .then(response => {
        dispatch({ type: "PATCH_POST", response });
        api.openNotification("success", "Пост", "Успешно обновлен!");
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            return api.openNotification("error", "Заказ", error);
          });
        }
        dispatch({ type: "PATCH_POST_FAILURE", response });
      });
  };
};

Actions.onCreate = () => {
  return (dispatch, getState) => {
    dispatch({ type: "POST_REQUEST" });
    const post = getState().posts.post;
    const post_user_roles_attributes = post.post_user_roles.map(item => {
      return {
        role: item,
        _destroy: false
      };
    });

    const data = {
      post: {
        post_type: post.post_type,
        post_available: post.post_available,
        title: post.title,
        body: post.body,
        post_user_roles_attributes: post_user_roles_attributes
      }
    };
    return api
      .post(`/posts`, data)
      .then(response => {
        dispatch({ type: "POST_SUCCEED", response });
        api.openNotification("success", "Пост", "Успешно создан!");
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            api.openNotification("error", "Пост", error);
          });
        }
        dispatch({ type: "POST_FAILURE", response });
      });
  };
};

Actions.onDelete = id => {
  return dispatch => {
    dispatch({ type: "DELETE_POST_REQUEST" });
    return api
      .delete(`/posts/${id}`)
      .then(response => {
        api.openNotification("success", "Пост", "Успешно удалено!");
        dispatch({ type: "DELETE_POST", response });
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            return api.openNotification("error", "Пост", error);
          });
        }
        dispatch({
          type: "DELETE_POST_FAILURE"
        });
      });
  };
};

Actions.onPublish = (id, value) => {
  return (dispatch, getState) => {
    dispatch({ type: "PUBLISH_POST_REQUEST" });
    const data = {
      post: {
        is_published: value
      }
    };
    api
      .patch(`/posts/${id}/publish`, data)
      .then(response => {
        dispatch({ type: "PUBLISH_POST_SUCCEED", response });
        api.openNotification("success", "Пост", "Успешно обновлен!");
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            return api.openNotification("error", "Пост", error);
          });
        }
        dispatch({ type: "PUBLISH_POST_FAILURE", response });
      });
  };
};

Actions.onChangePage = value => {
  return dispatch => {
    dispatch({
      type: "REQUEST_POST_CHANGE_PAGE",
      value: value
    });
    return Promise.resolve();
  };
};

Actions.onChangeLimit = value => {
  return dispatch => {
    dispatch({
      type: "REQUEST_POST_CHANGE_LIMIT",
      value: value
    });
    return Promise.resolve();
  };
};

Actions.onSortBy = (field, direction) => {
  return dispatch => {
    dispatch({
      type: "POSTS_SORT_BY",
      field: field,
      direction: direction
    });
    return Promise.resolve();
  };
};

Actions.onChangePostType = value => {
  return dispatch => {
    dispatch({
      type: "POST_CHANGE_POST_TYPE",
      value: value
    });
  };
};

Actions.onChangePostAvailable = value => {
  return dispatch => {
    dispatch({
      type: "POST_CHANGE_POST_AVAILABLE",
      value: value
    });
  };
};

Actions.onChangeTitle = value => {
  return dispatch => {
    dispatch({
      type: "POST_CHANGE_TITLE",
      value: value
    });
  };
};

Actions.onChangeBody = value => {
  return dispatch => {
    dispatch({
      type: "POST_CHANGE_BODY",
      value: value
    });
  };
};

Actions.onChangePostUserRoles = values => {
  return dispatch => {
    dispatch({
      type: "POST_CHANGE_POST_USER_ROLES",
      values: values
    });
  };
};

export default Actions;
