import update from "immutability-helper";

const initialState = {
  customer: {},
  errors: false,
  isLoading: true
};

export default function(state = initialState, action) {
  switch (action.type) {
    case "FETCH_CUSTOMER_REQUEST":
      return {
        ...state,
        isLoading: true
      };
    case "FETCH_CUSTOMER":
      return {
        ...state,
        customer: action.response.data,
        isLoading: false
      };
    case "PATCH_CUSTOMER_REQUEST":
      return {
        ...state,
        isLoading: true
      };
    case "PATCH_CUSTOMER":
      return {
        ...state,
        customer: action.response.data,
        isLoading: false
      };

    case "PATCH_CUSTOMER_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true
      };

    case "CUSTOMER_CHANGE_NAME":
      return update(state, {
        customer: { name: { $set: action.value } }
      });

    default:
      return state;
  }
}
