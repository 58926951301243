
const initialState = {
  faqs: [],
  faq: {},
  meta: {
    total_pages: 1,
    current_page: 1,
    total_count: 1
  },
  fetchParams: {
    search: null,
    sort: null,
    direction: null,
    page: 1,
    limit: 15
  },
  isDrawerVisible: false,
  isFaqLoading: false,
  isFaqsLoading: false,
  errors: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    // faqs
    case "FETCH_FAQS_REQUEST":
      return {
        ...state,
        isFaqsLoading: true,
        errors: false,
      };
    case "FETCH_FAQS":
      return {
        ...state,
        faqs: action.response.data,
        isFaqsLoading: false,
        errors: false,
      };

    case "FETCH_FAQS_FAILURE":
      return {
        ...state,
        isFaqsLoading: false,
        errors: true,
      };

    case "FETCH_FAQ_REQUEST":
      return {
        ...state,
        isFaqLoading: true,
        errors: false,
      };

    case "FETCH_FAQ":
      return {
        ...state,
        faq: action.response.data,
        isFaqLoading: false,
        errors: false,
      };

    case "FETCH_FAQ_FAILURE":
      return {
        ...state,
        isFaqLoading: false,
        errors: true,
      };

    case "FAQ_DRAWER_TOGGLE":
      return {
        ...state,
        isDrawerVisible: !state.isDrawerVisible,
      };
    default:
      return state;
  }
}
