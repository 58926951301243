// @flow
import React, { Component } from "react";
import {
  Form,
  Table,
  List,
  Avatar,
  DatePicker,
  Select,
  Rate,
  Statistic,
  Card,
  Icon,
  Tooltip,
} from "antd";
import { Row, Col, PageHeader } from "antd";
import _ from "lodash/array";

import PropTypes from "prop-types";

import moment from "moment";
import "moment/locale/ru";

import SelectMultiFetch from "../../../components/SelectMultiFetch";
import Details from "./Details";

const { RangePicker } = DatePicker;

class DataList extends Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      userId: null,
    };
  }

  onDetails = (userId) => {
    this.setState({ userId: userId }, () => {
      this.props.onDetails(userId);
    });
  };

  render() {
    const { userId } = this.state;
    const {
      height,
      loading,
      employees,
      statistic,
      fetchParams,
      onChangeDateRange,
      onChangeDepartments,
      // details
      isLoadingDetails,
      details,
    } = this.props;

    // render: (effectiveness, record) => `${Number(record.closed / record.total * 100).toFixed(2)}%`,

    const dateFormat = "DD.MM.YYYY HH:mm";

    const columns = [
      {
        title: "Сотрудник",
        dataIndex: "name",
        key: "name",
        render: (name, record) => (
          <List.Item>
            <List.Item.Meta
              avatar={
                record.avatar ? (
                  <Avatar size={"large"} src={record.avatar} />
                ) : (
                  <Avatar size={"large"}>
                    {name
                      .split(" ")
                      .map((n) => n[0])
                      .join(".")
                      .toUpperCase()}
                  </Avatar>
                )
              }
              title={name}
              description={record.title}
            />
          </List.Item>
        ),
        defaultSortOrder: "ascend",
        sorter: (a, b) => a.name.localeCompare(b.name),
        sortDirections: ["descend", "ascend"],
        width: "30%",
      },
      {
        title: "Подразделение",
        dataIndex: "department",
        key: "department",
        render: (department, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>{department ? department.name : null}</span>
            <span style={{ fontSize: 12 }}>
              {department.city ? department.city : null}
            </span>
          </div>
        ),
        sorter: (a, b) => a.name.localeCompare(b.name),
        sortDirections: ["descend", "ascend"],
        align: "center",
        width: "20%",
      },
      {
        title: "Рейтинг",
        dataIndex: "rating",
        key: "rating",
        render: (rating, record) => (
          <Rate disabled allowHalf value={parseFloat(rating)} />
        ),
        sorter: (a, b) => parseFloat(a.rating) - parseFloat(b.rating),
        align: "center",
        width: "18%",
      },
      {
        title: "Оценок",
        dataIndex: "count",
        key: "count",
        sorter: (a, b) => parseFloat(a.count) - parseFloat(b.count),
        align: "center",
        width: "12%",
      },
      {
        title: "Без оценки",
        dataIndex: "nonrated",
        key: "nonrated",
        render: (nonrated, record) => Number(nonrated),
        sorter: (a, b) => parseFloat(a.nonrated) - parseFloat(b.nonrated),
        align: "center",
        width: "10%",
      },
      {
        title: "Авто",
        dataIndex: "auto",
        key: "auto",
        render: (auto, record) => Number(auto),
        sorter: (a, b) => parseFloat(a.auto) - parseFloat(b.auto),
        align: "center",
        width: "10%",
      },
    ];


    return (
      <PageHeader
        onBack={() => window.history.back()}
        title={
          fetchParams.endDate
            ? `Рейтинг исполнителей на ${fetchParams.endDate}`
            : "Рейтинг исполнителей"
        }
        footer={
          <Row gutter={{ md: 8, lg: 24, xl: 48 }} style={{ paddingBottom: 15 }}>
            <Col xs={24} sm={24} md={24} lg={15} xl={15}>
              <Table
                size={"small"}
                loading={loading}
                columns={columns}
                dataSource={employees}
                pagination={false}
                rowKey="id"
                scroll={{ y: height - 260 }}
                onRow={(record, rowIndex) => {
                  return {
                    onClick: (event) => {
                      this.onDetails(record.id);
                    }, // click row
                  };
                }}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={9} xl={9}>
              <Row gutter={16}>
                <Col span={12}>
                  <Card>
                    <Statistic
                      title={
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <span>Средняя оценка</span>
                          <span>
                            <Tooltip
                              placement="top"
                              title={"средняя оценка"}
                            >
                              <Icon type="question-circle" />
                            </Tooltip>
                          </span>
                        </div>
                      }
                      value={
                        (parseFloat(statistic.rate))
                      }
                      precision={2}
                      valueStyle={{ color: "#3f8600" }}
                      
                    />
                  </Card>
                </Col>
                <Col span={12}>
                  <Card>
                    <Statistic
                      title={
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <span>Комментарии</span>
                          <span>
                            <Tooltip
                              placement="top"
                              title={
                                "% комментариев при выставлении оценки"
                              }
                            >
                              <Icon type="question-circle" />
                            </Tooltip>
                          </span>
                        </div>
                      }
                      value={
                        statistic.count > 0
                          ? (statistic.comments / statistic.count) * 100
                          : 0
                      }
                      precision={2}
                      valueStyle={{ color: "#cf1322" }}
                      suffix="%"
                    />
                  </Card>
                </Col>
                <Col span={24}></Col>
              </Row>
              <Row gutter={16} style={{ paddingTop: 10 }}>
                <Col span={24}>
                  <Details
                    loading={isLoadingDetails}
                    data={details}
                    userId={userId}
                    startDate={fetchParams.startDate}
                    endDate={fetchParams.endDate}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        }
      >
        <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
          <Col xs={12} sm={12} md={12} lg={10} xl={10}>
            <RangePicker
              style={{ width: "100%" }}
              value={[
                fetchParams.startDate
                  ? moment(fetchParams.startDate, dateFormat)
                  : null,
                fetchParams.endDate
                  ? moment(fetchParams.endDate, dateFormat)
                  : null,
              ]}
              format={dateFormat}
              onChange={onChangeDateRange}
              showTime={{
                hideDisabledOptions: true,
                defaultValue: [
                  moment("00:00:00", "HH:mm:ss"),
                  moment("23:59:59", "HH:mm:ss"),
                ],
              }}
            />
          </Col>

          <Col xs={24} sm={24} md={24} lg={14} xl={14}>
            <SelectMultiFetch
              showSearch
              placeholder="Подразделение"
              onChange={onChangeDepartments}
              selected={fetchParams.departments ? fetchParams.departments : []}
              url={"/departments/search/?"}
            />
          </Col>
        </Row>
      </PageHeader>
    );
  }
}

DataList.propTypes = {
  payments: PropTypes.arrayOf(PropTypes.object),
  meta: PropTypes.object,
  fetchParams: PropTypes.object,
};

export default Form.create()(DataList);
