import React from "react";
import { List, Avatar, Popover } from "antd";
import UserInfo from "../UserInfo";
import MemberInfo from "../MemberInfo";
import EmployeeInfo from "../EmployeeInfo";

export default function AssigneesCell({ assignees = [] }) {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {assignees.map((item) => {
        if (item.user) {
          return (
            <span key={`user-${item.user.id}`}>
              <UserInfo title={item.user.name} userId={item.user.id} />
            </span>
          );
        }

        if (item.member) {
          return (
            <span key={`member-${item.member.id}`}>
              <MemberInfo title={item.member.name} memberId={item.member.id} />
            </span>
          );
        }

        if (item.employee) {
          return (
            <span key={`employee-${item.employee.id}`}>
              <EmployeeInfo
                title={item.employee.name}
                employeeId={item.employee.id}
              />
            </span>
          );
        }
      })}
    </div>
  );
}
