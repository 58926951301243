// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Table, Input, Avatar, Button, Tooltip, Badge } from "antd";
import update from "immutability-helper";
import { Row, Col } from "antd";
import AssigneesCell from "../../../components/AssigneesCell";

import moment from "moment";
import "moment/locale/ru";

function statuses(status) {
    switch (status) {
      case "pending":
        return "error";
      case "processing":
        return "processing";
      case "finished":
        return "success";
      default:
        return "default";
    }
  }

class EntityTasks extends Component {
  render() {
    const { isLoading, entityTasks } = this.props;

    const columns = [
      {
        title: "#",
        dataIndex: "id",
        key: "number",
        render: (id, record) => (
          <Link to={`/entity_tasks/${id}/edit`}>{id}</Link>
        ),
        align: "center",
        width: "5%",
      },
      {
        title: "Статус",
        dataIndex: "state",
        render: (state, record) => <Badge status={statuses(record.status)} />,
        key: "state",
        align: "center",
        width: "5%",
      },
      {
        title: "План",
        dataIndex: "plan",
        render: (plan) =>
          plan ? (
            <span style={{ fontSize: 13 }}>{moment(plan).format("LLL")}</span>
          ) : null,
        key: "plan",
        align: "center",
        width: "10%",
      },
      {
        title: "Начало",
        dataIndex: "start",
        render: (start) =>
          start ? (
            <span style={{ fontSize: 13 }}>{moment(start).format("LLL")}</span>
          ) : null,
        key: "start",
        align: "center",
        width: "10%",
      },
      {
        title: "Окончание",
        dataIndex: "finish",
        render: (finish) =>
          finish ? (
            <span style={{ fontSize: 13 }}>{moment(finish).format("LLL")}</span>
          ) : null,
        key: "finish",
        align: "center",
        width: "10%",
      },
      {
        title: "Ответственный",
        dataIndex: "assignees",
        render: (assignees, record) => <AssigneesCell assignees={assignees} />,
        align: "center",
        width: "15%",
      },
    ];

    return (
      <div>
        <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
          <Col span={24}>
            <Table
              loading={isLoading}
              size={"middle"}
              bordered
              pagination={false}
              columns={columns}
              dataSource={entityTasks}
              rowKey="id"
            />
          </Col>
        </Row>
      </div>
    );
  }
}

EntityTasks.propTypes = {
  orderEntityTasks: PropTypes.arrayOf(PropTypes.object),
  total: PropTypes.number,
  discount: PropTypes.object,
  shippingCost: PropTypes.number,
  amountDiscountCode: PropTypes.number,
  onRemoveVariant: PropTypes.func,
};
export default EntityTasks;
