// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { List, Avatar, Popover, Tooltip, Button, Row, Col, Upload, Divider } from "antd";

import moment from "moment";
import "moment/locale/ru";

function selectIcon(contentType) {
  switch (contentType) {
    case "application/pdf":
      return "file-pdf";
    case "image/png":
      return "file-image";
    case "image/jpeg":
      return "file-image";
    case "application/msword":
      return "file-word";
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      return "file-word";
    case "application/vnd.ms-excel":
      return "file-excel";
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      return "file-excel";
    default:
      return "file-unknown";
  }
}

function selectColor(contentType) {
  switch (contentType) {
    case "application/pdf":
      return "red";
    case "image/png":
      return "brown";
    case "image/jpeg":
      return "brown";
    case "application/msword":
      return "cornflowerblue";
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      return "cornflowerblue";
    case "application/vnd.ms-excel":
      return "green";
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      return "green";
    default:
      return "brown";
  }
}

class Attachments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: []
    };
  }

  onUpload = ({ onSuccess, onError, file }) => {
    this.props.onUploadAttachment(file);
  };

  render() {
    const { isUploading, attachments, onDeleteAttachment } = this.props;

    return (
      <Row gutter={{ md: 8, lg: 24, xl: 48 }} style={{ marginBottom: "10px" }}>
        <Divider>
            Файлы{" "}
            
              <Tooltip placement="bottom" title={"Загрузить файл"}>
                <Upload customRequest={this.onUpload} showUploadList={false}>
                  <Button type="dashed" shape="circle" icon="plus" />
                </Upload>
              </Tooltip>
           
          </Divider>
        <Col span={24}>
          <List
            loading={isUploading}
            dataSource={attachments}
            renderItem={item => (
              <List.Item
                actions={[
                  <Tooltip placement="bottom" title={"Удалить"}>
                    <Popover
                      content={
                        <Button
                          type="danger"
                          style={{ width: "100%" }}
                          onClick={onDeleteAttachment.bind(this, item.id)}
                        >
                          Удалить
                        </Button>
                      }
                      title="Удаление файла"
                      trigger="click"
                    >
                      <Button type="dashed" shape="circle" icon="close" />
                    </Popover>
                  </Tooltip>
                ]}
              >
                <List.Item.Meta
                  avatar={
                    <Avatar
                      style={{
                        backgroundColor: "transparent",
                        color: selectColor(item.content_type)
                      }}
                      icon={selectIcon(item.content_type)}
                      size="large"
                    />
                  }
                  title={
                    <a href={item.url} download={item.name}>
                      {item.name}
                    </a>
                  }
                  description={`${moment(item.created_at).format("LLL")} - ${item.file_size}`}
                />
              </List.Item>
            )}
          />
        </Col>
      </Row>
    );
  }
}

Attachments.propTypes = {
  orderAttachments: PropTypes.arrayOf(PropTypes.object),
  total: PropTypes.number,
  discount: PropTypes.object,
  shippingCost: PropTypes.number,
  amountDiscountCode: PropTypes.number,
  onRemoveVariant: PropTypes.func
};
export default Attachments;
