// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import Actions from "../../../actions/entities";
import FaqActions from "../../../actions/faqs";
import CustomersList from "./List";

class Customers extends Component {
  componentWillMount = () => {
    this.props.dispatch(Actions.onSetFilters()).then(() => {
      this.props.dispatch(Actions.fetchEntities());
    });
  };


  onSearch = e => {
    this.props
      .dispatch(Actions.onSearch(e.target.value))
      .then(() => this.props.dispatch(Actions.fetchEntities()));
  };

  onChangePage = value => {
    this.props
      .dispatch(Actions.onChangePage(value))
      .then(() => this.props.dispatch(Actions.fetchEntities()));
  };

  onChangeLimit = (current, size) => {
    this.props
      .dispatch(Actions.onChangeLimit(size))
      .then(() => this.props.dispatch(Actions.fetchEntities()));
  };

  onSortBy = (field, direction) => {
    this.props
      .dispatch(Actions.onSortBy(field, direction))
      .then(() => this.props.dispatch(Actions.fetchEntities()));
  };

  // filters
  onClearFilters = () => {
    this.props.dispatch(Actions.onClearFilters()).then(() =>
      this.props.dispatch(Actions.fetchEntities()).then(() => {
        localStorage.setItem(
          "entitiesFilters",
          JSON.stringify(this.props.fetchParams)
        );
      })
    );
  };

  onChangeFilterUsers = values => {
    let users = [];
    values.map(value => {
      users.push({ id: String(value.id), name: String(value.name) });
    });
    // console.log(values)
    this.props.dispatch(Actions.onChangeFilterUsers(users)).then(() =>
      this.props.dispatch(Actions.fetchEntities()).then(() => {
        localStorage.setItem(
          "entitiesFilters",
          JSON.stringify(this.props.fetchParams)
        );
      })
    );
  };

  onChangeFilterMembers = values => {
    this.props.dispatch(Actions.onChangeFilterMembers(values)).then(() =>
      this.props.dispatch(Actions.fetchEntities()).then(() => {
        localStorage.setItem(
          "entitiesFilters",
          JSON.stringify(this.props.fetchParams)
        );
      })
    );
  };

  onChangeFilterRegions = values => {
    this.props.dispatch(Actions.onChangeFilterRegions(values)).then(() =>
      this.props.dispatch(Actions.fetchEntities()).then(() => {
        localStorage.setItem(
          "entitiesFilters",
          JSON.stringify(this.props.fetchParams)
        );
      })
    );
  };

  onChangeFilterEntityType = (value) => {
    this.props.dispatch(Actions.onChangeFilterEntityType(value)).then(() =>
      this.props.dispatch(Actions.fetchEntities()).then(() => {
        localStorage.setItem(
          "entitiesFilters",
          JSON.stringify(this.props.fetchParams)
        );
      })
    );
  };

  onChangeFilterActive = e => {
    this.props.dispatch(Actions.onChangeFilterActive(e.target.checked)).then(() =>
      this.props.dispatch(Actions.fetchEntities()).then(() => {
        localStorage.setItem(
          "entitiesFilters",
          JSON.stringify(this.props.fetchParams)
        );
      })
    );
  };

  // faqs
  onFaqToggle = () => {
    this.props.dispatch(FaqActions.onDrawerToggle()).then(() => {
      this.props.dispatch(FaqActions.fetchFaqs(61, 1));
    });
  };

  render() {
    const { isLoading, entities, meta, fetchParams } = this.props;
    return (
      <CustomersList
        loading={isLoading}
        entities={entities}
        meta={meta}
        fetchParams={fetchParams}
        onSearch={this.onSearch}
        onChangePage={this.onChangePage}
        onChangeLimit={this.onChangeLimit}
        onSortBy={this.onSortBy}
         // filters
         onClearFilters={this.onClearFilters}
         onChangeFilterUsers={this.onChangeFilterUsers}
         onChangeFilterMembers={this.onChangeFilterMembers}
         onChangeFilterRegions={this.onChangeFilterRegions}
         onChangeFilterEntityType={this.onChangeFilterEntityType}
         onChangeFilterActive={this.onChangeFilterActive}
        // faqs
        onFaqToggle={this.onFaqToggle}
      />
    );
  }
}

const mapStateToProps = state => ({
  isLoading: state.entities.isLoading,
  entities: state.entities.entities,
  meta: state.entities.meta,
  fetchParams: state.entities.fetchParams
});

export default connect(mapStateToProps)(Customers);
