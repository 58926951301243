import React from "react";
import { Table, Rate } from "antd";
import Comments from "./Comments";

export default function Details({ loading = false, userId = null, startDate= null, endDate = null, data = [] }) {
  return <TableDetails loading={loading} data={data} userId={userId} startDate={startDate} endDate={endDate} />;
}

function TableDetails({ loading = false, data = [], userId={userId}, startDate={startDate}, endDate={endDate} }) {
  const columns = [
    {
      title: "Рейтинг",
      dataIndex: "rate",
      render: (rate) => (
        <Rate disabled allowHalf value={parseFloat(rate)} />
      ),
      key: "rating",
      align: "center",
      width: "70%",
    },
    {
      title: "Оценок",
      dataIndex: "count",
      render: (count, record) => (
        <Comments count={count} userId={userId} rate={record.rate} startDate={startDate} endDate={endDate} />
      ),
      key: "count",
      align: "center",
      width: "30%",
    },
  ];

  return (
    <Table
      title={() => "Детализация оценок по исполнителю"}
      locale={ {emptyText: "Для получения детализации по оценкам, кликнете на строчку в таблице с исполнителями"}}
      rowClassName={"progress-row"}
      size={"small"}
      loading={loading}
      columns={columns}
      dataSource={data}
      pagination={false}
      rowKey="id"
    />
  );
}
