// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Actions from "../../actions/currentMember";
import ProfileForm from "./Form";

class Profile extends Component {
  componentWillMount = () => {
    this.props.dispatch(Actions.fetchCurrentMember());
  };

  onSave = () => {
    this.props.dispatch(Actions.onUpdate());
  };

  onClose = () => {
    this.props.history.push(`/`);
  };

  onChangeCurrentMemberName = e => {
    this.props.dispatch(Actions.onChangeCurrentMemberName(e.target.value));
  };


  onChangeCurrentMemberEmail = e => {
    this.props.dispatch(Actions.onChangeCurrentMemberEmail(e.target.value));
  };

  // change password
  onUpdateCurrentMemberPassword = () => {
    this.props.dispatch(Actions.onUpdateCurrentMemberPassword());
  };

  onChangeCurrentMemberPassword = e => {
    this.props.dispatch(Actions.onChangeCurrentMemberPassword(e.target.value));
  };

  onChangeCurrentMemberPasswordConfirmation = e => {
    this.props.dispatch(Actions.onChangeCurrentMemberPasswordConfirmation(e.target.value));
  };

  // notifications
  onChangeNotifyTickets = e => {
    this.props.dispatch(Actions.onChangeNotifyTickets(e.target.value));
  };

  onChangeNotifyEntityTasks = e => {
    this.props.dispatch(Actions.onChangeNotifyEntityTasks(e.target.value));
  };

  onChangeNotifyOrders = e => {
    this.props.dispatch(Actions.onChangeNotifyOrders(e.target.value));
  };

  onChangeNotifyOrderTasks = e => {
    this.props.dispatch(Actions.onChangeNotifyOrderTasks(e.target.value));
  };

  onChangeNotifyProjects = e => {
    this.props.dispatch(Actions.onChangeNotifyProjects(e.target.value));
  };

  render() {
    const { isLoading, CurrentMember } = this.props;
    return (
      <ProfileForm
        isLoading={isLoading}
        member={CurrentMember}
        onSave={this.onSave}
        onClose={this.onClose}
        onChangeCurrentMemberName={this.onChangeCurrentMemberName}
        onChangeCurrentMemberEmail={this.onChangeCurrentMemberEmail}
        onUpdateCurrentMemberPassword={this.onUpdateCurrentMemberPassword}
        onChangeCurrentMemberPassword={this.onChangeCurrentMemberPassword}
        onChangeCurrentMemberPasswordConfirmation={this.onChangeCurrentMemberPasswordConfirmation}
        // notifications
        onChangeNotifyTickets={this.onChangeNotifyTickets}
        onChangeNotifyEntityTasks={this.onChangeNotifyEntityTasks}
        onChangeNotifyOrders={this.onChangeNotifyOrders}
        onChangeNotifyOrderTasks={this.onChangeNotifyOrderTasks}
        onChangeNotifyProjects={this.onChangeNotifyProjects}
      />
    );
  }
}

Profile.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  CurrentMember: PropTypes.object,
  isLoading: PropTypes.bool
};

const mapStateToProps = state => ({
  isLoading: state.currentMember.isLoading,
  CurrentMember: state.currentMember.currentMember
});

export default connect(mapStateToProps)(Profile);
