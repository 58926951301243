// @flow
import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import { message } from "antd";
import Actions from "../../../actions/tasks";
import FaqActions from "../../../actions/faqs";
import TasksList from "./List";
import moment from "moment";
import "moment/locale/ru";

class Tasks extends Component {
  componentWillMount = () => {
    this.props.dispatch(Actions.onSetFilters()).then(() => {
      this.props.dispatch(Actions.fetchTasks()).then(() => {
        this.props.dispatch(Actions.fetchTaskActions({taskOwners: []}));
      });
    });
  };

  componentDidUpdate(prevProps) {
    if (this.props.location.key !== prevProps.location.key) {
      this.props.dispatch(Actions.onSetFilters()).then(() => {
        this.props.dispatch(Actions.fetchTasks());
      });
    }
  }

  onDelete = (ids) => {
    ids.map((id) => {
      this.props.dispatch(Actions.onDelete(id)).then(() => {
        this.props.dispatch(Actions.fetchTasks());
      });
    });
  };

  onSearch = (e) => {
    this.props
      .dispatch(Actions.onSearch(e.target.value))
      .then(() => this.props.dispatch(Actions.fetchTasks()));
  };

  onChangePage = (value) => {
    this.props
      .dispatch(Actions.onChangePage(value))
      .then(() => this.props.dispatch(Actions.fetchTasks()))
      .then(() => {
        localStorage.setItem(
          "tasksFilters",
          JSON.stringify(this.props.fetchParams)
        );
      });
  };

  onChangeLimit = (current, size) => {
    this.props
      .dispatch(Actions.onChangeLimit(size))
      .then(() => this.props.dispatch(Actions.fetchTasks()))
      .then(() => {
        localStorage.setItem(
          "tasksFilters",
          JSON.stringify(this.props.fetchParams)
        );
      });
  };

  onSortBy = (field, direction) => {
    this.props
      .dispatch(Actions.onSortBy(field, direction))
      .then(() => this.props.dispatch(Actions.fetchTasks()))
      .then(() => {
        localStorage.setItem(
          "tasksFilters",
          JSON.stringify(this.props.fetchParams)
        );
      });
  };

  // tasks
  onOpenTaskForm = (action) => {
    this.props.dispatch(Actions.fetchNew(action.id));
  };

  // timing
  onStartTiming = (id) => {
    this.props.dispatch(Actions.onStartTiming(id, moment()));
  };

  onPauseTiming = (id) => {
    this.props.dispatch(Actions.onPauseTiming(id, moment())).then(() => {
      message.warning("Не забудьте оставить комментарий к задаче!");
    });
  };

  // filters
  onClearFilters = () => {
    this.props.dispatch(Actions.onClearFilters()).then(() =>
      this.props.dispatch(Actions.fetchTasks()).then(() => {
        localStorage.setItem(
          "tasksFilters",
          JSON.stringify(this.props.fetchParams)
        );
      })
    );
  };

  onChangeFilterPeriod = (value, dateString) => {
    this.props
      .dispatch(Actions.onChangeFilterPeriod(dateString[0], dateString[1]))
      .then(() => this.props.dispatch(Actions.fetchTasks()))
      .then(() => {
        localStorage.setItem(
          "ticketsFilters",
          JSON.stringify(this.props.fetchParams)
        );
      });
  };

  onChangeFilterFinishPeriod = (value, dateString) => {
    this.props
      .dispatch(
        Actions.onChangeFilterFinishPeriod(dateString[0], dateString[1])
      )
      .then(() => this.props.dispatch(Actions.fetchTasks()))
      .then(() => {
        localStorage.setItem(
          "ticketsFilters",
          JSON.stringify(this.props.fetchParams)
        );
      });
  };

  onChangeFilterStatus = (values) => {
    this.props.dispatch(Actions.onChangeFilterStatus(values)).then(() => {
      this.props.dispatch(Actions.fetchTasks()).then(() => {
        localStorage.setItem(
          "tasksFilters",
          JSON.stringify(this.props.fetchParams)
        );
      });
    });
  };

  onChangeFilterTemplates = (values) => {
    this.props.dispatch(Actions.onChangeFilterTemplates(values)).then(() => {
      this.props.dispatch(Actions.fetchTasks()).then(() => {
        localStorage.setItem(
          "tasksFilters",
          JSON.stringify(this.props.fetchParams)
        );
      });
    });
  };

  onChangeFilterUsers = (values) => {
    let users = [];
    values.map((value) => {
      users.push({ id: String(value.id), name: String(value.name) });
    });
    // console.log(values)
    this.props.dispatch(Actions.onChangeFilterUsers(users)).then(() =>
      this.props.dispatch(Actions.fetchTasks()).then(() => {
        localStorage.setItem(
          "tasksFilters",
          JSON.stringify(this.props.fetchParams)
        );
      })
    );
  };

  onChangeFilterTaskUsers = (values) => {
    let users = [];
    values.map((value) => {
      users.push({ id: String(value.id), name: String(value.name) });
    });
    // console.log(values)
    this.props.dispatch(Actions.onChangeFilterTaskUsers(users)).then(() =>
      this.props.dispatch(Actions.fetchTasks()).then(() => {
        localStorage.setItem(
          "tasksFilters",
          JSON.stringify(this.props.fetchParams)
        );
      })
    );
  };

  onChangeFilterSupervisorUsers = (values) => {
    this.props
      .dispatch(Actions.onChangeFilterSupervisorUsers(values))
      .then(() =>
        this.props.dispatch(Actions.fetchTasks()).then(() => {
          localStorage.setItem(
            "tasksFilters",
            JSON.stringify(this.props.fetchParams)
          );
        })
      );
  };

  // notices
  onClearNotices = () => {
    this.props.dispatch(Actions.onClearNotices());
  };

  // faqs
  onFaqToggle = () => {
    this.props.dispatch(FaqActions.onDrawerToggle()).then(() => {
      this.props.dispatch(FaqActions.fetchFaqs(5, 1))
    });
  };

  downloadReport = () => {
    let params = this.props.fetchParams;
    params.limit = 1000;
    Actions.downloadReport(
      params,
      "tasks.xlsx",
      params.startDate
        ? `tasks ${params.startDate}-${params.endDate}.xlsx`
        : "tasks.xlsx"
    );
  };

  render() {
    const {
      currentUser,
      isLoading,
      tasks,
      meta,
      fetchParams,
      unread,
      notices,
      // actions
      actions,
      isTaskActionsLoading,
    } = this.props;
    return (
      <TasksList
        loading={isLoading}
        currentUser={currentUser}
        tasks={tasks}
        meta={meta}
        fetchParams={fetchParams}
        onDelete={this.onDelete}
        onSearch={this.onSearch}
        onChangePage={this.onChangePage}
        onChangeLimit={this.onChangeLimit}
        onSortBy={this.onSortBy}
        // tasks
        onOpenTaskForm={this.onOpenTaskForm}
        // timing
        onStartTiming={this.onStartTiming}
        onPauseTiming={this.onPauseTiming}
        // filters
        onClearFilters={this.onClearFilters}
        onChangeFilterStatus={this.onChangeFilterStatus}
        onChangeFilterTaskUsers={this.onChangeFilterTaskUsers}
        onChangeFilterUsers={this.onChangeFilterUsers}
        onChangeFilterSupervisorUsers={this.onChangeFilterSupervisorUsers}
        onChangeFilterPeriod={this.onChangeFilterPeriod}
        onChangeFilterFinishPeriod={this.onChangeFilterFinishPeriod}
        onChangeFilterTemplates={this.onChangeFilterTemplates}
        downloadReport={this.downloadReport}
        // notices
        unread={unread}
        notices={notices}
        onClearNotices={this.onClearNotices}
        // faqs
        onFaqToggle={this.onFaqToggle}
        // actions
        actions={actions}
        isTaskActionsLoading={isTaskActionsLoading}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.session.currentUser,
  isLoading: state.tasks.isLoading,
  tasks: state.tasks.tasks,
  meta: state.tasks.meta,
  fetchParams: state.tasks.fetchParams,
  notices: state.tasks.notices,
  unread: state.tasks.unread,
  // actions
  actions: state.tasks.actions,
  isTaskActionsLoading: state.tasks.isLoading,
});

export default connect(mapStateToProps)(Tasks);
