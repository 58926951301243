// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { List, Descriptions } from "antd";

class Info extends Component {
  render() {
    const { info } = this.props;

    return (
      <React.Fragment>
        <Descriptions title="Техническая информация" bordered>
          <Descriptions.Item label="location" span={3}>
            {info ? info.location : null}
          </Descriptions.Item>
          <Descriptions.Item label="userAgent" span={3}>
            {info ? info.userAgent : null}
          </Descriptions.Item>
        </Descriptions>
      </React.Fragment>
    );
  }
}
Info.propTypes = {
  entity: PropTypes.object,
  getFieldDecorator: PropTypes.func
};

export default Info;
