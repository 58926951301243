// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Row,
  Col,
  List,
  Card,
  Select,
  Form,
  Divider,
  Button,
  Avatar,
  Tooltip
} from "antd";

class User extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAddingAssignee: false,
      assignee: null
    };
  }

  render() {
    const { user } = this.props;

    return (
      <React.Fragment>
        <Divider>Инициатор заявки</Divider>
        <List>
          <List.Item>
            <List.Item.Meta
              avatar={<Avatar src={user ? user.avatar : null} />}
              title={user ? user.name : null}
              description={user ? user.title : null}
            />
            <div>{user ? user.department : null}</div>
          </List.Item>
        </List>
      </React.Fragment>
    );
  }
}
User.propTypes = {
  entity: PropTypes.object,
  getFieldDecorator: PropTypes.func
};

export default User;
