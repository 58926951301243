import api from "../api";

const Actions = {};

Actions.fetchCustomer = () => {
  return (dispatch, getState) => {
    dispatch({ type: "FETCH_CUSTOMER_REQUEST" });
    api
      .fetch(`/customer`)
      .then(response => {
        dispatch({ type: "FETCH_CUSTOMER", response });
      })
      .catch(response => {
        dispatch({ type: "FETCH_CUSTOMER_FAILURE", response });
      });
  };
};

Actions.onUpdate = () => {
  return (dispatch, getState) => {
    dispatch({ type: "PATCH_CUSTOMER_REQUEST" });
    const customer = getState().customer.customer;
    const data = {
      customer: {
        name: customer.name
      }
    };
    api
      .patch(`/customer`, data)
      .then(response => {
        dispatch({ type: "PATCH_CUSTOMER", response });
        api.openNotification("success", "Организация", "Успешно обновлена!");
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            return api.openNotification("error", "Организация", error);
          });
        }
        dispatch({ type: "PATCH_CUSTOMER_FAILURE", response });
      });
  };
};

Actions.onChangeName = value => {
  return dispatch => {
    dispatch({
      type: "CUSTOMER_CHANGE_NAME",
      value: value
    });
  };
};

export default Actions;
