// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, Button, Form, Card, Tabs, Menu, Dropdown } from "antd";
import Statuses from "./Statuses";
import EntityServices from "./EntityServices";
import EntityTasks from "./EntityTasks";
import Tickets from "./Tickets";
import Orders from "./Orders";
import Projects from "./Projects";
import General from "./General";
import Gallery from "./Gallery";
import Documents from "../Documents";
import ExternalDocuments from "../ExternalDocuments";
import Notes from "./Notes";

const { TabPane } = Tabs;

class EntityForm extends Component {
  constructor(props) {
    super(props);
  }

  onSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }
      this.props.onSave(values);
    });
  };

  callback = (key) => {
    if (key == "4" || key == "5") {
      this.props.fetchAttachments();
    }
  };

  onChangeTab = (key) => {
    if (key == "7") {
      this.props.fetchEntityTasks();
    }

    if (key == "8") {
      this.props.fetchTickets();
    }

    if (key == "9") {
      this.props.fetchOrders();
    }

    if (key == "10") {
      this.props.fetchProjects();
    }
  };

  // actions
  onAction = (actionId) => {
    const { actions, onOpenTaskForm } = this.props;
    let idx = actions.findIndex(function (o) {
      return o.id == actionId;
    });
    if (idx === -1) {
      return null;
    }
    onOpenTaskForm(actions[idx]);
  };

  ActionMenu = (actions) => {
    return (
      <Menu>
        {actions.map((action) => {
          return (
            <Menu.Item
              key={action.id}
              onClick={this.onAction.bind(this, action.id)}
            >
              {action.name}
            </Menu.Item>
          );
        })}
      </Menu>
    );
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      currentMember,
      isNew,
      isLoading,
      entity,
      onClose,
      // assignees
      isAssigneesLoading,
      onCreateAssignee,
      onDeleteAssignee,
      // attachments
      isAttachmentLoading,
      onSaveAttachment,
      onDeleteAttachment,
      onUploadAttachment,
      // entity_photos
      isLoadingImages,
      isUploadingImage,
      uploadPhoto,
      onDeletePhoto,
      // entity_schemas
      onDeleteEntitySchema,
      onUploadEntitySchema,
      //entity_services
      onSaveEntityService,
      // entity_tasks
      isEntityTasksLoading,
      entityTasks,
      // tickets
      isTicketsLoading,
      ticketFetchParams,
      onChangeTicketPage,
      onChangeTicketLimit,
      ticketMeta,
      tickets,
      onChangeTicketPeriod,
      onChangeTicketCategories,
      onChangeTicketServices,
      onDownloadTicketReport,
      // orders
      isOrdersLoading,
      orderFetchParams,
      onChangeOrderPage,
      onChangeOrderLimit,
      orderMeta,
      orders,
      onChangeOrderPeriod,
      onChangeOrderServices,
      onDownloadOrderReport,
      // projects
      isProjectsLoading,
      projects,
      // notes
      isEntityNotesLoading,
      onCreateNote,
      // actions
      actions,
    } = this.props;

    let organisationAssignees = [];
    let supervisorAssignees = [];
    let customerAssignees = [];

    if (entity.assignees) {
      organisationAssignees = isLoading
        ? []
        : entity.assignees.filter(function (item) {
            return item.assignee_type == "organisation";
          });
    }

    if (entity.assignees) {
      supervisorAssignees = entity.assignees.filter(function (item) {
        return item.assignee_type == "supervisor";
      });
    }

    if (entity.assignees) {
      customerAssignees = entity.assignees.filter(function (item) {
        return item.assignee_type == "customer";
      });
    }

    const internalAttachments = entity.attachments.filter(function (item) {
      return item.storage_type == "internal";
    });

    const externalAttachments = entity.attachments.filter(function (item) {
      return item.storage_type == "external";
    });

    // actions
    let actionsButton = null;

    if (actions.length > 0) {
      actionsButton = (
        <Dropdown key={99} overlay={this.ActionMenu(actions)}>
          <Button icon={"more"} style={{ marginLeft: "8px" }} />
        </Dropdown>
      );
    }

    return (
      <Form layout={"vertical"} onSubmit={this.onSubmit}>
        <Card
          loading={isLoading}
          title={
            <h2 style={{ marginBottom: 0 }}>{`Объект #${
              isNew ? "новый" : entity.name
            }`}</h2>
          }
          extra={[<Button key={100} onClick={onClose}>Закрыть</Button>, actionsButton]}
        >
          <Row gutter={16}>
            <Col xs={24} sm={24} md={24} lg={13} xl={13}>
              <Tabs defaultActiveKey="1" onChange={this.callback}>
                <TabPane tab="Основное" key="1">
                  <General
                    getFieldDecorator={getFieldDecorator}
                    entity={entity}
                  />
                </TabPane>

                <TabPane tab="Изображения" key="2">
                  {isLoadingImages ? null : (
                    <Gallery
                      isLoadingImages={isLoadingImages}
                      isUploadingImage={isUploadingImage}
                      images={entity.entity_photos}
                      uploadPhoto={uploadPhoto}
                      onDeletePhoto={onDeletePhoto}
                    />
                  )}
                </TabPane>

                <TabPane tab="Схемы" key="3">
                  <Gallery
                    isLoadingImages={isLoadingImages}
                    isUploadingImage={isUploadingImage}
                    images={entity.entity_schemas}
                    uploadPhoto={onUploadEntitySchema}
                    onDeletePhoto={onDeleteEntitySchema}
                  />
                </TabPane>
                <TabPane tab="Файлы" key="4">
                  {isLoadingImages ? null : (
                    <Documents
                      currentUser={currentMember}
                      isLoading={isAttachmentLoading}
                      attachments={internalAttachments}
                      onUpload={onUploadAttachment}
                      onDelete={onDeleteAttachment}
                    />
                  )}
                </TabPane>
                <TabPane tab="Ссылки" key="5">
                  {isLoadingImages ? null : (
                    <ExternalDocuments
                      currentUser={currentMember}
                      isLoading={isAttachmentLoading}
                      attachments={externalAttachments}
                      onSave={onSaveAttachment}
                      onUpload={onUploadAttachment}
                      onDelete={onDeleteAttachment}
                    />
                  )}
                </TabPane>
              </Tabs>
            </Col>

            <Col xs={0} sm={0} md={0} lg={1} xl={1} />
            <Col xs={24} sm={24} md={24} lg={10} xl={10}>
              <Row>
                <Statuses
                  getFieldDecorator={getFieldDecorator}
                  customer={entity.customer}
                  // assignees
                  organisationAssignees={organisationAssignees}
                  supervisorAssignees={supervisorAssignees}
                  customerAssignees={customerAssignees}
                  isAssigneesLoading={isAssigneesLoading}
                  onDeleteAssignee={onDeleteAssignee}
                  onCreateAssignee={onCreateAssignee}
                />
              </Row>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: "15px" }}>
            <Col span={24}>
              <Tabs defaultActiveKey="6" onChange={this.onChangeTab}>
                <TabPane tab="Разделы" key="6">
                  <EntityServices
                    entity={entity}
                    entityServices={entity.entity_services}
                    onSaveEntityService={onSaveEntityService}
                  />
                </TabPane>
                <TabPane tab="Регламент" key="7">
                  <EntityTasks
                    isLoading={isEntityTasksLoading}
                    entityTasks={entityTasks}
                  />
                </TabPane>
                <TabPane tab="Заявки" key="8">
                  <Tickets
                    isLoading={isTicketsLoading}
                    fetchParams={ticketFetchParams}
                    onChangePage={onChangeTicketPage}
                    onChangeLimit={onChangeTicketLimit}
                    meta={ticketMeta}
                    tickets={tickets}
                    onChangePeriod={onChangeTicketPeriod}
                    onChangeCategories={onChangeTicketCategories}
                    onChangeServices={onChangeTicketServices}
                    onDownloadReport={onDownloadTicketReport}
                  />
                </TabPane>
                <TabPane tab="Заказы" key="9">
                  <Orders
                    isLoading={isOrdersLoading}
                    fetchParams={orderFetchParams}
                    onChangePage={onChangeOrderPage}
                    onChangeLimit={onChangeOrderLimit}
                    meta={orderMeta}
                    orders={orders}
                    onChangePeriod={onChangeOrderPeriod}
                    onChangeServices={onChangeOrderServices}
                    onDownloadReport={onDownloadOrderReport}
                  />
                </TabPane>
                <TabPane tab="Проекты" key="10">
                  <Projects isLoading={isProjectsLoading} projects={projects} />
                </TabPane>
                {isNew ? null : (
                  <TabPane tab="Примечания" key="11">
                    <Notes
                      currentUser={currentMember}
                      isLoading={isEntityNotesLoading}
                      notes={entity.entity_notes}
                      onCreateNote={onCreateNote}
                    />
                  </TabPane>
                )}
              </Tabs>
            </Col>
          </Row>
        </Card>
      </Form>
    );
  }
}

EntityForm.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  entity: PropTypes.object,
  onSave: PropTypes.func,
  isLoading: PropTypes.bool,
  router: PropTypes.object,
  getFieldDecorator: PropTypes.func,
  form: PropTypes.object,
  fetchSuggestions: PropTypes.func,
  onChangeDate: PropTypes.func,
  onChangeUser: PropTypes.func,
  onChangeAgreement: PropTypes.func,
  onChangeAgreementDate: PropTypes.func,
  onChangeAdditional: PropTypes.func,
  onChangeComment: PropTypes.func,
  onChangeNotice: PropTypes.func,
  onChangePaymentType: PropTypes.func,
  onChangePaymentStatus: PropTypes.func,
  onChangeShippingStatus: PropTypes.func,
  onChangeShippingMethod: PropTypes.func,
  onAddVariant: PropTypes.func,
  onRemoveVariant: PropTypes.func,
  onChangeQuantity: PropTypes.func,
  onChangePrice: PropTypes.func,
};

export default Form.create()(EntityForm);
