// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import TaskActions from "../../../actions/tasks";
import Actions from "../../../actions/entities";
import { Spin } from "antd";
import EntityForm from "./Form";

class Entity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNew: false,
    };
  }

  componentWillMount = () => {
    this.props.dispatch(Actions.fetchEntity(this.props.match.params.id)).then(() => {
      this.props.dispatch(
        TaskActions.fetchTaskActions({ taskOwners: [6] })
      );
    });
  };

  onClose = () => {
    this.props.history.push(`/entities`);
  };

  // attachments
  fetchAttachments = () => {
    this.props.dispatch(Actions.fetchAttachments(this.props.match.params.id));
  };

  onSaveAttachment = (data) => {
    // console.log(data)
    this.props.dispatch(
      Actions.onSaveAttachment(this.props.match.params.id, data)
    );
  };

  onDeleteAttachment = (id) => {
    this.props
      .dispatch(Actions.onDeleteAttachment(this.props.match.params.id, id))
      .then(() => {
        if (!this.props.errors) {
          this.props.dispatch(
            Actions.fetchAttachments(this.props.match.params.id)
          );
        }
      });
  };

  onUploadAttachment = (file) => {
    this.props
      .dispatch(Actions.onUploadAttachment(this.props.match.params.id, file))
      .then(() => {
        if (!this.props.errors) {
          this.props.dispatch(
            Actions.fetchAttachments(this.props.match.params.id)
          );
        }
      });
  };

  // entity_photos
  uploadPhoto = (file) => {
    this.props.dispatch(Actions.uploadPhoto(this.props.entity.id, file));
  };

  onDeletePhoto = (item) => {
    this.props.dispatch(Actions.onDeletePhoto(this.props.entity.id, item.uid));
  };

  // entity_schemas
  onDeleteEntitySchema = (item) => {
    this.props.dispatch(
      Actions.onDeleteEntitySchema(this.props.entity.id, item.uid)
    );
  };

  onUploadEntitySchema = (file) => {
    this.props.dispatch(
      Actions.onUploadEntitySchema(this.props.entity.id, file)
    );
  };

  // assignees
  onCreateAssignee = (value, type) => {
    this.props
      .dispatch(
        Actions.onCreateAssignee(this.props.match.params.id, value, type)
      )
      .then(() => {
        if (!this.props.errors) {
          this.props.dispatch(
            Actions.fetchAssignees(this.props.match.params.id)
          );
        }
      });
  };

  onDeleteAssignee = (id) => {
    this.props
      .dispatch(Actions.onDeleteAssignee(this.props.match.params.id, id))
      .then(() => {
        if (!this.props.errors) {
          this.props.dispatch(
            Actions.fetchAssignees(this.props.match.params.id)
          );
        }
      });
  };

  // entity_tasks
  fetchEntityTasks = () => {
    this.props.dispatch(Actions.fetchEntityTasks(this.props.entity.id));
  };

  // tickets

  fetchTickets = () => {
    this.props.dispatch(Actions.fetchTickets(this.props.entity.id));
  };

  onChangeTicketPage = (value) => {
    this.props.dispatch(Actions.onChangeTicketPage(value))
      .then(() => this.props.dispatch(Actions.fetchTickets(this.props.entity.id)));
  };

  onChangeTicketLimit = (current, size) => {
    this.props.dispatch(Actions.onChangeTicketLimit(size))
      .then(() => this.props.dispatch(Actions.fetchTickets(this.props.entity.id)));
  };

  onChangeTicketCategories = (values) => {
    this.props.dispatch(Actions.onChangeTicketCategories(values))
      .then(() => this.props.dispatch(Actions.fetchTickets(this.props.entity.id)));
  };

  onChangeTicketServices = (values) => {
    this.props.dispatch(Actions.onChangeTicketServices(values))
      .then(() => this.props.dispatch(Actions.fetchTickets(this.props.entity.id)));
  };

  onChangeTicketPeriod = (value, dateString) => {
    this.props.dispatch(Actions.onChangeTicketPeriod({ startDate: dateString[0], endDate: dateString[1] }))
      .then(() => this.props.dispatch(Actions.fetchTickets(this.props.entity.id)));
  };

  onDownloadTicketReport = () => {
    this.props.dispatch(Actions.onDownloadTicketReport(this.props.entity.id))
  }

  // orders

  fetchOrders = () => {
    this.props.dispatch(Actions.fetchOrders(this.props.entity.id));
  };

  onChangeOrderPage = (value) => {
    this.props.dispatch(Actions.onChangeOrderPage(value))
      .then(() => this.props.dispatch(Actions.fetchOrders(this.props.entity.id)));
  };

  onChangeOrderLimit = (current, size) => {
    this.props.dispatch(Actions.onChangeOrderLimit(size))
      .then(() => this.props.dispatch(Actions.fetchOrders(this.props.entity.id)));
  };

  onChangeOrderServices = (values) => {
    this.props.dispatch(Actions.onChangeOrderServices(values))
      .then(() => this.props.dispatch(Actions.fetchOrders(this.props.entity.id)));
  };

  onChangeOrderPeriod = (value, dateString) => {
    this.props.dispatch(Actions.onChangeOrderPeriod({ startDate: dateString[0], endDate: dateString[1] }))
      .then(() => this.props.dispatch(Actions.fetchOrders(this.props.entity.id)));
  };

  onDownloadOrderReport = () => {
    this.props.dispatch(Actions.onDownloadOrderReport(this.props.entity.id))
  }

  // projects
  fetchProjects = () => {
    this.props.dispatch(Actions.fetchProjects(this.props.entity.id));
  };

  // notes
  onCreateNote = (value) => {
    this.props
      .dispatch(Actions.onCreateNote(this.props.match.params.id, value))
      .then(() => {
        if (!this.props.errors) {
          this.props.dispatch(
            Actions.fetchEntityNotes(this.props.match.params.id)
          );
        }
      });
  };

  // actions
  onOpenTaskForm = (action) => {
    const { entity } = this.props;
    this.props.dispatch(TaskActions.fetchNew(action.id, entity.id));
  };

  render() {
    const {
      currentMember,
      isLoading,
      isAssigneesLoading,
      isLoadingImages,
      isUploadingImage,
      entity,
      isAttachmentLoading,
      // entity_tasks
      isEntityTasksLoading,
      entityTasks,
      // tickets
      isTicketsLoading,
      ticketFetchParams,
      ticketMeta,
      tickets,
      // orders
      isOrdersLoading,
      orderFetchParams,
      orderMeta,
      orders,
      // projects
      isProjectsLoading,
      projects,
      // notes
      isEntityNotesLoading,
      // actions
      actions,
      isTaskActionsLoading,
    } = this.props;

    return isLoading ? (
      <Spin />
    ) : (
      <EntityForm
        currentMember={currentMember}
        isNew={this.state.isNew}
        entity={entity}
        onSave={this.onSave}
        onClose={this.onClose}
        // statuses
        isAssigneesLoading={isAssigneesLoading}
        onDeleteAssignee={this.onDeleteAssignee}
        //entity_services
        onSaveEntityService={this.onSaveEntityService}
        isLoadingImages={isLoadingImages}
        isUploadingImage={isUploadingImage}
        // attachments
        isAttachmentLoading={isAttachmentLoading}
        onSaveAttachment={this.onSaveAttachment}
        fetchAttachments={this.fetchAttachments}
        onDeleteAttachment={this.onDeleteAttachment}
        onUploadAttachment={this.onUploadAttachment}
        // entity_photos
        isLoadingImages={isLoadingImages}
        isUploadingImage={isUploadingImage}
        uploadPhoto={this.uploadPhoto}
        onDeletePhoto={this.onDeletePhoto}
        // entity_schemas
        onDeleteEntitySchema={this.onDeleteEntitySchema}
        onUploadEntitySchema={this.onUploadEntitySchema}
        // assignees
        onCreateAssignee={this.onCreateAssignee}
        onDeleteAssignee={this.onDeleteAssignee}
        // entity_tasks
        isEntityTasksLoading={isEntityTasksLoading}
        entityTasks={entityTasks}
        fetchEntityTasks={this.fetchEntityTasks}
        // tickets
        isTicketsLoading={isTicketsLoading}
        ticketFetchParams={ticketFetchParams}
        onChangeTicketPage={this.onChangeTicketPage}
        onChangeTicketLimit={this.onChangeTicketLimit}
        ticketMeta={ticketMeta}
        tickets={tickets}
        fetchTickets={this.fetchTickets}
        onChangeTicketPeriod={this.onChangeTicketPeriod}
        onChangeTicketCategories={this.onChangeTicketCategories}
        onChangeTicketServices={this.onChangeTicketServices}
        onDownloadTicketReport={this.onDownloadTicketReport}
        // orders
        isOrdersLoading={isOrdersLoading}
        orderFetchParams={orderFetchParams}
        onChangeOrderPage={this.onChangeOrderPage}
        onChangeOrderLimit={this.onChangeOrderLimit}
        orderMeta={orderMeta}
        orders={orders}
        fetchOrders={this.fetchOrders}
        onChangeOrderPeriod={this.onChangeOrderPeriod}
        onChangeOrderServices={this.onChangeOrderServices}
        onDownloadOrderReport={this.onDownloadOrderReport}
        // projects
        isProjectsLoading={isProjectsLoading}
        projects={projects}
        fetchProjects={this.fetchProjects}
        // notes
        isEntityNotesLoading={isEntityNotesLoading}
        onCreateNote={this.onCreateNote}
        // actions
        actions={actions}
        isTaskActionsLoading={isTaskActionsLoading}
        onOpenTaskForm={this.onOpenTaskForm}
      />
    );
  }
}

Entity.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  entity: PropTypes.object,
  isLoading: PropTypes.bool,
  errors: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  currentMember: state.session.currentMember,
  isLoading: state.entities.isLoading,
  errors: state.entities.errors,
  entity: state.entities.entity,
  isAttachmentLoading: state.entities.isAttachmentLoading,
  isLoadingImages: state.entities.isLoadingImages,
  isUploadingImage: state.entities.isUploadingImage,
  isAssigneesLoading: state.entities.isAssigneesLoading,
  // entity_tasks
  isEntityTasksLoading: state.entities.isEntityTasksLoading,
  entityTasks: state.entities.entityTasks,
  // tickets
  isTicketsLoading: state.entities.isTicketsLoading,
  ticketFetchParams: state.entities.ticketFetchParams,
  ticketMeta: state.entities.ticketMeta,
  tickets: state.entities.tickets,
  // orders
  isOrdersLoading: state.entities.isOrdersLoading,
  orderFetchParams: state.entities.orderFetchParams,
  orderMeta: state.entities.orderMeta,
  orders: state.entities.orders,
  // projects
  isProjectsLoading: state.entities.isProjectsLoading,
  projects: state.entities.projects,
  // notes
  isEntityNotesLoading: state.entities.isEntityNotesLoading,
  // actions
  actions: state.tasks.actions,
  isTaskActionsLoading: state.tasks.isLoading,
});

export default connect(mapStateToProps)(withRouter(Entity));
