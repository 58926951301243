// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, Card, Form, Select, Typography, Descriptions } from "antd";

import User from "./User";
import Assignees from "./Assignees";
import Documents from "./Documents";
import moment from "moment";
import "moment/locale/ru";

const { Paragraph } = Typography;
const FormItem = Form.Item;
const Option = Select.Option;

class Statuses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAddingAssignee: false,
      assignee: null,
    };
  }

  onUpload = ({ onSuccess, onError, file }) => {
    this.props.onUploadProposal(file);
  };

  render() {
    const {
      isNew,
      due_by,
      user,
      member,
      customer,
      priority_locale,
      currentUser,
      getFieldDecorator,
      organisationAssignees,
      customerAssignees,
      isAssigneesLoading,
      onCreateAssignee,
      onDeleteAssignee,
      onChangePriority,
      // numbers
      number,
      onChangeNumber,
      number_int,
      status_locale,
      // proposals
      isLoadingProposals,
      attachments,
      onDeleteProposal,
    } = this.props;

    return (
      <Card style={{ borderColor: "#1890ff" }}>
        {isNew ? (
          <Row gutter={10}>
            <Col span={24}>
              <FormItem label={"Приоритет"}>
                {getFieldDecorator("priority", {
                  onChange: onChangePriority,
                  initialValue: null
                })(
                  <Select
                    optionFilterProp="children"
                    filterOption={false}
                    placeholder="Приоритет"
                  >
                    <Option value={"low"}>Низкий</Option>
                    <Option value={"medium"}>Средний</Option>
                    <Option value={"high"}>Высокий</Option>
                  </Select>
                )}
              </FormItem>
            </Col>
          </Row>
        ) : null }

        {isNew ? null : (
          <Row gutter={10}>
            <Col span={24}>
              <Descriptions bordered>
                <Descriptions.Item label="Плановые дата и время" span={3}>
                  {due_by ? moment(due_by).format("lll") : null}
                </Descriptions.Item>
                <Descriptions.Item label="Номер" span={3}>
                  <Paragraph editable={{ onChange: onChangeNumber }}>
                    {number}
                  </Paragraph>
                </Descriptions.Item>
                <Descriptions.Item label="Номер КП" span={3}>
                  {number_int}
                </Descriptions.Item>
                <Descriptions.Item label="Статус" span={3}>
                  {status_locale}
                </Descriptions.Item>
                <Descriptions.Item label="Приоритет" span={3}>
                  {priority_locale}
                </Descriptions.Item>
              </Descriptions>
            </Col>
          </Row>
        )}
        <Row gutter={10}>
          <Col span={24}>
            <User user={user ? user : member} />
          </Col>
        </Row>
        <Row gutter={10}>
          <Col span={24}>
            <Assignees
              isReadOnly={true}
              isAssigneesLoading={isAssigneesLoading}
              assigneeType="organisation"
              customer={customer}
              assignees={organisationAssignees}
              onCreateAssignee={onCreateAssignee}
              onDeleteAssignee={onDeleteAssignee}
            />
          </Col>
        </Row>
        <Row gutter={10}>
          <Col span={24}>
            <Assignees
              title={"От заказчика"}
              isReadOnly={isNew ? true : false}
              isAssigneesLoading={isAssigneesLoading}
              assigneeType="customer"
              customer={customer}
              assignees={customerAssignees}
              onCreateAssignee={onCreateAssignee}
              onDeleteAssignee={onDeleteAssignee}
            />
          </Col>
        </Row>
        <Row gutter={10}>
          {isNew ? null : (
            <Documents
              isNew={isNew}
              onUpload={this.onUpload}
              isLoading={isLoadingProposals}
              attachments={attachments}
              onDeleteAttachment={onDeleteProposal}
            />
          )}
        </Row>
      </Card>
    );
  }
}
Statuses.propTypes = {
  entity: PropTypes.object,
  getFieldDecorator: PropTypes.func,
};

export default Statuses;
