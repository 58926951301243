/// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Actions from "../../actions/session";
import SettingsActions from "../../actions/settings";

import Dashboard from "../Dashboard";
import MainLayout from "../Layout";
import NotFound from "../../components/NotFound";
import Login from "../Login";
import Recovery from "../Recovery";
import Reset from "../Reset";
import Profile from "../Profile";

import Tickets from "../Tickets/List";
import Ticket from "../Tickets/Form";

import Entities from "../Entities/List";
import Entity from "../Entities/Form";
import EntitiesMap from "../Entities/EntitiesMap";

import EntityTasks from "../EntityTasks/List";
import EntityTask from "../EntityTasks/Form";

import Customer from "../Customer";

import Orders from "../Orders/List";
import Order from "../Orders/Form";

import Projects from "../Projects/List";
import Project from "../Projects/Form";

import OrderTasks from "../OrderTasks/List";
import OrderTask from "../OrderTasks/Form";

import SupportMessages from "../SupportMessages/List";
import SupportMessage from "../SupportMessages/Form";
import SupportMessageView from "../SupportMessages/View";

import Posts from "../Posts/List";
// import Post from "../Posts/Form";

import Notices from "../Notices/List";
import Faqs from "../Faqs/List";
import News from "../News/List";

// reports
import Plan from "../Reports/Plan";
import Daily from "../Reports/Daily";
import Employees from "../Reports/Employees";
import EmployeeRates from "../Reports/EmployeeRates";

// rates
import Rate from "../Rates";
import Finish from "../Rates/Finish";

// tasks
import Tasks from "../Tasks/List";
import Task from "../Tasks/Form";

class App extends Component {
  componentDidMount() {
    const token = localStorage.getItem("token");
    if (token) {
      this.props.dispatch(Actions.authenticate()).then(() => {
        this.props.dispatch(SettingsActions.fetchSettings());
      });
    } else {
      this.props.dispatch(Actions.unauthenticate());
    }
  }

  renderRoutes = (isAuthenticated) => {
    const PrivateRoute = ({ component: Component, ...rest }) => (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: "/auth/login",
                state: { from: props.location },
              }}
            />
          )
        }
      />
    );

    return (
      <Router>
        <Switch>
          <Route path="/auth/login" component={Login} />
          <Route path="/auth/recovery" component={Recovery} />
          <Route path="/auth/reset/:token" component={Reset} />
          <Route path="/rates/finish" component={Finish} exact />
          <Route path="/rates/:id" component={Rate} exact />
          {/* <Route path="/rates/finish" component={Finish} exact /> */}
          <MainLayout>
            <Switch>
              <PrivateRoute
                isAuthenticated
                path="/"
                exact
                component={Dashboard}
              />

              <PrivateRoute
                isAuthenticated
                path="/customer"
                component={Customer}
              />

              <PrivateRoute
                isAuthenticated
                path={`/employees`}
                component={Employees}
              />

              <PrivateRoute
                isAuthenticated
                path="/profile"
                component={Profile}
              />

              <PrivateRoute
                isAuthenticated
                path="/tickets"
                exact
                component={Tickets}
              />

              <PrivateRoute
                isAuthenticated
                path="/tickets/new"
                exact
                component={Ticket}
              />
              <PrivateRoute
                isAuthenticated
                path="/tickets/:id/edit"
                exact
                component={Ticket}
              />

              <PrivateRoute
                isAuthenticated
                path="/entities/map"
                exact
                component={EntitiesMap}
              />

              <PrivateRoute
                isAuthenticated
                path="/entities"
                exact
                component={Entities}
              />

              <PrivateRoute
                isAuthenticated
                path="/entities/new"
                exact
                component={Entity}
              />
              <PrivateRoute
                isAuthenticated
                path="/entities/:id/edit"
                exact
                component={Entity}
              />

              <PrivateRoute
                isAuthenticated
                path="/entity_tasks"
                exact
                component={EntityTasks}
              />

              <PrivateRoute
                isAuthenticated
                path="/entity_tasks/new"
                exact
                component={EntityTask}
              />

              <PrivateRoute
                isAuthenticated
                path="/entity_tasks/:id/edit"
                exact
                component={EntityTask}
              />

              <PrivateRoute
                isAuthenticated
                path="/orders"
                exact
                component={Orders}
              />

              <PrivateRoute
                isAuthenticated
                path="/orders/new"
                exact
                component={Order}
              />

              <PrivateRoute
                isAuthenticated
                path="/orders/:id/edit"
                exact
                component={Order}
              />

              <PrivateRoute
                isAuthenticated
                path="/order_tasks"
                exact
                component={OrderTasks}
              />

              <PrivateRoute
                isAuthenticated
                path="/order_tasks/new"
                exact
                component={OrderTask}
              />

              <PrivateRoute
                isAuthenticated
                path="/order_tasks/:id/edit"
                exact
                component={OrderTask}
              />

              <PrivateRoute
                isAuthenticated
                path="/projects"
                exact
                component={Projects}
              />

              <PrivateRoute
                isAuthenticated
                path="/projects/:id/edit"
                exact
                component={Project}
              />

              <PrivateRoute
                isAuthenticated
                path="/support_messages"
                exact
                component={SupportMessages}
              />

              <PrivateRoute
                isAuthenticated
                path="/support_messages/new"
                exact
                component={SupportMessage}
              />

              <PrivateRoute
                isAuthenticated
                path="/support_messages/:id/edit"
                exact
                component={SupportMessage}
              />

              <PrivateRoute
                isAuthenticated
                path="/support_messages/:id"
                exact
                component={SupportMessageView}
              />

              <PrivateRoute
                isAuthenticated
                path="/posts"
                exact
                component={Posts}
              />

              {/* <PrivateRoute
                isAuthenticated
                path="/posts/new"
                exact
                component={Post}
              />

              <PrivateRoute
                isAuthenticated
                path="/posts/:id/edit"
                exact
                component={Post}
              /> */}

              <PrivateRoute
                isAuthenticated
                path="/tasks"
                exact
                component={Tasks}
              />

              <PrivateRoute
                isAuthenticated
                path="/tasks/:id/edit"
                exact
                component={Task}
              />

              <PrivateRoute
                isAuthenticated
                path="/faqs"
                exact
                component={Faqs}
              />

              <PrivateRoute
                isAuthenticated
                path="/news"
                exact
                component={News}
              />

              <PrivateRoute
                isAuthenticated
                path="/notices"
                exact
                component={Notices}
              />

              <Route
                path="/reports"
                render={({ match: { url } }) => (
                  <>
                    <PrivateRoute
                      isAuthenticated
                      path={`${url}/plan`}
                      component={Plan}
                    />
                    <PrivateRoute
                      isAuthenticated
                      path={`${url}/daily`}
                      component={Daily}
                    />
                    <PrivateRoute
                      isAuthenticated
                      path={`${url}/employee_rates`}
                      component={EmployeeRates}
                    />
                  </>
                )}
              />
              <Route component={NotFound} />
            </Switch>
          </MainLayout>
        </Switch>
      </Router>
    );
  };

  render() {
    return (
      <div>
        {this.props.isLoading
          ? null
          : this.renderRoutes(this.props.isAuthenticated)}
      </div>
    );
  }
}

App.propTypes = {
  dispatch: PropTypes.func,
  isAuthenticated: PropTypes.bool.isRequired,
  currentMember: PropTypes.object,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.session.isAuthenticated,
  isLoading: state.session.isLoading,
});
export default connect(mapStateToProps)(App);
