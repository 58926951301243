// @flow
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import {
  Table,
  Button,
  PageHeader,
  Badge,
  Select,
  Icon,
  Typography,
  Tag,
  Progress,
  Popover,
} from "antd";
import { Pagination } from "antd";
import { Row, Col, Input } from "antd";

import PropTypes from "prop-types";
import AssigneesCell from "../../../components/AssigneesCell";
import OrderProgress from "../../../components/OrderProgress";
import NoticeIcon from "../../../components/NoticeIcon";

import moment from "moment";
import "moment/locale/ru";
import Filter from "./Filter";

const Search = Input.Search;
const { Paragraph } = Typography;

function serviceTag(entity_service) {
  return (
    <Tag key={entity_service.id} color="#108ee9">
      {entity_service.name}
    </Tag>
  );
}

class OrdersList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [],
      orderProgress: false,
      isFilterVisible: false,
    };
  }

  onFilterToggle = () => {
    this.setState({ isFilterVisible: !this.state.isFilterVisible });
  };

  onChangeTable = (pagination, filters, sorter) => {
    let direction = "desc";
    if (sorter.order === "descend") {
      direction = "desc";
    } else if (sorter.order === "ascend") {
      direction = "asc";
    }
    this.props.onSortBy(sorter.field, direction);
  };

  onOrderProgressChange = (id, visible) => {
    if (visible) {
      this.props.onOrderProgress(id);
    }
  };

  onItemClick = (item) => {
    this.props.history.push(`/orders/${item.parent_id}/edit`);
  };

  onClearNotices = () => {
    this.props.onClearNotices();
  };

  render() {
    const { isFilterVisible } = this.state;
    const {
      loading,
      orders,
      meta,
      fetchParams,
      notices,
      onSearch,
      onChangePage,
      onChangeLimit,
      onSortBy,
      // filters
      onClearFilters,
      onChangeFilterStatus,
      onChangeFilterPeriod,
      onChangeFilterUsers,
      onChangeFilterMembers,
    } = this.props;

    const columns = [
      {
        title: "Выполнение",
        dataIndex: "status",
        render: (status, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>
              <OrderProgress orderId={record.id} progress={record.progress} />
            </span>
            <span style={{ opacity: "0.7" }}>{record.status_locale}</span>
          </div>
        ),
        key: "status",
        align: "center",
        width: "10%",
      },
      {
        title: "Номер / Номер КП",
        dataIndex: "number",
        render: (number, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>
              <Link to={`/orders/${record.id}/edit`}>{number}</Link>
            </span>
            <span style={{ opacity: "0.7" }}>{record.number_int}</span>
          </div>
        ),
        key: "number",
        align: "center",
        width: "10%",
      },
      {
        title: "Дата",
        dataIndex: "created_at",
        render: (created_at) => moment(created_at).format("L"),
        sorter: true,
        sortDirections: ["ascend", "descend"],
        key: "created_at",
        align: "center",
        width: "15%",
      },
      {
        title: "Объект",
        dataIndex: "entity",
        render: (entity, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>
              {<Link to={`/entities/${entity.id}/edit`}>{entity.name}</Link>}
            </span>
            <span style={{ opacity: "0.7" }}>{entity.address}</span>
          </div>
        ),
        align: "left",
        width: "25%",
      },
      {
        title: "Тема / Раздел",
        dataIndex: "subject",
        render: (subject, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Paragraph ellipsis={{ rows: 3, expandable: true }}></Paragraph>
            <span>
              <Paragraph ellipsis={{ rows: 3, expandable: true }}>
                {subject}
              </Paragraph>
            </span>
            <span>
              {serviceTag(record.entity_service)}
              {/* {record.entity_service ? record.entity_service.name : null} */}
            </span>
          </div>
        ),
        align: "left",
        width: "25%",
      },
      {
        title: "Ответственный",
        dataIndex: "assignees",
        render: (assignees, record) => <AssigneesCell assignees={assignees} />,
        align: "center",
        width: "15%",
      },
    ];

    const faqButton = (
      <Button
        type={"ghost"}
        key="7"
        icon="question"
        onClick={this.props.onFaqToggle}
      />
    );

    let mainButton = (
      <Link
        key="2"
        className={"ant-btn ant-btn-primary ant-btn-background-ghost"}
        to={{ pathname: `/orders/new` }}
      >
        Создать
      </Link>
    );

    let filterButton = (
      <Button
        type={
          fetchParams.startDate ||
          fetchParams.status ||
          fetchParams.users ||
          fetchParams.members
            ? "primary"
            : "ghost"
        }
        key="3"
        icon="filter"
        onClick={this.onFilterToggle}
      />
    );

    const unread = notices.filter(function (item) {
      return item.read == false;
    });

    const noticeIcon = (
      <NoticeIcon
        key="5"
        count={unread.length}
        onItemClick={this.onItemClick}
        list={notices}
        showClear
        onClear={this.onClearNotices}
        emptyText={"новых комментариев нет"}
      />
    );

    return (
      <PageHeader
        className="page-header-with-notices"
        onBack={() => window.history.back()}
        title={`Заказы`}
        extra={[
          faqButton,
          noticeIcon,
          <Search
            key="4"
            placeholder="поиск..."
            value={fetchParams.search}
            onChange={onSearch}
            style={{ width: 250 }}
          />,
          mainButton,
          filterButton,
        ]}
        footer={
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col span={24}>
              <Table
                // bordered
                size={"middle"}
                loading={loading}
                pagination={false}
                columns={columns}
                dataSource={orders}
                onChange={this.onChangeTable}
                rowKey="id"
              />
            </Col>
            <Col
              span={24}
              style={{ paddingTop: "15px", paddingBottom: "15px" }}
            >
              <Pagination
                showSizeChanger
                pageSize={fetchParams.limit}
                current={meta.current_page}
                onChange={onChangePage}
                onShowSizeChange={onChangeLimit}
                total={meta.total_count}
              />
            </Col>
          </Row>
        }
      >
        <Filter
          visible={isFilterVisible}
          status={fetchParams.status}
          startDate={fetchParams.startDate}
          endDate={fetchParams.endDate}
          users={fetchParams.users}
          members={fetchParams.members}
          onClose={this.onFilterToggle}
          onClearFilters={onClearFilters}
          onChangeStatus={onChangeFilterStatus}
          onChangePeriod={onChangeFilterPeriod}
          onChangeUsers={onChangeFilterUsers}
          onChangeMembers={onChangeFilterMembers}
        />
      </PageHeader>
    );
  }
}

OrdersList.propTypes = {
  orders: PropTypes.arrayOf(PropTypes.object),
  meta: PropTypes.object,
  fetchParams: PropTypes.object,
};

export default withRouter(OrdersList);
