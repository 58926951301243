// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  List,
  Avatar,
  Popover,
  Tooltip,
  Button,
  Row,
  Col,
  Upload,
  Divider
} from "antd";
import moment from "moment";
import "moment/locale/ru";
import canCheck from "../../rules/canCheck";

function fileLink(url, name, target = "_self") {
  return (
    <a href={url} target={target} download={"123"}>
      {name}
    </a>
  );
}

function contentSet(item) {
  switch (item.content_type) {
    case "application/pdf":
      return {
        icon: "file-pdf",
        color: "red",
        target: "_self"
      };
    case "image/png":
      return {
        icon: "file-image",
        color: "brown",
        target: "_blank"
      };
    case "image/jpeg":
      return {
        icon: "file-image",
        color: "brown",
        target: "_blank"
      };
    case "application/msword":
      return {
        icon: "file-word",
        color: "cornflowerblue",
        target: "_self"
      };
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      return {
        icon: "file-word",
        color: "cornflowerblue",
        target: "_self"
      };
    case "application/vnd.ms-excel":
      return {
        icon: "file-excel",
        color: "green",
        target: "_self"
      };
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      return {
        icon: "file-excel",
        color: "green",
        target: "_self"
      };
    default:
      return {
        icon: "file-unknown",
        color: "brown",
        target: "_self"
      };
  }
}

class Attachments extends Component {
  static defaultProps = {
    title: "Файлы",
    isReadOnly: false
  };

  onUpload = ({ onSuccess, onError, file }) => {
    this.props.onUploadAttachment(file);
  };

  render() {
    const {
      currentUser,
      isUploading,
      attachments,
      onDeleteAttachment,
      title,
      isReadOnly
    } = this.props;

    return (
      <Row gutter={{ md: 8, lg: 24, xl: 48 }} style={{ marginBottom: "10px" }}>
        <Divider>
          {title}{" "}
          {isReadOnly ? null : (
            <Tooltip placement="bottom" title={"Загрузить файл"}>
              <Upload customRequest={this.onUpload} showUploadList={false}>
                <Button type="dashed" shape="circle" icon="plus" />
              </Upload>
            </Tooltip>
          )}
        </Divider>
        <Col span={24}>
          <List
            loading={isUploading}
            dataSource={attachments}
            renderItem={item => {
              let canDelete = canCheck(currentUser, "documents:delete", {
                memberId: currentUser.member_id,
                ownerId: item.member_id
              });
              let fileAttributes = contentSet(item);
              return (
                <List.Item
                  actions={[
                    canDelete ? (
                      <Tooltip placement="bottom" title={"Удалить"}>
                        <Popover
                          content={
                            <Button
                              type="danger"
                              style={{ width: "100%" }}
                              onClick={onDeleteAttachment.bind(this, item.id)}
                            >
                              Удалить
                            </Button>
                          }
                          title="Удаление файла"
                          trigger="click"
                        >
                          <Button type="dashed" shape="circle" icon="close" />
                        </Popover>
                      </Tooltip>
                    ) : null
                  ]}
                >
                  <List.Item.Meta
                    avatar={
                      <Avatar
                        style={{
                          backgroundColor: "transparent",
                          color: fileAttributes.color
                        }}
                        icon={fileAttributes.icon}
                        size="large"
                      />
                    }
                    title={fileLink(item.url, item.name, fileAttributes.target)}
                    description={`${moment(item.created_at).format("LLL")} - ${item.file_size}`}
                  />
                </List.Item>
              );
            }}
          />
        </Col>
      </Row>
    );
  }
}

Attachments.propTypes = {
  orderAttachments: PropTypes.arrayOf(PropTypes.object),
  total: PropTypes.number,
  discount: PropTypes.object,
  shippingCost: PropTypes.number,
  amountDiscountCode: PropTypes.number,
  onRemoveVariant: PropTypes.func
};
export default Attachments;
