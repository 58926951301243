// @flow
import React, { Component, lazy, Suspense } from "react";
import { Row, Col, Button, Spin, Tabs, Card, Empty } from "antd";
import Calendar from "./Calendar/Calendar";
import Gantt from "./Gantt";
import Kanban from "./Kanban";

const Statistic = lazy(() => import("./Statistic"));
const renderLoader = () => <Spin />;

const { TabPane } = Tabs;

function callback(key) {
  console.log(key);
}

class Container extends Component {
  render() {
    const {
      currentUser,
      onDrawerToggle,
      // params
      fetchParams,
      // statistic
      fetchTasks,
      fetchStatistic,
      isTasksLoading,
      isStatisticLoading,
      tasks,
      countOverdueTasks,
      onChangeTasksAssigneeType,
      tasksStatistic,
      taskSeries,
      fetchTasksParams,
      fetchStatisticParams,
      onChangeStatisticDateRange,
      onChangeStatisticPeriod,
      onChangeStatisticDepartments,
      projectSeries,
      projectsStatistic,
      ordersStatistic,
      ticketSeries,
      // entity_tasks
      entityTaskSeries,
      // intime_statistic
      isIntimeStatisticLoading,
      intimeStatistic,
      // services
      services,
    } = this.props;
    const operations = <Button onClick={onDrawerToggle}>Новости</Button>;
    return (
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Tabs
            defaultActiveKey="1"
            onChange={this.onChange}
            tabBarExtraContent={operations}
          >
            <TabPane tab="Статистика" key="1">
              <Suspense fallback={renderLoader()}>
                {/* <Card>
                  <Empty description={<span>В разработке</span>}></Empty>
                </Card> */}
                <Statistic
                  currentUser={currentUser}
                  fetchTasks={fetchTasks}
                  isTasksLoading={isTasksLoading}
                  fetchStatisticParams={fetchStatisticParams}
                  fetchStatistic={fetchStatistic}
                  isStatisticLoading={isStatisticLoading}
                  tasks={tasks}
                  countOverdueTasks={countOverdueTasks}
                  onChangeTasksAssigneeType={onChangeTasksAssigneeType}
                  tasksStatistic={tasksStatistic}
                  taskSeries={taskSeries}
                  fetchTasksParams={fetchTasksParams}
                  onChangeStatisticDateRange={onChangeStatisticDateRange}
                  onChangeStatisticPeriod={onChangeStatisticPeriod}
                  onChangeStatisticDepartments={onChangeStatisticDepartments}
                  projectSeries={projectSeries}
                  projectsStatistic={projectsStatistic}
                  ordersStatistic={ordersStatistic}
                  ticketSeries={ticketSeries}
                  // entity_tasks
                  entityTaskSeries={entityTaskSeries}
                  // intime_statistic
                  isIntimeStatisticLoading={isIntimeStatisticLoading}
                  intimeStatistic={intimeStatistic}
                  // services
                  services={services}
                />
              </Suspense>
            </TabPane>
          </Tabs>
        </Col>
      </Row>
    );
  }
}

export default Container;
