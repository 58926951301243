// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { Layout, Menu, Dropdown, Avatar, Icon, Tooltip } from "antd";
import NoticeIcon from "../../components/NoticeIconTabs";
import update from "immutability-helper";
import SupportMessage from "./SupportMessage";

const { Header } = Layout;

class MainHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
      isUploading: false,
      message: {}
    };
  }

  onMenuClick = () => {};

  onOpenFeedback = () => {
    this.setState(
      {
        message: {
          support_message_type: "empty",
          url: window.location,
          attachments: []
        }
      },
      () => {
        this.setState({ isVisible: true });
      }
    );
  };

  onCancelFeedback = () => {
    this.setState({ isVisible: false });
  };

  onSaveFeedback = () => {
    this.setState({ isVisible: false }, () => {
      this.props.onSaveFeedback(this.state.message);
    });
  };

  onChangeFeedbackType = value => {
    this.setState({
      message: update(this.state.message, {
        support_message_type: { $set: value }
      })
    });
  };

  onChangeSubject = e => {
    this.setState({
      message: update(this.state.message, {
        subject: { $set: e.target.value }
      })
    });
  };

  onChangeDescription = e => {
    this.setState({
      message: update(this.state.message, {
        description: { $set: e.target.value }
      })
    });
  };

  onUploadAttachment = imageUrl => {
    // console.log(imageUrl)
    this.setState({
      message: update(this.state.message, {
        attachments: { $push: [imageUrl.file] }
      })
    });
  };

  onDeleteAttachment = idx => {
    this.setState({
      message: update(this.state.message, {
        attachments: { $splice: [[idx, 1]] }
      })
    });
  };

  onViewMore = () => {
    this.props.history.push(`/support_messages`);
  };

  onItemClick = item => {
    this.props.history.push(`/support_messages/${item.id}`);
  };

  render() {
    const { isVisible, isUploading, message } = this.state;
    const {
      isSidebarCollapsed,
      onToggleSideBar,
      currentMember,
      supportMessages,
      onLogout
    } = this.props;
    const menu = (
      <Menu className={"menu"} selectedKeys={[]} onClick={this.onMenuClick}>
        <Menu.Item>
          <Link to={"/profile"}>Профиль</Link>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="logout" onClick={onLogout}>
          <Icon type="logout" />
          Выход
        </Menu.Item>
      </Menu>
    );

    return (
      <div>
        <Header
          className="main-header"
          style={{ position: "fixed", zIndex: 1, width: "100%" }}
        >
          <Tooltip
            placement="bottom"
            title={isSidebarCollapsed ? "Развернуть меню" : "Свернуть меню"}
          >
            <Icon
              className="trigger custom_trigger"
              type={isSidebarCollapsed ? "menu-unfold" : "menu-fold"}
              onClick={onToggleSideBar}
            />
          </Tooltip>
          <div
            className={"right"}
            style={{
              paddingRight: `${isSidebarCollapsed ? "100px" : "220px"}`
            }}
          >
            {/* <NoticeIcon count={supportMessages.length}>
              <NoticeIcon.Tab
                tabKey="notification"
                count={supportMessages.length}
                list={supportMessages}
                title={"Сообщения"}
                emptyText={"новых сообщений нет"}
                locale={{
                  viewMore: "Создать сообщение",
                  create: "Открыть сообщения"
                }}
                onItemClick={this.onItemClick}
                onViewMore={this.onViewMore}
                showOnViewMore
                showOnCreate
                onCreate={this.onOpenFeedback}
              />
            </NoticeIcon> */}

            {currentMember ? (
              <Dropdown overlay={menu}>
                <span className={`${"action"} ${"account"}`}>
                  <Avatar
                    size="default"
                    className={"avatar"}
                    src={currentMember.avatar}
                  />
                  <span className={"name"}>{currentMember.name}</span>
                </span>
              </Dropdown>
            ) : null}
          </div>
          <SupportMessage
            visible={isVisible}
            message={message}
            onSave={this.onSaveFeedback}
            onCancel={this.onCancelFeedback}
            onChangeFeedbackType={this.onChangeFeedbackType}
            onChangeSubject={this.onChangeSubject}
            onChangeDescription={this.onChangeDescription}
            // attachments
            isUploading={isUploading}
            onUploadAttachment={this.onUploadAttachment}
            onDeleteAttachment={this.onDeleteAttachment}
          />
        </Header>
      </div>
    );
  }
}

MainHeader.propTypes = {
  isLoading: PropTypes.bool,
  isSidebarCollapsed: PropTypes.bool,
  onToggleSideBar: PropTypes.func,
  onLogout: PropTypes.func,
  currentMember: PropTypes.object
};

export default withRouter(MainHeader);
