// @flow
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { Table, Button, PageHeader, Tooltip, Input } from "antd";
import { Pagination } from "antd";
import { Row, Col, Tag, Badge } from "antd";

import PropTypes from "prop-types";
import AssigneesCell from "../../../components/AssigneesCell";
import NoticeIcon from "../../../components/NoticeIcon";

import moment from "moment";
import "moment/locale/ru";
import Filter from "./Filter";

const Search = Input.Search;

function statuses(status) {
  switch (status) {
    case "pending":
      return "error";
    case "processing":
      return "processing";
    case "finished":
      return "success";
    default:
      return "default";
  }
}

function serviceTag(item) {
  return (
    <Tooltip
      key={`service-${item.entity_service.id}`}
      placement="rightTop"
      title={
        item.entity_service ? item.entity_service.service.extra.category : null
      }
    >
      <Tag color="#108ee9">{item.entity_service.service.name}</Tag>
    </Tooltip>
  );
}

class EntityTasksList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFilterVisible: false
    };
  }

  onFilterToggle = () => {
    this.setState({ isFilterVisible: !this.state.isFilterVisible });
  };

  onChangeTable = (pagination, filters, sorter) => {
    let direction = "desc";
    if (sorter.order === "descend") {
      direction = "desc";
    } else if (sorter.order === "ascend") {
      direction = "asc";
    }
    this.props.onSortBy(sorter.field, direction);
  };

  onItemClick = item => {
    this.props.history.push(`/entity_tasks/${item.parent_id}/edit`);
  };

  onClearNotices = () => {
    this.props.onClearNotices();
  };

  render() {
    const { isFilterVisible } = this.state;
    const {
      loading,
      entityTasks,
      meta,
      fetchParams,
      notices,
      onSearch,
      onChangePage,
      onChangeLimit,
      // filters
      onClearFilters,
      onChangeFilterStatus,
      onChangeFilterPeriod,
      onChangeFilterUsers,
      onChangeFilterMembers
    } = this.props;
    const columns = [
      {
        title: "#",
        dataIndex: "id",
        key: "number",
        render: (id, record) => (
          <Link to={`/entity_tasks/${id}/edit`}>{id}</Link>
        ),
        align: "center",
        width: "5%"
      },
      {
        title: "Статус",
        dataIndex: "state",
        render: (state, record) => <Badge status={statuses(record.status)} />,
        key: "state",
        align: "center",
        width: "5%"
      },
      {
        title: "План",
        dataIndex: "plan",
        render: plan =>
          plan ? (
            <span style={{ fontSize: 13 }}>{moment(plan).format("LLL")}</span>
          ) : null,
        sorter: true,
        sortDirections: ["ascend", "descend"],
        key: "plan",
        align: "center",
        width: "10%"
      },
      {
        title: "Начало",
        dataIndex: "start",
        render: start =>
          start ? (
            <span style={{ fontSize: 13 }}>{moment(start).format("LLL")}</span>
          ) : null,
        sorter: true,
        sortDirections: ["ascend", "descend"],
        key: "start",
        align: "center",
        width: "10%"
      },
      {
        title: "Окончание",
        dataIndex: "finish",
        render: finish =>
          finish ? (
            <span style={{ fontSize: 13 }}>{moment(finish).format("LLL")}</span>
          ) : null,
        sorter: true,
        sortDirections: ["ascend", "descend"],
        key: "finish",
        align: "center",
        width: "10%"
      },
      {
        title: "Объект",
        dataIndex: "entity",
        render: (entity, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>
              <Link to={`/entities/${entity.id}/edit`}>{entity.name}</Link>
            </span>
            <span style={{ opacity: "0.7" }}>
              {record.entity ? record.entity.address : null}
            </span>
          </div>
        ),
        align: "center",
        width: "25%"
      },
      {
        title: "Раздел",
        dataIndex: "entity_task_items",
        key: "entity_task_items",
        render: (entity_task_items, record) => {
          return entity_task_items.map(item => {
            return serviceTag(item);
          });
        },
        align: "center",
        width: "20%"
      },
      {
        title: "Ответственный",
        dataIndex: "assignees",
        render: (assignees, record) => <AssigneesCell assignees={assignees} />,
        align: "center",
        width: "15%"
      }
    ];

    const faqButton = (
      <Button
        type={"ghost"}
        key="7"
        icon="question"
        onClick={this.props.onFaqToggle}
      />
    );

    let filterButton = (
      <Button
        type={
          fetchParams.startDate || fetchParams.status || fetchParams.users
            ? "primary"
            : "ghost"
        }
        key="2"
        icon="filter"
        onClick={this.onFilterToggle}
      />
    );

    const unread =  notices.filter(function(item) {
      return item.read == false;
    });

    const noticeIcon = (
      <NoticeIcon
        key="5"
        count={unread.length}
        onItemClick={this.onItemClick}
        list={notices}
        showClear
        onClear={this.onClearNotices}
        emptyText={"новых комментариев нет"}
      />
    );

    return (
      <PageHeader
        className="page-header-with-notices"
        onBack={() => window.history.back()}
        title={`Регламент`}
        subTitle={
          fetchParams.startDate
            ? `c ${fetchParams.startDate} по ${fetchParams.endDate}`
            : null
        }
        extra={[
          faqButton,
          noticeIcon,
          <Search
            allowClear
            key="1"
            placeholder="поиск..."
            value={fetchParams.search}
            onChange={onSearch}
            style={{ width: 250 }}
          />,
          filterButton
        ]}
        footer={
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col span={24}>
              <Table
                size={"middle"}
                loading={loading}
                pagination={false}
                columns={columns}
                dataSource={entityTasks}
                onChange={this.onChangeTable}
                rowKey="id"
              />
            </Col>
            <Col
              span={24}
              style={{ paddingTop: "15px", paddingBottom: "15px" }}
            >
              <Pagination
                showSizeChanger
                pageSize={fetchParams.limit}
                current={meta.current_page}
                onChange={onChangePage}
                onShowSizeChange={onChangeLimit}
                total={meta.total_count}
              />
            </Col>
          </Row>
        }
      >
        <Filter
          visible={isFilterVisible}
          status={fetchParams.status}
          startDate={fetchParams.startDate}
          endDate={fetchParams.endDate}
          users={fetchParams.users}
          onClose={this.onFilterToggle}
          onClearFilters={onClearFilters}
          onChangeStatus={onChangeFilterStatus}
          onChangePeriod={onChangeFilterPeriod}
          onChangeUsers={onChangeFilterUsers}
          onChangeMembers={onChangeFilterMembers}
        />
      </PageHeader>
    );
  }
}

EntityTasksList.propTypes = {
  entityTasks: PropTypes.arrayOf(PropTypes.object),
  meta: PropTypes.object,
  fetchParams: PropTypes.object
};

export default withRouter(EntityTasksList);
