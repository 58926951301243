// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Row,
  Col,
  List,
  Select,
  Form,
  Divider,
  DatePicker,
  Button,
  Avatar,
  Tooltip,
  InputNumber,
} from "antd";
import moment from "moment";
import "moment/locale/ru";
import _ from "lodash/array";
import SelectFetch from "../../../components/SelectFetch";
import Documents from "./Documents";
import Assignees from "../../../components/Assignees";
import User from "./User";

import Uploads from "../../../components/Uploads";

const FormItem = Form.Item;

const Option = Select.Option;

class Statuses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAddingAssignee: false,
      assignee: null,
    };
  }

  onUpload = ({ onSuccess, onError, file }) => {
    this.props.onUploadAttachment(file);
  };

  onUploadDefectAct = ({ onSuccess, onError, file }) => {
    this.props.onUploadDefectAct(file);
  };

  onOpenAddingAssignee = () => {
    this.setState({ isAddingAssignee: true });
  };

  onCloseAddingAssignee = () => {
    this.setState({ isAddingAssignee: false });
  };

  onSelectAssignee = (value) => {
    this.setState({ assignee: value });
  };

  onChangeAssignees = () => {
    const { assignee } = this.state;
    // const idx = _.findIndex(this.props.assignees, ["user.id", assignee.id]);
    const idx = _.findIndex(this.props.assignees, function (o) {
      return o.user.id == assignee.id;
    });
    if (assignee && idx == -1) {
      this.props.onCreateAssignee(this.state.assignee);
      this.setState({ isAddingAssignee: false, assignee: null });
    }
  };

  render() {
    const {
      currentMember,
      isNew,
      ticketId,
      isLoading,
      isUploading,
      getFieldDecorator,
      is_replaced,
      isTicketReplaceAvailable,
      isSupplier,
      dueBy,
      user,
      member,
      assignees,
      attachments,
      status,
      replaced_status,
      ticketPriority,
      incident,
      amount,
      onChangeStatus,
      onChangePiority,
      onChangeIncident,
      onChangeAmount,
      onChangeDueBy,
      onDeleteAttachment,
      onChangeReplacedStatus,
      // preUpload
      tempAttachments,
      onTempUpload,
      onDeleteTempAttachment,
      // assignees
      organisationAssignees,
      supervisorAssignees,
      customerAssignees,
      companyAssignees,
      isAssigneesLoading,
      onDeleteAssignee,
      onCreateAssignee,
    } = this.props;

    const { isAddingAssignee, assignee } = this.state;

    return (
      <React.Fragment>
        <Row gutter={10}>
          <Col md={{ span: 24 }} xs={{ span: 24 }}>
            <FormItem label={"Плановые дата и время"}>
              {getFieldDecorator("dueBy", {
                onChange: onChangeDueBy,
                initialValue: dueBy ? moment(dueBy) : null,
              })(
                <DatePicker
                  disabled={dueBy ? false : true}
                  placeholder="Будет расчитано автоматически"
                  format={"LLL"}
                  showTime
                  className="ant-btn-width-100"
                />
              )}
            </FormItem>
          </Col>
          {isNew ? null : (
            <Col span={12}>
              <FormItem label={"Статус"}>
                {getFieldDecorator("status", {
                  initialValue: status ? status : null,
                })(
                  <SelectFetch
                    placeholder="Статус"
                    onChange={onChangeStatus}
                    selected={status ? status : null}
                    url={`/tickets/${ticketId}/statuses`}
                  />
                )}
              </FormItem>
            </Col>
          )}
          <Col span={12}>
            <FormItem label={"Приоритет"}>
              {getFieldDecorator("ticketPriority", {
                initialValue: ticketPriority ? ticketPriority : null,
                rules: [
                  {
                    required: true,
                    message: "Введите приоритет!",
                  },
                ],
              })(
                <SelectFetch
                  showSearch
                  placeholder="Приоритет"
                  onChange={onChangePiority}
                  selected={ticketPriority ? ticketPriority : null}
                  url={`/ticket_priorities/search/?`}
                />
              )}
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem label={"Тип инцидента"}>
              {getFieldDecorator("incident", {
                initialValue: incident,
                onChange: onChangeIncident,
                rules: [
                  {
                    required: true,
                    message: "Введите тип инцидента!",
                  },
                ],
              })(
                <Select
                  placeholder="Тип инцидента"
                  optionFilterProp="children"
                  filterOption={false}
                >
                  <Option value={"by_ticket"}>выезд по заявке</Option>
                  <Option value={"by_schedule"}>выезд по регламенту</Option>
                  <Option value={"by_payment"}>платный выезд</Option>
                </Select>
              )}
            </FormItem>
          </Col>
        </Row>

        <Row gutter={10}>
          {isSupplier ? (
            <Col span={24}>
            <Assignees
              isReadOnly={true}
              isAssigneesLoading={isAssigneesLoading}
              assigneeType="company"
              assignees={companyAssignees}
              onCreateAssignee={onCreateAssignee}
              onDeleteAssignee={onDeleteAssignee}
            />
            </Col>
          ) : (
            <Col span={24}>
            <Assignees
              isReadOnly={true}
              isAssigneesLoading={isAssigneesLoading}
              assigneeType="organisation"
              assignees={organisationAssignees}
              onCreateAssignee={onCreateAssignee}
              onDeleteAssignee={onDeleteAssignee}
            />
            </Col>
          )}
          
        </Row>
        <Row gutter={10}>
          <Col span={24}>
            <Assignees
              title={"От заказчика"}
              isReadOnly={isNew ? true : false}
              isAssigneesLoading={isAssigneesLoading}
              assigneeType="customer"
              assignees={customerAssignees}
              onCreateAssignee={onCreateAssignee}
              onDeleteAssignee={onDeleteAssignee}
            />
          </Col>
        </Row>
        {isTicketReplaceAvailable ? (
        <Row gutter={10}>
          <Col span={24}>
            <Uploads
              title={"Файлы"}
              currentMember={currentMember}
              onUpload={onTempUpload}
              isUploading={isUploading}
              attachments={tempAttachments}
              onDeleteAttachment={onDeleteTempAttachment}
            />
          </Col>
        </Row>
        ) : null}
      </React.Fragment>
    );
  }
}
Statuses.propTypes = {
  invoice: PropTypes.object,
  getFieldDecorator: PropTypes.func,
};

export default Statuses;
