// @flow
import React, { Component } from "react";
import { withRouter } from 'react-router'
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Actions from "../../actions/session";
import RecoveryForm from "../../components/RecoveryForm";


class Recovery extends Component {
  static contextTypes = {
    router: PropTypes.object
  };

  handleRecovery = data => {
    const { history: { push } } = this.props;
    this.props.dispatch(Actions.recovery(data, push));
  };

  render() {
    return <RecoveryForm isRecovered={this.props.isRecovered} errors={this.props.errors} onSubmit={this.handleRecovery} />;
  }
}

Recovery.propTypes = {
  dispatch: PropTypes.func
};

const mapStateToProps = state => ({
  isRecovered: state.session.isRecovered,
  errors: state.session.errors
});

export default connect(mapStateToProps)(withRouter(Recovery));
