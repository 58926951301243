// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Actions from "../../actions/customer";
import Form from "./Form";

class Customer extends Component {
  componentWillMount = () => {
    this.props.dispatch(Actions.fetchCustomer());
  };

  onSave = () => {
    this.props.dispatch(Actions.onUpdate());
  };

  onClose = () => {
    this.props.history.push(`/`);
  };


  render() {
    const { isLoading, customer } = this.props;
    return (
      <Form
        isLoading={isLoading}
        customer={customer}
        onSave={this.onSave}
        onClose={this.onClose}
      />
    );
  }
}

Customer.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  customer: PropTypes.object,
  isLoading: PropTypes.bool
};

const mapStateToProps = state => ({
  isLoading: state.customer.isLoading,
  customer: state.customer.customer
});

export default connect(mapStateToProps)(Customer);
