import update from "immutability-helper";

const initialState = {
  posts: [],
  post: {},
  meta: {
    total_pages: 1,
    current_page: 1,
    total_count: 1
  },
  fetchParams: {
    sort: null,
    direction: null,
    page: 1,
    limit: 15
  },
  errors: false,
  isLoading: true
};

export default function(state = initialState, action) {
  switch (action.type) {
    case "FETCH_POSTS_REQUEST":
      return {
        ...state,
        isLoading: true
      };
    case "FETCH_POSTS":
      return {
        ...state,
        posts: action.response.data,
        meta: action.response.meta,
        isLoading: false
      };
    case "FETCH_POST_REQUEST":
      return {
        ...state,
        isLoading: true
      };
    case "FETCH_POST":
      return {
        ...state,
        post: action.response.data,
        isLoading: false
      };
    case "PATCH_POST_REQUEST":
      return {
        ...state,
        isLoading: true,
        errors: false
      };
    case "PATCH_POST":
      return {
        ...state,
        post: action.response.data,
        isLoading: false,
        errors: false
      };

    case "PATCH_POST_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true
      };

    case "POST_REQUEST":
      return {
        ...state,
        errors: false,
        isLoading: true
      };
    case "POST_SUCCEED":
      return {
        ...state,
        errors: false,
        post: action.response.data,
        isLoading: false
      };

    case "POST_FAILURE":
      return {
        ...state,
        errors: true,
        isLoading: false
      };

    case "PUBLISH_POST_REQUEST":
      return {
        ...state,
        errors: false,
        isLoading: true
      };
    case "PUBLISH_POST_SUCCEED":
      return {
        ...state,
        errors: false,
        post: action.response.data,
        isLoading: false
      };

    case "PUBLISH_POST_FAILURE":
      return {
        ...state,
        errors: true,
        isLoading: false
      };

    case "DELETE_POST_REQUEST":
      return {
        ...state,
        isLoading: true,
        errors: false
      };
    case "DELETE_POST":
      return {
        ...state,
        isLoading: false,
        errors: false
      };
    case "DELETE_POST_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true
      };

    case "POST_NEW":
      return {
        ...state,
        errors: false,
        post: { post_available: "for_all", post_user_roles: [] },
        isLoading: false
      };

    case "POST_CHANGE_POST_TYPE":
      return update(state, {
        post: { post_type: { $set: action.value } }
      });

    case "POST_CHANGE_POST_AVAILABLE":
      return update(state, {
        post: { post_available: { $set: action.value } }
      });

    case "POST_CHANGE_TITLE":
      return update(state, {
        post: { title: { $set: action.value } }
      });

    case "POST_CHANGE_BODY":
      return update(state, {
        post: { body: { $set: action.value } }
      });

    case "POST_CHANGE_POST_USER_ROLES":
      return update(state, {
        post: { post_user_roles: { $set: action.values } }
      });

    default:
      return state;
  }
}
